import React, {useState} from "react";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import FormField from "@amzn/awsui-components-react/polaris/form-field";

interface FileUploadProps {
    onchange: (detail: any) => void
    value: any
    label: any
    description: any
    errorText: any
}

export default function FileUploadForm(props: FileUploadProps) {
    return (
        <FormField
            label={props.label}
            description={props.description}
        >
            <FileUpload
                onChange={props.onchange}
                value={props.value}
                i18nStrings={{
                    uploadButtonText: e =>
                        e ? "Choose files" : "Choose the file what you want to upload",
                    dropzoneText: e =>
                        e
                            ? "Drop files to upload"
                            : "Drop file to upload",
                    removeFileAriaLabel: e =>
                        `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error"
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                errorText={props.errorText}
                constraintText="File size should not exceed 4 MB"
            />
        </FormField>
    );
}
