import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation";
import ToolsPanel from "../TableView/ToolsPanel";
import React, {useState} from 'react';
import {appLayoutLabels} from "../Common/labels";
import SpecialTable from "../Common/SpecialTable";
import SearchDashboard from "./SearchDashboard";
import SelfTopNavigation from "../topNavigation/topNavigation";

export default function SearchLayout() {
    const [toolsOpen, setToolsOpen] = useState(false);
    const toolsChangeHandler = () => {
        setToolsOpen(!toolsOpen);
    };
    const [navigationOpen, setNavigationOpen] = useState(false);
    const navigationChangeHandler = () => {
        setNavigationOpen(!navigationOpen)
    };
    return (
        // <body>
        //         <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            content={<SearchDashboard/>}
            breadcrumbs={<Breadcrumbs items={[{text: 'Search Result', href: ''}]}/>}
            // notifications={<AlertBar type={'info'} header={'Aladdin DashBoard Lunch New Version'}
            //                          content={'From April 1st, all PainPoint/GoodIdea submissions will use the Aladdin Dashboard. Data before April 1st will be gradually synchronized from Quip to the System'} dismissible={true}/>}
            contentType="default"
            // maxContentWidth={Number.MAX_VALUE}
            toolsOpen={toolsOpen}
            onNavigationChange={navigationChangeHandler}
            navigationOpen={navigationOpen}
            onToolsChange={toolsChangeHandler}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
        />
        // </body>
    )
}