import React, {useState, useRef, useEffect} from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Box from "@amzn/awsui-components-react/polaris/box";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import axios from 'axios'
import AutoLink, {DetailPopover, updateDateToString, UserDetail} from "../Common/AutoLink";
import Link from '@amzn/awsui-components-react/polaris/link';
import MarkDown from "../Common/ReactMarkDown";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import Form from "@amzn/awsui-components-react/polaris/form";
import {AtDemo} from "../table/Demo";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import Table from "@amzn/awsui-components-react/polaris/table";
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import Multiselect, {MultiselectProps} from "@amzn/awsui-components-react/polaris/multiselect";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {pushValues} from "../Common/Token";

export interface CommentsDetail {
    Comments: any
    loginUser: string
    CommentsValue: string
    CommentsonChange: (event: any) => void
    CommentsReset: () => void
    CommentsSubmit: () => void
    AreaInvalid: boolean
    WorkLogs: any
    MarkDownValue: boolean
    setMarkDownValue: (anc: any) => void
    updateDetail: () => void
    updateInformation: () => void
    sustainability_id: string
    OwnerOptions: any[]
    onChangeMention: () => void
    SubmitLoading: boolean
    ItemState: string
    PromoteProps: any
    ChildrenList: any[]
    ParentId: string
}


export default function SustainabilityDetailTab(props: CommentsDetail) {
    const [poundList, setPoundList] = useState([
        {label: "Pain Point", value: "Pain Point"},
        {label: "Good Idea", value: "Good Idea"}
    ]);
    const [
        selectedTargetGroup,
        setSelectedTargetGroup
    ] = useState<MultiselectProps.Options>([]);
    const [
        targetGroupOptions,
        setTargetGroupOptions
    ] = useState<MultiselectProps.Options>([]);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/personal",
        }).then(res => {
            setTargetGroupOptions(pushToTagsWithList(res.data))
        })
    }, [])
    const columnDefinitions = [
        {
            id: "Markdown",
            header: "Markdown",
            cell: item => item.Markdown || "-",
            sortingField: "Markdown",
            isRowHeader: true
        },
        {
            id: "Output",
            header: "Output",
            cell: item => (
                <MarkDown textContent={item.Output} height='188px'/>
            ),
            sortingField: "Output"
        }
    ]
    const [markdownConfig, setMarkdownConfig] = useState<any>([
        {
            Markdown: "*italic text*",
            Output: "*italic text*",
        }, {
            Markdown: "**bold text**",
            Output: "**bold text**",
        }, {
            Markdown: "`inline code`",
            Output: "`inline code`",
        }, {
            Markdown: "~~strikethrough text~~",
            Output: "~~strikethrough text~~",
        }, {
            Markdown: "[hyperlink](https://aladdin.amazon.dev/)",
            Output: "[hyperlink](https://aladdin.amazon.dev/)",
        }, {
            Markdown: "![image](/favicon-16x16.png)",
            Output: "![image](https://taskei.amazon.dev/favicon-16x16.png)",
        }, {
            Markdown: "> block quotation",
            Output: "> block quotation",
        }, {
            Markdown: `\`\`\`
code block
\`\`\``,
            Output: `\`\`\`
code block
\`\`\``,
        }, {
            Markdown: "| table header | ... |\n" +
                "| - | - |\n" +
                "| table cell | ... |",
            Output: "| table header | ... |\n" +
                "| - | - |\n" +
                "| table cell | ... |",
        }
    ])
    const scrollComment = useRef<HTMLDivElement>(null)
    const [promoteValue, setPromoteValue] = useState("Global");
    const [promoteReason, setPromoteReason] = useState("")
    const [promoteLoading, setPromoteLoading] = useState(false)

    const [editOption, setEditOption] = useState<any>([])
    const [editCommentValue, setEditCommentValue] = useState<any>([])
    const [markdownEditOption, setMarkdownEditOption] = useState<any>([])

    const markdownEditOptionOnChange = (location, value) => {
        const newState = [...markdownEditOption];
        newState[location] = value;
        setMarkdownEditOption(newState);
    };
    const editOptionOnChange = (location, value) => {
        const newState = [...editOption];
        newState[location] = value;
        setEditOption(newState);
    };
    const editCommentValueOnChange = (location, value) => {
        const newState = [...editCommentValue];
        newState[location] = value;
        setEditCommentValue(newState);
    }
    const submitPromotion = () => {
        if (promoteValue == 'Personal') {
            if (selectedTargetGroup.length == 0) {
                alert('Please select least one target group!')
                return
            }
        }
        setPromoteLoading(true)
        axios({
            method: "POST",
            data: {
                id: props.sustainability_id,
                PromoteValue: promoteValue,
                PromoteReason: promoteReason,
                PromoteType: 'Sustainability',
                TargetGroup: promoteValue == 'Personal' ? pushValues(selectedTargetGroup) : null
            },
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/promote"
        }).then(res => {
            setPromoteReason('')
            setPromoteLoading(false)
            setSelectedTargetGroup([])
            setPromoteValue('Global')
            props.updateDetail()
            alert('Successful!')
        })
    }
    const pushToTagsWithList = (personalGroup) => {
        let result: any = []
        personalGroup.map((item) => {
            result.push({
                value: item.PermissionName,
                label: item.PermissionName,
                description: item.Description,
            })
        })
        return result
    }

    const updateComment = (commentId, index) => {
        axios({
            method: "PUT",
            data: {
                Type: 'Sustainability',
                CommentsContent: editCommentValue[index],
                CommentsMarkdown: markdownEditOption[index],
                CommentsUser: props.loginUser,
                CommentsID: commentId
            },
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/comments/" + props.sustainability_id
        }).then(res => {
            editOptionOnChange(index, false)
            props.updateInformation()
            props.updateDetail()
        })
    }

    return (
        <Tabs tabs={[
            {
                label: "Comments",
                id: "Comments",
                content:
                    <div>
                        <Container
                            header={
                                <Grid
                                    gridDefinition={[
                                        {colspan: 1},
                                        {colspan: 9},
                                        {colspan: 2}
                                    ]}
                                >
                                    <Box textAlign="center" variant="div" margin={{top: 'xs'}}>
                                        <UserDetail login={props.loginUser}/>
                                    </Box>
                                    <div ref={scrollComment}>
                                        <TextContent>
                                            <h5>Add Comments</h5>
                                            <p>Markdown is support now!</p>
                                            <Popover
                                                fixedWidth
                                                size="large"
                                                content={
                                                    <TextContent>
                                                        <strong>Common Markdown Format Guide</strong>
                                                        <p>Below are some examples of the most common Markdown
                                                            syntax.</p>
                                                        <Table
                                                            items={markdownConfig}
                                                            columnDefinitions={columnDefinitions}
                                                            sortingDisabled
                                                            variant="embedded"
                                                        />
                                                    </TextContent>
                                                }
                                            >
                                                Common Markdown Format Guide (Click here!)
                                            </Popover>
                                        </TextContent>
                                    </div>
                                    <div>
                                        <Badge color="blue">Correspondence</Badge>
                                    </div>
                                </Grid>
                            }>
                            <Grid
                                gridDefinition={[
                                    {colspan: 1},
                                    {colspan: 10},
                                    {colspan: 1}
                                ]}
                            >
                                <div></div>
                                <div>
                                    <SpaceBetween size="l">
                                        {props.MarkDownValue ?
                                            <ColumnLayout columns={2}>
                                                <Textarea
                                                    name='markdown-area'
                                                    invalid={props.AreaInvalid}
                                                    value={props.CommentsValue}
                                                    onChange={props.CommentsonChange}
                                                    rows={8}
                                                />
                                                <div style={{
                                                    border: '2px solid rgb(125 137 152)',
                                                    borderRadius: '5px',
                                                    minHeight: '184px',
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden'
                                                }}>
                                                    <div style={{marginLeft: "10px", marginRight: "10px"}}>
                                                        <MarkDown textContent={props.CommentsValue} height='188px'/>
                                                    </div>
                                                </div>
                                            </ColumnLayout> :
                                            <TextContent>
                                                {/*<Textarea*/}
                                                {/*    invalid={props.AreaInvalid}*/}
                                                {/*    value={props.CommentsValue}*/}
                                                {/*    onChange={props.CommentsonChange}*/}
                                                {/*    rows={5}*/}
                                                {/*/>*/}
                                                <AtDemo userList={props.OwnerOptions} poundList={poundList}
                                                        onChange={props.onChangeMention}
                                                />
                                            </TextContent>
                                        }
                                        <ColumnLayout columns={2}>
                                            <Box textAlign="right" variant="div">
                                                <Toggle
                                                    onChange={props.setMarkDownValue}
                                                    checked={props.MarkDownValue}
                                                >
                                                    MarkDown
                                                </Toggle>
                                            </Box>
                                            <Box textAlign="right" variant="div">
                                                <Button variant="link" onClick={props.CommentsReset}>
                                                    Reset
                                                </Button>
                                                <Button onClick={props.CommentsSubmit} loading={props.SubmitLoading}
                                                        variant="primary">Submit</Button>
                                            </Box>
                                        </ColumnLayout>
                                    </SpaceBetween>
                                </div>
                                <div></div>
                            </Grid>
                        </Container>
                        <br/>
                        {
                            // props.Comments.length === 0 ? console.log(props.Comments) : console.warn(props.Comments)
                            props.Comments.length === 0 ? <></> : (props.Comments.slice().reverse().map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Container
                                                key={index}
                                                header={
                                                    <div>
                                                        <Grid
                                                            gridDefinition={[
                                                                {colspan: 1},
                                                                {colspan: 9},
                                                                {colspan: 2}
                                                            ]}
                                                        >
                                                            <Box textAlign="center" variant="div"
                                                                 margin={{top: 'xs'}}>
                                                                <UserDetail login={item.CommentsUser}/>
                                                            </Box>
                                                            <div>
                                                                <TextContent>
                                                                    <h5>
                                                                        <Link
                                                                            href={'https://phonetool.amazon.com/users/' + item.CommentsUser}>{item.CommentsUser}@</Link>
                                                                    </h5>
                                                                    <p>&nbsp;Update
                                                                        in {item.hasOwnProperty('LastUpdateDate') ? updateDateToString(item.CommentsData) + ' (Edited in ' + updateDateToString(item.LastUpdateDate) + ')' : updateDateToString(item.CommentsData)}</p>
                                                                </TextContent>
                                                            </div>
                                                            <div>
                                                                <Badge color="blue">Correspondence</Badge>
                                                            </div>
                                                        </Grid>
                                                    </div>
                                                }>
                                                <Grid
                                                    gridDefinition={[
                                                        {colspan: 1},
                                                        {colspan: 10},
                                                        {colspan: 1}
                                                    ]}
                                                >
                                                    <div></div>
                                                    <SpaceBetween size="l">
                                                        {
                                                            editOption[index] ?
                                                                <Textarea
                                                                    value={editCommentValue[index]}
                                                                    onChange={({detail}) => editCommentValueOnChange(index, detail.value)}
                                                                    rows={5}
                                                                />
                                                                :
                                                                item.CommentsMarkdown ?
                                                                    <MarkDown textContent={item.CommentsContent}
                                                                              height='188px'/> :
                                                                    <AutoLink text={item.CommentsContent}></AutoLink>
                                                        }
                                                        <Box textAlign="right" variant="div">
                                                            {item.CommentsUser == props.loginUser && !editOption[index] ?
                                                                <Button variant="link" onClick={() => {
                                                                    editOptionOnChange(index, editOption[index] ? !editOption[index] : true)
                                                                    editCommentValueOnChange(index, item.CommentsContent)
                                                                    markdownEditOptionOnChange(index, item.CommentsMarkdown)
                                                                }}>
                                                                    Edit
                                                                </Button>
                                                                : item.CommentsUser == props.loginUser && editOption[index] ?
                                                                    <ColumnLayout columns={2}>
                                                                        <Toggle
                                                                            onChange={({detail}) => markdownEditOptionOnChange(index, detail.checked)}
                                                                            checked={markdownEditOption[index]}
                                                                        >
                                                                            MarkDown
                                                                        </Toggle>
                                                                        <Box textAlign="right" variant="div">
                                                                            <Button variant="primary"
                                                                                    onClick={() => updateComment(item.CommentsID, index)}>Save
                                                                            </Button>
                                                                            <Button variant="link" onClick={() => {
                                                                                editOptionOnChange(index, editOption[index] ? !editOption[index] : false)
                                                                            }}>
                                                                                Cancel
                                                                            </Button>
                                                                        </Box>
                                                                    </ColumnLayout>
                                                                    : null
                                                            }
                                                        </Box>
                                                    </SpaceBetween>
                                                    <div></div>
                                                </Grid>
                                            </Container>
                                            <br/>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>

            },
            {
                label: "Work Logs",
                id: "Work Logs",
                content:
                    props.WorkLogs.length === 0 ? (
                        <Container>
                            <Box textAlign="center" color="inherit">
                                <b>No Work Log</b>
                                <Box
                                    padding={{bottom: "s"}}
                                    variant="p"
                                    color="inherit"
                                >
                                    No Work Log to display.
                                </Box>
                            </Box>
                        </Container>
                    ) : props.WorkLogs.map((items, index) => {
                        return (
                            <div key={index}>
                                <Container
                                    key={index}
                                    header={
                                        <div>
                                            <Grid
                                                gridDefinition={[
                                                    {colspan: 1},
                                                    {colspan: 9},
                                                    {colspan: 2}
                                                ]}
                                            >
                                                <Box textAlign="center" variant="div" margin={{top: 'xs'}}>
                                                    <UserDetail login={items.WorkLogsUser}/>
                                                </Box>
                                                <div>
                                                    <TextContent>
                                                        <h5>{items.WorkLogsUser}</h5>
                                                        <p>Update in {updateDateToString(items.WorkLogsData)}</p>
                                                    </TextContent>
                                                </div>
                                                <div>
                                                    <Badge color="blue">WorkLogs</Badge>
                                                </div>
                                            </Grid>
                                        </div>
                                    }>
                                    <Grid
                                        gridDefinition={[
                                            {colspan: 1},
                                            {colspan: 9},
                                            {colspan: 2}
                                        ]}
                                    >
                                        <div></div>
                                        <div>{items.WorkLogsContent}</div>
                                        <div></div>
                                    </Grid>
                                </Container>
                                <br/>
                            </div>
                        )
                    })
            },
            {
                label: "Promote Option",
                id: "Promote Option",
                content:
                    <Container>
                        <SpaceBetween size="xxl">
                            {props.ItemState == 'Approved' ? null : <Alert
                                statusIconAriaLabel="Error"
                                type="error"
                                header="It must be Approved"
                            >
                                If you want to promote the Kaizen Project, it must be Approved.
                            </Alert>}
                            {props.PromoteProps != null ? <Alert
                                statusIconAriaLabel="warning"
                                type="warning"
                                header="Do you want to overwrite the promote reason?"
                            >
                                The Sustainability Initiative was already promoted. Do you want to overwrite the
                                promote
                                reason? </Alert> : null}
                            <Form actions={
                                <SpaceBetween direction="horizontal" size="xxl">
                                    <Button variant="primary" onClick={() => submitPromotion()} loading={promoteLoading}
                                            disabled={!(props.ItemState == 'Approved')}>Update Project
                                        Promotion</Button>
                                </SpaceBetween>}>
                                <ColumnLayout columns={2}>
                                    <Box>
                                        <Box variant="awsui-key-label">Globle Sustainability Initiative Promote
                                            Option</Box>
                                        <SpaceBetween size="xxxs">
                                            <RadioGroup
                                                onChange={({detail}) => setPromoteValue(detail.value)}
                                                value={promoteValue}
                                                items={[
                                                    {
                                                        value: "Global",
                                                        label: "Kaizen Project",
                                                        // disabled: !props.permissionUser,
                                                        description: "It will push to Kaizen Project for sharing to other cluters."
                                                    },
                                                    {
                                                        value: "Personal",
                                                        label: "Personal Group Notice",
                                                        // disabled: !props.permissionUser,
                                                        description: "It will notice to target groups for sharing to other cluters by email."
                                                    },
                                                ]}
                                            />
                                            <FormField
                                            >
                                                <Multiselect
                                                    selectedOptions={selectedTargetGroup}
                                                    disabled={!(promoteValue == 'Personal')}
                                                    onChange={({detail}) =>
                                                        setSelectedTargetGroup(detail.selectedOptions)
                                                    }
                                                    options={targetGroupOptions}
                                                    placeholder="Choose options"
                                                />
                                            </FormField>

                                        </SpaceBetween>
                                    </Box>
                                    <Box>
                                        <Box variant="awsui-key-label">Please provide your promote reason:
                                            (Optional)</Box>
                                        <Textarea
                                            onChange={({detail}) => setPromoteReason(detail.value)}
                                            value={promoteReason}
                                            placeholder="This is a good idea for sharing with other regions"
                                            rows={5}
                                        />
                                        {/*<Box variant="awsui-key-label">Add identity</Box>*/}
                                        {/*<Box variant="p">Add identity or groups who have..........</Box>*/}
                                        {/*<Input*/}
                                        {/*    onChange={({detail}) => setSearchValue(detail.value)}*/}
                                        {/*    value={SearchValue}*/}
                                        {/*    placeholder="Search"*/}
                                        {/*    type="search"*/}
                                        {/*    disabled={true}*/}
                                        {/*/>*/}
                                    </Box>
                                </ColumnLayout>
                            </Form>
                        </SpaceBetween>
                    </Container>
            },
            {
                label: "Parent-Child",
                id: "Parent-Child",
                content:
                    <Container>
                        <SpaceBetween size="xxl">
                            {props.ParentId != null ? <Box>
                                <Box variant="h3">Parent ID</Box>
                                <DetailPopover uuid={props.ParentId} type={'Sustainability'}/>
                            </Box> : props.ChildrenList.length > 0 ?
                                <Box>
                                    <Box variant="h3">Children List({props.ChildrenList.length})</Box>
                                    <SpaceBetween size="xxl">
                                        {props.ChildrenList.map((item, index) => {
                                            return (
                                                <Box>
                                                    <Box variant="awsui-key-label">
                                                        Children {index + 1}:
                                                    </Box>
                                                    <DetailPopover uuid={item} type={'Sustainability'}/>
                                                </Box>
                                            )
                                        })}
                                    </SpaceBetween>
                                </Box>
                                : <Box textAlign="center"><img
                                    src={'https://test-wide.s3.us-east-2.amazonaws.com/bad62458-f8a4-11ee-9ed8-ee1889bc7e2c-piccolo'}/></Box>}
                        </SpaceBetween>
                    </Container>
            }
        ]}
        />
    )
}