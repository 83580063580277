import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation";
import AlertBar from "../Common/Bars";
import ToolsPanel from "../TableView/ToolsPanel";
import React, {useState} from 'react';
import {appLayoutLabels} from "../Common/labels";
import AnalyticsPage from "./AnalyticsPage";
import SelfTopNavigation from "../topNavigation/topNavigation";

export default function AnalyticsLayout() {
    const [toolsOpen, setToolsOpen] = useState(false);
    const toolsChangeHandler = () => {
        if (toolsOpen) {
            setToolsOpen(false);
        } else {
            setToolsOpen(true);
        }
    };
    return (
        // <body>
        //         <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            content={<AnalyticsPage/>}
            breadcrumbs={<Breadcrumbs items={[{text: 'Analytics', href: ''}]}/>}
            notifications={<AlertBar type={'info'} header={'Beta version for submission rate'}
                                     content={'Please ignor some small error in there, we will modify it as soon as possible!'}
                                     dismissible={true}/>}
            contentType="default"
            toolsOpen={toolsOpen}
            onToolsChange={toolsChangeHandler}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
            // maxContentWidth={Number.MAX_VALUE}
        />
        // </body>
    )
}