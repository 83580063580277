import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {ServiceNavigation} from "../navigation";
import AlertBar from "../Common/Bars";
import ToolsPanel from "../TableView/ToolsPanel";
import React, {useState} from 'react';
import {appLayoutLabels} from "../Common/labels";
import ReportDashboard from "./ReportDashboard";
import SelfTopNavigation from "../topNavigation/topNavigation";

export default function ReportLayout() {
    const [toolsOpen, setToolsOpen] = useState(false);
    const toolsChangeHandler = () => {
        if (toolsOpen) {
            setToolsOpen(false);
        } else {
            setToolsOpen(true);
        }
    };
    return (
        // <body>
        //         <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            content={<ReportDashboard/>}
            // notifications={<AlertBar type={'info'} header={'Aladdin DashBoard Lunch New Version'}
            //                          content={'From April 1st, all PainPoint/GoodIdea submissions will use the Aladdin Dashboard. Data before April 1st will be gradually synchronized from Quip to the System'} dismissible={true}/>}
            contentType="default"
            toolsOpen={toolsOpen}
            onToolsChange={toolsChangeHandler}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
            // maxContentWidth={Number.MAX_VALUE}
        />
        // </body>
    )
}