import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation";
import AlertBar from "../Common/Bars";
import ToolsPanel from "../TableView/ToolsPanel";
import React, {useState} from 'react';
import {appLayoutLabels} from "../Common/labels";
import CopyPainPoint from "./CopyPainPoint";
import CopySustainability from "./CopySustainability";

export default function CopyLayout(props) {
    const [itemType, setItemType] = useState(props.match.params.type)
    const [itemId, setItemId] = useState(props.match.params.id)
    const [toolsOpen, setToolsOpen] = useState(false);
    const toolsChangeHandler = () => {
        if (toolsOpen) {
            setToolsOpen(false);
        } else {
            setToolsOpen(true);
        }
    };
    return (
        // <body>
        //         <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            content={itemType == 'sustainability' ? <CopySustainability id={itemId}/> :
                <CopyPainPoint id={itemId}/>}
            breadcrumbs={<Breadcrumbs items={[{text: itemType == 'sustainability' ? 'Create Children Sustainability Initiative' : 'Create Children Pain Point && Good Idea', href: ''}]}/>}
            // notifications={<AlertBar type={'info'} header={'Aladdin DashBoard Lunch New Version'}
            //                          content={'From April 1st, all PainPoint/GoodIdea submissions will use the Aladdin Dashboard. Data before April 1st will be gradually synchronized from Quip to the System'} dismissible={true}/>}
            contentType="default"
            toolsOpen={toolsOpen}
            onToolsChange={toolsChangeHandler}
            maxContentWidth={Number.MAX_VALUE}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
        />
        // </body>
    )
}