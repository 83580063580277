import React from "react";
import Multiselect, {MultiselectProps} from "@amzn/awsui-components-react/polaris/multiselect";
import FormField from "@amzn/awsui-components-react/polaris/form-field";

export interface TagsProps {
    selectedOptions: MultiselectProps.Options
    handleTags: (detail: any) => void
    errorValue: string
    label: string
    constraintText:string
    options: any
}

export default function PainPointTags(props: TagsProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
        >
            <Multiselect
                selectedOptions={props.selectedOptions}
                onChange={props.handleTags}
                deselectAriaLabel={e => `Remove ${e.label}`}
                options={props.options}
                placeholder="Choose Tags"
                selectedAriaLabel="Selected"
                tokenLimit={3}
            />
        </FormField>

    );
}

export interface GroupTagsProps {
    selectedOptions: MultiselectProps.Options
    handleTags: (detail: any) => void
    errorValue: string
    label: string
    options: any
    constraintText: string
}

export function PainPointGroupTags(props: GroupTagsProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
        >
            <Multiselect
                tokenLimit={3}
                filteringType="auto"
                selectedOptions={props.selectedOptions}
                onChange={props.handleTags}
                deselectAriaLabel={e => `Remove ${e.label}`}
                options={props.options}
                placeholder="Choose Impact"
                selectedAriaLabel="Selected"
            />
        </FormField>

    );
}
