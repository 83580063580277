import Autosuggest from "@amzn/awsui-components-react/polaris/autosuggest";

export interface EmployeeAutoSuggestProps {
    autoSuggestValue: string
    options: any
    onChange: (event: any) => void
    invalid:boolean
}


export default function EmployeeAutoSuggest(props: EmployeeAutoSuggestProps) {
    return (
        <Autosuggest
            onChange={props.onChange}
            value={props.autoSuggestValue}
            options={props.options}
            invalid={props.invalid}
            enteredTextLabel={value => `Use: "${value}"`}
            ariaLabel="Autosuggest example with suggestions"
            placeholder="Enter value"
            empty="No matches found"
        />
    );
}

