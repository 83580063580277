import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import HomepageContent from './HomepageContent';
import './styles.scss';
import SelfTopNavigation from "../topNavigation/topNavigation";

export default function Homepage() {
    return (
        // <body>
        // <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            content={<HomepageContent/>}
            contentType="default"
            navigationHide={true}
            toolsHide={true}
            disableContentPaddings={true}
            maxContentWidth={Number.MAX_VALUE}
        />
        // </body>
    );
}
