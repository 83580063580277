import {useEffect, useState} from 'react';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from '@amzn/awsui-components-react/polaris/box';
import CopyText from "../Common/CopyText";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import ProgressBar from "@amzn/awsui-components-react/polaris/progress-bar";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Token from "../Common/Token";
import Link from "@amzn/awsui-components-react/polaris/link";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Button from "@amzn/awsui-components-react/polaris/button";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import EmployeeAutoSuggest from "../Common/AutoSuggest";
import Form from "@amzn/awsui-components-react/polaris/form";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import PainPointTags from "../Common/Tags";
import {MultiselectProps} from "@amzn/awsui-components-react/polaris/multiselect";
import axios from 'axios'
import {useHistory} from "react-router-dom";
import {AutoSuggestFormField} from "../Common/FormField";
import AutoLink, {updateDateToString} from "../Common/AutoLink";
import {DetailFavoriteFunction} from "../Common/ButtonFunctions";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {pushToTag, pushToTagsWithList, pushValues} from "../Common/Token";
import DetailTab from "./DetailTab";

export interface DEMO_PainPointDetail {
    Itemslist: any
    loginUser: string,
    updateDetail: () => void
    updateDateDoNotCloseModal: () => void
    permissionUser: boolean
    OwnerOptions: any[]
}

export default function DetailView(PainPointDetail: DEMO_PainPointDetail) {
    const history = useHistory()
    const [
        selectedLabelOptions,
        setSelectedLabelOptions
    ] = useState<MultiselectProps.Options>([]);
    const [secondReviewerValue, setSecondReviewerValue] = useState(PainPointDetail.Itemslist.SecondReviewer)

    const [
        selectedCategoriesOptions,
        setSelectedCategoriesOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        selectedPrioritiesOptions,
        setSelectedPrioritiesOptions
    ] = useState<MultiselectProps.Options>([]);
    const [Description, setDescription] = useState('')

    const [Countermeasure, setCountermeasure] = useState('')
    const [DescriptionValue, setDescriptionValue] = useState([
        {value: 'Good Catch'},
        {value: 'Modify'},
        {value: 'No'},
    ]);
    const [CountermeasureValue, setCountermeasureValue] = useState([
        {value: 'Feasible'},
        {value: 'Modify'},
        {value: 'No go'},
    ]);
    const handleStatusOwnerChange = (Status, Owner) => {
        setStatus(Status)
        setOwnerValue(Owner)
    }
    const [DetailsExpend, setDetailsExpend] = useState(true)
    const handleDetailsExpendChange = (detail) => {
        setDetailsExpend(detail.expanded)
    }
    const [ApprovalExpend, setApprovalExpend] = useState(true)
    const handleApprovalExpendChange = (detail) => {
        setApprovalExpend(detail.expanded)
    }
    const [DescriptionInvalid, setDescriptionInvalid] = useState(false)
    const [CountermeasureInvalid, setCountermeasureInvalid] = useState(false)
    const [Status, setStatus] = useState('')
    const [StatusOptions, setStatusOptions] = useState([
        {value: 'Assign'},
        {value: 'Working in Process'},
        {value: 'Pending'},
        {value: 'Resolved'}
    ])
    const [StatusInvalid, setStatusInvalid] = useState(false)

    const [OwnerValue, setOwnerValue] = useState('')
    const [OwnerInvalid, setOwnerInvalid] = useState(false)
    const [attachmentKey, setAttachmentKey] = useState('')
    const handleOwner = (value) => {
        setOwnerValue(value)
    }
    const handleSecondReviewer = (value) => {
        setSecondReviewerValue(value)
    }
    const [ownerLoading, setOwnerLoading] = useState(false)

    useEffect(() => {
        PainPointDetail.Itemslist.Attachment ?
            axios({
                method: "POST",
                data: {Key: PainPointDetail.Itemslist.Attachment},
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/attachment",
            }).then(res => {
                setAttachmentKey(res.data)
            }) : null
    }, [PainPointDetail])

    const handleDecisionChange = (DescriptionDecision, CountermeasureDecision) => {
        setDescription(DescriptionDecision)
        setCountermeasure(CountermeasureDecision)
    }
    const updateDetails = async () => {
        await axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/" + PainPointDetail.Itemslist.id,
        }).then(res => {
            setSelectedLabelOptions(pushToTagsWithList(res.data.Label))
            setSelectedCategoriesOptions(pushToTagsWithList(res.data.Category))
            setSelectedPrioritiesOptions(pushToTagsWithList(res.data.Priority))
        })
    }
    useEffect(() => {
        PainPointDetail.loginUser == PainPointDetail.Itemslist.SecondReviewer ? handleDecisionChange(PainPointDetail.Itemslist.CMDescription, PainPointDetail.Itemslist.CMCountermeasure) : (
            PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer ? handleDecisionChange(PainPointDetail.Itemslist.LMDescription, PainPointDetail.Itemslist.LMCountermeasure) : null
        )
        handleStatusOwnerChange(PainPointDetail.Itemslist.Status, PainPointDetail.Itemslist.Owner)
        updateDetails()
        setCountermeasureInvalid(false)
        setDescriptionInvalid(false)
        setStatusInvalid(false)
        setOwnerInvalid(false)
    }, [PainPointDetail.Itemslist.id]);

    const handleLabel = (detail) => {
        if (detail.selectedOptions.length > 1) {
            alert("Only 1 tag is allowed!")
        } else {
            setSelectedLabelOptions(detail.selectedOptions)
        }
    }
    const handleCategories = (detail) => {
        setSelectedCategoriesOptions(detail.selectedOptions)
    }
    const handlePriorities = (detail) => {
        setSelectedPrioritiesOptions(detail.selectedOptions)
    }
    // const [HighlightValue, setHighlightValue] = useState("NO");

    const handleDescription = (value) => {
        setDescription(value)
    }
    const handleCountermeasure = (value) => {
        setCountermeasure(value)
    }
    const handleStatus = (value) => {
        setStatus(value)
    }
    const handleApprovalSubmit = () => {
        const StateValue = Description === 'No' ?
            'Unapproved' :
            (Description === 'Good Catch' && Countermeasure === 'Feasible' ?
                "Approved" :
                "Reviewed")
        let secondReviewer = pushValues(selectedLabelOptions)[0] == "Good Idea" ? secondReviewerValue : ''
        const dataDetail = PainPointDetail.loginUser == PainPointDetail.Itemslist.SecondReviewer ? {
            Type: 'ClusterManager',
            Label: pushValues(selectedLabelOptions),
            Priority: pushValues(selectedPrioritiesOptions),
            Category: pushValues(selectedCategoriesOptions),
            CMApproval: StateValue,
            CMCountermeasure: Countermeasure,
            CMDescription: Description,
            SecondReviewer: secondReviewer
        } : PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer ? {
            Type: 'LineManager',
            Label: pushValues(selectedLabelOptions),
            Priority: pushValues(selectedPrioritiesOptions),
            Category: pushValues(selectedCategoriesOptions),
            LMApproval: StateValue,
            LMCountermeasure: Countermeasure,
            LMDescription: Description,
            SecondReviewer: secondReviewer
        } : null
        Description === '' ? setDescriptionInvalid(true) : null
        Countermeasure === '' ? setCountermeasureInvalid(true) : null

        Description !== '' && Countermeasure !== '' ? (
            axios({
                method: "POST",
                data: dataDetail,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/approval/" + PainPointDetail.Itemslist.id,
            }).then(res => {
                PainPointDetail.updateDetail()
                history.push('/approval')
            })
        ) : console.log('Submit successful')

    }
    const resolveUnapprove = () => {
        alert('Before Resolve the item, Please Search Out Reviewer to Approve it.')
        setOwnerLoading(false)
    }
    const handleStatusOwner = () => {
        setOwnerLoading(true)
        Status === '' ? setStatusInvalid(true) : null
        OwnerValue === '' ? setOwnerInvalid(true) : null
        const dataDetail = {
            Status: Status,
            Owner: OwnerValue
        }
        Status !== '' && OwnerValue !== '' ?
            Status != 'Resolved' ?
                axios({
                    method: "POST",
                    data: dataDetail,
                    url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/assign/" + PainPointDetail.Itemslist.id,
                }).then(res => {
                    setOwnerLoading(false)
                    PainPointDetail.updateDetail()
                }) : PainPointDetail.Itemslist.State == 'Approved' || PainPointDetail.Itemslist.State == 'Unapproved' ?
                    axios({
                        method: "POST",
                        data: dataDetail,
                        url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/assign/" + PainPointDetail.Itemslist.id,
                    }).then(res => {
                        setOwnerLoading(false)
                        PainPointDetail.updateDetail()
                    }) : resolveUnapprove()
            : null
    }
    const [CommentsValue, setCommentsValue] = useState("")
    const onChangeMention = () => {
        const atItemList = document.getElementById('editorMultiple')?.innerHTML || '';
        setCommentsValue(atItemList);
    }
    const handleComments = (value) => {
        setCommentsValue(value)
    }
    const resetComments = () => {
        setCommentsValue("")
        if (document.getElementById('editorMultiple')) {
            // @ts-ignore
            document.getElementById('editorMultiple').innerHTML = ''
        }
    }
    const [AreaInvalid, setAreaInvalid] = useState(false)
    const [SubmitLoading, setSubmitLoading] = useState(false)
    const [MarkDownValue, setMarkDownValue] = useState(false);
    const [ReplyComments, setReplyComments] = useState<any>()
    const submitComments = () => {
        setSubmitLoading(true)
        const replyContext = ReplyComments ? ReplyComments : undefined
        const CommentsDetails = {
            "CommentsContent": CommentsValue,
            "CommentsUser": PainPointDetail.loginUser,
            "id": PainPointDetail.Itemslist.id,
            "ReplyComments": replyContext,
            'CommentsMarkdown': MarkDownValue
        }
        if (CommentsValue === '') {
            setAreaInvalid(true)
        } else {
            axios({
                method: "POST",
                data: CommentsDetails,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/comments/" + PainPointDetail.Itemslist.id,
            }).then(res => {

                setSubmitLoading(false)
                PainPointDetail.updateDetail()
                setCommentsValue("")
                if (document.getElementById('editorMultiple')) {
                    // @ts-ignore
                    document.getElementById('editorMultiple').innerHTML = ''
                }
                setSubmitLoading(false)
            })
        }
    }
    useEffect(() => {
        if (document.getElementById('editorMultiple') != null) {
            // @ts-ignore
            document.getElementById('editorMultiple').innerHTML = CommentsValue
        } else {
            let testStr = CommentsValue.replace(/<div>/g, "\n");
            testStr = testStr.replace(/<\/?.+?>/g, "");
            testStr = testStr.replace(/&nbsp;/g, "");
            setCommentsValue(testStr)
        }
    }, [MarkDownValue])
    useEffect(() => {
        if (document.getElementById('editorMultiple')) {
            // @ts-ignore
            document.getElementById('editorMultiple').innerHTML = ''
        }
        setCommentsValue('')
        setReplyComments(undefined)
    }, [PainPointDetail.Itemslist])
    return (
        <SpaceBetween size="l">
            <TextContent>
                <SpaceBetween size="xxs">
                    <h4>Title</h4>
                    <AutoLink text={PainPointDetail.Itemslist.Title}></AutoLink>
                    <h4>Problem Statement</h4>
                    <AutoLink text={PainPointDetail.Itemslist.Description}></AutoLink>
                    <h4>Countermeasure</h4>
                    <AutoLink text={PainPointDetail.Itemslist.Countermeasure}></AutoLink>
                    <h4>Benefit</h4>
                    <AutoLink text={PainPointDetail.Itemslist.Benefit}></AutoLink>
                    {PainPointDetail.Itemslist.Attachment ?
                        <Link external href={attachmentKey}>
                            {PainPointDetail.Itemslist.Attachment.split(PainPointDetail.Itemslist.id + '-')[1]}
                        </Link> :
                        <></>}
                    {PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer || PainPointDetail.loginUser === PainPointDetail.Itemslist.SecondReviewer ? (
                        <ExpandableSection
                            expanded={ApprovalExpend}
                            onChange={({detail}) => handleApprovalExpendChange(detail)}
                            headerText={
                                <StatusIndicator
                                    type={PainPointDetail.Itemslist.State === "Approved" ? "success" : 'warning'}>
                                    {PainPointDetail.Itemslist.State === "Approved" ? "Completed Review" : "Pending Review"}
                                    <Badge color="blue">Review Options</Badge>
                                </StatusIndicator>
                            }>
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        {PainPointDetail.loginUser == PainPointDetail.Itemslist.FirstReviewer ?
                                            <Button onClick={() => handleApprovalSubmit()}
                                                    variant="primary">Submit</Button> : null}
                                        {PainPointDetail.loginUser == PainPointDetail.Itemslist.SecondReviewer ?
                                            <Button onClick={() => handleApprovalSubmit()} variant="primary"
                                                    disabled={!(PainPointDetail.Itemslist.State == "Reviewed" && PainPointDetail.Itemslist.LMApproval == "Approved")}>Submit</Button> : null}
                                    </SpaceBetween>
                                }
                            >
                                <SpaceBetween size="l">
                                    {PainPointDetail.loginUser === PainPointDetail.Itemslist.SecondReviewer ?
                                        (PainPointDetail.Itemslist.State != "Reviewed" || PainPointDetail.Itemslist.LMApproval != "Approved" ?
                                                <Alert
                                                    statusIconAriaLabel="Error"
                                                    type="error"
                                                    header="The first reviewer has not reviewed this item."
                                                >
                                                    Please notify the first reviewer to review this item.
                                                </Alert> : null
                                        )
                                        : null}
                                    <ColumnLayout columns={2} variant="text-grid">
                                        <SpaceBetween size="l">
                                            <div>
                                                <Box variant="awsui-key-label">Description Decision</Box>
                                                <span>Please select one</span>
                                                <Grid
                                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                                >
                                                    <div>
                                                        <EmployeeAutoSuggest options={DescriptionValue}
                                                                             invalid={DescriptionInvalid}
                                                                             onChange={(event) => handleDescription(event.detail.value)}
                                                                             autoSuggestValue={Description}/>
                                                    </div>
                                                    <div></div>
                                                </Grid>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Countermeasure Decision</Box>
                                                <span>Please select one</span>
                                                <Grid
                                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                                >
                                                    <div>
                                                        <EmployeeAutoSuggest options={CountermeasureValue}
                                                                             invalid={CountermeasureInvalid}
                                                                             onChange={(event) => handleCountermeasure(event.detail.value)}
                                                                             autoSuggestValue={Countermeasure}/>
                                                    </div>
                                                    <div></div>
                                                </Grid>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Reviewers</Box>
                                                <SpaceBetween size="xs">
                                                    <Link
                                                        href={"https://phonetool.amazon.com/users/" + PainPointDetail.Itemslist.FirstReviewer}><Icon
                                                        name="user-profile"/>First
                                                        Reviewer: {PainPointDetail.Itemslist.FirstReviewer}
                                                    </Link>
                                                    {PainPointDetail.Itemslist.SecondReviewer != '' ? <Link
                                                        href={"https://phonetool.amazon.com/users/" + PainPointDetail.Itemslist.SecondReviewer}><Icon
                                                        name="user-profile"/>Second
                                                        Reviewer: {PainPointDetail.Itemslist.SecondReviewer}
                                                    </Link> : null}
                                                    {PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer && selectedLabelOptions.length > 0 && pushValues(selectedLabelOptions)[0] == "Good Idea" ?
                                                        <AutoSuggestFormField
                                                            label={<div>Second reviewer<span
                                                                style={{color: "red"}}> Optional</span></div>}
                                                            autoSuggestValue={secondReviewerValue} errorValue={''}
                                                            options={PainPointDetail.OwnerOptions}
                                                            onChange={(event) => handleSecondReviewer(event.detail.value)}
                                                            invalid={false}
                                                            constraintText={'The second viewer by default is empty, if you promote it to Good Idea, please select a reviewer for next step.'}/>
                                                        : null}
                                                </SpaceBetween>
                                            </div>
                                        </SpaceBetween>
                                        <SpaceBetween size="l">
                                            <div>
                                                <Grid
                                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                                >
                                                    <PainPointTags label={'Label'} errorValue={""}
                                                                   handleTags={({detail}) => handleLabel(detail)}
                                                                   selectedOptions={selectedLabelOptions}
                                                                   options={[{
                                                                       label: "Pain Point",
                                                                       value: "Pain Point",
                                                                   }, {
                                                                       label: "Good Idea",
                                                                       value: "Good Idea",
                                                                   }]}
                                                                   constraintText={''}/>
                                                    <div></div>
                                                </Grid>
                                            </div>
                                            <div>
                                                <Grid
                                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                                >
                                                    <PainPointTags label={'Categories'} errorValue={""}
                                                                   handleTags={({detail}) => handleCategories(detail)}
                                                                   selectedOptions={selectedCategoriesOptions}
                                                                   options={[{
                                                                       label: "Tools",
                                                                       value: "Tools",
                                                                   }, {
                                                                       label: "Employee Experience",
                                                                       value: "Employee Experience",
                                                                   }, {
                                                                       label: "Process",
                                                                       value: "Process",
                                                                   }, {
                                                                       label: "Operation",
                                                                       value: "Operation",
                                                                   }, {
                                                                       label: "Script",
                                                                       value: "Script",
                                                                   }]}
                                                                   constraintText={''}/>
                                                    <div></div>
                                                </Grid>
                                                {/*<Box variant="awsui-key-label">Highlight Item(Not Available Now)</Box>*/}
                                                {/*<RadioGroup*/}
                                                {/*    onChange={({detail}) => setHighlightValue(detail.value)}*/}
                                                {/*    value={HighlightValue}*/}
                                                {/*    items={[*/}
                                                {/*        {value: "YES", label: "Highlight the Pain Point", disabled: true,},*/}
                                                {/*        {value: "NO", label: "Don't Highlight the Pain Point", disabled: true,},*/}
                                                {/*    ]}*/}
                                                {/*/>*/}
                                            </div>
                                            <div>
                                                <Grid
                                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                                >
                                                    <PainPointTags label={'Priorities'} errorValue={""}
                                                                   handleTags={({detail}) => handlePriorities(detail)}
                                                                   selectedOptions={selectedPrioritiesOptions}
                                                                   options={[{
                                                                       label: "Safety",
                                                                       value: "Safety",
                                                                   }, {
                                                                       label: "Security",
                                                                       value: "Security",
                                                                   }, {
                                                                       label: "Costing",
                                                                       value: "Costing",
                                                                   }, {
                                                                       label: "Availability",
                                                                       value: "Availability",

                                                                   }, {
                                                                       label: "Efficiency",
                                                                       value: "Efficiency",
                                                                   }, {
                                                                       label: "Productivity",
                                                                       value: "Productivity",
                                                                   }, {
                                                                       label: "Quality",
                                                                       value: "Quality",

                                                                   }]}
                                                                   constraintText={""}/>

                                                    <div></div>
                                                </Grid>
                                            </div>
                                        </SpaceBetween>
                                    </ColumnLayout>
                                </SpaceBetween>
                            </Form>
                        </ExpandableSection>) : <div></div>}
                    <DetailFavoriteFunction FavoriteID={PainPointDetail.Itemslist.id}
                                            Hate={PainPointDetail.Itemslist.Hate}
                                            login={PainPointDetail.loginUser} Like={PainPointDetail.Itemslist.Like}
                                            updateDetail={PainPointDetail.updateDateDoNotCloseModal}></DetailFavoriteFunction>
                    <div>
                        <ProgressBar
                            value={
                                PainPointDetail.Itemslist.Status === 'Assign' ? 0 : (PainPointDetail.Itemslist.Status === "Working in Process" ? 30 : (PainPointDetail.Itemslist.Status === "Pending" ? 60 : (PainPointDetail.Itemslist.Status === "Resolved") ? 100 : 0))
                            }
                            additionalInfo={<div>PainPoint Process status is <StatusIndicator
                                type={PainPointDetail.Itemslist.Status === 'Assign' ? 'info' : (PainPointDetail.Itemslist.Status === 'Working in Process' ? 'in-progress' : (PainPointDetail.Itemslist.Status === 'Pending' ? 'pending' : (PainPointDetail.Itemslist.Status === 'Resolved' ? 'success' : 'error')))}>
                                {PainPointDetail.Itemslist.Status}
                            </StatusIndicator> now!</div>}
                            description="Status Value: Assigin, Working in Process, Pending, Resolved"
                            label={<strong>Process Status of PainPoint</strong>}
                        />
                    </div>
                    <Form
                        actions={
                            PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer ||
                            PainPointDetail.loginUser === PainPointDetail.Itemslist.SecondReviewer || PainPointDetail.loginUser === PainPointDetail.Itemslist.Owner || PainPointDetail.Itemslist.Owner === 'TBD' || PainPointDetail.permissionUser ?
                                (<SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link" onClick={() => console.log()}>
                                        Reset
                                    </Button>
                                    <Button onClick={() => handleStatusOwner()} loading={ownerLoading}
                                            variant="primary">Submit</Button>
                                </SpaceBetween>) :
                                <></>
                        }
                    >
                        <ColumnLayout columns={2} variant="text-grid">
                            <SpaceBetween size="l">
                                <div>
                                    <Box variant="awsui-key-label">Impact Team</Box>
                                    <Token items={PainPointDetail.Itemslist.Team}/>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Label</Box>
                                    <Token items={PainPointDetail.Itemslist.Label}/>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Submitter</Box>
                                    <Link
                                        href={"https://phonetool.amazon.com/users/" + PainPointDetail.Itemslist.Submitter}><Icon
                                        name="user-profile"/>{PainPointDetail.Itemslist.Submitter}</Link>
                                </div>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <div>
                                    <Box variant="awsui-key-label">State</Box>
                                    <StatusIndicator
                                        type={PainPointDetail.Itemslist.State === 'Unapproved' ? 'error' : 'success'}>
                                        {PainPointDetail.Itemslist.State}
                                    </StatusIndicator>
                                </div>

                                <div>
                                    {PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer ||
                                    PainPointDetail.loginUser === PainPointDetail.Itemslist.SecondReviewer || PainPointDetail.loginUser === PainPointDetail.Itemslist.Owner || PainPointDetail.Itemslist.Owner === 'TBD' || PainPointDetail.permissionUser ? (
                                        <Grid
                                            gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                        >
                                            <AutoSuggestFormField label={"Status"} autoSuggestValue={Status}
                                                                  errorValue={""}
                                                                  options={StatusOptions}
                                                                  onChange={(event) => handleStatus(event.detail.value)}
                                                                  invalid={StatusInvalid}
                                                                  constraintText={''}/>
                                            <div></div>
                                        </Grid>
                                    ) : (<div><Box variant="awsui-key-label">Status</Box><StatusIndicator
                                        type={PainPointDetail.Itemslist.Status === 'Assign' ? 'info' : (PainPointDetail.Itemslist.Status === 'Working in Process' ? 'in-progress' : (PainPointDetail.Itemslist.Status === 'Pending' ? 'pending' : (PainPointDetail.Itemslist.Status === 'Resolved' ? 'success' : 'error')))}>
                                        {PainPointDetail.Itemslist.Status}
                                    </StatusIndicator></div>)
                                    }
                                </div>
                                <div>
                                    {PainPointDetail.loginUser === PainPointDetail.Itemslist.FirstReviewer ||
                                    PainPointDetail.loginUser === PainPointDetail.Itemslist.SecondReviewer || PainPointDetail.loginUser === PainPointDetail.Itemslist.Owner || PainPointDetail.Itemslist.Owner === 'TBD' || PainPointDetail.permissionUser ? (
                                        <Grid
                                            gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                        >
                                            <AutoSuggestFormField label={"Action Owner"} autoSuggestValue={OwnerValue}
                                                                  errorValue={""}
                                                                  options={PainPointDetail.OwnerOptions}
                                                                  onChange={(event) => handleOwner(event.detail.value)}
                                                                  invalid={OwnerInvalid}
                                                                  constraintText={''}/>
                                            <div></div>
                                        </Grid>
                                    ) : (<div><Box variant="awsui-key-label">Action Owner</Box>
                                        <Link
                                            href={"https://phonetool.amazon.com/users/" + PainPointDetail.Itemslist.Owner}><Icon
                                            name="user-profile"/>{PainPointDetail.Itemslist.Owner}
                                        </Link></div>)
                                    }
                                </div>
                            </SpaceBetween>
                        </ColumnLayout>
                    </Form>
                </SpaceBetween>
            </TextContent>
            <ExpandableSection expanded={DetailsExpend} headerText='More Details'
                               onChange={({detail}) => handleDetailsExpendChange(detail)}>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Sharing Link</Box>
                            <CopyText
                                copyText={`https://aladdin.portal.amazon.dev/#/detail/` + PainPointDetail.Itemslist.id}
                                copyButtonLabel="Copy Sharing Link"
                                successText="Sharing Link copied"
                                errorText="Sharing Link failed to copy"
                                showText={PainPointDetail.Itemslist.id}
                            />
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Submit Date</Box>
                            <div>{updateDateToString(PainPointDetail.Itemslist.SubmitDate)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Review Date</Box>
                            {PainPointDetail.Itemslist.ReviewDate === "" ? 'Not Review' :
                                <div>{updateDateToString(PainPointDetail.Itemslist.ReviewDate)}</div>}
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Impact Site</Box>
                            {PainPointDetail.Itemslist.Site.length == 0 ? <span>N/A</span> :
                                <Token items={PainPointDetail.Itemslist.Site}/>}
                        </div>
                        <div>
                            <Box variant="awsui-key-label">FirstReviewer</Box>
                            <Link
                                href={"https://phonetool.amazon.com/users/" + PainPointDetail.Itemslist.FirstReviewer}><Icon
                                name="user-profile"/>{PainPointDetail.Itemslist.FirstReviewer}
                            </Link>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Review Decision</Box>
                            <SpaceBetween size="l">
                                <Badge
                                    color={PainPointDetail.Itemslist.LMApproval === 'Approved' ? 'green' : (PainPointDetail.Itemslist.LMApproval === 'Unapproved' ? 'red' : (PainPointDetail.Itemslist.LMApproval === 'Reviewed' ? 'blue' : 'grey'))}>First
                                    Reviewer
                                    - {PainPointDetail.Itemslist.LMApproval === '' ? 'Not Reviewed' : PainPointDetail.Itemslist.LMApproval}</Badge>
                                {PainPointDetail.Itemslist.SecondReviewer != '' ? <Badge
                                    color={PainPointDetail.Itemslist.CMApproval === 'Approved' ? 'green' : (PainPointDetail.Itemslist.CMApproval === 'Unapproved' ? 'red' : (PainPointDetail.Itemslist.CMApproval === 'Reviewed' ? 'blue' : 'grey'))}>Second
                                    Reviewer
                                    - {PainPointDetail.Itemslist.CMApproval === '' ? 'Not Reviewed' : PainPointDetail.Itemslist.CMApproval}</Badge> : null}
                            </SpaceBetween>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            </ExpandableSection>

            <DetailTab Comments={PainPointDetail.Itemslist['Comments']}
                       loginUser={PainPointDetail.loginUser}
                       OwnerOptions={PainPointDetail.OwnerOptions} onChangeMention={onChangeMention}
                       CommentsonChange={(event) => handleComments(event.detail.value)}
                       CommentsValue={CommentsValue} CommentsReset={() => resetComments()}
                       CommentsSubmit={() => submitComments()} SubmitLoading={SubmitLoading}
                       AreaInvalid={AreaInvalid} ItemState={PainPointDetail.Itemslist['State']}
                       ItemLabel={PainPointDetail.Itemslist['Label']}
                       WorkLogs={PainPointDetail.Itemslist['WorkLogs']} ReplyComments={ReplyComments}
                       setReplyComments={setReplyComments} MarkDownValue={MarkDownValue}
                       setMarkDownValue={({detail}) => setMarkDownValue(detail.checked)}
                       permissionUser={PainPointDetail.permissionUser} updateDetail={PainPointDetail.updateDetail}
                       Submitter={PainPointDetail.Itemslist['Submitter']}
                       painpoint_id={PainPointDetail.Itemslist['id']}
                       PromoteProps={PainPointDetail.Itemslist['PromoteProps']}
                       ChildrenList={PainPointDetail.Itemslist.hasOwnProperty('ChildrenList') ? PainPointDetail.Itemslist.ChildrenList : []}
                       ParentId={PainPointDetail.Itemslist.hasOwnProperty('ParentId') ? PainPointDetail.Itemslist.ParentId : null}
            />

        </SpaceBetween>
    )
}