import IndexRouter from '../Router/IndexRouter'
import SelfTopNavigation from '../components/topNavigation/topNavigation'

export default function App() {
    return (
        <div>
            <div id="h" style={{
                position: 'sticky',
                top: 0,
                zIndex: 1002
            }}>
                <SelfTopNavigation></SelfTopNavigation>
            </div>
            <IndexRouter></IndexRouter>
        </div>
    )
}