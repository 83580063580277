import {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useCollection} from '@amzn/awsui-collection-hooks';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Header from '@amzn/awsui-components-react/polaris/header';
import axios from 'axios'
import Button from "@amzn/awsui-components-react/polaris/button";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import {
    DEFAULT_PREFERENCES_SEARCH,
    getMatchesCountText,
    PAGE_SIZE_OPTIONS,
    VISIBLE_CONTENT_OPTIONS
} from "../TableView/table-config";
import {Distribution} from "../interfaces";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import AutoLink, {UserIcon} from "../Common/AutoLink";

export default function LoginPage() {
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/cleandraft",
        }).then(res => {
            console.log(res.data)
        })
    }, []);

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        // info={<Link>Info</Link>}
                        description="Every PainPoint/GoodIdea deserves to be applauded."
                        actions={
                            <Button href='https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/'
                                    variant="primary" target="_blank">Help</Button>
                        }
                    >
                        Review the PainPoint/GoodIdea
                    </Header>
                </SpaceBetween>
            }>
            <SpaceBetween size="xs">
                <Box textAlign="center" margin={{top: 'xxxl'}}>
                    <img width='500px'
                         src='https://maxis-service-prod-dub.amazon.com/issues/8f158fd4-6951-463c-b3ec-87d77ead7ba8/attachments/9877ff46d60cd4e1bd0596dca0086afd2723326b60d67b4122edd0be3f913340_dcf9afac-7c9d-4c58-a54e-6ded1fd405a5'/>
                </Box>
                <Box textAlign="center" variant="div">
                    <h1 style={{color: '#0073bb'}}>This PainPoint/GoodIdea is not exist in Aladdin.</h1>
                </Box>
                <Box textAlign="center" variant="p">
                    Please confirm the link to the PainPoint/GoodIdea.
                    <br/>
                    To get more information about the PainPoint/GoodIdea, <Link external
                                                                                href="https://phonetool.amazon.com/users/ayunlong">
                    please contact with system admin ayunlong@.
                </Link>
                </Box>
            </SpaceBetween>
        </ContentLayout>
    );
}