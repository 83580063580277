import {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Form from "@amzn/awsui-components-react/polaris/form";
import {
    AutoSuggestFormField,
    InputFormField,
    SelectFormField,
    TextareaFormField,
    TilesFormField
} from "../Common/FormField"
import PainPointTags, {PainPointGroupTags} from '../Common/Tags'
import {MultiselectProps} from "@amzn/awsui-components-react/polaris/multiselect";
import axios from 'axios'
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import {Distribution} from '../interfaces';
import FileUploadForm from "../Common/FileUpload";
import {CHILDREN, CLUSTER, LOCATION} from "../Common/config";
import {pushToTag, pushToTagsWithList, pushValues} from "../Common/Token";
import Box from '@amzn/awsui-components-react/polaris/box';
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

export interface EditContentProps {
    id: any
}

export default function EditContent(props: EditContentProps) {
    const [painpoint_id, setPainpoint_id] = useState(props.id)
    const [painPointDetails, setPainPointDetails] = useState<any>()
    const [allLocation, setAllLocation] = useState<any>(LOCATION)
    const [childrenLocationList, setChildrenLocationList] = useState<any>(CHILDREN)
    const [OptionImpactCluster, setOptionImpactCluster] = useState<any>(pushToTagsWithList(CLUSTER))
    const [SubmitterCluster, setSubmitterCluster] = useState<any>({})
    const [SubmitterClusterOptions, setSubmitterClusterOptions] = useState(pushToTagsWithList(CLUSTER))
    const [SubmitterAZ, setSubmitterAZ] = useState<any>({})
    const [SubmitterAZOptions, setSubmitterAZOptions] = useState<any>([])
    const [SubmitterTeam, setSubmitterTeam] = useState<any>({})
    const [SubmitterTeamOptions, setSubmitterTeamOptions] = useState([{
        label: "DCO",
        value: "DCO"
    }, {label: "DCEO", value: "DCEO"}, {label: "Logistics", value: "Logistics"}, {
        label: "ID",
        value: "ID"
    }, {label: "Security", value: "Security"}, {
        value: "Decom Tech",
        label: "Decom Tech"
    }])
    const [secondReviewerValue, setSecondReviewerValue] = useState("")
    useEffect(() => {
        console.log(painpoint_id)
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/" + painpoint_id,
        }).then(res => {
            setPainPointDetails(res.data)
            setTitleValue(res.data.Title)
            setTilesValue(res.data.Owner)
            setDescriptionValue(res.data.Description)
            setCountermeasureValue(res.data.Countermeasure)
            setSelectedLabelOptions(pushToTagsWithList(res.data.Label))
            setSelectedCategoriesOptions(pushToTagsWithList(res.data.Category))
            setSelectedPrioritiesOptions(pushToTagsWithList(res.data.Priority))
            setImpactSiteOptions(pushToTagsWithList(res.data.Site))
            setReviewerValue((res.data.FirstReviewer))
            setSecondReviewerValue((res.data.SecondReviewer))
            setBenefitValue(res.data.Benefit)
            setAttachmentValue(res.data.Attachment)
            setImpactTeamOptions(pushToTagsWithList(res.data.Team))
            setImpactClusterOptions(pushToTagsWithList(res.data.Cluster))
            setImpactAZOptions(pushToTagsWithList(res.data.AZ))
            setSubmitterCluster(pushToTag(res.data.SubmitterCluster))
            setSubmitterAZ(pushToTag(res.data.SubmitterAZ))
            res.data.SubmitterTeam.length > 0 ?
                setSubmitterTeam(pushToTag(res.data.SubmitterTeam)) :
                setSubmitterTeam({value: '', label: 'All'})
        })
    }, []);
    useEffect(() => {
        let setOpt: any = []
        if (Object.keys(SubmitterCluster).length > 0) {
            Object.keys(allLocation[SubmitterCluster.value]).map((item) => {
                setOpt.push({
                    label: item,
                    value: item
                })
            })
        }
        setSubmitterAZOptions(setOpt)

    }, [SubmitterCluster])
    useEffect(() => {
        console.log(SubmitterAZOptions)
        console.log(SubmitterAZ)
        let checker = false
        SubmitterAZOptions.map(item => {
            item['value'] == SubmitterAZ.value ? checker = true : null
        })
        checker ? null : setSubmitterAZ(SubmitterAZOptions[0])

    }, [SubmitterAZOptions])
    const history = useHistory();
    const [loginUser, setLoginUser] = useState('')
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, [painpoint_id]);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/scanlogin",
        }).then(res => {
            const reviewerOptionList: any = []
            res.data.map((item, index) => {
                reviewerOptionList.push({
                    "label": item['gecos'],
                    "value": item['uid']
                })
            })
            setreviewerOptionsValue(reviewerOptionList)
        })
    }, [])
    useEffect(() => {
        setTilesItemsValue([
            {
                label: "Myself",
                description: "I will find the solution for it",
                value: loginUser
            },
            {
                label: "TBD",
                description: "PainPoint/GoodIdea Commit will assign other people to find solution",
                value: "TBD"
            }
        ])
    }, [loginUser])

    const [titleValue, setTitleValue] = useState<any>('');
    const [countermeasureValue, setCountermeasureValue] = useState<any>('');
    const [tilesItemsValue, setTilesItemsValue] = useState([
        {
            label: "Myself",
            description: "I will find the solution for it",
            value: loginUser
        },
        {
            label: "TBD",
            description: "PainPoint/GoodIdea Commit will assign other people to find solution",
            value: "TBD"
        }
    ])
    const [attachmentValue, setAttachmentValue] = useState<any>('');
    const [tilesValue, setTilesValue] = useState<any>('');
    const [reviewerValue, setReviewerValue] = useState<any>('')
    const [benefitValue, setBenefitValue] = useState<any>('')
    const [errorBenefitValue, setErrorBenefitValue] = useState("")
    const [reviewerOptionsValue, setreviewerOptionsValue] = useState([])
    const [descriptionValue, setDescriptionValue] = useState<any>('')
    const [errorTitleValue, setErrorTitleValue] = useState("");
    const [errorDescriptionValue, setErrorDescriptionValue] = useState("");
    const [errorCountermeasureValue, setErrorCountermeasureValue] = useState("");
    const [FileUploadValue, setFileUploadValue] = useState<any>([])
    const [errorTextFileUpload, setErrorTextFileUpload] = useState("")
    const [ErrorReviewerValue, setErrorReviewerValue] = useState("")
    const [SubmitBtnLoading, setSubmitBtnLoading] = useState(false)

    const [
        selectedLabelOptions,
        setSelectedLabelOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        selectedCategoriesOptions,
        setSelectedCategoriesOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        selectedPrioritiesOptions,
        setSelectedPrioritiesOptions
    ] = useState<MultiselectProps.Options>([]);

    const [
        ImpactTeamOptions,
        setImpactTeamOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        ImpactClusterOptions,
        setImpactClusterOptions
    ] = useState<any>([]);
    const [
        ImpactAZOptions,
        setImpactAZOptions
    ] = useState<any>([]);
    const [
        ImpactSiteOptions,
        setImpactSiteOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        OptionImpactAZ,
        setOptionImpactAZ
    ] = useState([]);
    const [
        OptionImpactSite,
        setOptionImpactSite
    ] = useState([]);

    const handleLabel = (detail) => {
        if (detail.selectedOptions.length > 1) {
            alert("Only 1 tag is allowed!")
        } else {
            setSelectedLabelOptions(detail.selectedOptions)
        }
    }

    const handleCategories = (detail) => {
        setSelectedCategoriesOptions(detail.selectedOptions)
    }
    const handlePriorities = (detail) => {
        setSelectedPrioritiesOptions(detail.selectedOptions)
    }
    const handleImpactTeam = (detail) => {
        setImpactTeamOptions(detail.selectedOptions)
    }
    const handleImpactSite = (detail) => {
        setImpactSiteOptions(detail.selectedOptions)
    }
    useEffect(() => {
        const selectCluster: any = []
        const OptionsAZ: any = []
        if (ImpactClusterOptions.length > 0) {
            ImpactClusterOptions.map((item) => {
                selectCluster.push(item.value)
            })
            selectCluster.map((item) => {
                Object.keys(allLocation[item]).map((az) => {
                    OptionsAZ.push({
                        label: az,
                        value: az
                    })
                })

            })
            setOptionImpactAZ(OptionsAZ)
        }
    }, [ImpactClusterOptions])
    const handleImpactCluster = (detail) => {
        setImpactClusterOptions(detail.selectedOptions)
        setImpactAZOptions([])
        setImpactSiteOptions([])

    }
    useEffect(() => {
        const selectAZ: any = []
        const OptionsSite: any = []
        if (ImpactAZOptions.length > 0) {
            ImpactAZOptions.map((item) => {
                console.log(item.value)
                console.log(childrenLocationList)
                childrenLocationList[item.value].map((site) => selectAZ.push(site))
            })
            selectAZ.sort()
            selectAZ.map((item) => {
                OptionsSite.push({
                    label: item,
                    value: item
                })
            })
            setOptionImpactSite(OptionsSite)
        }
    }, [ImpactAZOptions])
    const handleImpactAZ = (detail) => {
        setImpactAZOptions(detail.selectedOptions)
        setImpactSiteOptions([])
    }
    const handleFileUpload = (value) => {
        console.log(value)
        if (value.length > 0) {
            value[0]['size'] > 4194304 ?
                setErrorTextFileUpload('File size should not exceed 4 MB!!!') :
                setFileUploadValue(value)
        } else if (value.length == 0) {
            setFileUploadValue(value)
        }
    }
    const handleDetail = (value) => {
        setDescriptionValue(value)
    }
    const handlTitleInput = (value) => {
        setTitleValue(value)
    }
    const handlCountermeasureInput = (value) => {
        setCountermeasureValue(value)
    }
    const handleReviewer = (value) => {
        setReviewerValue(value)
    }
    const handleSecondReviewer = (value) => {
        setSecondReviewerValue(value)
    }
    const handleBenefit = (value) => {
        setBenefitValue(value)
    }
    const handleActionOwner = (value) => {
        setTilesValue(value)
    }
    const handleAttachment = (value) => {
        setAttachmentValue(value)
    }
    const handleSubmitterCluster = (value) => {
        setSubmitterCluster(value)
    }
    const handleSubmitterAZ = (value) => {
        setSubmitterAZ(value)
    }
    const handleSubmitterTeam = (value) => {
        setSubmitterTeam(value)
    }

    const submitAction = (e) => {
        setSubmitBtnLoading(true)
        let fileName = ''
        let fileType = ''
        FileUploadValue[0] ? fileName = FileUploadValue[0]['name'] : fileName = ''
        FileUploadValue[0] ? fileType = FileUploadValue[0]['type'].replace('/', '-') : fileType = ''
        if (SubmitterTeam['value'] == '') {
            alert('Please Select your team!')
            setSubmitBtnLoading(false)
        } else {
            if (titleValue != "" && descriptionValue != "" && benefitValue != "" && reviewerValue != "") {
                let secondReviewer = ''
                pushValues(selectedLabelOptions) == 'Good Idea' ? secondReviewer = secondReviewerValue : secondReviewer = ''
                const submitDict = {
                    "id": painpoint_id,
                    "Title": titleValue,
                    "Description": descriptionValue,
                    "Countermeasure": countermeasureValue,
                    "Label": pushValues(selectedLabelOptions),
                    "Category": pushValues(selectedCategoriesOptions),
                    "Priority": pushValues(selectedPrioritiesOptions),
                    "Owner": tilesValue,
                    "FirstReviewer": reviewerValue,
                    "SecondReviewer": secondReviewer,
                    "Benefit": benefitValue,
                    "Team": pushValues(ImpactTeamOptions),
                    "Attachment": attachmentValue,
                    "Submitter": loginUser,
                    "Site": pushValues(ImpactSiteOptions),
                    "Cluster": pushValues(ImpactClusterOptions),
                    "AZ": pushValues(ImpactAZOptions),
                    "SubmitterCluster": SubmitterCluster['value'],
                    "SubmitterAZ": SubmitterAZ['value'],
                    "SubmitterTeam": SubmitterTeam['value'],
                }

                axios({
                    method: "POST",
                    data: submitDict,
                    url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint",
                }).then(res => {
                    if (FileUploadValue[0]) {
                        axios({
                            method: "POST",
                            data: FileUploadValue[0],
                            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/uploadfile/" + painpoint_id + '/' + fileName + '/' + fileType,
                        }).then(res => {
                            setSubmitBtnLoading(false)
                        })
                    }
                    history.push('/PainPoint & GoodIdea')
                })
            } else {
                if (titleValue == "") {
                    setErrorTitleValue('Please add the Pain Point Title!')
                } else {
                    setErrorTitleValue("")
                }
                if (descriptionValue == "") {
                    setErrorDescriptionValue('Please add the Pain Point Description!')
                } else {
                    setErrorDescriptionValue("")
                }
                if (benefitValue == "") {
                    setErrorBenefitValue('Please add the Pain Point Benefit!')
                } else {
                    setErrorBenefitValue("")
                }
                if (reviewerValue == "") {
                    setErrorReviewerValue('Please add the Pain Point Reviewer!')
                } else {
                    setErrorReviewerValue("")
                }
                setSubmitBtnLoading(false)
            }
        }
    }

    const resetAction = () => {
        setTitleValue("")
        setTilesValue(loginUser)
        setDescriptionValue("")
        setCountermeasureValue("")
        setSelectedLabelOptions([])
        setReviewerValue("")
        setBenefitValue("")
        setImpactSiteOptions([])
        setFileUploadValue('')
        setImpactTeamOptions([])
        setSelectedPrioritiesOptions([])
        setSelectedCategoriesOptions([])
        setSelectedLabelOptions([])
        setImpactSiteOptions([])
        setImpactAZOptions([])
        setImpactClusterOptions([])
    }
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        // info={<Link>Info</Link>}
                        description="You can edit the PainPoint/GoodIdea here."
                        actions={
                            <Button
                                href='https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/'
                                variant="primary"
                                target="_blank">Help</Button>
                        }
                    >
                        Edit the PainPoint/GoodIdea
                    </Header>
                </SpaceBetween>
            }
        >
            <Container
            >
                {painPointDetails ?
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link"
                                        onClick={() => history.push('/PainPoint & GoodIdea')}>
                                    Cancel
                                </Button>
                                <Button loading={SubmitBtnLoading} onClick={(e) => submitAction(e)}
                                        variant="primary">Submit</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween size="xs">
                            <FormField label={'Assign Group'}>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <SelectFormField label={'Cluster'} constraintText={'Default value is your Cluster'}
                                                     selectedOption={SubmitterCluster} options={SubmitterClusterOptions}
                                                     disable={false}
                                                     onChange={({detail}) => handleSubmitterCluster(detail.selectedOption)}
                                                     errorValue={''} description={''}/>
                                    <SelectFormField label={'Availability Zone'}
                                                     constraintText={'Please select assign Availability Zone'}
                                                     selectedOption={SubmitterAZ} options={SubmitterAZOptions}
                                                     onChange={({detail}) => handleSubmitterAZ(detail.selectedOption)}
                                                     disable={false}
                                                     errorValue={''} description={''}/>
                                    <SelectFormField label={'Team'} constraintText={'Please select assign Team'}
                                                     selectedOption={SubmitterTeam} options={SubmitterTeamOptions}
                                                     onChange={({detail}) => handleSubmitterTeam(detail.selectedOption)}
                                                     disable={false}
                                                     errorValue={''} description={''}/>
                                </ColumnLayout>
                            </FormField>
                            <InputFormField label={<div><span style={{color: "red"}}>*</span>Title</div>}
                                            inputValue={titleValue} errorValue={errorTitleValue}
                                            onChange={(event) => handlTitleInput(event.detail.value)}
                                            constraintText={'Please keep it within 10 words.'}/>
                            <TextareaFormField label={<div><span style={{color: "red"}}>*</span>Problem Statement</div>}
                                               textareaValue={descriptionValue} errorValue={errorDescriptionValue}
                                               onChange={(event) => handleDetail(event.detail.value)}
                                               constraintText={'Please provide more information to describe your pain point.'}/>
                            <TextareaFormField label='Countermeasure (Optional)'
                                               textareaValue={countermeasureValue} errorValue={errorCountermeasureValue}
                                               onChange={(event) => handlCountermeasureInput(event.detail.value)}
                                               constraintText={'Please provide your suggestion if you have the one.'}/>
                            <TextareaFormField label={<div><span style={{color: "red"}}>*</span>Benefit</div>}
                                               textareaValue={benefitValue} errorValue={errorBenefitValue}
                                               onChange={(event) => handleBenefit(event.detail.value)}
                                               constraintText={'Please describe the scope of impact if the pain point is improved.'}/>
                            <FormField>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <PainPointTags label={'Label'} errorValue={""}
                                                   handleTags={({detail}) => handleLabel(detail)}
                                                   selectedOptions={selectedLabelOptions}
                                                   options={[{
                                                       label: "Pain Point",
                                                       value: "Pain Point",
                                                   }, {
                                                       label: "Good Idea",
                                                       value: "Good Idea",
                                                   }]}
                                                   constraintText={'Please select the improvement category of this pain point, if it is a good idea, please submit it get manager approval.'}/>
                                    <PainPointTags label={'Categories'} errorValue={""}
                                                   handleTags={({detail}) => handleCategories(detail)}
                                                   selectedOptions={selectedCategoriesOptions}
                                                   options={[{
                                                       label: "Tools",
                                                       value: "Tools",
                                                   }, {
                                                       label: "Employee Experience",
                                                       value: "Employee Experience",
                                                   }, {
                                                       label: "Process",
                                                       value: "Process",
                                                   }, {
                                                       label: "Operation",
                                                       value: "Operation",
                                                   }, {
                                                       label: "Script",
                                                       value: "Script",
                                                   }]}
                                                   constraintText={'Please select the improvement category of this pain point, if it is a good idea, please submit it get manager approval.'}/>
                                    <PainPointTags label={'Priorities'} errorValue={""}
                                                   handleTags={({detail}) => handlePriorities(detail)}
                                                   selectedOptions={selectedPrioritiesOptions}
                                                   options={[{
                                                       label: "Safety",
                                                       value: "Safety",
                                                   }, {
                                                       label: "Security",
                                                       value: "Security",
                                                   }, {
                                                       label: "Availability",
                                                       value: "Availability",
                                                   }, {
                                                       label: "Productivity",
                                                       value: "Productivity",
                                                   }, {
                                                       label: "Efficiency",
                                                       value: "Efficiency",
                                                   }, {
                                                       label: "Cost",
                                                       value: "Cost",
                                                   }, {
                                                       label: "Quality",
                                                       value: "Quality",
                                                   }]}
                                                   constraintText={'Please select the improvement category of this pain point, if it is a good idea, please submit it get manager approval.'}/>
                                </ColumnLayout>
                            </FormField>
                            <TilesFormField label={"Do you want to be action owner?"} tilesValue={tilesValue}
                                            items={tilesItemsValue}
                                            errorValue={""} onChange={(event) => handleActionOwner(event.detail.value)}
                                            constraintText={'Please evaluate whether you can act as action owner according to your time and skill.'}/>
                            <AutoSuggestFormField label={"First reviewer"} autoSuggestValue={reviewerValue}
                                                  errorValue={ErrorReviewerValue}
                                                  options={reviewerOptionsValue}
                                                  onChange={(event) => handleReviewer(event.detail.value)}
                                                  invalid={false}
                                                  constraintText={'The first viewer by default is the line manager, if this is a special item that needs to be reviewed by others, you can modify it.'}/>
                            {selectedLabelOptions.length > 0 && pushValues(selectedLabelOptions)[0] == "Good Idea" ?
                                <AutoSuggestFormField
                                    label={<div>Second reviewer<span style={{color: "red"}}> Optional</span></div>}
                                    autoSuggestValue={secondReviewerValue} errorValue={''}
                                    options={reviewerOptionsValue}
                                    onChange={(event) => handleSecondReviewer(event.detail.value)} invalid={false}
                                    constraintText={'The second viewer by default is empty, if this is a special item that needs second reviewer, we recommend filling in your OE Engineer or Cluster Manager'}/>
                                : null}
                            <PainPointGroupTags selectedOptions={ImpactTeamOptions} label={'Impact Teams'}
                                                errorValue={''}
                                                options={[{
                                                    label: "Select All",
                                                    options: [
                                                        {
                                                            label: "DCO",
                                                            value: "DCO",
                                                        },
                                                        {
                                                            label: "DCEO",
                                                            value: "DCEO",
                                                        }, {
                                                            label: "ID",
                                                            value: "ID",
                                                        }, {
                                                            label: "Security",
                                                            value: "Security",
                                                        }, {
                                                            label: "Logistic",
                                                            value: "Logistic",
                                                        }, {
                                                            value: "Decom Tech",
                                                            label: "Decom Tech"
                                                        }
                                                    ]
                                                }]} handleTags={({detail}) => handleImpactTeam(detail)}
                                                constraintText={'Please select all impacted team'}/>
                            <FormField>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <PainPointGroupTags selectedOptions={ImpactClusterOptions} label={'Impact Cluster'}
                                                        errorValue={''}
                                                        options={[{
                                                            label: "Select All",
                                                            options: OptionImpactCluster
                                                        }]} handleTags={({detail}) => handleImpactCluster(detail)}
                                                        constraintText={'Please select all impacted Clusters'}/>
                                    <PainPointGroupTags selectedOptions={ImpactAZOptions} label={'Impact AZ'}
                                                        errorValue={''}
                                                        options={[{
                                                            label: "Select All",
                                                            options: OptionImpactAZ
                                                        }]}
                                                        handleTags={({detail}) => handleImpactAZ(detail)}
                                                        constraintText={'Please select all impacted AZs'}/>
                                    <PainPointGroupTags selectedOptions={ImpactSiteOptions} label={'Impact Site'}
                                                        errorValue={''}
                                                        options={[{
                                                            label: "Select All",
                                                            options: OptionImpactSite
                                                        }]}
                                                        handleTags={({detail}) => handleImpactSite(detail)}
                                                        constraintText={'Please select all impacted Sites'}/>
                                </ColumnLayout>
                            </FormField>
                            <FileUploadForm onchange={({detail}) => handleFileUpload(detail.value)}
                                            value={FileUploadValue}
                                            label={'Attachment'} description={'Please upload the Attachment.'}
                                            errorText={errorTextFileUpload}/>
                        </SpaceBetween>
                    </Form>
                    : <Box textAlign="center"><Spinner size="large"/></Box>
                }
            </Container>
        </ContentLayout>
    );
}