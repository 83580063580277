import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Tiles from "@amzn/awsui-components-react/polaris/tiles";
import EmployeeAutoSuggest from '../Common/AutoSuggest'
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import Select from "@amzn/awsui-components-react/polaris/select";
import Box from "@amzn/awsui-components-react/polaris/box";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Grid from "@amzn/awsui-components-react/polaris/grid";

export interface InputFormFieldProps {
    label: any
    errorValue: string
    inputValue: string
    onChange: (event: any) => void
    constraintText: string
}

export interface TextareaFormFieldProps {
    label: any
    errorValue: string
    textareaValue: string
    onChange: (event: any) => void
    constraintText: string
}

export interface ButtonDropdownFormFieldProps {
    label: any
    context: string
    errorValue: string
    items: any
    onItemClick: (event: any) => void
    constraintText: string
}

export interface AutoSuggestFormFieldProps {
    label: any
    errorValue: string
    autoSuggestValue: string
    onChange: (event: any) => void
    options: any
    invalid: boolean
    constraintText: string
}

export interface TilesFormFieldProps {
    label: any
    errorValue: string
    tilesValue: string
    onChange: (event: any) => void
    items: any
    constraintText: string
}

export interface SelectPerferencesFormFieldProps {
    label: any
    description: any
    placeholder: any
    errorValue: string
    selectedOptions: any
    onChange: (event: any) => void
    options: any
    constraintText: string
}

export interface ToggleFormFieldProps {
    label: any
    errorValue: string
    onChange: (event: any) => void
    checked: boolean
    constraintText: string
    Content: string
}

export function InputFormField(props: InputFormFieldProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <Input
                    value={props.inputValue}
                    onChange={props.onChange}
                />
            </Box>
        </FormField>
    );
}

export interface InputNumberFormFieldProps {
    label: any
    errorValue: string
    inputValue: string
    onChange: (event: any) => void
    constraintText: string
    unit: string
}

export function InputNumberFormField(props: InputNumberFormFieldProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
        >
            <Grid
                disableGutters
                gridDefinition={[{colspan: 8}, {colspan: 4}]}
            >
                <Input
                    type='number'
                    value={props.inputValue}
                    onChange={props.onChange}
                    placeholder="0"
                />

                <TextContent>
                    <p>&nbsp;{props.unit}</p>
                </TextContent>
            </Grid>

        </FormField>
    );
}


export function AutoSuggestFormField(props: AutoSuggestFormFieldProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <EmployeeAutoSuggest
                    invalid={props.invalid}
                    autoSuggestValue={props.autoSuggestValue}
                    onChange={props.onChange}
                    options={props.options}
                />
            </Box>
        </FormField>
    );
}

export function TextareaFormField(props: TextareaFormFieldProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <Textarea
                    value={props.textareaValue}
                    onChange={props.onChange}
                    rows={4}
                />
            </Box>
        </FormField>
    );
}


export function ButtonDropdownFormField(props: ButtonDropdownFormFieldProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <ButtonDropdown
                    items={props.items}
                    onItemClick={props.onItemClick}
                >
                    {props.context}
                </ButtonDropdown>
            </Box>
        </FormField>
    );
}

export function TilesFormField(props: TilesFormFieldProps) {

    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <Tiles
                    onChange={props.onChange}
                    value={props.tilesValue}
                    items={props.items}
                />
            </Box>
        </FormField>
    );
}

export function SelectPerferences(props: SelectPerferencesFormFieldProps) {
    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            description={props.description}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <Multiselect
                    onChange={props.onChange}
                    selectedOptions={props.selectedOptions}
                    options={props.options}
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    placeholder={props.placeholder}
                    selectedAriaLabel="Selected"
                    filteringType="auto"
                    tokenLimit={3}
                />
            </Box>
        </FormField>
    );
}

export function ToggleForm(props: ToggleFormFieldProps) {
    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <Toggle
                    onChange={props.onChange}
                    checked={props.checked}
                >
                    {props.Content}
                </Toggle>
            </Box>
        </FormField>
    );
}

export interface SelectFormFieldProps {
    label: any
    errorValue: string
    constraintText: string
    selectedOption: any
    onChange: (detail: any) => void
    options: any
    description: any
    disable: boolean
}

export function SelectFormField(props: SelectFormFieldProps) {
    return (
        <FormField
            constraintText={props.constraintText}
            errorText={props.errorValue}
            label={props.label}
            description={props.description}
            stretch={true}
        >
            <Box
                margin={{right: "xxxl"}}>
                <Select
                    filteringType="auto"
                    selectedOption={props.selectedOption}
                    onChange={props.onChange}
                    options={props.options}
                    disabled={props.disable}
                    selectedAriaLabel="Selected"
                />
            </Box>
        </FormField>
    );
}

export interface DatePickerFormFieldProps {
    value: string
    onChange: (detail: any) => void
    label: any
    errorValue: string
    constraintText: string
    description: any
}

export function DatePickerFormField(props: DatePickerFormFieldProps) {
    return (
        <FormField
            label={props.label}
            constraintText={props.constraintText}
            stretch={true}
            errorText={props.errorValue}
            description={props.description}
        >
            <DatePicker
                ariaLabelledby="certificate-expiry-label"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                nextMonthAriaLabel="Next month"
                todayAriaLabel="Today"
                value={props.value}
                onChange={props.onChange}
                openCalendarAriaLabel={selectedDate =>
                    'Choose certificate expiry date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                }
            />
        </FormField>
    )
}