import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";

export interface PainPointFilterProps {
    query: any
    countText: string
    onChange: (event: any) => void
    filteringProperties: any
    filteringOptions: any
}


export default function PainPointPropertyFilter(props: PainPointFilterProps) {
    return (
        <PropertyFilter
            onChange={props.onChange}
            query={props.query}
            i18nStrings={{
                filteringAriaLabel: "your choice",
                dismissAriaLabel: "Dismiss",
                filteringPlaceholder:
                    "Filter distributions by text, property or value",
                groupValuesText: "Values",
                groupPropertiesText: "Properties",
                operatorsText: "Operators",
                operationAndText: "and",
                operationOrText: "or",
                operatorLessText: "Less than",
                operatorLessOrEqualText: "Less than or equal",
                operatorGreaterText: "Greater than",
                operatorGreaterOrEqualText:
                    "Greater than or equal",
                operatorContainsText: "Contains",
                operatorDoesNotContainText: "Does not contain",
                operatorEqualsText: "Equals",
                operatorDoesNotEqualText: "Does not equal",
                editTokenHeader: "Edit filter",
                propertyText: "Property",
                operatorText: "Operator",
                valueText: "Value",
                cancelActionText: "Cancel",
                applyActionText: "Apply",
                allPropertiesLabel: "All properties",
                tokenLimitShowMore: "Show more",
                tokenLimitShowFewer: "Show fewer",
                clearFiltersText: "Clear filters",
                removeTokenButtonAriaLabel: (token =>
                    `Remove token ${token.propertyKey} ${token.operator} ${token.value}`),
                enteredTextLabel: (text => `Use: "${text}"`)
            }}
            countText={props.countText}
            expandToViewport={true}
            filteringOptions={props.filteringOptions}
            filteringProperties={props.filteringProperties}
        />
    );
}