import {useEffect, useRef, useState} from 'react';
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table, {TableProps} from '@amzn/awsui-components-react/polaris/table';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import {TextFilterProps} from '@amzn/awsui-components-react/polaris/text-filter';
import axios from 'axios'
import {PropertyFilterProps} from "@amzn/awsui-components-react/polaris/property-filter";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import {
    CUSTOM_PREFERENCE_OPTIONS,
    EmptyState,
    getMatchesCountText,
    PAGE_SELECTOR_OPTIONS,
    paginationLabels,
    SUSTAINABILITY_CONTENT_SELECTOR_OPTIONS,
    SUSTAINABILITY_DEFAULT_PREFERENCES,
} from "../TableView/table-config";
import PainPointPropertyFilter from "../Common/PainPointFilter";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import ModalBox, {ConfirmationModalBox} from "../Common/ModalBox";
import AutoLink, {LoginIcon, updateDateToString, UserIcon} from "../Common/AutoLink";
import Box from "@amzn/awsui-components-react/polaris/box";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import "../Common/table-select.scss";
import SustainabilityDetailView from "../SustainabilityDetailView/SustainabilityDetailView";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";

export default function SustainabilityDashboard() {
    const defaultCluster: any = {value: 'Any', label: 'Any Cluster'}
    const SUSTAINABILITY_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
        {
            id: 'ProjectName',
            header: 'Project Name',
            cell: item => (<Box>{item.hasOwnProperty('ChildrenList') && item.ChildrenList.length > 0 ?
                <Badge
                    color="green">Parent</Badge> : item.hasOwnProperty('AdoptState') && item.AdoptState == 'Children' ?
                    <Badge color="red">Children</Badge> : null}{" "}<Link
                onFollow={() => ModalCell(item)}>{item.ProjectName}</Link></Box>),
            // cell: item => (<Link onFollow={ModalCell}>{item.Title}</Link>),
            minWidth: 330,
            // maxWidth: 240,
            width: 330,
        }, {
            id: 'BriefDescription',
            // cell: item => <TablePopover content={item.Description}></TablePopover>,
            cell: item => item.BriefDescription,
            header: 'Brief Description',
            minWidth: 210,
            // maxWidth: 160,
            width: 210,
        }, {
            id: 'Cluster',
            header: 'Cluster',
            cell: item => item.Cluster,
            minWidth: 130,
            // maxWidth: 100,
            width: 130,
            sortingField: 'Cluster'
        }, {
            id: 'InfraOpsOwner',
            header: 'InfraOps Owner',
            cell: item => (<Badge color="green">{item.InfraOpsOwner}</Badge>),
            minWidth: 180,
            // maxWidth: 100,
            width: 180,
            sortingField: 'InfraOpsOwner'
        }, {
            id: 'EnviroOwner',
            header: 'Enviro Owner',
            cell: item => (<LoginIcon user={item.EnviroOwner}/>),
            minWidth: 180,
            // maxWidth: 100,
            width: 180,
            sortingField: 'EnviroOwner'
        }, {
            id: 'Submitter',
            header: 'Submitter',
            cell: item => (<Box textAlign="center"><UserIcon user={item.Submitter}/></Box>),
            minWidth: 110,
            // maxWidth: 100,
            width: 110,
            sortingField: 'Submitter'
        }, {
            id: 'SubmitDate',
            header: 'Submit Date',
            cell: item => (<div>{item.SubmitDate}</div>),
            minWidth: 110,
            // maxWidth: 140,
            width: 110,
            sortingField: 'SubmitDate'
        },
        {
            id: 'State',
            header: 'State',
            cell: item => (<StatusIndicator
                type={item.State === 'Assigned' ? 'info' : item.State === 'Researching' ? 'warning' : item.State === 'Working in Process' ? 'in-progress' : item.State === 'Pending' ? 'pending' : item.State === 'Completed' ? 'success' : 'error'}>{item.State}</StatusIndicator>),
            minWidth: 140,
            // maxWidth: 100,
            width: 140,
            sortingField: 'State'
        },
        {
            id: 'ProjectOutcomes',
            header: 'Project Outcomes',
            cell: item => (
                <ColumnLayout columns={3} variant="text-grid">
                    <div>
                        <Box variant="awsui-key-label">Financial Savings</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.Financial}&nbsp;$USD/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Energy Efficiency</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.EnergyEfficiency}&nbsp;kWh/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Carbon footprint</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.CarbonFootprint}&nbsp;Tonnes CO₂e/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Water efficiency</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.WaterEfficiency}&nbsp;m³/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Waste recycling & reduction</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.WasteRecycling}&nbsp;Kg/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Energy Efficiency(Diesel)</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.DieselEnergy}&nbsp;kL/Year
                        </StatusIndicator>
                    </div>
                </ColumnLayout>
            ),
            minWidth: 740,
            // maxWidth: 100,
            width: 740,
        }, {
            id: 'ProjectCosts',
            header: 'Project Costs & Expenses',
            cell: item => <ColumnLayout columns={3} variant="text-grid">
                <div>
                    <Box variant="awsui-key-label">Time to payback</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.Payback}&nbsp;Months
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Effort (Implement)</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.ImplementEffort}&nbsp;Man hours
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Effort (Ongoing)</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.OngoingEffort}&nbsp;Man hours/Year
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Capital costs</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.CapitalCosts}&nbsp;$USD
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Operational costs</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.OperationalCosts}&nbsp;$USD/Year
                    </StatusIndicator>
                </div>
            </ColumnLayout>,
            minWidth: 740,
            // maxWidth: 100,
            width: 740,
        }, {
            id: 'Relevant',
            header: 'Relevant',
            cell: item => item.Relevant,
            minWidth: 160,
            // maxWidth: 100,
            width: 160,
            sortingField: 'Relevant'
        }, {
            id: 'Impact',
            header: 'Impact',
            cell: item => item.Impact,
            minWidth: 210,
            // maxWidth: 100,
            width: 210,
            sortingField: 'Impact'
        }, {
            id: 'Investment',
            header: 'Investment',
            cell: item => item.Investment,
            minWidth: 150,
            // maxWidth: 100,
            width: 150,
            sortingField: 'Investment'
        }, {
            id: 'Implementation',
            header: 'Implementation',
            cell: item => item.Implementation,
            minWidth: 150,
            // maxWidth: 100,
            width: 2150,
            sortingField: 'Implementation'
        }, {
            id: 'StartDate',
            header: 'Start Date',
            cell: item => (<div>{item.StartDate} </div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'StartDate'
        }, {
            id: 'CompletedDate',
            header: 'Completed Date',
            cell: item => (<div>{item.CompletedDate}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'CompletedDate'
        },
    ]
    const [permissionUser, setPermissionUser] = useState(false)
    const [allItems, setAllItems] = useState<any>([])
    const [loginUser, setLoginUser] = useState('')

    const selectClusterOptions = prepareSelectOptions('SubmitterCluster', {value: 'Any', label: 'Any Cluster'})
    const ModalCell = (detail) => {
        setItemslist(detail)
        setVisible(true)
    }
    const removeAction = () => {
        console.log('remove')
        axios({
            method: "DELETE",
            data: {id: selectedSustainabilityItems[0]["id"]},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/promote",
        }).then(res => {
            loadItems()
        })
    }

    function prepareSelectOptions(field: any, defaultOption: any) {
        const optionSet = [];
        // Building a non redundant list of the field passed as parameter.

        allItems.forEach((item: any) => {
            // @ts-ignore
            if (optionSet.indexOf(item[field]) === -1) {
                // @ts-ignore
                optionSet.push(item[field]);
            }
        });
        optionSet.sort();

        // The first element is the default one.
        const options = [defaultOption];

        // Adding the other element ot the list.
        optionSet.forEach((item, index) => options.push({label: item, value: (index + 1).toString()}));
        return options;
    }

    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/getuser",
        }).then(res => {
            let userSetting: any = []
            res.data.hasOwnProperty('Preferences') ? userSetting = res.data.Preferences :
                console.log('Default Setting')
            userSetting.push('PromoteBy')
            userSetting.push('PromoteDate')
            userSetting.push('PromoteReason')
            res.data.hasOwnProperty('Preferences') ? setPreferences(userSetting) :
                console.log('Default Setting')

        })
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [preferences, setPreferences] = useState(SUSTAINABILITY_DEFAULT_PREFERENCES);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);
    const [refreshLoading, setRefreshLoading] = useState(false)
    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);
    // useEffect(() => {
    //     propertyFilterProps.query.operation = query.operation
    //     propertyFilterProps.query.tokens = query.tokens
    // })
    const loadItems = async () => {
        setLoading(true);
        setRefreshLoading(true)
        setSelectedSustainabilityItems([])
        await axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/pool",
        }).then(res => {
            setAllItems(res.data)
            setLoading(false);
            setRefreshLoading(false)
        });
    }

    function matchesEngine(item, clusterFilter) {
        return clusterFilter.value === 'Any' || item.SubmitterCluster === clusterFilter.label;
    }

    useEffect(() => {
        loadItems()
    }, []);
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    })
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
        propertyFilterProps
    } = useCollection(
        allItems || [],
        {
            propertyFiltering: {
                defaultQuery: query,
                filteringProperties: [
                    {
                        key: "Submitter",
                        operators: ["=", ":"],
                        propertyLabel: "Submitter",
                        groupValuesLabel: "Submitter"
                    }
                ],
                empty: (
                    <EmptyState
                        title="No Sustainability Initiative"
                        subtitle="No Sustainability Initiative to display."
                        action={<Button href="#/create_painpoint">Create PainPoint/GoodIdea</Button>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No Sustainability Initiative"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={<p>Please verify your filter condition!</p>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {
                defaultState: {
                    sortingColumn: SUSTAINABILITY_COLUMN_DEFINITIONS[13],
                    isDescending: true,
                }
            },


            // selection: {}
        }
    )
    // const selectedItems = collectionProps.selectedItems!;
    const [selectedSustainabilityItems, setSelectedSustainabilityItems] = useState<any>([])
    const isOnlyOneSelected = selectedSustainabilityItems.length === 1;
    const [Itemslist, setItemslist] = useState<any>({})
    const [visible, setVisible] = useState<boolean>(false);
    const [deleteAction, setDeleteAction] = useState('')
    const [DeniedVisible, setDeniedVisible] = useState<boolean>(false);
    const [DeleteVisible, setDeleteVisible] = useState<boolean>(false);
    const setUserPreferences = (event) => {
        setPreferences(event)
        // axios({
        //     method: "POST",
        //     data: event,
        //     url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/updatepreferences",
        // }).then(res => {
        //     console.log(event)
        // })
    }

    const handlePainPointFilter = (event) => {
        setQuery(event.detail)
        loadItems()
        propertyFilterProps.query.operation = event.detail.operation
        propertyFilterProps.query.tokens = event.detail.tokens
    }

    return (
        <ContentLayout
            header={
                <></>
            }
        >
            <SpaceBetween size="m">
                <Table
                    {...collectionProps}
                    selectedItems={selectedSustainabilityItems}
                    onSelectionChange={({detail}) => setSelectedSustainabilityItems(detail.selectedItems)}
                    onRowClick={({detail}) => setSelectedSustainabilityItems([detail.item])}
                    visibleColumns={preferences.visibleContent}
                    wrapLines={preferences.wrapLines}
                    loading={loading}
                    loadingText="Loading Pain Point/Good Idea List"
                    selectionType="single"
                    stickyHeader={true}
                    variant='container'
                    // onRowClick={ModalCell}
                    // sortingDescending={true}
                    resizableColumns={true}
                    header={
                        <Header
                            variant="awsui-h1-sticky"
                            counter={
                                allItems &&
                                (selectedSustainabilityItems!.length ? `(${selectedSustainabilityItems!.length}/${allItems.length})` : `(${allItems.length})`)
                            }
                            actions={
                                <SpaceBetween size="xs" direction="horizontal">
                                    <Button onClick={() => loadItems()} loading={refreshLoading}><Icon
                                        name="refresh"/>&ensp; Refresh</Button>

                                    <Button disabled={!isOnlyOneSelected} onClick={() => {
                                        window.location.href = "#/sustainability/detail/" + selectedSustainabilityItems[0]["id"]
                                    }}>Trace items</Button>
                                    <Button disabled={!isOnlyOneSelected} onClick={removeAction}>Remove</Button>
                                </SpaceBetween>
                            }
                        >
                            Sustainability Initiatives Pool
                        </Header>
                    }
                    columnDefinitions={SUSTAINABILITY_COLUMN_DEFINITIONS}
                    items={items}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                    filter={
                        <Grid
                            gridDefinition={[{colspan: 3}, {colspan: 4}, {colspan: 3}, {colspan: 2}]}
                        >
                            <PainPointPropertyFilter
                                {...propertyFilterProps}
                                countText={getMatchesCountText(filteredItemsCount!)}
                                filteringOptions={[
                                    {
                                        propertyKey: "Submitter",
                                        value: loginUser
                                    }
                                ]}
                                filteringProperties={propertyFilterProps.filteringProperties}
                                onChange={(event) => handlePainPointFilter(event)}
                                query={query}
                            />
                            <div></div>
                            <div></div>
                            <div></div>
                        </Grid>

                    }
                    preferences={
                        <CollectionPreferences
                            preferences={preferences}
                            onConfirm={event => setUserPreferences(event.detail)}
                            title="Preferences Setting"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            visibleContentPreference={{
                                title: 'Select visible columns',
                                options: SUSTAINABILITY_CONTENT_SELECTOR_OPTIONS
                            }}
                            pageSizePreference={{
                                title: 'Page size',
                                options: PAGE_SELECTOR_OPTIONS
                            }}
                            wrapLinesPreference={{
                                label: 'Wrap lines',
                                description: 'Check to see all the text and wrap the lines'
                            }}
                            customPreference={(state, setState) => (
                                <FormField stretch={true} label="View as">
                                    <RadioGroup
                                        value={state}
                                        onChange={event => setState(event.detail.value)}
                                        items={CUSTOM_PREFERENCE_OPTIONS}
                                    />
                                </FormField>
                            )}
                        />
                    }
                />
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    className="ModelPainPointDetails"
                    closeAriaLabel="Close modal"
                    id="ModelPainPointDetails"
                    size="large"
                    header="Sustainability Initiative Details"
                >
                    {Itemslist.hasOwnProperty('id') ?
                        <SustainabilityDetailView SustainabilityDetail={Itemslist}
                                                  loginUser={loginUser} updateDetail={loadItems}/>
                        : <Box textAlign="center"><Spinner size="large"/></Box>}

                </Modal>
                <ModalBox cancel={() => setDeniedVisible(false)} onDismiss={() => setDeniedVisible(false)} content={
                    <h4>You don't have permission to Edit/Remove the Item</h4>
                } id={"ModelPermissionDenied"} header={'Error'} visible={DeniedVisible}
                          confirm={() => setDeniedVisible(false)}/>
            </SpaceBetween>
        </ContentLayout>
    )
}