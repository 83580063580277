import {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Form from "@amzn/awsui-components-react/polaris/form";
import {
    DatePickerFormField,
    InputFormField, InputNumberFormField,
    SelectFormField,
    TextareaFormField,
} from "../Common/FormField"
import axios from 'axios'
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import ModalBox from "../Common/ModalBox";
import FileUploadForm from "../Common/FileUpload";
import useInterval from "../Common/IntervalHook";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import {CHILDREN, CLUSTER, LOCATION} from "../Common/config";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {pushToTag, pushToTagsWithList} from "../Common/Token";
import Box from '@amzn/awsui-components-react/polaris/box';
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

export interface CopySustainabilityContentProps {
    id: any
}

export default function CopySustainability(props: CopySustainabilityContentProps) {
    const Notification_id = props.id
    const [sustainabilityId, setSustainabilityId] = useState('')
    const [sustainabilityDetail, setSustainabilityDetail] = useState<any>()
    const history = useHistory();
    const [loginUser, setLoginUser] = useState<any>()
    const [SubmitterCluster, setSubmitterCluster] = useState<any>({})
    const [SubmitterClusterOptions, setSubmitterClusterOptions] = useState(pushToTagsWithList(CLUSTER))
    useEffect(() => {
        setStartDate(nowDate)
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
            axios({
                method: "GET",
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/username/" + res.data.authorizer.lambda.midwayUser,
            }).then(res => {
                setSubmitterCluster(pushToTag(res.data.Cluster))
            })

        })
    }, [])
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/notification/" + Notification_id,
        }).then(res => {
            setSustainabilityId(res.data.RelatedProject)
            axios({
                method: "GET",
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability/" + res.data.RelatedProject,
            }).then(res => {
                setSustainabilityDetail(res.data)
                setSubmissionDate(res.data.SubmitDate)
                setStartDate(res.data.StartDate)
                setEndDate(res.data.CompletedDate)
                setTitleValue(res.data.ProjectName)
                setCountermeasureValue(res.data.Impact)
                setInfraOpsOwnerValue(res.data.InfraOpsOwner)
                setEnviroOwnerValue(res.data.EnviroOwner)
                setBenefitValue(res.data.Investment)
                setDescriptionValue(res.data.BriefDescription)
                setImplementationValue(res.data.Implementation)
                setRelevantValue(res.data.Relevant)
                setDistributionPanelData(res.data.Unit)
            })
        })
    }, [])

    const [titleValue, setTitleValue] = useState<string>("");
    const [countermeasureValue, setCountermeasureValue] = useState("");
    // const [attachmentValue, setAttachmentValue] = useState("");
    const [InfraOpsOwnerValue, setInfraOpsOwnerValue] = useState("");
    const [EnviroOwnerValue, setEnviroOwnerValue] = useState("");
    const [errorBenefitValue, setErrorBenefitValue] = useState("")
    const [errorTextFileUpload, setErrorTextFileUpload] = useState("")
    const [benefitValue, setBenefitValue] = useState("")
    const [descriptionValue, setDescriptionValue] = useState("");
    const [errorTitleValue, setErrorTitleValue] = useState("");
    const [errorDescriptionValue, setErrorDescriptionValue] = useState("");
    const [errorCountermeasureValue, setErrorCountermeasureValue] = useState("");
    const [SubmitBtnLoading, setSubmitBtnLoading] = useState(false)
    const [SubmitSuccess, setSubmitSuccess] = useState(false)
    const [FileUploadValue, setFileUploadValue] = useState<any>([])
    const [startDate, setStartDate] = useState<string>('')
    const [submissionDate, setSubmissionDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')

    const handleDetail = (value) => {
        setDescriptionValue(value)
    }
    const [implementationValue, setImplementationValue] = useState("");
    const [errorImplementationValue, setErrorImplementationValue] = useState("");

    const handleImplementation = (value) => {
        setImplementationValue(value)
    }
    const [relevantValue, setRelevantValue] = useState("");
    const [errorRelevantValue, setErrorRelevantValue] = useState("");
    const handleRelevant = (value) => {
        setRelevantValue(value)
    }
    const handlTitleInput = (value: string) => {
        setTitleValue(value)
    }
    const handlCountermeasureInput = (value) => {
        setCountermeasureValue(value)
    }
    const handleBenefit = (value) => {
        setBenefitValue(value)
    }
    const handleInfraOpsOwner = (value) => {
        setInfraOpsOwnerValue(value)
    }
    const handleEnviroOwner = (value) => {
        setEnviroOwnerValue(value)
    }
    const handleFileUpload = (value) => {
        console.log(value)
        if (value.length > 0) {
            value[0]['size'] > 4194304 ?
                setErrorTextFileUpload('File size should not exceed 4 MB!!!') :
                setFileUploadValue(value)
        } else if (value.length == 0) {
            setFileUploadValue(value)
        }
    }
    const handleSubmitterCluster = (value) => {
        setSubmitterCluster(value)
    }

    const submitAction = () => {
        setSubmitBtnLoading(true)
        let fileName = ''
        let fileType = ''
        FileUploadValue[0] ? fileName = FileUploadValue[0]['name'] : fileName = ''
        FileUploadValue[0] ? fileType = FileUploadValue[0]['type'].replace('/', '-') : fileType = ''
        if (titleValue != "" && descriptionValue != "" && benefitValue != "") {
            let distributionPanelDataNew: any = {}
            for (let key in distributionPanelData) {
                distributionPanelDataNew[key] = distributionPanelData[key]
                if (distributionPanelData[key] == '') {
                    distributionPanelDataNew[key] = '0'
                }
            }
            const submitDict = {
                "NotificationID": Notification_id,
                "RelatedProject": sustainabilityId,
                "ProjectName": titleValue,
                "BriefDescription": descriptionValue,
                "Impact": countermeasureValue,
                "Investment": benefitValue,
                "Relevant": relevantValue,
                "Implementation": implementationValue,
                "InfraOpsOwner": InfraOpsOwnerValue,
                "EnviroOwner": EnviroOwnerValue,
                "Attachment": FileUploadValue,
                "Submitter": loginUser,
                "SubmitDate": submissionDate,
                "Cluster": SubmitterCluster['value'],
                "Unit": distributionPanelDataNew,
                "StartDate": startDate,
                "CompletedDate": endDate
            }
            axios({
                method: "POST",
                data: submitDict,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability",
            }).then(res => {
                let painpoint_id = res.data
                if (FileUploadValue[0]) {
                    axios({
                        method: "POST",
                        data: FileUploadValue[0],
                        url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/uploadsustainabilityfile/" + painpoint_id + '/' + fileName + '/' + fileType,
                    }).then(res => {
                        setSubmitBtnLoading(false)
                    })
                }
                setSubmitSuccess(true)
            })
        } else {
            if (titleValue == "") {
                setErrorTitleValue('Please add the Sustainability Initiate Title!')
            } else {
                setErrorTitleValue("")
            }
            if (descriptionValue == "") {
                setErrorDescriptionValue('Please add the Sustainability Initiate Description!')
            } else {
                setErrorDescriptionValue("")
            }
            if (benefitValue == "") {
                setErrorBenefitValue('Please add the Sustainability Initiate Benefit!')
            } else {
                setErrorBenefitValue("")
            }
            setSubmitBtnLoading(false)
        }
    }
    const resetAction = () => {
        setTitleValue("")
        setInfraOpsOwnerValue("")
        setEnviroOwnerValue("")
        setDescriptionValue("")
        setCountermeasureValue("")
        setBenefitValue("")
        setFileUploadValue('')
    }
    const BackToHome = () => {
        history.push('/Sustainability Initiative')
        setSubmitSuccess(false)
    }
    const [AutoSaveAlert, setAutoSaveAlert] = useState<any>([])
    const Format = (date, format) => {
        var o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "H+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return format;
    }
    const nowDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份从0开始，需要加1
        const day = date.getDate();
        return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    }
    const defaultState = {
        Financial: '',
        EnergyEfficiency: '',
        CarbonFootprint: '',
        WaterEfficiency: '',
        WasteRecycling: '',
        Payback: '',
        ImplementEffort: '',
        OngoingEffort: '',
        CapitalCosts: '',
        OperationalCosts: '',
        DieselEnergy: ''
    };

    const [distributionPanelData, setDistributionPanelData] = useState(defaultState)
    const onChange = (attribute, value) => {
        const newState = {...distributionPanelData};
        console.log(newState)
        newState[attribute] = value;
        console.log(newState)
        setDistributionPanelData(newState);
    };

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Flashbar items={AutoSaveAlert}></Flashbar>
                    <Header
                        variant="h1"
                        actions={
                            <Button href='https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/' variant="primary"
                                    target="_blank">Help</Button>
                        }
                    >
                        Sustainability Champions Program Case Study
                    </Header>
                </SpaceBetween>
            }
        >
            <Container>
                {sustainabilityDetail ?
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="l">
                                <Button formAction="none" variant="link"
                                        onClick={() => history.push('/Sustainability Initiative')}>
                                    Cancel
                                </Button>
                                <Button loading={SubmitBtnLoading} onClick={() => submitAction()}
                                        variant="primary">Submit</Button>
                            </SpaceBetween>
                        }
                    >

                        <Grid
                            gridDefinition={[{colspan: 10}, {colspan: 2}]}
                        >
                            <SpaceBetween size="xs">
                                <ColumnLayout columns={4} variant="text-grid">
                                    <SelectFormField label={<div><span style={{color: "red"}}>*</span>Cluster</div>}
                                                     constraintText={'Default value is your Cluster'}
                                                     selectedOption={SubmitterCluster} options={SubmitterClusterOptions}
                                                     disable={false}
                                                     onChange={({detail}) => handleSubmitterCluster(detail.selectedOption)}
                                                     errorValue={''} description={''}/>
                                    <DatePickerFormField onChange={({detail}) => setSubmissionDate(detail.value)}
                                                         value={submissionDate}
                                                         label={<div><span style={{color: "red"}}>*</span>Submission
                                                             Date</div>}
                                                         constraintText={'Please select date of submission'}
                                                         errorValue={''} description={''}/>

                                    <DatePickerFormField onChange={({detail}) => setStartDate(detail.value)}
                                                         value={startDate}
                                                         label={<div><span style={{color: "red"}}>*</span>Start Date
                                                         </div>}
                                                         constraintText={'Please select date of project implementation'}
                                                         errorValue={''} description={''}/>

                                    <DatePickerFormField onChange={({detail}) => setEndDate(detail.value)}
                                                         value={endDate}
                                                         label={<div><span style={{color: "red"}}>*</span>End Date
                                                         </div>}
                                                         constraintText={'Please select date of project end. Choose 2 years after implementation if project ongoing.'}
                                                         errorValue={''} description={''}/>
                                </ColumnLayout>
                                <InputFormField
                                    label={<div><span style={{color: "red"}}>*</span>Project Name</div>}
                                    inputValue={titleValue} errorValue={errorTitleValue}
                                    onChange={(event) => handlTitleInput(event.detail.value)}
                                    constraintText={'Please keep it within 10 words.'}/>
                                <TextareaFormField
                                    label={<div><span style={{color: "red"}}>*</span>Brief Description</div>}
                                    textareaValue={descriptionValue} errorValue={errorDescriptionValue}
                                    onChange={(event) => handleDetail(event.detail.value)}
                                    constraintText={'Please provide more information to describe your Sustainability Initiate.'}/>
                                <TextareaFormField label='Estimated impact at time of submission'
                                                   textareaValue={countermeasureValue}
                                                   errorValue={errorCountermeasureValue}
                                                   onChange={(event) => handlCountermeasureInput(event.detail.value)}
                                                   constraintText={''}/>
                                <InputFormField
                                    label={<div><span style={{color: "red"}}>*</span>Estimated investment at time of
                                        submission (man hour & cost in USD)
                                    </div>}
                                    inputValue={benefitValue} errorValue={errorBenefitValue}
                                    onChange={(event) => handleBenefit(event.detail.value)}
                                    constraintText={''}/>

                                <TextareaFormField
                                    label={<div><span style={{color: "red"}}>*</span>Where is this project relevant
                                        (equipment/geography etc):</div>}
                                    textareaValue={relevantValue} errorValue={errorRelevantValue}
                                    onChange={(event) => handleRelevant(event.detail.value)}
                                    constraintText={''}/>

                                <TextareaFormField
                                    label={<div><span style={{color: "red"}}>*</span>Challenges during implementation
                                        AND/OR
                                        trick and tips:</div>}
                                    textareaValue={implementationValue} errorValue={errorImplementationValue}
                                    onChange={(event) => handleImplementation(event.detail.value)}
                                    constraintText={''}/>

                                <InputFormField
                                    label={<div><span style={{color: "red"}}>*</span>InfraOps POC/s</div>}
                                    inputValue={InfraOpsOwnerValue}
                                    errorValue={""} onChange={(event) => handleInfraOpsOwner(event.detail.value)}
                                    constraintText={'(Alias POC1, Alias POC2,........)'}/>
                                <InputFormField
                                    label={<div><span style={{color: "red"}}>*</span>AWS Enviro POC/s</div>}
                                    inputValue={EnviroOwnerValue}
                                    errorValue={""} onChange={(event) => handleEnviroOwner(event.detail.value)}
                                    constraintText={'(Alias POC1, Alias POC2,........)'}/>

                                <FormField stretch={true}
                                           label={<div>Project Outcomes:</div>}>
                                    <ColumnLayout columns={3}>
                                        <InputNumberFormField
                                            label={"Financial Savings"} inputValue={distributionPanelData.Financial}
                                            errorValue={""} onChange={({detail}) => onChange('Financial', detail.value)}
                                            constraintText={'Enter energy savings'} unit={"$USD/Year"}/>
                                        <InputNumberFormField
                                            label={"Energy Efficiency"}
                                            inputValue={distributionPanelData.EnergyEfficiency}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('EnergyEfficiency', detail.value)}
                                            constraintText={'Enter energy savings'} unit={"kWh/Year"}/>
                                        <InputNumberFormField
                                            label={"Carbon footprint"}
                                            inputValue={distributionPanelData.CarbonFootprint}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('CarbonFootprint', detail.value)}
                                            constraintText={'Enter greenhouse gas savings'}
                                            unit={"Tonnes CO₂e/Year"}/>
                                        <InputNumberFormField
                                            label={"Water efficiency"}
                                            inputValue={distributionPanelData.WaterEfficiency}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('WaterEfficiency', detail.value)}
                                            constraintText={'Enter water savings'} unit={"m³/Year"}/>
                                        <InputNumberFormField
                                            label={"Waste recycling & reduction"}
                                            inputValue={distributionPanelData.WasteRecycling}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('WasteRecycling', detail.value)}
                                            constraintText={'Enter waste savings'} unit={"Kg/Year"}/>
                                        <InputNumberFormField
                                            label={"Energy Efficiency"}
                                            inputValue={distributionPanelData.DieselEnergy}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('DieselEnergy', detail.value)}
                                            constraintText={'Enter diesel savings'} unit={"kL/Year"}/>
                                    </ColumnLayout>
                                </FormField>
                                <FormField stretch={true}
                                           label={<div>Project Costs:</div>}>
                                    <ColumnLayout columns={3}>
                                        <InputNumberFormField
                                            label={"Time to payback"} inputValue={distributionPanelData.Payback}
                                            errorValue={""} onChange={({detail}) => onChange('Payback', detail.value)}
                                            constraintText={'Enter simple payback'} unit={"Months"}/>
                                        <InputNumberFormField
                                            label={"Effort (Implement)"}
                                            inputValue={distributionPanelData.ImplementEffort}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('ImplementEffort', detail.value)}
                                            constraintText={'Enter staff time'} unit={"Man hours"}/>
                                        <InputNumberFormField
                                            label={"Effort (Ongoing)"} inputValue={distributionPanelData.OngoingEffort}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('OngoingEffort', detail.value)}
                                            constraintText={'Enter staff time'} unit={"Man hours/Year"}/>
                                        <InputNumberFormField
                                            label={"Capital costs"} inputValue={distributionPanelData.CapitalCosts}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('CapitalCosts', detail.value)}
                                            constraintText={'Enter costs'} unit={"$USD"}/>
                                        <InputNumberFormField
                                            label={"Operational costs"}
                                            inputValue={distributionPanelData.OperationalCosts}
                                            errorValue={""}
                                            onChange={({detail}) => onChange('OperationalCosts', detail.value)}
                                            constraintText={'Enter costs'} unit={"$USD/Year"}/>
                                    </ColumnLayout>
                                </FormField>
                                <FileUploadForm onchange={({detail}) => handleFileUpload(detail.value)}
                                                value={FileUploadValue}
                                                label={'Attachment'} description={'Please upload the Attachment.'}
                                                errorText={errorTextFileUpload}/>
                            </SpaceBetween>
                            <div></div>
                        </Grid>
                    </Form>
                    : <Box textAlign="center"><Spinner size="large"/></Box>
                }
            </Container>
            <ModalBox cancel={resetAction} onDismiss={() => setSubmitSuccess(false)} content={
                <h4>Submitted Successful! Please return back to home page.</h4>
            } id={"ModalSubmitSuccess"} header={'Successful'} visible={SubmitSuccess} confirm={() => BackToHome()}/>
        </ContentLayout>
    )
        ;
}