import React from 'react'
import Popover from "@amzn/awsui-components-react/polaris/popover";
import Link from "@amzn/awsui-components-react/polaris/link";
import '../Common/base.scss'
import Badge from "@amzn/awsui-components-react/polaris/badge";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Grid from "@amzn/awsui-components-react/polaris/grid";
import {useEffect, useRef, useState} from 'react';
import '../DetailView/badge.scss'
import axios from 'axios';
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';

export interface AutoLinkProps {
    text: string | undefined
}

export default function AutoLink(props: AutoLinkProps) {
    // const delimiter =/(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi
    // const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;
    // return (
    //     <div>
    //         {props.text ? (props.text.split(delimiter).map((word, index) => {
    //             let match = word.match(delimiter);
    //             console.log(word)
    //             if (match) {
    //                 console.log(match)
    //                 let url = match[0];
    //                 if (url.startsWith("http") || url.startsWith("https")) {
    //                     return <div key={index}><a href={url.startsWith("https") ? url : `https://${url}`} key={url}
    //                                                target="_blank">{url}</a></div>
    //                 } else {
    //                     return <span key={index} dangerouslySetInnerHTML={{__html: htmlBackEscape(word)}}></span>
    //                 }
    //             }
    //             return word.split('\n').map((item, index) => {
    //                 if (item != '') {
    //                     return <span key={index} dangerouslySetInnerHTML={{__html: htmlBackEscape(item)}}></span>
    //                 }
    //
    //             })
    //         })) : null}
    //     </div>
    // )
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
        <div>
            {props.text ? (props.text.split(delimiter).map((word, index) => {
                let match = word.match(delimiter);
                if (match) {
                    let url = match[0];
                    if (url.startsWith("http") || url.startsWith("https")) {
                        return <div key={index}><a href={url.startsWith("https") ? url : `https://${url}`} key={url}
                                                   target="_blank">{url}</a></div>
                    } else {
                        return <span key={index} dangerouslySetInnerHTML={{__html: htmlBackEscape(word)}}></span>
                    }
                }
                return word.split('\n').map((item, index) => {
                    if (item != '') {
                        return <div key={index} dangerouslySetInnerHTML={{__html: htmlBackEscape(item)}}></div>
                    }
                })
            })) : null}
        </div>
    )
}

const htmlTest = (html: string) => {

    let ver = <div></div>
    return html.replace(/((?<=(<span contenteditable="false"><span class="at-item">)).*(?=(<\/span><\/span>)))/g, function (match, pos, originalText) {
        switch (match) {
            case "&lt;":
                return "<";
            case "&gt;":
                return ">"
            case "&amp;":
                return "&";
            case "&quot;":
                return "\"";
            case "&nbsp;":
                return " ";
            default:
                return match;
        }
    });
}

export const htmlBackEscape = (html: string) => {
    return html.replace(/(&lt;)|(&gt;)|(&quot;)|(&amp;)|(&nbsp;)/g, function (match, pos, originalText) {
        switch (match) {
            case "&lt;":
                return "<";
            case "&gt;":
                return ">"
            case "&amp;":
                return "&";
            case "&quot;":
                return "\"";
            case "&nbsp;":
                return " ";
            default:
                return match;
        }
    });
}

interface DetailPopover {
    uuid: string;
    type: string;
}

export function DetailPopover(props: DetailPopover) {
    const [detailLoading, setDetailLoading] = useState(true)
    const [itemDetail, setItemDetail] = useState<any>({})
    useEffect(() => {
        getItemDetail()
    }, [])
    const getItemDetail = () => {
        axios({
            method: "GET",
            url: props.type == 'PainPoint&&GoodIdea' ? "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/" + props.uuid : "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability/" + props.uuid,
        }).then(res => {
            setItemDetail(res.data)
            setDetailLoading(false)
        })
    }
    return (
        <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="custom"
            content={detailLoading ?
                <Box textAlign="center"><Spinner size="large"/></Box> :
                props.type == 'Sustainability' ?
                    <SpaceBetween size="xxs">
                        <Box>
                            <Box variant="awsui-key-label">Project Name</Box>
                            <AutoLink text={itemDetail.ProjectName}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Brief Description</Box>
                            <AutoLink text={itemDetail.BriefDescription}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Estimated impact at time of submission</Box>
                            <AutoLink text={itemDetail.Impact}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Estimated investment at time of submission (man hour & cost
                                in USD)</Box>
                            <AutoLink text={itemDetail.Investment}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Where is this project relevant (equipment/geography
                                etc)</Box>
                            <AutoLink text={itemDetail.Relevant}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Challenges during implementation AND/OR trick and tips</Box>
                            <AutoLink text={itemDetail.Implementation}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">InfraOps POC/s</Box>
                            <AutoLink text={itemDetail.InfraOpsOwner}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">AWS Enviro POC/s</Box>
                            <AutoLink text={itemDetail.EnviroOwner}></AutoLink>
                        </Box>
                        <Link external={true} href={'#/sustainability/detail/' + props.uuid}>Details</Link>
                    </SpaceBetween> :
                    <SpaceBetween size="xxs">
                        <Box>
                            <Box variant="awsui-key-label">Title</Box>
                            <AutoLink text={itemDetail.Title}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Problem Statement</Box>
                            <AutoLink text={itemDetail.Description}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Countermeasure</Box>
                            <AutoLink text={itemDetail.Countermeasure}></AutoLink>
                        </Box>
                        <Box>
                            <Box variant="awsui-key-label">Benefit</Box>
                            <AutoLink text={itemDetail.Benefit}></AutoLink>
                        </Box>
                        <Link external={true} href={'#/detail/' + props.uuid}>Details</Link>
                    </SpaceBetween>
            }
        >
            {detailLoading ? <Spinner/> : <Link external={true}>
                {props.type == 'Sustainability' ? itemDetail.ProjectName : props.type == 'PainPoint&&GoodIdea' ? itemDetail.Title : null}
            </Link>}
        </Popover>)
}


interface UserProps {
    user: string;
}

export function UserIcon(props: UserProps
) {
    const [detailLoading, setDetailLoading] = useState(true)
    const [userDetail, setUserDetail] = useState<any>({})
    const getDetail = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/username/" + props.user,
        }).then(res => {
            setUserDetail(res.data)
            setDetailLoading(false)
        })
    }
    return (
        <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="custom"
            fixedWidth
            content={
                detailLoading ?
                    <Box textAlign="center"><Spinner size="large"/></Box> :
                    <Grid
                        gridDefinition={[
                            {colspan: 3},
                            {colspan: 9}
                        ]}
                    >
                        <img style={{width: '90%', height: '90%'}}
                             src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.user}/>
                        <TextContent>
                            <Link
                                onFollow={() => window.open('https://phonetool.amazon.com/users/' + props.user)}>{userDetail.gecos} ({props.user})</Link>
                            <p>{userDetail.description}, {userDetail.amzndeptname}</p>
                            <p>{userDetail.amznlocdescr}, {userDetail.amzncity}</p>
                            <SpaceBetween direction="horizontal" size="xxl">
                                <Link
                                    onFollow={() => window.open('https://app.chime.aws/conversations/new?email=' + userDetail.mail)}
                                    external={true}>Chime</Link>
                                <Link
                                    onFollow={() => window.open('https://slack.com/signin?redir=%2Fapp_redirect%3Fchannel%3D%2540' + props.user)}
                                    external={true}>Slack</Link>
                                <Link onFollow={() => alert(userDetail.mail)} external={true}>Email</Link>
                            </SpaceBetween>
                        </TextContent>
                    </Grid>
            }
        >
            <div key={props.user} className={"css-user-pic"} onClick={() => getDetail()}
                 style={{backgroundImage: 'url(https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.user + ')'}}/>
        </Popover>
    )
}

interface LoginProps {
    user: string;
}

export function LoginIcon(props: LoginProps) {
    const [detailLoading, setDetailLoading] = useState(true)
    const [userDetail, setUserDetail] = useState<any>({})
    const getDetail = () => {
        if (props.user != 'TBD') {
            axios({
                method: "GET",
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/username/" + props.user,
            }).then(res => {
                setUserDetail(res.data)
                setDetailLoading(false)
            })
        }
    }
    return (
        <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="custom"
            content={
                detailLoading ?
                    <Box textAlign="center">
                        {props.user == 'TBD' ? <Box><p>Still Pending Assign to Owner</p></Box> : null}
                        <Spinner size="large"/></Box> :
                    <Grid
                        gridDefinition={[
                            {colspan: 3},
                            {colspan: 9}
                        ]}
                    >
                        <img style={{width: '90%', height: '90%'}}
                             src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.user}/>
                        <TextContent>
                            <Link
                                onFollow={() => window.open('https://phonetool.amazon.com/users/' + props.user)}>{userDetail.gecos} ({props.user})</Link>
                            <p>{userDetail.description}, {userDetail.amzndeptname}</p>
                            <p>{userDetail.amznlocdescr}, {userDetail.amzncity}</p>
                            <SpaceBetween direction="horizontal" size="xxl">
                                <Link
                                    onFollow={() => window.open('https://app.chime.aws/conversations/new?email=' + userDetail.mail)}
                                    external={true}>Chime</Link>
                                <Link
                                    onFollow={() => window.open('https://slack.com/signin?redir=%2Fapp_redirect%3Fchannel%3D%2540' + props.user)}
                                    external={true}>Slack</Link>
                                <Link onFollow={() => alert(userDetail.mail)} external={true}>Email</Link>
                            </SpaceBetween>
                        </TextContent>
                    </Grid>
            }
        >
            <div onClick={() => getDetail()}>
                {props.user === 'TBD' ? <Badge color="red">{props.user}</Badge> :
                    <Badge color="green">{props.user}</Badge>}
            </div>
        </Popover>
    );
}


interface DetailProps {
    login: string;
}

export function UserDetail(props: DetailProps) {
    const [detailLoading, setDetailLoading] = useState(true)
    const [userDetail, setUserDetail] = useState<any>({})
    const getDetail = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/username/" + props.login,
        }).then(res => {
            setUserDetail(res.data)
            setDetailLoading(false)
        })
    }


    return (
        <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="custom"
            content={
                detailLoading ?
                    <Box textAlign="center"><Spinner size="large"/></Box> :
                    <Grid
                        gridDefinition={[
                            {colspan: 3},
                            {colspan: 9}
                        ]}
                    >
                        <img style={{width: '90%', height: '90%'}}
                             src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.login}/>
                        <TextContent>
                            <Link
                                onFollow={() => window.open('https://phonetool.amazon.com/users/' + props.login)}>{userDetail.gecos} ({props.login})</Link>
                            <p>{userDetail.description}, {userDetail.amzndeptname}</p>
                            <p>{userDetail.amznlocdescr}, {userDetail.amzncity}</p>
                            <SpaceBetween direction="horizontal" size="xxl">
                                <Link
                                    onFollow={() => window.open('https://app.chime.aws/conversations/new?email=' + userDetail.mail)}
                                    external={true}>Chime</Link>
                                <Link
                                    onFollow={() => window.open('https://slack.com/signin?redir=%2Fapp_redirect%3Fchannel%3D%2540' + props.login)}
                                    external={true}>Slack</Link>
                                <Link onFollow={() => alert(userDetail.mail)}>Email</Link>
                            </SpaceBetween>
                        </TextContent>
                    </Grid>
            }
        >
            <div
                className="badge-photo size-medium badge-photo-circle badge-photo-border"
                onClick={() => getDetail()}>
                <img src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + props.login}/>
            </div>
        </Popover>
    )
}

export const updateDateToString = (date) => {
    if (date) {
        let [Y, M, D, H, m, s] = date.split(/\D/)
        let updated = new Date(Date.UTC(Y, M - 1, D, H, m, s))
        return updated.toLocaleString('en-US', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false
        }).replace(',', '')
    }
}

export const pasteFunction = (e) => {
    e.preventDefault();
    let blob = null
    let text;
    let clp = (e.originalEvent || e).clipboardData
    console.log(clp.getData("text"))
    if (clp) {
        if (clp.types[0] == 'Files') {
            blob = clp.items[0].getAsFile()
            // @ts-ignore
            let fileType = blob['name'].split('.')[1]
            // @ts-ignore
            let fileName = blob['name'].split('.')[0]
            console.log(fileName)
            console.log(fileType)
            axios({
                method: "POST",
                data: blob,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/commentsfile/" + fileName + '/' + fileType,
            }).then(res => {
                console.log(res.data)
                window.open('https://test-wide.s3.us-east-2.amazonaws.com/' + res.data)
            })
        } else {
            text = clp.getData('text/plain') || "";
            if (text !== "") {
                document.execCommand('insertText', false, text);
            }
        }
    } else {
        text = e.clipboardData.getData("text") || "";
        if (text !== "") {
            if (e.getSelection) {
                // 针对于ie11 10 9 safari
                var newNode = document.createElement("span");
                newNode.innerHTML = text;
                e.getSelection().getRangeAt(0).insertNode(newNode);
            } else {
                // 兼容ie10 9 8 7 6 5
                e.selection.createRange().pasteHTML(text);
            }
        }
    }
}