import TokenGroup from "@amzn/awsui-components-react/polaris/token-group";

export interface TokenProps {
    items: any
}

export const pushToTag = (tag) => {
    return {
        value: tag,
        label: tag
    }
}

export const pushToTagsWithList = (tagList) => {
    let result: any = []
    tagList.map((item) => {
        result.push({
            value: item,
            label: item
        })
    })
    return result
}
export const pushValues = (pushList) => {
    const ValueList: any = []
    pushList.map((item) => {
        ValueList.push(item['value'])
    })
    return ValueList
}
export default function Token(props: TokenProps) {
    const itemsValue: any = []
    props.items.map((item) => {
        itemsValue.push({
            'label': item,
            'value': item
        })
    })
    return (
        <TokenGroup
            items={itemsValue}
            limit={3}
            onDismiss={() => console.warn('It can not be removed.')}
        />
    );
}

export function DogSvg() {
    return (
        <svg viewBox="0 0 180 160" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g className="Zcp3PONWSf-delete-with-friction">
                <g className="Zcp3PONWSf-Dog">
                    <g className="Zcp3PONWSf-Dog">
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head Zcp3PONWSf-Neck Zcp3PONWSf-Sitting">
                            <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head Zcp3PONWSf-Neck">
                                <rect width="12.6818"
                                      height="21.2727"
                                      rx="0.818182"
                                      transform="matrix(-1 0 0 1 98.1817 60.9768)"
                                      fill="#F4F4F4"
                                      stroke="#000716"
                                      strokeWidth="1.63636"
                                      strokeLinejoin="round"
                                      className="Zcp3PONWSf-illustration-primary-grey"></rect>
                            </g>
                            <path
                                d="M98.1817 77.9131L85.909 70.795V61.3859H98.1817L98.1817 77.9131Z"
                                fill="#000716"
                                className="Zcp3PONWSf-Shadow"></path>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Torso Zcp3PONWSf-Sitting">
                            <path
                                d="M78.5453 151.918H122.77C133.923 151.918 140.493 131.037 134.171 121.853L107.89 83.6097C105.77 80.5287 102.269 78.6904 98.5345 78.6904H89.9103C83.6322 78.6904 78.5526 83.7788 78.5526 90.0584V143.56L78.5453 151.918Z"
                                fill="#F4F4F4" stroke="#000716"
                                strokeWidth="1.50346"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-Torso Zcp3PONWSf-illustration-primary-grey"></path>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Body Zcp3PONWSf-Tail">
                            <path
                                d="M158.4 104.155C156.713 103.181 156.135 101.022 157.109 99.334C158.084 97.6465 160.242 97.0675 161.929 98.0413C163.616 99.0152 164.194 101.174 163.22 102.862C162.245 104.549 160.087 105.128 158.4 104.155Z"
                                fill="#0972D3" stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-illustration-primary-blue"></path>
                            <path
                                d="M158.185 104.382L142.639 131.308"
                                stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="square"
                                className="Zcp3PONWSf-outline-only-grey"></path>
                            <g className="Zcp3PONWSf-Motion Zcp3PONWSf-Lines Zcp3PONWSf-Sparks">
                                <line y1="-0.818182" x2="9"
                                      y2="-0.818182"
                                      transform="matrix(-0.5 0.866025 0.866025 0.5 169.262 87.1587)"
                                      stroke="#000716"
                                      strokeWidth="1.63636"
                                      className="Zcp3PONWSf-Line Zcp3PONWSf-outline-only-grey"></line>
                                <line y1="-0.818182" x2="9"
                                      y2="-0.818182"
                                      transform="matrix(0.258819 0.965926 0.965926 -0.258819 158.19 84.4264)"
                                      stroke="#000716"
                                      strokeWidth="1.63636"
                                      className="Zcp3PONWSf-Line Zcp3PONWSf-outline-only-grey"></line>
                                <line y1="-0.818182" x2="9"
                                      y2="-0.818182"
                                      transform="matrix(-0.965926 0.258819 0.258819 0.965926 176.605 96.1219)"
                                      stroke="#000716"
                                      strokeWidth="1.63636"
                                      className="Zcp3PONWSf-Line Zcp3PONWSf-outline-only-grey"></line>
                            </g>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Leg Zcp3PONWSf-Sitting Zcp3PONWSf-Frontleg">
                            <path
                                d="M77.7272 99.4314H86.7272V150.977H77.7272V99.4314Z"
                                fill="#D1D5DB" stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-illustration-secondary-grey"></path>
                            <path d="M86.7272 122.34H77.7272"
                                  stroke="#000716"
                                  strokeWidth="1.50346"
                                  className="Zcp3PONWSf-Vector"></path>
                            <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Body Zcp3PONWSf-Leg Zcp3PONWSf-Foot Zcp3PONWSf-Default">
                                <path
                                    d="M86.7272 143.613H72.7992C69.1537 143.613 66.1844 146.466 66.1844 149.996V151.966H86.7272V143.621V143.613Z"
                                    fill="white" stroke="#000716"
                                    strokeWidth="1.63636"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="Zcp3PONWSf-illustration-white"></path>
                            </g>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Body Zcp3PONWSf-Hinge Zcp3PONWSf-Front">
                            <path
                                d="M82.6362 103.727C76.9894 103.727 72.409 99.4673 72.409 94.2155C72.409 88.9636 76.9894 84.7041 82.6362 84.7041C88.2831 84.7041 92.8635 88.9636 92.8635 94.2155C92.8635 99.4673 88.2831 103.727 82.6362 103.727V103.727Z"
                                fill="#000716"
                                className="Zcp3PONWSf-Shadow"></path>
                            <path
                                d="M82.1015 102.861C77.3167 102.861 73.436 98.9786 73.436 94.1917C73.436 89.4048 77.3167 85.5223 82.1015 85.5223C86.8862 85.5223 90.7669 89.4048 90.7669 94.1917C90.7669 98.9786 86.8862 102.861 82.1015 102.861V102.861Z"
                                fill="#D1D5DB" stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-illustration-secondary-grey"></path>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Body Zcp3PONWSf-Leg Zcp3PONWSf-Foot Zcp3PONWSf-Default">
                            <path
                                d="M125.182 143.613H111.254C107.608 143.613 104.639 146.466 104.639 149.996V151.966H125.182V143.621V143.613Z"
                                fill="white" stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-illustration-white"></path>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Body Zcp3PONWSf-Hinge Zcp3PONWSf-Back">
                            <path
                                d="M129.124 151.291C119.554 151.291 111.793 143.526 111.793 133.952C111.793 124.378 119.554 116.613 129.124 116.613C138.693 116.613 146.454 124.378 146.454 133.952C146.454 143.526 138.693 151.291 129.124 151.291Z"
                                fill="#D1D5DB" stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-illustration-secondary-grey"></path>
                            <path
                                d="M129.122 137.798C126.998 137.798 125.271 136.07 125.271 133.945C125.271 131.82 126.998 130.092 129.122 130.092C131.246 130.092 132.974 131.82 132.974 133.945C132.974 136.07 131.246 137.798 129.122 137.798Z"
                                fill="#0972D3" stroke="#000716"
                                strokeWidth="1.63636"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="Zcp3PONWSf-illustration-primary-blue"></path>
                        </g>
                        <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head">
                            <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head Zcp3PONWSf-Face Zcp3PONWSf-Open">
                                <path
                                    d="M62.7216 46.6763C61.5466 46.3463 60.8733 45.1383 61.1944 43.9684L64.5706 32.1921C65.1794 30.0337 67.4118 28.7746 69.5808 29.371L112.427 40.8516C113.6 41.1887 114.273 42.3967 113.952 43.5666L112.417 49.0694L109.701 58.8077L107.285 67.4845C106.954 68.6596 105.747 69.333 104.577 69.012L86.5273 64.4573L85.945 64.3013L59.931 57.034C58.7561 56.7039 58.0828 55.4959 58.4039 54.3261L58.6075 53.5662C58.9109 52.4905 60.0288 51.869 61.0991 52.1634L78.8138 57.1156L79.5939 54.318L62.7216 46.6763Z"
                                    fill="white" stroke="#000716"
                                    strokeWidth="1.50346"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="Zcp3PONWSf-illustration-white"></path>
                            </g>
                            <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head Zcp3PONWSf-Ear">
                                <path
                                    d="M117.365 49.798C118.417 45.8698 116.086 41.832 112.158 40.7795L97.1421 36.756L88.4599 69.1584C88.226 70.0314 88.744 70.9286 89.6169 71.1625L103.052 74.7625C106.98 75.8151 111.018 73.4839 112.071 69.5556L117.365 49.798Z"
                                    fill="#D1D5DB"
                                    stroke="#000716"
                                    strokeWidth="1.63636"
                                    strokeLinejoin="round"
                                    className="Zcp3PONWSf-illustration-secondary-grey"></path>
                            </g>
                            <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head Zcp3PONWSf-Eye Zcp3PONWSf-Open">
                                <path
                                    d="M86.5332 44.1155C85.1053 44.0997 83.9594 42.9277 83.9754 41.4996C83.9914 40.0715 85.1633 38.9255 86.5912 38.9413C88.0191 38.9572 89.1649 40.1292 89.149 41.5573C89.133 42.9854 87.9611 44.1314 86.5332 44.1155Z"
                                    fill="#0972D3"
                                    stroke="#000716"
                                    strokeWidth="1.63636"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="Zcp3PONWSf-illustration-primary-blue"></path>
                            </g>
                            <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Head Zcp3PONWSf-Nose">
                                <path
                                    d="M73.8559 30.5165L62.953 36.72L64.1754 32.1578C64.7602 29.9754 67.003 28.6802 69.1853 29.265L73.8559 30.5165Z"
                                    fill="#000716"
                                    className="Zcp3PONWSf-Vector"></path>
                            </g>
                        </g>
                    </g>
                    <g className="Zcp3PONWSf-Dog Zcp3PONWSf-Body Zcp3PONWSf-Ground">
                        <path d="M167.727 152L67.0908 152"
                              stroke="#000716"
                              strokeWidth="1.63636"
                              strokeLinecap="square"
                              strokeLinejoin="round"
                              className="Zcp3PONWSf-outline-only"></path>
                    </g>
                </g>
                <g className="Zcp3PONWSf-General Zcp3PONWSf-Communication">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M46.0904 6C46.5423 6 46.9086 6.36631 46.9086 6.81818V36.3578V43.1816C46.9086 43.8621 46.1264 44.2452 45.5888 43.828L36.1841 36.5296C36.0406 36.4182 35.8641 36.3578 35.6825 36.3578H6.81772C6.36585 36.3578 5.99954 35.9915 5.99954 35.5396V6.81818C5.99954 6.36631 6.36585 6 6.81772 6H46.0904Z"
                          fill="#0972D3" stroke="#000716"
                          strokeWidth="1.63636"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="Zcp3PONWSf-Chat Zcp3PONWSf-bubble Zcp3PONWSf-illustration-primary-blue"></path>
                    <g className="Zcp3PONWSf-! Zcp3PONWSf-icon">
                        <path
                            d="M26.9578 12.277H25.4904C24.5543 12.277 23.8205 13.0344 23.8964 13.9383L24.6301 22.7826C24.706 23.5889 25.3892 24.1997 26.2241 24.1997C27.059 24.1997 27.7421 23.5889 27.818 22.7826L28.5517 13.9383C28.6276 13.0344 27.8939 12.277 26.9578 12.277ZM26.7301 26.2275H25.7433C24.8578 26.2275 24.1495 26.9116 24.1495 27.7667V28.1576C24.1495 29.0127 24.8578 29.6968 25.7433 29.6968H26.7301C27.6156 29.6968 28.324 29.0127 28.324 28.1576V27.7667C28.324 26.9116 27.6156 26.2275 26.7301 26.2275Z"
                            fill="white"
                            className="Zcp3PONWSf-mark"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}