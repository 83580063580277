import {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Link from "@amzn/awsui-components-react/polaris/link";
import Box from '@amzn/awsui-components-react/polaris/box';
import Input from "@amzn/awsui-components-react/polaris/input";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Table, {TableProps} from "@amzn/awsui-components-react/polaris/table";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import axios from 'axios'
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import DateRangePicker from "../Common/DateRangePicker";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import BarChart from "@amzn/awsui-components-react/polaris/bar-chart";
import {MixedChart_DEFINITIONS, PieDonutChart_DEFINITIONS} from "../Common/i18nStrings";
import MixedCharts from "../Common/MixedCharts";
import PieDonutCharts from "../Common/PieDonutCharts";
import {updateDateToString, UserIcon} from "../Common/AutoLink";
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import {Distribution} from "../interfaces";
import {useCollection} from '@amzn/awsui-collection-hooks';
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import {EmptyState, paginationLabels} from "../TableView/table-config";
import {getMatchesCountText} from "../table/table-config";


export default function AnalyticsPage() {
    const {login} = useParams<{ login: string }>();
    const history = useHistory();
    const [searchLogin, setSearchLogin] = useState<string>(login);
    const [allItems, setAllItems] = useState<any>([])
    const [allDetails, setAllDetails] = useState<any>([])
    const [pageManager, setPageManager] = useState<any>({Login: 'Login', Name: 'Name'})
    const [summaryUser, setSummaryUser] = useState<string>(login)
    const [summaryLoading, setSummaryLoading] = useState(true)
    const [detailLoading, setDetailLoading] = useState(true)
    const [managerLoading, setManagerLoading] = useState(true)
    const [analyticsDateLimitValue, setAnalyticsDateLimitValue] = useState<any>({
        type: "relative",
        amount: 3,
        unit: "month"
    })

    const COLUMN_DEFINITIONS: any = [
        {
            id: "Name",
            header: "Name",
            cell: (item): any => (
                item.Login == 'Team Summary' ? <strong>Team Summary</strong> :
                    <Link href={"#/analytics/" + item.Login}>{item.Name || "-"}</Link>
            ),
            sortingField: "Login",
            isRowHeader: true
        },
        {
            id: "SubmitAll",
            header: "SubmitAll",
            cell: item => (
                <Link variant="primary" onFollow={() => setAllDetails(item.SubmitUnit)}>{item.SubmitAll || "-"}</Link>),
            sortingField: "SubmitAll"
        }, {
            id: "SubmitRate",
            header: "SubmitRate",
            cell: item => (item.SubmitRate == '1/1' ?
                <StatusIndicator type={'success'}>Meet</StatusIndicator> : (item.SubmitRate == '0/1' ?
                    <StatusIndicator type={'error'}>Miss</StatusIndicator> : item.SubmitRate)),
            sortingField: "SubmitRate"
        },
        {
            id: "TestRate",
            header: "TestRate",
            cell: item => item.TestRate || "-"
        }
    ]
    const COLUMN_DEFINITIONS_DETAIL: TableProps.ColumnDefinition<Distribution>[] = [
        {
            id: 'Title',
            header: 'Title',
            cell: item => (<Link href={"#/detail/" + item.id} target="_blank">{item.Title}</Link>),
            // cell: item => (<Link onFollow={ModalCell}>{item.Title}</Link>),
            minWidth: 330,
            // maxWidth: 240,
            width: 330
        }, {
            id: 'Description',
            // cell: item => <TablePopover content={item.Description}></TablePopover>,
            cell: item => item.Description,
            header: 'Description',
            minWidth: 310,
            // maxWidth: 160,
            width: 310,
        }, {
            id: 'Label',
            header: 'Label',
            cell: item => (<div>{item.Label}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'Label'
        }, {
            id: 'Status',
            header: 'Status',
            cell: item => (<StatusIndicator
                type={item.Status === 'Assign' ? 'info' : (item.Status === 'Working in Process' ? 'in-progress' : (item.Status === 'Pending' ? 'pending' : (item.Status === 'Resolved' ? 'success' : 'error')))}>{item.Status}</StatusIndicator>),
            minWidth: 100,
            // maxWidth: 100,
            width: 140,
            sortingField: 'Status'
        }, {
            id: 'Submitter',
            header: 'Submitter',
            cell: item => (<Box textAlign="center"><UserIcon user={item.Submitter}/></Box>),
            minWidth: 110,
            // maxWidth: 110,
            width: 110,
            sortingField: 'Submitter'
        }, {
            id: 'SubmitDate',
            header: 'SubmitDate',
            cell: item => updateDateToString(item.SubmitDate),
            minWidth: 80,
            maxWidth: 200,
            width: 200,
            sortingField: 'SubmitDate',
            sortingComparator: (a: any, b: any) => {
                return new Date(a.SubmitDate).getTime() - new Date(b.SubmitDate).getTime();
            }
        },
    ]
    const {items, paginationProps, collectionProps, actions, filteredItemsCount, filterProps} = useCollection(
        allDetails || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={<p>Please verify your filter condition!</p>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {
                defaultState: {
                    sortingColumn: COLUMN_DEFINITIONS_DETAIL[5],
                    isDescending: true,
                }
            },
        }
    );

    useEffect(() => {
        setSearchLogin(login)
    }, [login])
    useEffect(() => {
        setMixedChartLoading('loading')
        setPieDonutGoodIdeaChartLoading('loading')
        setSummaryLoading(true)
        setDetailLoading(true)
        axios({
            method: "POST",
            data: analyticsDateLimitValue,
            // method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/analytics/" + login,
        }).then(res => {
            if (res.data) {
                setAllItems(res.data)
                setSummaryLoading(false)
                setAllDetails(res.data[0]['SubmitUnit'])
                setDetailLoading(false)

                const MixedChartDetail = res.data
                console.log(res.data)
                let MixedChartDataList: any[] = []
                MixedChartDetail.map((item) => {
                    if (item['Login'] != 'Team Summary') {
                        MixedChartDataList.push(item['Login'])
                    }
                })
                console.log(MixedChartDataList)
                setMixedChartXDomain(MixedChartDataList)
                let maxDomain = 0
                MixedChartDetail.map((item) => {
                    item['SubmitAll'] > maxDomain ?
                        maxDomain = item['SubmitAll'] : null
                })
                maxDomain != 0 ? setMixedChartYDomain([0, Math.ceil(Number((maxDomain / 10).toFixed(1) + 1)) * 10]) : null
                setMixedChartSeries([
                    {
                        title: "Pain Point",
                        type: "bar",
                        data: MixedChartDetail.map((item) => {
                            let pp_unit = 0
                            item['SubmitUnit'].map((un) => {
                                if (un['Label'][0] == 'Pain Point') {
                                    pp_unit += 1
                                }
                            })
                            return {x: item['Login'], y: pp_unit}
                        }),
                        valueFormatter: e =>
                            e.toLocaleString("zh-CN")
                    },
                    {
                        title: "Good Idea",
                        type: "bar",
                        color: '#2ea597',
                        data: MixedChartDetail.map((item) => {
                            let gi_unit = 0
                            item['SubmitUnit'].map((un) => {
                                if (un['Label'][0] == 'Good Idea') {
                                    gi_unit += 1
                                }
                            })
                            return {x: item['Login'], y: gi_unit}
                        }),
                        valueFormatter: e =>
                            e.toLocaleString("zh-CN")
                    }
                ])
                // setLineChartXDomain([new Date(LineChartDetail['EndDate'] + 'T00:00'), new Date(LineChartDetail['StartDate'] + 'T00:00')])
                setMixedChartLoading('finished')
                let PieDonutDataList: any[] = []
                res.data.map((item) => {
                    if (item['Login'] != 'Team Summary') {
                        PieDonutDataList.push({
                            title: item['Login'],
                            value: parseInt(item['SubmitAll'])
                        })
                    }
                })
                setPieDonutGoodIdeaValue(PieDonutDataList)
                setPieDonutGoodIdeaChartLoading('finished')
            } else {
                alert(searchLogin + ' is not exist. Please Check your input value.')
                history.push('')
            }
        })
    }, [login, analyticsDateLimitValue])

    useEffect(() => {
        setManagerLoading(true)
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/username/" + login,
        }).then(res => {
            console.log(res.data)
            setPageManager({
                Login: String(res.data.manager).split('cn=')[1].split(',')[0].split(' (')[1].split(')')[0],
                Name: String(res.data.manager).split('cn=')[1].split(',')[0].split(' (')[0]
            })
            setSummaryUser(res.data.gecos)
            setManagerLoading(false)
        })
    }, [login])
    const [MixedChartSeries, setMixedChartSeries] = useState<any>([])
    const [MixedChartXDomain, setMixedChartXDomain] = useState<any>([])
    const [MixedChartYDomain, setMixedChartYDomain] = useState<any>([0, 30])
    const [MixedChartLoading, setMixedChartLoading] = useState('loading')
    const [PieDonutGoodIdeaValue, setPieDonutGoodIdeaValue] = useState<any>([])
    const [PieDonutGoodIdeaChartLoading, setPieDonutGoodIdeaChartLoading] = useState('loading')
    const Format = (date, format) => {
        var o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "H+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return format;
    }
    const handleAnalyticsDateLimit = (detail) => {
        setAnalyticsDateLimitValue(detail.value)
    }

    function numberFormatter(e) {
        return Math.abs(e) >= 1e9
            ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
            "G"
            : Math.abs(e) >= 1e6
                ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                "M"
                : Math.abs(e) >= 1e3
                    ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                    "K"
                    : e.toFixed(2);
    }

    return (<ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        actions={
                            <Input
                                onChange={({detail}) => setSearchLogin(detail.value)}
                                onKeyDown={({detail}) => {
                                    if (detail.keyCode === 13) {
                                        history.push('/analytics/' + encodeURIComponent(searchLogin))
                                    }
                                }}
                                value={searchLogin}
                                type="search"
                            />
                        }
                        description={"This report summarizes PainPoint/GoodIdea resources owned by " + login + "'’s team which contain submit rate that need to be highlight."}
                    >
                        Team Submit Rate Analytics Report
                    </Header>
                </SpaceBetween>
            }
        >
            <SpaceBetween size="l">

                <Container
                    header={
                        <SpaceBetween size="xxxs">
                            <Header
                                variant="h2">
                                {summaryUser}'s team summary
                            </Header>
                            <Grid
                                gridDefinition={[{colspan: 3}, {colspan: 9}]}
                            >
                                <Box float="left">
                                    <strong>Manager: {managerLoading ? <Spinner/> :
                                        <Link href={"/#/analytics/" + pageManager.Login}
                                              onFollow={() => console.log(pageManager.Login)}>{pageManager.Name}</Link>}</strong>
                                </Box>
                                <Box float="right">
                                    <DateRangePicker onChange={({detail}) => handleAnalyticsDateLimit(detail)}
                                                     value={analyticsDateLimitValue}
                                                     relativeOptions={[
                                                         {
                                                             key: "previous-3-month",
                                                             amount: 3,
                                                             unit: "month",
                                                             type: "relative"
                                                         }, {
                                                             key: "previous-6-months",
                                                             amount: 6,
                                                             unit: "month",
                                                             type: "relative"
                                                         }, {
                                                             key: "previous-1-year",
                                                             amount: 1,
                                                             unit: "year",
                                                             type: "relative"
                                                         }
                                                     ]} rangeSelectorMode={undefined}/>
                                </Box>
                            </Grid>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size="xxxs">
                        <ColumnLayout columns={2}>
                            <MixedCharts xTitle="Availability Zone"
                                         yTitle="Submit Counts" series={MixedChartSeries} xDomain={MixedChartXDomain}
                                         yDomain={MixedChartYDomain}
                                         i18nStrings={MixedChart_DEFINITIONS} statusType={MixedChartLoading}/>
                            <PieDonutCharts i18nStrings={PieDonutChart_DEFINITIONS} data={PieDonutGoodIdeaValue}
                                            detailPopoverContent={(datum, sum) => [
                                                {key: "Resource count", value: datum.value},
                                                {
                                                    key: "Percentage",
                                                    value: `${((datum.value / sum) * 100).toFixed(
                                                        0
                                                    )}%`
                                                }
                                            ]} statusType={PieDonutGoodIdeaChartLoading}/>
                        </ColumnLayout>
                        <Box textAlign="center" color="inherit">-1 (-100%)</Box>
                        <TextContent>
                            <p>
                                <strong>Team Summary:</strong> This table includes Submission Rate for the entire team.
                            </p>
                            <p>
                                <strong style={{color: 'rgb(0, 158, 115)'}}>Green numbers</strong> Submission rate met
                                the
                                goal.
                                <strong style={{color: 'rgb(213, 94, 0)'}}>Orange numbers</strong> Submission rate not
                                met
                                the goal.
                            </p>
                        </TextContent>
                        <Table
                            loading={summaryLoading}
                            loadingText="Loading Pain Point/Good Idea Resources"
                            sortingDisabled
                            resizableColumns={true}
                            columnDefinitions={COLUMN_DEFINITIONS}
                            items={allItems}
                            variant="embedded"
                            empty={
                                <Box
                                    margin={{vertical: "xs"}}
                                    textAlign="center"
                                    color="inherit"
                                >
                                    <SpaceBetween size="m">
                                        <strong>No resources</strong>
                                        <p>{login}@’s team does not have any resources with PainPoint/GoodIdea
                                            resources.</p>
                                    </SpaceBetween>
                                </Box>
                            }
                        />
                    </SpaceBetween>
                </Container>

                <Container>
                    <Table
                        {...collectionProps}
                        loading={detailLoading}
                        loadingText="Loading Pain Point/Good Idea Details"
                        resizableColumns={true}
                        header={
                            <Header>
                                Submission Details
                            </Header>
                        }
                        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                        columnDefinitions={COLUMN_DEFINITIONS_DETAIL}
                        sortingDisabled
                        items={items}
                        filter={
                            <TextFilter
                                {...filterProps}
                                countText={getMatchesCountText(filteredItemsCount!)}
                                filteringAriaLabel="Filter records"
                            />
                        }
                        variant="embedded"
                        empty={
                            <Box
                                margin={{vertical: "xs"}}
                                textAlign="center"
                                color="inherit"
                            >
                                <SpaceBetween size="m">
                                    <strong>No Submission</strong>
                                    <p>{login}@’s does not have any Submission with PainPoint/GoodIdea
                                        resources.</p>
                                </SpaceBetween>
                            </Box>
                        }
                    />
                </Container>
            </SpaceBetween>
        </ContentLayout>
    )
}