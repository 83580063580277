import {useEffect, useRef, useState} from 'react';
import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table, {TableProps} from '@amzn/awsui-components-react/polaris/table';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import {TextFilterProps} from '@amzn/awsui-components-react/polaris/text-filter';
import axios from 'axios'
import {PropertyFilterProps} from "@amzn/awsui-components-react/polaris/property-filter";
import Input from "@amzn/awsui-components-react/polaris/input";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import {
    CONTENT_SELECTOR_OPTIONS,
    CUSTOM_PREFERENCE_OPTIONS,
    DEFAULT_PREFERENCES,
    EmptyState,
    getMatchesCountText,
    PAGE_SELECTOR_OPTIONS,
    paginationLabels,
    SUSTAINABILITY_CONTENT_SELECTOR_OPTIONS,
    SUSTAINABILITY_DEFAULT_PREFERENCES,
} from './table-config';
import {Distribution} from '../interfaces';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Link from '@amzn/awsui-components-react/polaris/link';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import PainPointPropertyFilter from "../Common/PainPointFilter";
import DetailView from "../DetailView/DetailView";
import ModalBox, {ConfirmationModalBox} from "../Common/ModalBox";
import AutoLink, {LoginIcon, updateDateToString, UserIcon} from "../Common/AutoLink";
import DateRangePicker from "../Common/DateRangePicker";
import {FavoriteFunction} from "../Common/ButtonFunctions";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Select from "@amzn/awsui-components-react/polaris/select";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import SustainabilityDetailView from "../SustainabilityDetailView/SustainabilityDetailView";
import {DogSvg} from "../Common/Token";

export default function CollectionHooksTable(props) {
    const [OwnerOptions, setOwnerOptions] = useState([])
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/scanlogin",
        }).then(res => {
            let ownerOptionList: any = []
            res.data.sort().map((item) => {
                ownerOptionList.push({
                    "label": item['gecos'],
                    "value": item['uid']
                })
            })
            setOwnerOptions(ownerOptionList)
        })
    }, [])
    const SUSTAINABILITY_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
        {
            id: 'ProjectName',
            header: 'Project Name',
            cell: item => (<Box>{item.hasOwnProperty('ChildrenList') && item.ChildrenList.length > 0 ?
                <Badge
                    color="green">Parent</Badge> : item.hasOwnProperty('AdoptState') && item.AdoptState == 'Children' ?
                    <Badge color="red">Children</Badge> : null}{" "}<Link
                onFollow={() => SustainabilityModalCell(item)}>{item.ProjectName}</Link></Box>),
            // cell: item => (<Link onFollow={ModalCell}>{item.Title}</Link>),
            minWidth: 330,
            // maxWidth: 240,
            width: 330,
        }, {
            id: 'BriefDescription',
            // cell: item => <TablePopover content={item.Description}></TablePopover>,
            cell: item => item.BriefDescription,
            header: 'Brief Description',
            minWidth: 210,
            // maxWidth: 160,
            width: 210,
        }, {
            id: 'Cluster',
            header: 'Cluster',
            cell: item => (<Badge color="green">{item.Cluster}</Badge>),
            minWidth: 130,
            // maxWidth: 100,
            width: 130,
            sortingField: 'Cluster'
        }, {
            id: 'InfraOpsOwner',
            header: 'InfraOps Owner',
            cell: item => item.InfraOpsOwner,
            minWidth: 180,
            // maxWidth: 100,
            width: 180,
            sortingField: 'InfraOpsOwner'
        }, {
            id: 'EnviroOwner',
            header: 'Enviro Owner',
            cell: item => item.EnviroOwner,
            minWidth: 180,
            // maxWidth: 100,
            width: 180,
            sortingField: 'EnviroOwner'
        }, {
            id: 'Submitter',
            header: 'Submitter',
            cell: item => (<Box textAlign="center"><UserIcon user={item.Submitter}/></Box>),
            minWidth: 110,
            // maxWidth: 100,
            width: 110,
            sortingField: 'Submitter'
        }, {
            id: 'SubmitDate',
            header: 'Submit Date',
            cell: item => (<div>{item.SubmitDate}</div>),
            minWidth: 110,
            // maxWidth: 140,
            width: 110,
            sortingField: 'SubmitDate'
        },
        {
            id: 'State',
            header: 'State',
            cell: item => (<StatusIndicator
                type={item.State === 'Assigned' ? 'info' : item.State === 'Researching' ? 'warning' : item.State === 'Working in Process' ? 'in-progress' : item.State === 'Pending' ? 'pending' : item.State === 'Completed' ? 'success' : 'error'}>
                {item.State}
            </StatusIndicator>),
            minWidth: 140,
            // maxWidth: 100,
            width: 140,
            sortingField: 'State'
        },
        {
            id: 'ProjectOutcomes',
            header: 'Project Outcomes',
            cell: item => (
                <ColumnLayout columns={3} variant="text-grid">
                    <div>
                        <Box variant="awsui-key-label">Financial Savings</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.Financial}&nbsp;$USD/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Energy Efficiency</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.EnergyEfficiency}&nbsp;kWh/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Carbon footprint</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.CarbonFootprint}&nbsp;Tonnes CO₂e/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Water efficiency</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.WaterEfficiency}&nbsp;m³/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Waste recycling & reduction</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.WasteRecycling}&nbsp;Kg/Year
                        </StatusIndicator>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Energy Efficiency(Diesel)</Box>
                        <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                            {item.Unit.DieselEnergy}&nbsp;kL/Year
                        </StatusIndicator>
                    </div>
                </ColumnLayout>
            ),
            minWidth: 740,
            // maxWidth: 100,
            width: 740,
        }, {
            id: 'ProjectCosts',
            header: 'Project Costs & Expenses',
            cell: item => <ColumnLayout columns={3} variant="text-grid">
                <div>
                    <Box variant="awsui-key-label">Time to payback</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.Payback}&nbsp;Months
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Effort (Implement)</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.ImplementEffort}&nbsp;Man hours
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Effort (Ongoing)</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.OngoingEffort}&nbsp;Man hours/Year
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Capital costs</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.CapitalCosts}&nbsp;$USD
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant="awsui-key-label">Operational costs</Box>
                    <StatusIndicator type={item.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                        {item.Unit.OperationalCosts}&nbsp;$USD/Year
                    </StatusIndicator>
                </div>
            </ColumnLayout>,
            minWidth: 740,
            // maxWidth: 100,
            width: 740,
        }, {
            id: 'Relevant',
            header: 'Relevant',
            cell: item => item.Relevant,
            minWidth: 160,
            // maxWidth: 100,
            width: 160,
            sortingField: 'Relevant'
        }, {
            id: 'Impact',
            header: 'Impact',
            cell: item => item.Impact,
            minWidth: 210,
            // maxWidth: 100,
            width: 210,
            sortingField: 'Impact'
        }, {
            id: 'Investment',
            header: 'Investment',
            cell: item => item.Investment,
            minWidth: 150,
            // maxWidth: 100,
            width: 150,
            sortingField: 'Investment'
        }, {
            id: 'Implementation',
            header: 'Implementation',
            cell: item => item.Implementation,
            minWidth: 150,
            // maxWidth: 100,
            width: 2150,
            sortingField: 'Implementation'
        }, {
            id: 'StartDate',
            header: 'Start Date',
            cell: item => (<div>{item.StartDate} </div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'StartDate'
        }, {
            id: 'CompletedDate',
            header: 'Completed Date',
            cell: item => (<div>{item.CompletedDate}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'CompletedDate'
        },
    ]
    const [allItems, setAllItems] = useState<Distribution[]>([])
    const [allSustainabilityItems, setAllSustainabilityItems] = useState<any[]>([])
    const [loginUser, setLoginUser] = useState('')
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);
    useEffect(() => {

        loadSustainabilityItems()
    }, []);

    const [loading, setLoading] = useState<boolean>(true);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [sustainabilityPreferences, setSustainabilityPreferences] = useState(SUSTAINABILITY_DEFAULT_PREFERENCES);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);
    const [refreshLoading, setRefreshLoading] = useState(true)
    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);

    const loadSustainabilityItems = async () => {
        setLoading(true);
        setRefreshLoading(true)
        setSelectedSustainabilityItems([])
        await axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability",
        }).then(res => {
            setAllSustainabilityItems(res.data)
            setLoading(false);
            setRefreshLoading(false)
        });
    }
    // const likeReloadItems = async () => {
    //     await axios({
    //         method: "POST",
    //         data: DateLimitValue,
    //         url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/datelimit",
    //     }).then(res => {
    //         setAllItems(res.data)
    //     });
    // }

    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    });
    const {items, actions, filteredItemsCount, collectionProps, paginationProps, filterProps, propertyFilterProps} =
        useCollection(
            allSustainabilityItems || [],
            {
                propertyFiltering: {
                    defaultQuery: query,
                    filteringProperties: [
                        {
                            key: "Submitter",
                            operators: ["=", ":"],
                            propertyLabel: "Submitter",
                            groupValuesLabel: "Submitter"
                        }
                    ],
                    empty: (
                        <EmptyState
                            title="No Sustainability Initiative"
                            subtitle="No Sustainability Initiative to display."
                            action={<Button href="#/create_sustainability">Create Sustainability Initiative</Button>}
                        />
                    ),
                    noMatch: (
                        <EmptyState
                            title="No Sustainability Initiative"
                            subtitle="We can’t find a match."
                            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                        />
                    )
                },
                filtering: {
                    empty: (
                        <EmptyState
                            title="No records"
                            subtitle="No records to display."
                            action={<p>Please verify your filter condition!</p>}
                        />
                    ),
                    noMatch: (
                        <EmptyState
                            title="No matches"
                            subtitle="We can’t find a match."
                            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                        />
                    )
                },
                pagination: {
                    pageSize: sustainabilityPreferences.pageSize
                },
                sorting: {
                    defaultState: {
                        sortingColumn: SUSTAINABILITY_COLUMN_DEFINITIONS[6],
                        isDescending: true,
                    }
                },


                // selection: {}
            }
        )

    // useEffect(() => {
    //     TypeFilter.value == 'Aladdin' ?
    //         loadItems() :
    //         loadSustainabilityItems()
    // }, [TypeFilter])

    // const selectedItems = collectionProps.selectedItems!;
    const [selectedSustainabilityItems, setSelectedSustainabilityItems] = useState<any>([])
    const [Itemslist, setItemslist] = useState<any>()
    const [sustainabilityItem, setSustainabilityItem] = useState<any>({})


    const [sustainabilityVisible, setSustainabilityVisible] = useState<boolean>(false);

    const [DeniedVisible, setDeniedVisible] = useState<boolean>(false);
    const [sustainabilityDeleteVisible, setSustainabilityDeleteVisible] = useState<boolean>(false);
    const [DateLimitValue, setDateLimitValue] = useState<any>({
        type: "relative",
        amount: 6,
        unit: "month"
    });
    const SustainabilityModalCell = (detail) => {
        setSustainabilityItem(detail)
        setSustainabilityVisible(true)
    }
    const sustainabilityDeleteAction = () => {
        setSustainabilityDeleteVisible(false)
        setRefreshLoading(true)
        setSelectedSustainabilityItems([])
        axios({
            method: "DELETE",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability/" + selectedSustainabilityItems[0]["id"],
        }).then(res => {
            loadSustainabilityItems()
            setDeleteConfirmInputText('')
        })
    }
    const setUserSustainabilityPreferences = (event) => {
        setSustainabilityPreferences(event)
    }

    const handlePainPointFilter = (event) => {
        setQuery(event.detail)
    }
    const deleteConsentText = 'confirm';
    const [deleteConfirmInputText, setDeleteConfirmInputText] = useState<any>('')
    useEffect(() => {
        setDeleteConfirmInputText('');
    }, [sustainabilityDeleteVisible]);


    return (
        <SpaceBetween size="m">
            <Table
                {...collectionProps}
                selectedItems={selectedSustainabilityItems}
                onSelectionChange={({detail}) => setSelectedSustainabilityItems(detail.selectedItems)}
                onRowClick={({detail}) => setSelectedSustainabilityItems([detail.item])}
                visibleColumns={sustainabilityPreferences.visibleContent}
                wrapLines={sustainabilityPreferences.wrapLines}
                loading={loading}
                loadingText="Loading Pain Point/Good Idea List"
                selectionType="single"
                stickyHeader={true}
                variant='full-page'
                // onRowClick={ModalCell}
                // sortingDescending={true}
                resizableColumns={true}
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        counter={
                            allItems &&
                            (selectedSustainabilityItems!.length ? `(${selectedSustainabilityItems!.length}/${allSustainabilityItems.length})` : `(${allSustainabilityItems.length})`)
                        }
                        actions={
                            <SpaceBetween size="xs" direction="horizontal">
                                <Button onClick={() => loadSustainabilityItems()} loading={refreshLoading}><Icon
                                    name="refresh"/>&ensp; Refresh</Button>
                                <Button disabled={selectedSustainabilityItems.length !== 1} onClick={() => {
                                    window.location.href = "#/sustainability/detail/" + selectedSustainabilityItems[0]["id"]
                                }}>Trace items</Button>
                                <Button disabled={selectedSustainabilityItems.length !== 1}
                                        onClick={() => {
                                            window.location.href = "#/sustainability/edit/" + selectedSustainabilityItems[0]["id"]
                                        }}>Edit</Button>
                                <Button disabled={selectedSustainabilityItems.length !== 1} onClick={() => {
                                    setSustainabilityDeleteVisible(true)
                                }}>Delete</Button>
                                <Button href="#/create_sustainability" variant="primary">
                                    Create Sustainability Initiative
                                </Button>
                            </SpaceBetween>
                        }
                        description="A dashboard for sustainability initiatives to shine."
                    >
                        Sustainability Initiative Dashboard
                    </Header>
                }
                columnDefinitions={SUSTAINABILITY_COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                filter={
                    <Grid
                        gridDefinition={[{colspan: 6}, {colspan: 6}]}
                    >
                        <PainPointPropertyFilter
                            {...propertyFilterProps}
                            countText={getMatchesCountText(filteredItemsCount!)}
                            filteringOptions={[
                                {
                                    propertyKey: "Submitter",
                                    value: loginUser
                                }
                            ]}
                            filteringProperties={propertyFilterProps.filteringProperties}
                            onChange={(event) => handlePainPointFilter(event)}
                            query={query}
                        />
                        <div></div>
                    </Grid>
                }
                preferences={
                    <CollectionPreferences
                        preferences={sustainabilityPreferences}
                        onConfirm={event => setUserSustainabilityPreferences(event.detail)}
                        title="Preferences Setting"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        visibleContentPreference={{
                            title: 'Select visible columns',
                            options: SUSTAINABILITY_CONTENT_SELECTOR_OPTIONS
                        }}
                        pageSizePreference={{
                            title: 'Page size',
                            options: PAGE_SELECTOR_OPTIONS
                        }}
                        wrapLinesPreference={{
                            label: 'Wrap lines',
                            description: 'Check to see all the text and wrap the lines'
                        }}
                        customPreference={(state, setState) => (
                            <FormField stretch={true} label="View as">
                                <RadioGroup
                                    value={state}
                                    onChange={event => setState(event.detail.value)}
                                    items={CUSTOM_PREFERENCE_OPTIONS}
                                />
                            </FormField>
                        )}
                    />
                }
            />
            <Modal
                onDismiss={() => setSustainabilityVisible(false)}
                visible={sustainabilityVisible}
                className="ModelSustainabilityInitiativeDetails"
                closeAriaLabel="Close modal"
                id="ModelSustainabilityInitiativeDetails"
                size="large"
                header="Sustainability Initiative Details"
            >
                {sustainabilityItem.hasOwnProperty('id') ?
                    <SustainabilityDetailView SustainabilityDetail={sustainabilityItem}
                                              loginUser={loginUser} updateDetail={loadSustainabilityItems}/>
                    : <div>Loading...</div>}
            </Modal>

            <ModalBox cancel={() => setDeniedVisible(false)} onDismiss={() => setDeniedVisible(false)} content={
                <h4>You don't have permission to Edit/Delete the Item</h4>
            } id={"ModelPermissionDenied"} header={'Error'} visible={DeniedVisible}
                      confirm={() => setDeniedVisible(false)}/>

            <ConfirmationModalBox id='ConfirmationModalBox' visible={sustainabilityDeleteVisible}
                                  onDismiss={() => setSustainabilityDeleteVisible(false)}
                                  disabled={!(deleteConfirmInputText == deleteConsentText)}
                                  content={
                                      <SpaceBetween size="m">
                                          <Box variant="span">
                                              Permanently delete the item{' '}
                                              <Box variant="span" fontWeight="bold">
                                                  {selectedSustainabilityItems.length > 0 ? selectedSustainabilityItems[0]['ProjectName'] : null}
                                              </Box>
                                              ? You can’t undo this action.
                                          </Box>
                                          <Alert type="warning"
                                                 statusIconAriaLabel="Warning">
                                              Proceeding with this action will delete the
                                              Sustainability Initiative with all their team members and
                                              can affect related viewers.{' '}
                                              <Link external={true} href="#"
                                                    ariaLabel="Learn more about permission management, opens in new tab">
                                                  Learn more
                                              </Link>
                                          </Alert>

                                          <Box>To avoid accidental deletions, we ask you
                                              to provide additional written consent.</Box>

                                          <FormField
                                              label={`To confirm this deletion, type "${deleteConsentText}".`}>
                                              <ColumnLayout columns={2}>
                                                  <Input
                                                      placeholder={deleteConsentText}
                                                      onChange={({detail}) => setDeleteConfirmInputText(detail.value)}
                                                      value={deleteConfirmInputText}
                                                  />
                                              </ColumnLayout>
                                          </FormField>
                                          {selectedSustainabilityItems.length > 0 ? (
                                              <TextContent>
                                                  <Grid
                                                      gridDefinition={[{colspan: 7}, {colspan: 5}]}
                                                  >
                                                      <Box>
                                                          <Box>
                                                              <strong>Project Name</strong>
                                                              <AutoLink
                                                                  text={selectedSustainabilityItems[0]['ProjectName']}></AutoLink>
                                                          </Box>
                                                          <Box>
                                                              <strong>Brief Description</strong>
                                                              <AutoLink
                                                                  text={selectedSustainabilityItems[0]['BriefDescription']}></AutoLink>
                                                          </Box>
                                                          <Box>
                                                              <strong>Impact</strong>
                                                              <AutoLink
                                                                  text={selectedSustainabilityItems[0]['Impact']}></AutoLink>
                                                          </Box>
                                                          <Box>
                                                              <strong>Submitter</strong>
                                                              <AutoLink
                                                                  text={selectedSustainabilityItems[0]['Submitter']}></AutoLink>
                                                          </Box>
                                                          <Box>
                                                              <strong>Submit Date</strong>
                                                              <AutoLink
                                                                  text={updateDateToString(selectedSustainabilityItems[0]['SubmitDate'])}></AutoLink>
                                                          </Box>
                                                      </Box>
                                                      <Box>
                                                          <DogSvg/>
                                                      </Box>
                                                  </Grid>
                                              </TextContent>) : null}
                                      </SpaceBetween>
                                  }
                                  header='Attention'
                                  confirm={() => sustainabilityDeleteAction()}
                                  cancel={() => setSustainabilityDeleteVisible(false)}/>
        </SpaceBetween>
    )
}
