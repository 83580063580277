import React, {useState, useEffect} from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import AlertBar from '../Common/Bars'
import {Breadcrumbs, ServiceNavigation} from '../navigation';
import ToolsPanel from './ToolsPanel';
import DetailsTable from './Table';
import {appLayoutLabels} from '../Common/labels';
import axios from 'axios'
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Link from "@amzn/awsui-components-react/polaris/link";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import SelfTopNavigation from "../topNavigation/topNavigation";

// const flashbarItems: FlashbarProps.MessageDefinition[] = [
//     {type: 'success', content: 'Resource created successfully', dismissible: true, dismissLabel: "Dismiss message"}
// ];


// Class TableView is a skeleton of a TableView using AWS-UI React components.
export default function TableView() {
    const [toolsOpen, setToolsOpen] = useState(false);
    const toolsChangeHandler = () => {
        if (toolsOpen) {
            setToolsOpen(false);
        } else {
            setToolsOpen(true);
        }
    };
    const [Popular, setPopular] = useState<any>()
    const LikeIcon = <Icon
        name="settings"
        variant="warning"
        svg={
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="thumbs-up"
                className="svg-inline--fa fa-thumbs-up fa-w-16 fa-1x "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{width: "1.5em"}}
            >
                <path
                    fill="currentColor"
                    d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
                ></path>
            </svg>
        }
    />
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/popular",
        }).then(res => {
            setPopular(res.data)
        })
    }, [])

    return (
        // <body>
        //         <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            // flash notification for demo purposes
            breadcrumbs={<Breadcrumbs items={[{text: 'Pain Point & Good Idea List', href: ''}]}/>}
            notifications={Popular ?
                (<AlertBar type={'info'} header={'Popular PainPoint/GoodIdea Display:'}
                           content={(<TextContent>
                               <div><Grid
                                   gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                                   <div><strong>Top1: </strong><Link color="inverted"
                                                                     href={'#/detail/' + Popular.Top1.Item.id}>{Popular.Top1.Item.Title}</Link>
                                   </div>
                                   <div><strong>{LikeIcon} {Popular.Top1.Number}</strong></div>
                               </Grid></div>
                               <div><Grid
                                   gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                                   <div><strong>Top2: </strong><Link color="inverted"
                                                                     href={'#/detail/' + Popular.Top2.Item.id}>{Popular.Top2.Item.Title}</Link>
                                   </div>
                                   <div><strong>{LikeIcon} {Popular.Top2.Number}</strong></div>
                               </Grid></div>
                               <div><Grid
                                   gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                                   <div><strong>Top3: </strong><Link color="inverted"
                                                                     href={'#/detail/' + Popular.Top3.Item.id}>{Popular.Top3.Item.Title}</Link>
                                   </div>
                                   <div><strong>{LikeIcon} {Popular.Top3.Number}</strong></div>
                               </Grid></div>
                           </TextContent>)}
                           dismissible={true}></AlertBar>) : <></>}
            content={<DetailsTable/>}
            contentType="table"
            maxContentWidth={Number.MAX_VALUE}
            toolsOpen={toolsOpen}
            onToolsChange={toolsChangeHandler}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
        />
        // </body>
    );

}
