import {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Form from "@amzn/awsui-components-react/polaris/form";
import {
    AutoSuggestFormField,
    InputFormField,
    SelectFormField,
    TextareaFormField,
    TilesFormField
} from "../Common/FormField"
import PainPointTags, {PainPointGroupTags} from '../Common/Tags'
import {MultiselectProps} from "@amzn/awsui-components-react/polaris/multiselect";
import axios from 'axios'
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import ModalBox from "../Common/ModalBox";
import FileUploadForm from "../Common/FileUpload";
import useInterval from "../Common/IntervalHook";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import Link from "@amzn/awsui-components-react/polaris/link";
import {CHILDREN, CLUSTER, LOCATION} from "../Common/config";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Box from "@amzn/awsui-components-react/polaris/box";
import Select from "@amzn/awsui-components-react/polaris/select";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import {FavoriteFunction} from "../Common/ButtonFunctions";
import AutoLink from "../Common/AutoLink";
import {pushToTag, pushToTagsWithList, pushValues} from "../Common/Token";

export default function CreateContent() {
    const [allLocation, setAllLocation] = useState<any>(LOCATION)
    const [childrenLocationList, setChildrenLocationList] = useState<any>(CHILDREN)

    const [OptionImpactCluster, setOptionImpactCluster] = useState<any>(pushToTagsWithList(CLUSTER))
    const history = useHistory();
    const [loginUser, setLoginUser] = useState('')
    const [SubmitterCluster, setSubmitterCluster] = useState<any>({})
    const [SubmitterClusterOptions, setSubmitterClusterOptions] = useState(pushToTagsWithList(CLUSTER))
    const [SubmitterAZ, setSubmitterAZ] = useState<any>({})
    const [SubmitterAZOptions, setSubmitterAZOptions] = useState([])
    const [SubmitterTeam, setSubmitterTeam] = useState<any>({})
    const [SubmitterTeamOptions, setSubmitterTeamOptions] = useState([
        {
            label: "DCO",
            value: "DCO"
        }, {label: "DCEO", value: "DCEO"}, {label: "Logistics", value: "Logistics"}, {
            label: "ID",
            value: "ID"
        }, {label: "Security", value: "Security"}, {
            value: "Decom Tech",
            label: "Decom Tech"
        }])
    const [
        selectedLabelOptions,
        setSelectedLabelOptions
    ] = useState<MultiselectProps.Options>([
        {
            label: "Pain Point",
            value: "Pain Point",
        }
    ]);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/getuser",
        }).then(res => {
            if (res.data.Team.length > 0) {
                setSubmitterTeam(pushToTag(res.data.Team))
            } else {
                setSubmitterTeam({value: '', label: 'All'})
            }
            Object.keys(res.data.CreateDraft).length == 0 || res.data.CreateDraft['empty'] ? axios({
                    method: "GET",
                    url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/username/" + res.data.Login,
                }).then(res => {
                    const ManagerInformation = String(res.data.manager).split('cn=')[1].split(',')[0].split(' (')[1].split(')')[0]
                    console.log(res.data)
                    setReviewerValue(ManagerInformation)
                    setSubmitterCluster(pushToTag((res.data.amznlocdescr).split('-')[0].replace(' ', '').substring(0, 3)))
                    console.log((res.data.amznlocdescr).split('-')[0].replace(' ', '').substring(0, 3))
                    console.log((res.data.amznlocdescr).split('-')[0].replace(' ', '').substring(0, 5))
                    for (let options in childrenLocationList) {
                        if (childrenLocationList[options].includes((res.data.amznlocdescr).split('-')[0].replace(' ', '').substring(0, 5)) > 0) {
                            setSubmitterAZ(pushToTag(options))
                        }
                    }
                    setTilesItemsValue([
                        {
                            label: "Myself",
                            description: "I will find the solution for it",
                            value: res.data.uid
                        },
                        {
                            label: "TBD",
                            description: "PainPoint Commit will assign other people to find solution",
                            value: "TBD"
                        }
                    ])
                    setTilesValue(res.data.uid)
                })
                : setDraft(res.data.CreateDraft, res.data.Login)
        })
    }, []);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/scanlogin",
        }).then(res => {
            const reviewerOptionList: any = []
            res.data.map((item, index) => {
                reviewerOptionList.push({
                    "label": item['gecos'],
                    "value": item['uid']
                })
            })
            setreviewerOptionsValue(reviewerOptionList)
        })
    }, [])
    useEffect(() => {
        let setOpt: any = []
        if (Object.keys(SubmitterCluster).length > 0) {
            Object.keys(allLocation[SubmitterCluster.value]).map((item) => {
                setOpt.push({
                    label: item,
                    value: item
                })
            })
        }
        setSubmitterAZOptions(setOpt)
    }, [SubmitterCluster])
    useEffect(() => {
        setSubmitterAZ(SubmitterAZOptions[0])
    }, [SubmitterAZOptions])
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);
    const setDraft = (item, login) => {
        setTilesItemsValue([
            {
                label: "Myself",
                description: "I will find the solution for it",
                value: login
            },
            {
                label: "TBD",
                description: "PainPoint Commit will assign other people to find solution",
                value: "TBD"
            }
        ])
        setSubmitterCluster(pushToTag(item.SubmitterCluster))
        setTitleValue(item.Title)
        setSubmitterTeam(pushToTag(item.SubmitterTeam))
        setBenefitValue(item.Benefit)
        setDescriptionValue(item.Description)
        setCountermeasureValue(item.Countermeasure)
        setSubmitterAZ(pushToTag(item.SubmitterAZ))
        setSecondReviewerValue(item.SecondReviewer)
        item.Label.length > 0 ? setSelectedLabelOptions(pushToTagsWithList(item.Label)) : false
        item.Category.length > 0 ? setSelectedCategoriesOptions(pushToTagsWithList(item.Category)) : false
        setTilesValue(item.Owner)
        setReviewerValue(item.FirstReviewer)
        item.Team.length > 0 ? setImpactTeamOptions(pushToTagsWithList(item.Team)) : false
        item.Cluster.length > 0 ? setImpactClusterOptions(pushToTagsWithList(item.Cluster)) : false
        item.AZ.length > 0 ? setImpactAZOptions(pushToTagsWithList(item.AZ)) : false
        item.Site.length > 0 ? setImpactSiteOptions(pushToTagsWithList(item.Site)) : false
    }


    const [titleValue, setTitleValue] = useState<string>("");
    const [countermeasureValue, setCountermeasureValue] = useState("");
    const [tilesItemsValue, setTilesItemsValue] = useState([
        {
            label: "Myself",
            description: "I will find the solution for it",
            value: loginUser
        },
        {
            label: "TBD",
            description: "PainPoint Commit will assign other people to find solution",
            value: "TBD"
        }
    ])
    // const [attachmentValue, setAttachmentValue] = useState("");
    const [tilesValue, setTilesValue] = useState(loginUser);
    useEffect(() => {
        setTilesItemsValue([
            {
                label: "Myself",
                description: "I will find the solution for it",
                value: loginUser
            },
            {
                label: "TBD",
                description: "PainPoint Commit will assign other people to find solution",
                value: "TBD"
            }
        ])
        setTilesValue(loginUser)
    }, [loginUser])
    const [errorBenefitValue, setErrorBenefitValue] = useState("")
    const [errorTextFileUpload, setErrorTextFileUpload] = useState("")
    const [reviewerValue, setReviewerValue] = useState("")
    const [secondReviewerValue, setSecondReviewerValue] = useState("")
    const [ErrorReviewerValue, setErrorReviewerValue] = useState("")
    const [benefitValue, setBenefitValue] = useState("")
    const [reviewerOptionsValue, setreviewerOptionsValue] = useState([{}])
    const [descriptionValue, setDescriptionValue] = useState("");
    const [errorTitleValue, setErrorTitleValue] = useState("");
    const [errorDescriptionValue, setErrorDescriptionValue] = useState("");
    const [errorCountermeasureValue, setErrorCountermeasureValue] = useState("");
    const [SubmitBtnLoading, setSubmitBtnLoading] = useState(false)
    const [
        selectedCategoriesOptions,
        setSelectedCategoriesOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        selectedPrioritiesOptions,
        setSelectedPrioritiesOptions
    ] = useState<MultiselectProps.Options>([]);
    const [
        ImpactTeamOptions,
        setImpactTeamOptions
    ] = useState<MultiselectProps.Options>([
        {
            label: "DCO",
            value: "DCO",
        }
    ]);
    const [SubmitSuccess, setSubmitSuccess] = useState(false)
    const [
        ImpactClusterOptions,
        setImpactClusterOptions
    ] = useState<any>([]);
    const [
        ImpactAZOptions,
        setImpactAZOptions
    ] = useState<any>([]);
    const [
        ImpactSiteOptions,
        setImpactSiteOptions
    ] = useState<any>([]);
    const [
        OptionImpactAZ,
        setOptionImpactAZ
    ] = useState([]);
    const [
        OptionImpactSite,
        setOptionImpactSite
    ] = useState([]);

    const [FileUploadValue, setFileUploadValue] = useState<any>([])

    const handleLabel = (detail) => {
        if (detail.selectedOptions.length > 1) {
            alert("Only 1 tag is allowed!")
        } else {
            setSelectedLabelOptions(detail.selectedOptions)
        }

    }
    const handleCategories = (detail) => {
        setSelectedCategoriesOptions(detail.selectedOptions)
    }
    const handlePriorities = (detail) => {
        setSelectedPrioritiesOptions(detail.selectedOptions)
    }
    const handleImpactTeam = (detail) => {
        setImpactTeamOptions(detail.selectedOptions)
    }
    useEffect(() => {
        const selectCluster: any = []
        const OptionsAZ: any = []
        ImpactClusterOptions.map((item) => {
            selectCluster.push(item.value)
        })
        selectCluster.map((item) => {
            Object.keys(allLocation[item]).map((az) => {
                OptionsAZ.push({
                    label: az,
                    value: az
                })
            })

        })
        setOptionImpactAZ(OptionsAZ)
    }, [ImpactClusterOptions])
    const handleImpactCluster = (detail) => {
        setImpactClusterOptions(detail.selectedOptions)
        setImpactAZOptions([])
        setImpactSiteOptions([])

    }
    useEffect(() => {
        const OptionsSite: any = []
        if (ImpactAZOptions.length > 0) {
            const selectAZ: any = []
            ImpactAZOptions.map((item) => {
                childrenLocationList[item.value].map((site) => selectAZ.push(site))
            })
            selectAZ.sort()
            selectAZ.map((item) => {
                OptionsSite.push({
                    label: item,
                    value: item
                })
            })
        }
        setOptionImpactSite(OptionsSite)
    }, [ImpactAZOptions])
    const handleImpactAZ = (detail) => {
        setImpactAZOptions(detail.selectedOptions)
        setImpactSiteOptions([])
    }
    const handleImpactSite = (detail) => {
        setImpactSiteOptions(detail.selectedOptions)
    }
    const handleDetail = (value) => {
        setDescriptionValue(value)
    }
    const [duplication, setDuplication] = useState<any>()
    const [duplicationLoading, setDuplicationLoading] = useState(true)
    useEffect(() => {
        setDuplicationLoading(true)
        const duplicateValue = {Value: titleValue, Type: 'Title'}
        axios({
            method: "POST",
            data: duplicateValue,
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/duplications",
        }).then(res => {
            setDuplication(res.data)
            console.log(res.data)
            setDuplicationLoading(false)
        })
    }, [titleValue])
    const handlTitleInput = (value: string) => {
        setTitleValue(value)
    }
    const handlCountermeasureInput = (value) => {
        setCountermeasureValue(value)
    }
    const handleReviewer = (value) => {
        setReviewerValue(value)
    }
    const handleSecondReviewer = (value) => {
        setSecondReviewerValue(value)
    }
    const handleBenefit = (value) => {
        setBenefitValue(value)
    }
    const handleActionOwner = (value) => {
        setTilesValue(value)
    }
    const handleFileUpload = (value) => {
        console.log(value)
        if (value.length > 0) {
            value[0]['size'] > 4194304 ?
                setErrorTextFileUpload('File size should not exceed 4 MB!!!') :
                setFileUploadValue(value)
        } else if (value.length == 0) {
            setFileUploadValue(value)
        }
    }
    const handleSubmitterCluster = (value) => {
        setSubmitterCluster(value)
    }
    const handleSubmitterAZ = (value) => {
        setSubmitterAZ(value)
    }
    const handleSubmitterTeam = (value) => {
        setSubmitterTeam(value)
    }

    // const handleAttachment = (value) => {
    //     setAttachmentValue(value)
    // }
    const submitAction = () => {
        setSubmitBtnLoading(true)
        let fileName = ''
        let fileType = ''
        FileUploadValue[0] ? fileName = FileUploadValue[0]['name'] : fileName = ''
        FileUploadValue[0] ? fileType = FileUploadValue[0]['type'].replace('/', '-') : fileType = ''
        if (SubmitterTeam['value'] == '') {
            alert('Please Select your team!')
            setSubmitBtnLoading(false)
        } else {
            if (titleValue != "" && descriptionValue != "" && benefitValue != "" && reviewerValue != "") {
                let secondReviewer = ''
                pushValues(selectedLabelOptions) == 'Good Idea' ? secondReviewer = secondReviewerValue : secondReviewer = ''
                const submitDict = {
                    "Title": titleValue,
                    "Description": descriptionValue,
                    "Countermeasure": countermeasureValue,
                    "Label": pushValues(selectedLabelOptions),
                    "Category": pushValues(selectedCategoriesOptions),
                    "Priority": pushValues(selectedPrioritiesOptions),
                    "Owner": tilesValue,
                    "FirstReviewer": reviewerValue,
                    "SecondReviewer": secondReviewer,
                    "Benefit": benefitValue,
                    "Team": pushValues(ImpactTeamOptions),
                    "Attachment": FileUploadValue,
                    "Submitter": loginUser,
                    "Site": pushValues(ImpactSiteOptions),
                    "Cluster": pushValues(ImpactClusterOptions),
                    "AZ": pushValues(ImpactAZOptions),
                    "SubmitterCluster": SubmitterCluster['value'],
                    "SubmitterAZ": SubmitterAZ['value'],
                    "SubmitterTeam": SubmitterTeam['value'],
                }
                axios({
                    method: "POST",
                    data: submitDict,
                    url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint",
                }).then(res => {
                    const emptyDict = {'empty': true}
                    axios({
                        method: "POST",
                        data: emptyDict,
                        url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/createdraft",
                    }).then(res => {

                    })
                    let painpoint_id = res.data
                    if (FileUploadValue[0]) {
                        axios({
                            method: "POST",
                            data: FileUploadValue[0],
                            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/uploadfile/" + painpoint_id + '/' + fileName + '/' + fileType,
                        }).then(res => {
                            setSubmitBtnLoading(false)
                        })
                    }
                    setSubmitSuccess(true)
                })
            } else {
                if (titleValue == "") {
                    setErrorTitleValue('Please add the Pain Point Title!')
                } else {
                    setErrorTitleValue("")
                }
                if (selectedLabelOptions.length == 0) {
                    alert('Please add the Pain Point Label!')
                }
                if (reviewerValue == secondReviewerValue) {
                    setErrorReviewerValue('Please add different reviewer!')
                } else {
                    setErrorReviewerValue("")
                }
                if (descriptionValue == "") {
                    setErrorDescriptionValue('Please add the Pain Point Description!')
                } else {
                    setErrorDescriptionValue("")
                }
                if (benefitValue == "") {
                    setErrorBenefitValue('Please add the Pain Point Benefit!')
                } else {
                    setErrorBenefitValue("")
                }
                if (reviewerValue == "") {
                    setErrorReviewerValue('Please add the Pain Point Reviewer!')
                } else {
                    setErrorReviewerValue("")
                }
                setSubmitBtnLoading(false)
            }
        }
    }
    const resetAction = () => {
        setTitleValue("")
        setTilesValue(loginUser)
        setDescriptionValue("")
        setCountermeasureValue("")
        setSelectedLabelOptions([])
        setBenefitValue("")
        setFileUploadValue('')
        setImpactSiteOptions([])
        setImpactTeamOptions([])
        setSelectedPrioritiesOptions([])
        setSelectedCategoriesOptions([])
        setSelectedLabelOptions([])
        setImpactSiteOptions([])
        setImpactAZOptions([])
        setImpactClusterOptions([])
    }
    const BackToHome = () => {
        history.push('/PainPoint & GoodIdea')
        setSubmitSuccess(false)
    }
    const [AutoSaveAlert, setAutoSaveAlert] = useState<any>([])
    const Format = (date, format) => {
        var o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "H+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return format;
    }
    useInterval(
        () => {
            let secondReviewer = ''
            pushValues(selectedLabelOptions) == 'Good Idea' ? secondReviewer = secondReviewerValue : secondReviewer = ''
            const submitDict = {
                "Title": titleValue,
                "Description": descriptionValue,
                "Countermeasure": countermeasureValue,
                "Label": pushValues(selectedLabelOptions),
                "Category": pushValues(selectedCategoriesOptions),
                "Priority": pushValues(selectedPrioritiesOptions),
                "Owner": tilesValue,
                "FirstReviewer": reviewerValue,
                "SecondReviewer": secondReviewer,
                "Benefit": benefitValue,
                "Team": pushValues(ImpactTeamOptions),
                "Attachment": FileUploadValue,
                "Submitter": loginUser,
                "Site": pushValues(ImpactSiteOptions),
                "Cluster": pushValues(ImpactClusterOptions),
                "AZ": pushValues(ImpactAZOptions),
                "SubmitterCluster": SubmitterCluster['value'],
                "SubmitterAZ": SubmitterAZ['value'],
                "SubmitterTeam": SubmitterTeam['value'],
            }
            axios({
                method: "POST",
                data: submitDict,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/createdraft",
            }).then(res => {
                setAutoSaveAlert([
                    {
                        type: "success",
                        dismissible: true,
                        dismissLabel: "Dismiss message",
                        content: (
                            <>
                                Automation Saving! Last automation-saving time:{" "}
                                <Link color="inverted">
                                    {Format(new Date(), 'yyyy-MM-dd HH:mm:ss')}
                                </Link>
                            </>
                        ),
                        onDismiss: () => setAutoSaveAlert([]),
                    }
                ])
            })
        },
        30000
    )
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Flashbar items={AutoSaveAlert}></Flashbar>
                    <Header
                        variant="h1"
                        actions={
                            <Button
                                href='https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/'
                                variant="primary"
                                target="_blank">Help</Button>
                        }
                    >
                        Create New PainPoint/GoodIdea
                    </Header>
                </SpaceBetween>
            }
        >
            <Container>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="l">
                            <Button formAction="none" variant="link"
                                    onClick={() => history.push('/PainPoint & GoodIdea')}>
                                Cancel
                            </Button>
                            <Button loading={SubmitBtnLoading} onClick={() => submitAction()}
                                    variant="primary">Submit</Button>
                        </SpaceBetween>
                    }
                >
                    <Grid
                        gridDefinition={[{colspan: 8}, {colspan: 4}]}
                    >
                        <SpaceBetween size="xs">
                            <FormField label={'Assign Group'}
                                       stretch={true}>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <SelectFormField label={'Cluster'} constraintText={'Default value is your Cluster'}
                                                     selectedOption={SubmitterCluster} options={SubmitterClusterOptions}
                                                     disable={false}
                                                     onChange={({detail}) => handleSubmitterCluster(detail.selectedOption)}
                                                     errorValue={''} description={''}/>
                                    <SelectFormField label={'Availability Zone'}
                                                     constraintText={'Please select assign Availability Zone'}
                                                     selectedOption={SubmitterAZ} options={SubmitterAZOptions}
                                                     onChange={({detail}) => handleSubmitterAZ(detail.selectedOption)}
                                                     disable={false}
                                                     errorValue={''} description={''}/>
                                    <SelectFormField label={'Team'} constraintText={'Please select assign Team'}
                                                     selectedOption={SubmitterTeam} options={SubmitterTeamOptions}
                                                     onChange={({detail}) => handleSubmitterTeam(detail.selectedOption)}
                                                     disable={false}
                                                     errorValue={''} description={''}/>
                                </ColumnLayout>
                            </FormField>
                            <InputFormField
                                label={<div><span style={{color: "red"}}>*</span>Title</div>}
                                inputValue={titleValue} errorValue={errorTitleValue}
                                onChange={(event) => handlTitleInput(event.detail.value)}
                                constraintText={'Please keep it within 10 words.'}/>
                            <TextareaFormField
                                label={<div><span style={{color: "red"}}>*</span>Problem Statement</div>}
                                textareaValue={descriptionValue} errorValue={errorDescriptionValue}
                                onChange={(event) => handleDetail(event.detail.value)}
                                constraintText={'Please provide more information to describe your pain point.'}/>
                            <TextareaFormField label='Countermeasure (Optional)'
                                               textareaValue={countermeasureValue} errorValue={errorCountermeasureValue}
                                               onChange={(event) => handlCountermeasureInput(event.detail.value)}
                                               constraintText={'Please provide your suggestion if you have the one.'}/>
                            <TextareaFormField label={<div><span style={{color: "red"}}>*</span>Benefit</div>}
                                               textareaValue={benefitValue} errorValue={errorBenefitValue}
                                               onChange={(event) => handleBenefit(event.detail.value)}
                                               constraintText={'Please describe the scope of impact if the pain point is improved.'}/>
                            <FormField stretch={true}>
                                <Box
                                    margin={{right: "xxxl"}}>
                                    <ColumnLayout columns={3} variant="text-grid">
                                        <PainPointTags label={'Label'} errorValue={""}
                                                       handleTags={({detail}) => handleLabel(detail)}
                                                       selectedOptions={selectedLabelOptions}
                                                       options={[{
                                                           label: "Pain Point",
                                                           value: "Pain Point",
                                                       }, {
                                                           label: "Good Idea",
                                                           value: "Good Idea",
                                                       }]}
                                                       constraintText={'Please select the improvement category of this pain point, if it is a good idea, please submit it get manager approval.'}/>
                                        <PainPointTags label={'Categories'} errorValue={""}
                                                       handleTags={({detail}) => handleCategories(detail)}
                                                       selectedOptions={selectedCategoriesOptions}
                                                       options={[{
                                                           label: "Tools",
                                                           value: "Tools",
                                                       }, {
                                                           label: "Employee Experience",
                                                           value: "Employee Experience",
                                                       }, {
                                                           label: "Process",
                                                           value: "Process",
                                                       }, {
                                                           label: "Operation",
                                                           value: "Operation",
                                                       }, {
                                                           label: "Script",
                                                           value: "Script",
                                                       }]}
                                                       constraintText={'Please select the improvement category of this pain point, if it is a good idea, please submit it get manager approval.'}/>
                                        <PainPointTags label={'Priorities'} errorValue={""}
                                                       handleTags={({detail}) => handlePriorities(detail)}
                                                       selectedOptions={selectedPrioritiesOptions}
                                                       options={[{
                                                           label: "Safety",
                                                           value: "Safety",
                                                       }, {
                                                           label: "Security",
                                                           value: "Security",
                                                       }, {
                                                           label: "Availability",
                                                           value: "Availability",
                                                       }, {
                                                           label: "Productivity",
                                                           value: "Productivity",
                                                       }, {
                                                           label: "Efficiency",
                                                           value: "Efficiency",
                                                       }, {
                                                           label: "Cost",
                                                           value: "Cost",
                                                       }, {
                                                           label: "Quality",
                                                           value: "Quality",
                                                       }]}
                                                       constraintText={'Please select the improvement category of this pain point, if it is a good idea, please submit it get manager approval.'}/>
                                    </ColumnLayout>
                                </Box>
                            </FormField>
                            <TilesFormField
                                label={"Do you want to be action owner?"} tilesValue={tilesValue}
                                items={tilesItemsValue}
                                errorValue={""} onChange={(event) => handleActionOwner(event.detail.value)}
                                constraintText={'Please evaluate whether you can act as action owner according to your time and skill.'}/>
                            <AutoSuggestFormField
                                label={<div><span style={{color: "red"}}>*</span>First reviewer</div>}
                                autoSuggestValue={reviewerValue} errorValue={ErrorReviewerValue}
                                options={reviewerOptionsValue}
                                onChange={(event) => handleReviewer(event.detail.value)} invalid={false}
                                constraintText={'The first viewer by default is the line manager, if this is a special item that needs to be reviewed by others, you can modify it.'}/>
                            {selectedLabelOptions.length > 0 && pushValues(selectedLabelOptions)[0] == "Good Idea" ?
                                <AutoSuggestFormField
                                    label={<div>Second reviewer<span style={{color: "red"}}> Optional</span></div>}
                                    autoSuggestValue={secondReviewerValue} errorValue={''}
                                    options={reviewerOptionsValue}
                                    onChange={(event) => handleSecondReviewer(event.detail.value)} invalid={false}
                                    constraintText={'The second viewer by default is empty, if this is a special item that needs second reviewer, we recommend filling in your OE Engineer or Cluster Manager'}/>
                                : null}
                            <PainPointGroupTags
                                selectedOptions={ImpactTeamOptions} label={'Impact Teams'} errorValue={''}
                                options={[{
                                    label: "Select All",
                                    options: [
                                        {
                                            label: "DCO",
                                            value: "DCO",
                                        },
                                        {
                                            label: "DCEO",
                                            value: "DCEO",
                                        }, {
                                            label: "ID",
                                            value: "ID",
                                        }, {
                                            label: "Security",
                                            value: "Security",
                                        }, {
                                            label: "Logistic",
                                            value: "Logistic",
                                        }, {
                                            value: "Decom Tech",
                                            label: "Decom Tech"
                                        }
                                    ]
                                }]} handleTags={({detail}) => handleImpactTeam(detail)}
                                constraintText={'Please select all impacted team'}/>
                            <FormField stretch={true}>
                                <Box
                                    margin={{right: "xxxl"}}>
                                    <ColumnLayout columns={3} variant="text-grid">
                                        <PainPointGroupTags selectedOptions={ImpactClusterOptions}
                                                            label={'Impact Cluster'}
                                                            errorValue={''}
                                                            options={[{
                                                                label: "Select All",
                                                                options: OptionImpactCluster
                                                            }]} handleTags={({detail}) => handleImpactCluster(detail)}
                                                            constraintText={'Please select all impacted Clusters'}/>
                                        <PainPointGroupTags selectedOptions={ImpactAZOptions} label={'Impact AZ'}
                                                            errorValue={''}
                                                            options={[{
                                                                label: "Select All",
                                                                options: OptionImpactAZ
                                                            }]}
                                                            handleTags={({detail}) => handleImpactAZ(detail)}
                                                            constraintText={'Please select all impacted AZs'}/>
                                        <PainPointGroupTags selectedOptions={ImpactSiteOptions} label={'Impact Site'}
                                                            errorValue={''}
                                                            options={[{
                                                                label: "Select All",
                                                                options: OptionImpactSite
                                                            }]}
                                                            handleTags={({detail}) => handleImpactSite(detail)}
                                                            constraintText={'Please select all impacted Sites'}/>
                                    </ColumnLayout>
                                </Box>
                            </FormField>
                            {/*<InputFormField label={"Attachment"} inputValue={attachmentValue} errorValue={""}*/}
                            {/*                onChange={(event) => handleAttachment(event.detail.value)}*/}
                            {/*                constraintText={'Please fill the attachment file link.'}/>*/}

                            <FileUploadForm onchange={({detail}) => handleFileUpload(detail.value)}
                                            value={FileUploadValue}
                                            label={'Attachment'} description={'Please upload the Attachment.'}
                                            errorText={errorTextFileUpload}/>
                        </SpaceBetween>
                        {duplicationLoading ?
                            <Box textAlign="center" margin={{top: "xxl"}} padding={{top: "xxl"}}>
                                <Spinner size="large"/>
                            </Box>

                            :
                            (duplication ?
                                <SpaceBetween size="xxl">
                                    <Alert statusIconAriaLabel="Warning" type="warning"
                                           header="Dupicate Detetion">
                                        Please check the similar of this item to avoid duplicate efforts.
                                    </Alert>
                                    {titleValue == '' ?
                                        <Box textAlign="center" margin={{top: "xxl"}} padding={{top: "xxl"}}
                                             color="text-body-secondary">
                                            Enter a title to view similar items before deciding whether to submit it.
                                        </Box>
                                        : duplication.map((item, index) => {
                                            if (item.Result > 0) {
                                                return (
                                                    <Box key={index}>
                                                        {item.Similar.Label.length > 0 ?
                                                            <SpaceBetween size="s" direction="horizontal">
                                                                <Badge
                                                                    color={item.Similar.Label[0] == "Good Idea" ? "green" : "blue"}>{item.Similar.Label[0]}</Badge>
                                                                <StatusIndicator
                                                                    type={item.Similar.State === 'Unapproved' ? 'error' : (item.Similar.State === 'Approved' ? 'success' : (item.Similar.State === 'Reviewed' ? 'pending' : 'info'))}>{item.Similar.State}</StatusIndicator>
                                                                <StatusIndicator
                                                                    type={item.Similar.Status === 'Assign' ? 'info' : (item.Similar.Status === 'Working in Process' ? 'in-progress' : (item.Similar.Status === 'Pending' ? 'pending' : (item.Similar.Status === 'Resolved' ? 'success' : 'error')))}>{item.Similar.Status}</StatusIndicator>
                                                                <FavoriteFunction FavoriteID={item.id}
                                                                                  login={loginUser}
                                                                                  Like={item.Similar.Like}
                                                                                  Hate={item.Similar.Hate}/>
                                                            </SpaceBetween>
                                                            :
                                                            <Badge>N/A</Badge>
                                                        }
                                                        <SpaceBetween size="xxxs">
                                                            <Link
                                                                onFollow={() => {
                                                                    window.open('#/detail/' + item.Similar.id, '_blank')
                                                                }}>
                                                                {item.Similar.Title}
                                                            </Link>
                                                            <Popover
                                                                dismissButton={false}
                                                                size="large"
                                                                triggerType="custom"
                                                                content={
                                                                    <SpaceBetween size="xxs">
                                                                        <Box>
                                                                            <Box variant="awsui-key-label">Title</Box>
                                                                            <AutoLink
                                                                                text={item.Similar.Title}></AutoLink>
                                                                        </Box>
                                                                        <Box>
                                                                            <Box variant="awsui-key-label">Problem
                                                                                Statement</Box>
                                                                            <AutoLink
                                                                                text={item.Similar.Description}></AutoLink>
                                                                        </Box>
                                                                        <Box>
                                                                            <Box
                                                                                variant="awsui-key-label">Countermeasure</Box>
                                                                            <AutoLink
                                                                                text={item.Similar.Countermeasure}></AutoLink>
                                                                        </Box>
                                                                        <Box>
                                                                            <Box variant="awsui-key-label">Benefit</Box>
                                                                            <AutoLink
                                                                                text={item.Similar.Benefit}></AutoLink>
                                                                        </Box>
                                                                    </SpaceBetween>
                                                                }
                                                            >
                                                                <Box
                                                                    color="text-body-secondary">
                                                                    {item.Similar.Description.length > 100 ? item.Similar.Description.substring(0, 100) + "..." : item.Similar.Description}
                                                                </Box>
                                                            </Popover>
                                                        </SpaceBetween>
                                                    </Box>
                                                )
                                            }
                                        })}
                                </SpaceBetween>
                                : null)
                        }

                    </Grid>

                </Form>
            </Container>
            <ModalBox cancel={resetAction} onDismiss={() => setSubmitSuccess(false)} content={
                <h4>Submitted Successful! Please return back to home page.</h4>
            } id={"ModalSubmitSuccess"} header={'Successful'} visible={SubmitSuccess} confirm={() => BackToHome()}/>
        </ContentLayout>
    )
        ;
}