export const LineChart_DEFINITIONS = {
    filterLabel: "Filter displayed data",
    filterPlaceholder: "Filter data",
    filterSelectedAriaLabel: "selected",
    detailPopoverDismissAriaLabel: "Dismiss",
    legendAriaLabel: "Legend",
    chartAriaRoleDescription: "line chart",
    xTickFormatter: e =>
        e.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        })
            .split(",")
            .join("\n"),
    yTickFormatter: function l(e) {
        return Math.abs(e) >= 1e9
            ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
            "G"
            : Math.abs(e) >= 1e6
                ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                "M"
                : Math.abs(e) >= 1e3
                    ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                    "K"
                    : e.toFixed(2);
    }
}

export const DateRangePicker_DEFINITIONS = {
    todayAriaLabel: "Today",
    nextMonthAriaLabel: "Next month",
    previousMonthAriaLabel: "Previous month",
    customRelativeRangeDurationLabel: "Duration",
    customRelativeRangeDurationPlaceholder:
        "Enter duration",
    customRelativeRangeOptionLabel: "Custom range",
    customRelativeRangeOptionDescription:
        "Set a custom range in the past",
    customRelativeRangeUnitLabel: "Unit of time",
    formatRelativeRange: e => {
        const n =
            1 === e.amount ? e.unit : `${e.unit}s`;
        return `Last ${e.amount} ${n}`;
    },
    formatUnit: (e, n) => (1 === n ? e : `${e}s`),
    dateTimeConstraintText:
        "Range is 6 to 30 days. For date, use YYYY/MM/DD. For time, use 24 hr format.",
    relativeModeTitle: "Relative range",
    absoluteModeTitle: "Absolute range",
    relativeRangeSelectionHeading: "Choose a range",
    startDateLabel: "Start date",
    endDateLabel: "End date",
    startTimeLabel: "Start time",
    endTimeLabel: "End time",
    clearButtonLabel: "Clear and dismiss",
    cancelButtonLabel: "Cancel",
    applyButtonLabel: "Apply"
}

export const MixedChart_DEFINITIONS = {
    filterLabel: "Filter displayed data",
    filterPlaceholder: "Filter data",
    filterSelectedAriaLabel: "selected",
    detailPopoverDismissAriaLabel: "Dismiss",
    legendAriaLabel: "Legend",
    chartAriaRoleDescription: "line chart",
    yTickFormatter: function o(e) {
        return Math.abs(e) >= 1e9
            ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
            "G"
            : Math.abs(e) >= 1e6
                ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                "M"
                : Math.abs(e) >= 1e3
                    ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                    "K"
                    : e.toFixed(2);
    }
}

export const PieDonutChart_DEFINITIONS = {
                detailsValue: "Value",
                detailsPercentage: "Percentage",
                filterLabel: "Filter displayed data",
                filterPlaceholder: "Filter data",
                filterSelectedAriaLabel: "selected",
                detailPopoverDismissAriaLabel: "Dismiss",
                legendAriaLabel: "Legend",
                chartAriaRoleDescription: "pie chart",
                segmentAriaRoleDescription: "segment"
            }