import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';

export default function ToolsPanel() {
    return (
        <HelpPanel
            // loading
            // loadingText="Loding Help Panel"
            header={<h2>Contact Us!</h2>}
            footer={
                <ul>
                    <li>
                        <a href="https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/">
                            UserGuide
                        </a>
                    </li>
                    <li>
                        <a href="https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/Roadmap">
                            Road Map
                        </a>
                    </li>
                    <li>
                        <a href="https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/ContactUs">
                            Contact Us
                        </a>
                    </li>
                </ul>
            }
        >
            <div>
                <p>
                    If you have any question about the Aladdin Dashboard, Please reach out <code>PEK-Dev Team</code> to
                    get support!
                </p>
                <code>
                    C: Company-wide Services
                    T: BJSPEK Dev Team
                    I: BJSPEK development
                </code>
                <h5>What is Aladdin Dashboard?</h5>
                <p>
                    Aladdin Dashboard is a dashboard for good idea to shine.This system is mainly used to record all
                    pain points in your daily work.
                </p>
                <h5>What is the Benefit?</h5>
                <p>
                    It is more convenient and clear to conduct PainPoint/GoodIdea reviews.It is easier to present and
                    reward good ideas.
                </p>
                <h5>What is the Aladdin Process?</h5>
                <dl>
                    <dd>1.Submit the New PainPoint/GoodIdea(fill all information which is required).</dd>
                    <dd>2.Reviewer select whether the PainPoint Go or Not to Go.</dd>
                    <dd>3.Action Owner provide improve actions and assign to next action owner.</dd>
                    <dd>4.All actions completed, resolve the PainPoint/GoodIdea.</dd>
                </dl>
            </div>

        </HelpPanel>
    );
}
