import {useEffect, useRef, useState} from 'react';
import axios from 'axios'
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from '@amzn/awsui-components-react/polaris/container';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Link from "@amzn/awsui-components-react/polaris/link";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import AutoLink, {updateDateToString} from "../Common/AutoLink";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import {ConfirmationModalBox} from "../Common/ModalBox";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {useHistory, useParams} from "react-router-dom";
import {EmptyState} from "../TableView/table-config";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {DogSvg} from "../Common/Token";

export default function NotificationDashboard() {
    const [defaultItems, setDefaultItems] = useState<any>();
    const [allDetails, setAllDetails] = useState<any>();
    const [visibleRejectModal, setVisibleRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [notificationDetail, setNotificationDetail] = useState<any>();
    const [rejectLoading, setRejectLoading] = useState(false);
    const [userDetail, setUserDetail] = useState<any>();
    const history = useHistory();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filteringText, setFilteringText] = useState("");
    const [highLightCount, setHighLightCount] = useState<any>();
    const loadNotifications = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/notifications",
        }).then(res => {
            setDefaultItems(res.data)
            setAllDetails(sliceList(res.data))
            setTotalPages(sliceList(res.data).length)
            console.log(res.data)
            let highLight: any = {
                'High': 0,
                'Medium': 0,
                'Low': 0
            }
            res.data.map(item => {
                if (item.Priority == 'High') {
                    highLight['High'] += 1
                } else if (item.Priority == 'Medium') {
                    highLight['Medium'] += 1
                } else {
                    highLight['Low'] += 1
                }
            })
            setHighLightCount(highLight)
        })
    }
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/getuser",
        }).then(res => {
            setUserDetail(res.data)
            console.log(res.data)
        })
    }, []);

    useEffect(() => {
        loadNotifications()
    }, [])
    const RejectAction = () => {
        setRejectLoading(true)
        axios({
            method: "POST",
            data: {
                RejectReason: rejectReason,
                id: notificationDetail.id,
                Type: notificationDetail.Type,
                RelatedProject: notificationDetail.RelatedProject
            },
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/reject",
        }).then(res => {
            setRejectLoading(false)
            setVisibleRejectModal(false)
            setRejectReason('')
            loadNotifications()
            history.push('/notification')
        })
    }

    const sliceList = (arr) => {
        let result: any[] = []
        for (let i = 0; i < arr.length; i += 10) {
            result.push(arr.slice(i, i + 10));
        }
        return result;
    }
    const searchTextFilter = (filteringText, defaultItems) => {
        let searchResult: any[] = []

        defaultItems.map(item => {
            for (let key in item) {
                if (typeof item[key] === 'string' && item[key].includes(filteringText)) {
                    searchResult.push(item);
                    break;
                }
            }
        })
        return searchResult
    }

    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        description={"Review the notifications which is sharing to you."}
                    >
                        Notification Center
                    </Header>
                </SpaceBetween>
            }
        >
            <SpaceBetween size="m">
                <Container
                    header={
                        <Header
                            variant="h2"
                            description={
                                <TextContent>
                                    <p>
                                        HighLights of notifications owned by {userDetail ? <a
                                            href={"https://phonetool.amazon.com/users/" + userDetail.Login}>{userDetail.Login}</a> :
                                        <Spinner/>}.
                                    </p>
                                </TextContent>
                            }
                        >
                            Highlights
                        </Header>
                    }
                >
                    <ColumnLayout columns={3} variant="text-grid">
                        <Box textAlign="center">
                            <Box variant="h5">High Priority</Box>
                            <Link fontSize="heading-l" href="/notification">
                                {highLightCount ? highLightCount['High'] : <Spinner/>}
                            </Link>
                        </Box>
                        <Box textAlign="center">
                            <Box variant="h5">Medium Priority</Box>
                            <Link fontSize="heading-l" href="/notification">
                                {highLightCount ? highLightCount['Medium'] : <Spinner/>}
                            </Link>
                        </Box>
                        <Box textAlign="center">
                            <Box variant="h5">Low Priority</Box>
                            <Link fontSize="heading-l" href="/notification">
                                {highLightCount ? highLightCount['Low'] : <Spinner/>}
                            </Link>
                        </Box>
                    </ColumnLayout>
                </Container>
                <Container
                    header={
                        <Header
                            variant="h2"
                            description={
                                <TextContent>
                                    <p>
                                        Summary of notification and priority owned by {userDetail ? <a
                                            href={"https://phonetool.amazon.com/users/" + userDetail.Login}>{userDetail.Login}</a> :
                                        <Spinner/>} which shows <strong>Pain Point && Good Idea and Sustainability
                                        Initiatives.</strong>{" "}(<span
                                        style={{color: "red"}}>High Priority</span>)Select{" "}
                                        <code>Adopt</code> to create a Children Item for localization adoption
                                        measures, select <code>Reject</code> to Reject this promote measures. (<span
                                        style={{color: "green"}}>Medium and Low Priority</span>)Select <code>Acknowledge</code> to
                                        remove yourself from the notification.
                                    </p>
                                </TextContent>
                            }>
                            Notifications Details
                        </Header>
                    }
                >
                    <SpaceBetween size="l">
                        {allDetails === undefined ?
                            <Box textAlign="center">
                                <Spinner size="large"/>
                            </Box> :
                            <SpaceBetween size='l'>
                                <Grid
                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                >
                                    <Box>
                                        <TextFilter
                                            filteringText={filteringText}
                                            filteringPlaceholder="Find Notifications"
                                            filteringAriaLabel="Filter Notifications"
                                            onChange={({detail}) => {
                                                setFilteringText(detail.filteringText)
                                                console.log(searchTextFilter(detail.filteringText, defaultItems))
                                                console.log(sliceList(searchTextFilter(detail.filteringText, defaultItems)))
                                                setAllDetails(sliceList(searchTextFilter(detail.filteringText, defaultItems)))
                                                setCurrentPageIndex(1)
                                                setTotalPages(sliceList(searchTextFilter(detail.filteringText, defaultItems)).length)
                                            }
                                            }
                                        />
                                    </Box>
                                    <Box float={'right'}>
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({detail}) =>
                                                setCurrentPageIndex(detail.currentPageIndex)
                                            }
                                            pagesCount={totalPages}
                                        />
                                    </Box>
                                </Grid>
                                {allDetails.length === 0 ?
                                    <EmptyState
                                        title="Congratulations!"
                                        subtitle="All Notifications are Cleared. Please Keep this Good Habbits!"
                                        action={<Button onClick={() => console.log('ts')}>I Want to Post
                                            Notification to
                                            Others</Button>}
                                    />
                                    : allDetails[currentPageIndex - 1].map((item: any, index: any) => {
                                        return (
                                            <Alert
                                                statusIconAriaLabel="Info"
                                                header={item.Header}
                                                type={item.Priority === "High" ? "warning" : item.Priority === "Medium" ? "success" : "info"}
                                            >
                                                <SpaceBetween size='xs'>
                                                    <Grid
                                                        gridDefinition={[{colspan: 10}, {colspan: 2}]}
                                                    >
                                                        <AutoLink text={item.Description}/>
                                                        <SpaceBetween size="xs" direction="horizontal">
                                                            <Badge>{updateDateToString(item.CreateDate)}</Badge>
                                                            <Badge color="red">{item.Type}</Badge>
                                                            <Badge color="green">{item.Creator}</Badge>
                                                        </SpaceBetween>
                                                    </Grid>
                                                    <TextContent>
                                                        <strong>More Details:{" "}
                                                            <Link external
                                                                  href={item.Type === "Sustainability" ? "#/sustainability/detail/" + item.RelatedProject : "#/detail/" + item.RelatedProject}>
                                                                {item.Title}
                                                            </Link></strong>
                                                    </TextContent>
                                                    <SpaceBetween size="xl" direction="horizontal">
                                                        {item.Priority == 'Low' ?
                                                            <Button variant="primary"
                                                                    onClick={() => console.log(item)}>
                                                                <Icon name="check"/>{' '}Acknowledge
                                                            </Button> :
                                                            <Button variant="primary"
                                                                    onClick={() => history.push('/copy/' + (item.Type).toLowerCase() + '/' + item.id)}>
                                                                <Icon name="check"/>{' '}Adpot
                                                            </Button>}
                                                        <Button loading={rejectLoading} onClick={() => {
                                                            setNotificationDetail(item)
                                                            setVisibleRejectModal(true)
                                                        }}>
                                                            <Icon name="close"/>{' '}Reject
                                                        </Button>
                                                    </SpaceBetween>
                                                </SpaceBetween>
                                            </Alert>
                                        )
                                    })}
                            </SpaceBetween>
                        }
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
            <ConfirmationModalBox id='ConfirmationModalBox' visible={visibleRejectModal}
                                  onDismiss={() => setVisibleRejectModal(false)}
                                  disabled={rejectReason == ''}
                                  content={
                                      <SpaceBetween size="m">
                                          <Box variant="span">
                                              Rejct the promote item{' '}
                                              <Box variant="span" fontWeight="bold">
                                                  {notificationDetail ? notificationDetail['Title'] : 'Loading....'}
                                              </Box>
                                              ? You can’t undo this action.
                                          </Box>
                                          <Alert type="warning"
                                                 statusIconAriaLabel="Warning">
                                              Proceeding with this action will reject the
                                              Aladdin Item with <Box variant="span" fontWeight="bold">
                                              {notificationDetail ? notificationDetail['Creator'] : 'Loading....'}
                                          </Box> and must
                                              provide the reject reason.{' '}
                                              <Link external={true} href="#"
                                                    ariaLabel="Learn more about permission management, opens in new tab">
                                                  Learn more
                                              </Link>
                                          </Alert>

                                          <Box>To avoid accidental reject notifications, we ask you
                                              to provide additional written reason.</Box>
                                          <TextContent>
                                              <Grid
                                                  gridDefinition={[{colspan: 7}, {colspan: 5}]}
                                              >
                                                  <FormField
                                                      constraintText={'Please explain why you reject the promote item.'}
                                                      label={'Reject Reason'}
                                                      stretch={true}
                                                  >
                                                      <Textarea
                                                          value={rejectReason}
                                                          onChange={({detail}) => setRejectReason(detail.value)}
                                                          rows={8}
                                                      />
                                                  </FormField>
                                                  <Box>
                                                      <DogSvg/>
                                                  </Box>
                                              </Grid>
                                          </TextContent>
                                      </SpaceBetween>
                                  }
                                  header='Reject Promote Item'
                                  confirm={() => RejectAction()}
                                  cancel={() => setVisibleRejectModal(false)}/>
        </ContentLayout>
    );
}