import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import FormPage from '../components/form/FormPage';
import TableView from '../components/TableView'
import DetailPage from '../components/DetailView/DetailPage'
import CreatePainPoint from '../components/CreatePage/CreatePainPoint'
import PetsTable from '../components/table/Table'
import EditPainPoint from "../components/EditPage/EditPainPoint";
import ReportLayout from "../components/Report/ReportLayout";
import PermissionLayout from "../components/Permission/PermissionLayout";
import PreferencesLayout from "../components/Preferences/PreferencesLayout";
import ApprovalLayout from "../components/PendingApproval/ApprovalLayout";
import RequestByMeLayout from "../components/RequestByMe/RequestByMeLayout";
import NotificationsLayout from "../components/Notifications/NotificationsLayout";
import ResolvedReportLayout from "../components/Report/ResolvedReportLayout";
import ResolvedLayout from "../components/ResolvedPage/ResolvedLayout";
import OpenLayout from "../components/OpenPage/OpenLayout";
import GlobalPoolLayout from "../components/GlobalPoolPage/GlobalPoolLayout";
import SearchLayout from "../components/SearchPage/SearchLayout";
import Homepage from "../components/home";
import AnalyticsLayout from "../components/Analytics/AnalyticsLayout";
import LoginPage from "../components/Common/LoginPage";
import SustainabilityLayout from "../components/SustainabilityPage/SustainabilityLayout";
import SustainabilityCreateLayout from "../components/SustainabilityCreate/SustainabilityCreateLayout";
import SustainabilityDetailPage from "../components/SustainabilityDetailView/SustainabilityDetailPage";
import NotificationLayout from "../components/NotificationPage/NotificationLayout";
import CopyLayout from "../components/CopyPage/CopyLayout";
import EditSustainability from "../components/SustainabilityCreate/SustainabilityEditLayout";
import SustainabilityTableView from "../components/TableView/SustainabilityIndex";

export default function IndexRouter() {
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/PainPoint & GoodIdea" component={TableView}/>
                <Route exact path="/open" component={OpenLayout}/>
                <Route exact path="/form/:mode/:id" component={FormPage}/>
                <Route exact path="/detail/:id" component={DetailPage}/>
                <Route exact path="/create" component={CreatePainPoint}/>
                <Route exact path="/edit/:id" component={EditPainPoint}/>
                <Route exact path="/report/submission" component={ReportLayout}/>
                <Route exact path="/resolved" component={ResolvedLayout}/>
                <Route exact path="/report/resolved" component={ResolvedReportLayout}/>
                <Route exact path="/permission" component={PermissionLayout}/>
                <Route exact path="/preferences" component={PreferencesLayout}/>
                <Route exact path="/approval" component={ApprovalLayout}/>
                <Route exact path="/request" component={RequestByMeLayout}/>
                <Route exact path="/global" component={GlobalPoolLayout}/>
                {/*<Route exact path="/notifications" component={NotificationsLayout}/>*/}
                <Route exact path="/Sustainability Initiative" component={SustainabilityTableView}/>
                <Route exact path="/notification" component={NotificationLayout}/>
                <Route exact path="/search/:searchContent" component={SearchLayout}/>
                <Route exact path="/niube666" component={Homepage}/>
                <Route exact path="/analytics/:login" component={AnalyticsLayout}/>
                <Route exact path="/login" component={LoginPage}/>
                <Route exact path="/sustainability" component={SustainabilityLayout}/>
                <Route exact path="/create_sustainability" component={SustainabilityCreateLayout}/>
                <Route exact path="/sustainability/detail/:id" component={SustainabilityDetailPage}/>
                <Route exact path="/sustainability/edit/:id" component={EditSustainability}/>
                <Route exact path="/copy/:type/:id" component={CopyLayout}/>
                <Redirect from='/' to='/PainPoint & GoodIdea'/>
            </Switch>
        </HashRouter>
    );
}