import ReactMarkdown from 'react-markdown';
import React from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {darcula} from 'react-syntax-highlighter/dist/cjs/styles/prism';
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import remarkGfm from 'remark-gfm'

export interface MarkDownProps {
    textContent: string;
    height: any
}

export default function MarkDown(props: MarkDownProps) {
    const {textContent, height} = props

    return (
        <TextContent>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    img(props) {
                        return <img {...props} style={{maxWidth: '100%'}}/>
                    }
                }}
            >
                {textContent}
            </ReactMarkdown>
        </TextContent>
    )
}

// function MarkDown13(props: MarkDownProps) {
//     const {textContent, height} = props
//
//     return (
//         <TextContent>
//             <ReactMarkdown
//                 remarkPlugins={[remarkGfm]}
//                 components={{
//                     code({node, inline, className, children, ...props}) {
//                         const match = /language-(\w+)/.exec(className || '');
//                         return !inline ? (
//                             <SyntaxHighlighter
//                                 showLineNumbers={true}
//                                 style={darcula}
//                                 language='javascript'
//                                 PreTag="div"
//                                 {...props}
//                             >
//                                 {String(children).replace(/\n$/, '')}
//                             </SyntaxHighlighter>
//
//                         ) : (
//                             <code className={className} {...props}>
//                                 {children}
//                             </code>
//                         );
//                     },
//                     img(props) {
//                         return <img {...props} style={{maxWidth: '100%'}}/>
//                     }
//                 }}
//             >
//                 {textContent}
//             </ReactMarkdown>
//         </TextContent>
//     )
// }