export const CLUSTER: any = ['ABQ', 'ACT', 'ACV', 'ADS', 'AEX', 'AKL', 'ALE', 'AMA', 'AMS', 'AOH', 'APA', 'ARN', 'ATH', 'ATL', 'AUS', 'AYQ', 'BAH', 'BER', 'BHM', 'BHX', 'BIS', 'BJS', 'BKK', 'BLR', 'BNA', 'BOG', 'BOI', 'BOM', 'BOS', 'BPM', 'BRQ', 'BTP', 'BWI', 'CAE', 'CAN', 'CDG', 'CGK', 'CID', 'CJJ', 'CJS', 'CLL', 'CLT', 'CMB', 'CMH', 'COS', 'CPT', 'CRP', 'CRW', 'CVG', 'DBO', 'DCA', 'DEN', 'DFW', 'DLH', 'DSM', 'DTW', 'DUB', 'DXB', 'EUG', 'EVV', 'EWR', 'FAR', 'FFZ', 'FLG', 'FLL', 'FMO', 'FRA', 'FSD', 'GDL', 'GEG', 'GGG', 'GIG', 'GPT', 'GRU', 'GTU', 'GUS', 'HEF', 'HEL', 'HHR', 'HII', 'HKG', 'HLN', 'HND', 'HNL', 'HOT', 'HOU', 'HWY', 'HYD', 'IAD', 'IAH', 'ICN', 'ICT', 'IND', 'JAN', 'JBR', 'JFK', 'JLN', 'KIX', 'KUL', 'LAS', 'LAX', 'LBB', 'LCH', 'LCK', 'LFK', 'LGA', 'LHR', 'LNK', 'LTW', 'LUX', 'MAA', 'MAD', 'MAF', 'MCI', 'MCO', 'MDW', 'MEL', 'MEM', 'MFE', 'MIA', 'MLI', 'MMU', 'MRE', 'MSN', 'MSP', 'MSY', 'MUC', 'MXP', 'NCL', 'NRT', 'OKC', 'OLV', 'OMA', 'ORD', 'ORH', 'OSL', 'OSU', 'OUTDE', 'OUTGI', 'OUTPR', 'OUTUK', 'OUTUS', 'PDT', 'PDX', 'PEK', 'PHX', 'PIT', 'PRC', 'PRG', 'PUQ', 'PVG', 'QRO', 'RAD', 'RDU', 'RIC', 'RIO', 'RNO', 'ROA', 'RST', 'RSW', 'SAN', 'SAT', 'SBN', 'SCC', 'SCL', 'SDF', 'SEA', 'SEL', 'SFO', 'SHA', 'SHV', 'SIN', 'SJC', 'SJT', 'SLC', 'SMF', 'SNA', 'STC', 'STL', 'SUX', 'SVA', 'SYD', 'SZX', 'TLH', 'TLV', 'TOL', 'TPA', 'TPE', 'TSN', 'TTN', 'TUL', 'TUP', 'TUS', 'TYO', 'TYR', 'US', 'US-POPS', 'VIE', 'WUX', 'YTO', 'YUL', 'YYC', 'ZAZ', 'ZHY', 'ZRH']

export const CHILDREN: any = {
    'IAD7': ['IAD316', 'IAD315', 'IAD77', 'IAD193', 'IAD211', 'IAD112', 'IAD602', 'IAD109', 'IAD74', 'IAD24', 'IAD108', 'IAD314', 'IAD97', 'IAD96', 'IAD131', 'IAD104', 'IAD197', 'IAD95', 'IAD103', 'IAD113', 'IAD14', 'IAD224', 'IAD195', 'IAD102', 'IAD7', 'IAD107', 'IAD101', 'IAD223', 'IAD133', 'IAD110', 'IAD222', 'IAD106', 'IAD85', 'IAD221', 'IAD11', 'IAD100', 'IAD317', 'IAD607', 'IAD59', 'IAD805', 'IAD235', 'IAD132', 'IAD206', 'IAD370', 'IAD105', 'IAD210', 'IAD73', 'IAD111', 'IAD35', 'IAD130', 'IAD205', 'IAD225', 'IAD75', 'IAD234', 'IAD52', 'IAD200'],
    'IAD12': ['IAD230', 'IAD143', 'IAD57', 'IAD227', 'IAD167', 'IAD147', 'IAD141', 'IAD142', 'IAD282', 'IAD168', 'IAD15', 'IAD192', 'IAD285', 'IAD90', 'IAD56', 'IAD92', 'IAD149', 'IAD500', 'IAD58', 'IAD76', 'IAD146', 'IAD16', 'IAD290', 'IAD145', 'IAD88', 'IAD148', 'IAD608', 'IAD89', 'IAD144', 'IAD603', 'IAD91', 'IAD175', 'IAD501', 'IAD12', 'IAD166', 'IAD51', 'IAD140', 'IAD214', 'IAD226', 'IAD165'],
    'OUTDE1': ['OUTDE1'],
    'PDT2': ['PDT195', 'PDT54', 'PDT65', 'PDT63', 'PDT130', 'PDT60', 'PDT67', 'PDT197', 'PDT2', 'PDT51', 'PDT196'],
    'PDX2': ['PDX65', 'PDX2', 'PDX95', 'PDX197', 'PDX63', 'PDX67', 'PDX57', 'PDX69', 'PDX131', 'PDX132', 'PDX121', 'PDX194', 'PDX96', 'PDX605', 'PDX133', 'PDX60', 'PDX196', 'PDX130', 'PDX51', 'PDX195', 'PDX54'],
    'SAT250': ['SAT250'],
    'DTW251': ['DTW251'],
    'ZTS100': ['ZTS100'],
    'LFK250': ['LFK250'],
    'NRT12': ['NRT98', 'NRT64', 'NRT12', 'NRT67', 'NRT56', 'NRT73', 'NRT72', 'NRT96', 'NRT600', 'NRT74'],
    'RDU250': ['RDU250'],
    'MXP60': ['MXP73', 'MXP104', 'MXP83', 'MXP100', 'MXP109', 'MXP90', 'MXP87', 'MXP60', 'MXP84', 'MXP86', 'MXP63', 'MXP85', 'MXP106', 'MXP101'],
    'DCA54': ['DCA54', 'DCA64'],
    'PEK7': ['PEK7'],
    'DUB8': ['DUB52', 'DUB8', 'DUB68', 'DUB162', 'DUB49', 'DUB161', 'DUB32', 'DUB601', 'DUB78', 'DUB91', 'DUB60', 'DUB57', 'DUB58'],
    'LAS250': ['LAS250'],
    'SUX251': ['SUX251'],
    'LHR55': ['LHR38', 'LHR602', 'LHR65', 'LHR59', 'LHR95', 'LHR55', 'LHR37'],
    'CMH50': ['CMH143', 'CMH83', 'CMH59', 'CMH142', 'CMH73', 'CMH133', 'CMH105', 'CMH116', 'CMH70', 'CMH101', 'CMH76', 'CMH135', 'CMH103', 'CMH120', 'CMH107', 'CMH141', 'CMH139', 'CMH148', 'CMH137', 'CMH145', 'CMH82', 'CMH138', 'CMH140', 'CMH102', 'CMH136', 'CMH85', 'CMH56', 'CMH144', 'CMH149', 'CMH121', 'CMH600', 'CMH134', 'CMH62', 'CMH146', 'CMH106', 'CMH147', 'CMH79', 'CMH53', 'CMH100', 'CMH84', 'CMH50'],
    'FRA54': ['FRA19', 'FRA60', 'FRA167', 'FRA16', 'FRA54', 'FRA168', 'FRA32', 'FRA169', 'FRA87', 'FRA86', 'FRA20', 'FRA61', 'FRA74', 'FRA15'],
    'BWI700': ['BWI700'],
    'IAD87': ['IAD87'],
    'STL250': ['STL250'],
    'DUB62': ['DUB87', 'DUB72', 'DUB62'],
    'OUTUS10': ['OUTUS10'],
    'LTW62': ['LTW62'],
    'ZAZ61': ['ZAZ71', 'ZAZ61'],
    'DFW62': ['DFW62'],
    'PDT1': ['PDT64', 'PDT56', 'PDT66', 'PDT170', 'PDT62', 'PDT110', 'PDT53', 'PDT59', 'PDT50', 'PDT1', 'PDT112', 'PDT109'],
    'LCK51': ['LCK66', 'LCK54', 'LCK51', 'LCK63'],
    'BOM52': ['BOM79', 'BOM76', 'BOM62', 'BOM74', 'BOM52', 'BOM106', 'BOM66', 'BOM111', 'BOM97'],
    'BLR140': ['BLR140'],
    'LHR53': ['LHR186', 'LHR112', 'LHR600', 'LHR40', 'LHR114', 'LHR103', 'LHR86', 'LHR99', 'LHR56', 'LHR57', 'LHR53'],
    'RIC84': ['RIC84'],
    'BOM53': ['BOM108', 'BOM60', 'BOM53', 'BOM59', 'BOM63'],
    'OUTUS16': ['OUTUS16'],
    'ICN52': ['ICN56', 'ICN62', 'ICN59', 'ICN71', 'ICN63', 'ICN52'],
    'PDX4': ['PDX401', 'PDX91', 'PDX4', 'PDX55', 'PDX162', 'PDX97', 'PDX52', 'PDX179', 'PDX93', 'PDX92', 'PDX154', 'PDX178', 'PDX181', 'PDX61', 'PDX606', 'PDX180', 'PDX202', 'PDX90', 'PDX58'],
    'ZHY50': ['ZHY50', 'ZHY60'],
    'IAD6': ['IAD50', 'IAD667', 'IAD169', 'IAD80', 'IAD164', 'IAD216', 'IAD114', 'IAD99', 'IAD179', 'IAD13', 'IAD116', 'IAD420', 'IAD606', 'IAD668', 'IAD54', 'IAD183', 'IAD98', 'IAD138', 'IAD78', 'IAD71', 'IAD182', 'IAD79', 'IAD161', 'IAD60', 'IAD228', 'IAD181', 'IAD129', 'IAD244', 'IAD243', 'IAD163', 'IAD115', 'IAD174', 'IAD213', 'IAD6', 'IAD118', 'IAD229', 'IAD215', 'IAD119', 'IAD139', 'IAD601', 'IAD128', 'IAD180', 'IAD117', 'IAD10', 'IAD32', 'IAD162'],
    'YUL55': ['YUL55', 'YUL65'],
    'MIA50': ['MIA50'],
    'FFZ80': ['FFZ80'],
    'IAD62': ['IAD614', 'IAD68', 'IAD62', 'IAD120', 'IAD172', 'IAD81', 'IAD121', 'IAD93', 'IAD83', 'IAD609', 'IAD251', 'IAD122'],
    'DTW250': ['DTW250'],
    'FOX200': ['FOX200'],
    'MUC350': ['MUC350'],
    'NRT700': ['NRT700'],
    'OKC250': ['OKC250'],
    'ORD253': ['ORD253'],
    'DUB4': ['DUB159', 'DUB54', 'DUB165', 'DUB105', 'DUB64', 'DUB10', 'DUB51', 'DUB74', 'DUB600', 'DUB104', 'DUB144', 'DUB50', 'DUB164', 'DUB84', 'DUB41', 'DUB90', 'DUB94', 'DUB114', 'DUB139'],
    'WIW400': ['WIW400'],
    'DEN2': ['DEN2'],
    'OMA250': ['OMA250'],
    'ZTS200': ['ZTS200'],
    'PDX80': ['PDX81', 'PDX138', 'PDX245', 'PDX146', 'PDX84', 'PDX607', 'PDX83', 'PDX98', 'PDX82', 'PDX80', 'PDX140', 'PDX139'],
    'MEL62': ['MEL63', 'MEL64', 'MEL62'],
    'AUS250': ['AUS250'],
    'BKK62': ['BKK62'],
    'HKG60': ['HKG63', 'HKG60', 'HKG66'],
    'FRA57': ['FRA57'],
    'SYD6': ['SYD64', 'SYD66', 'SYD6', 'SYD67', 'SYD62', 'SYD61'],
    'CMH52': ['CMH78', 'CMH602', 'CMH75', 'CMH92', 'CMH93', 'CMH94', 'CMH58', 'CMH81', 'CMH61', 'CMH52', 'CMH55', 'CMH64', 'CMH72', 'CMH91', 'CMH117'],
    'GUS81': ['GUS81'],
    'MUC91': ['MUC91'],
    'BKK61': ['BKK61'],
    'RNO250': ['RNO250'],
    'CID250': ['CID250'],
    'OUTUS21': ['OUTUS21'],
    'LNK250': ['LNK250'],
    'FRA53': ['FRA41', 'FRA91', 'FRA17', 'FRA29', 'FRA18', 'FRA137', 'FRA44', 'FRA90', 'FRA134', 'FRA135', 'FRA63', 'FRA141', 'FRA142', 'FRA28', 'FRA40', 'FRA92', 'FRA53'],
    'IAD1': ['IAD156', 'IAD61', 'IAD157', 'IAD171', 'IAD134', 'IAD170', 'IAD1', 'IAD124', 'IAD9', 'IAD153', 'IAD159', 'IAD152', 'IAD126', 'IAD135', 'IAD155', 'IAD125', 'IAD136', 'IAD150', 'IAD158', 'IAD154', 'IAD86', 'IAD65', 'IAD600', 'IAD63', 'IAD151', 'IAD127'],
    'SIN850': ['SIN850'],
    'DUB2': ['DUB179', 'DUB83', 'DUB80', 'DUB149', 'DUB79', 'DUB99', 'DUB180', 'DUB35', 'DUB150', 'DUB66', 'DUB97', 'DUB53', 'DUB151', 'DUB33', 'DUB9', 'DUB89', 'DUB602', 'DUB56', 'DUB63', 'DUB169', 'DUB999', 'DUB55', 'DUB69'],
    'ZAZ62': ['ZAZ62', 'ZAZ72'],
    'MXP62': ['MXP125', 'MXP81', 'MXP120', 'MXP62', 'MXP80', 'MXP121'],
    'LAS251': ['LAS251'],
    'BKK60': ['BKK60'],
    'ICN53': ['ICN66', 'ICN72', 'ICN53', 'ICN69'],
    'IAD5': ['IAD5'],
    'PDX1': ['PDX604', 'PDX173', 'PDX111', 'PDX59', 'PDX68', 'PDX56', 'PDX171', 'PDX53', 'PDX110', 'PDX50', 'PDX62', 'PDX64', 'PDX112', 'PDX109', 'PDX1', 'PDX172', 'PDX170', 'PDX99', 'PDX260', 'PDX66'],
    'SMF700': ['SMF700'],
    'SYD52': ['SYD54', 'SYD52', 'SYD58', 'SYD700', 'SYD56'],
    'KIX700': ['KIX700'],
    'BOM54': ['BOM56', 'BOM75', 'BOM61', 'BOM107', 'BOM69', 'BOM54'],
    'DUB401': ['DUB401'],
    'DCA57': ['DCA57'],
    'ARN50': ['ARN60', 'ARN50'],
    'ICN54': ['ICN61', 'ICN65', 'ICN54'],
    'HEL90': ['HEL90'],
    'TPE61': ['TPE61', 'TPE64'],
    'YYC60': ['YYC60'],
    'FRA52': ['FRA64', 'FRA72', 'FRA82', 'FRA35', 'FRA55', 'FRA88', 'FRA56', 'FRA26', 'FRA62', 'FRA95', 'FRA33', 'FRA24', 'FRA14', 'FRA25', 'FRA34', 'FRA52'],
    'CJJ700': ['CJJ700'],
    'MXP61': ['MXP110', 'MXP76', 'MXP111', 'MXP74', 'MXP61', 'MXP113'],
    'SIN4': ['SIN63', 'SIN69', 'SIN64', 'SIN4', 'SIN68', 'SIN78', 'SIN62', 'SIN92', 'SIN58'],
    'KUL60': ['KUL60', 'KUL63', 'KUL70'],
    'MFE1': ['MFE1'],
    'LAX62': ['LAX62'],
    'GEG250': ['GEG250'],
    'SYD5': ['SYD55', 'SYD86', 'SYD51', 'SYD53', 'SYD5', 'SYD57', 'SYD701', 'SYD84'],
    'FLL250': ['FLL250'],
    'OSU61': ['OSU67', 'OSU64', 'OSU61'],
    'HYD110': ['HYD111', 'HYD112', 'HYD110'],
    'CGK407': ['CGK407'],
    'CPT60': ['CPT60'],
    'STC250': ['STC250'],
    'ARN57': ['ARN57'],
    'LAS254': ['LAS254'],
    'PDT4': ['PDT55', 'PDT61', 'PDT90', 'PDT58', 'PDT178', 'PDT91', 'PDT93', 'PDT4', 'PDT181'],
    'SFO8': ['SFO69', 'SFO8'],
    'OKC251': ['OKC251'],
    'SIN2': ['SIN50', 'SIN53', 'SIN67', 'SIN72', 'SIN2', 'SIN60'],
    'SNA252': ['SNA252'],
    'MEL61': ['MEL71', 'MEL70', 'MEL61'],
    'KUL61': ['KUL64', 'KUL61'],
    'CGK62': ['CGK65', 'CGK72', 'CGK62'],
    'CMH51': ['CMH51', 'CMH77', 'CMH86', 'CMH60', 'CMH601', 'CMH80', 'CMH87', 'CMH57', 'CMH109', 'CMH88', 'CMH54', 'CMH71', 'CMH74', 'CMH63', 'CMH89'],
    'OUTUS12': ['OUTUS12'],
    'ATL250': ['ATL250'],
    'DCA50': ['DCA60', 'DCA50'],
    'HYV200': ['HYV200'],
    'SIN3': ['SIN79', 'SIN61', 'SIN54', 'SIN51', 'SIN3', 'SIN65'],
    'MAF250': ['MAF250'],
    'DCA56': ['DCA56'],
    'IAD55': ['IAD196', 'IAD604', 'IAD84', 'IAD64', 'IAD94', 'IAD55'],
    'KIX52': ['KIX59', 'KIX58', 'KIX56', 'KIX52'],
    'IAH250': ['IAH250'],
    'BER28': ['BER28'],
    'SEA14': ['SEA15', 'SEA14'],
    'CDG54': ['CDG70', 'CDG99', 'CDG79', 'CDG76', 'CDG89', 'CDG72', 'CDG54', 'CDG95', 'CDG84', 'CDG92', 'CDG88'],
    'HEF61': ['HEF61'],
    'SFO9': ['SFO9', 'SFO61', 'SFO71', 'SFO11'],
    'GRU1': ['GRU51', 'GRU50', 'GRU67', 'GRU53', 'GRU54', 'GRU1'],
    'DCA52': ['DCA52'],
    'BJS11': ['BJS50', 'BJS11', 'BJS52', 'BJS51'],
    'APA62': ['APA62'],
    'BIS250': ['BIS250'],
    'AEX250': ['AEX250'],
    'HYV100': ['HYV100'],
    'MRE81': ['MRE81'],
    'NRT8': ['NRT82', 'NRT22', 'NRT11', 'NRT78', 'NRT20', 'NRT79'],
    'SYD7': ['SYD7', 'SYD71', 'SYD70'],
    'GRU4': ['GRU60', 'GRU4', 'GRU65', 'GRU61'],
    'SAN251': ['SAN251'],
    'DFW56': ['DFW56'],
    'BER38': ['BER38'],
    'TPA250': ['TPA250'],
    'HYD60': ['HYD60'],
    'SIN402': ['SIN402'],
    'IAD450': ['IAD457', 'IAD450'],
    'TTN700': ['TTN700'],
    'YTO53': ['YTO53'],
    'TLV60': ['TLV60'],
    'TYO4': ['TYO4'],
    'PHX53': ['PHX53'],
    'DXB60': ['DXB60', 'DXB600'],
    'BOS250': ['BOS250'],
    'FSD250': ['FSD250'],
    'WIW100': ['WIW100'],
    'MUC140': ['MUC140'],
    'QTA300': ['QTA300'],
    'FFZ60': ['FFZ60'],
    'HKG62': ['HKG62', 'HKG65'],
    'KIX51': ['KIX51', 'KIX61', 'KIX60', 'KIX62'],
    'LCK50': ['LCK62', 'LCK53', 'LCK50', 'LCK65'],
    'SAT253': ['SAT253'],
    'CRW250': ['CRW250'],
    'DCA51': ['DCA51', 'DCA62'],
    'EUG250': ['EUG250'],
    'WIW200': ['WIW200'],
    'MRE68': ['MRE68'],
    'OUTUS6': ['OUTUS6'],
    'GRU2': ['GRU62', 'GRU63', 'GRU2'],
    'LTW61': ['LTW61'],
    'FLG250': ['FLG250'],
    'BAH54': ['BAH54'],
    'KIX53': ['KIX53', 'KIX55', 'KIX50', 'KIX54'],
    'TUP250': ['TUP250'],
    'LCK52': ['LCK52', 'LCK67', 'LCK64', 'LCK55'],
    'COS250': ['COS250'],
    'MSP250': ['MSP250'],
    'RDU84': ['RDU84'],
    'BJS12': ['BJS60', 'BJS12'],
    'IAD802': ['IAD802'],
    'SEA250': ['SEA250'],
    'MEM252': ['MEM252'],
    'CDG55': ['CDG94', 'CDG85', 'CDG96', 'CDG86', 'CDG69', 'CDG55', 'CDG78', 'CDG87'],
    'ALE61': ['ALE61'],
    'MSN250': ['MSN250'],
    'QRO62': ['QRO62'],
    'IAD350': ['IAD353', 'IAD350', 'IAD351'],
    'CDG53': ['CDG63', 'CDG90', 'CDG83', 'CDG53', 'CDG93', 'CDG91', 'CDG60', 'CDG80'],
    'DXB62': ['DXB602', 'DXB62'],
    'SMF250': ['SMF250'],
    'SBN250': ['SBN250'],
    'DSM250': ['DSM250'],
    'WIW500': ['WIW500'],
    'PHX250': ['PHX250'],
    'NRT55': ['NRT75', 'NRT80', 'NRT62', 'NRT76', 'NRT69', 'NRT66', 'NRT55'],
    'GTU250': ['GTU250'],
    'ALE60': ['ALE60'],
    'CGK408': ['CGK408'],
    'HHR250': ['HHR250'],
    'MDW61': ['MDW61'],
    'ZHY51': ['ZHY51', 'ZHY61'],
    'KUL62': ['KUL62'],
    'ZAZ60': ['ZAZ70', 'ZAZ60'],
    'ARN51': ['ARN51', 'ARN61'],
    'NRT6': ['NRT6'],
    'CLT253': ['CLT253'],
    'TPE70': ['TPE70'],
    'LHR119': ['LHR119'],
    'SNA251': ['SNA251'],
    'SFO6': ['SFO21', 'SFO60', 'SFO53', 'SFO20', 'SFO62'],
    'MCO251': ['MCO251'],
    'LHR54': ['LHR82', 'LHR79', 'LHR54', 'LHR63', 'LHR94', 'LHR64', 'LHR601', 'LHR74'],
    'TLH250': ['TLH250'],
    'DEN22': ['DEN22'],
    'BOG90': ['BOG90'],
    'HNL251': ['HNL251'],
    'SCL91': ['SCL91'],
    'ACV250': ['ACV250'],
    'ALE62': ['ALE62'],
    'BJS20': ['BJS70', 'BJS74', 'BJS20', 'BJS71', 'BJS72', 'BJS73'],
    'LCH250': ['LCH250'],
    'PDX311': ['PDX311'],
    'ICN80': ['ICN80'],
    'DFW64': ['DFW64'],
    'SCL60': ['SCL60'],
    'PHX101': ['PHX101'],
    'HOT250': ['HOT250'],
    'BNA262': ['BNA262'],
    'HHR251': ['HHR251'],
    'TPE2': ['TPE2'],
    'ORH250': ['ORH250'],
    'SHV250': ['SHV250'],
    'SVA250': ['SVA250'],
    'TUL80': ['TUL80'],
    'BOI252': ['BOI252'],
    'IND250': ['IND250'],
    'APA60': ['APA60'],
    'DLH250': ['DLH250'],
    'DEN250': ['DEN250'],
    'HOU250': ['HOU250'],
    'QTA100': ['QTA100'],
    'TPE62': ['TPE65', 'TPE62'],
    'NCL62': ['NCL62'],
    'JFK140': ['JFK140'],
    'HYD120': ['HYD121', 'HYD122', 'HYD120'],
    'QRO61': ['QRO61'],
    'HLN250': ['HLN250'],
    'HYD100': ['HYD100', 'HYD101'],
    'SEA72': ['SEA72'],
    'ORD62': ['ORD62'],
    'SEA31': ['SEA31'],
    'QTA400': ['QTA400'],
    'SCL61': ['SCL61'],
    'TPE71': ['TPE71'],
    'VIE2': ['VIE2'],
    'PHX251': ['PHX251'],
    'FFZ70': ['FFZ70'],
    'ZRH61': ['ZRH61'],
    'MMU250': ['MMU250'],
    'ATL75': ['ATL75'],
    'CDG77': ['CDG77'],
    'CVG120': ['CVG120'],
    'SEA32': ['SEA32'],
    'YYC61': ['YYC61'],
    'SIN403': ['SIN403'],
    'EVV250': ['EVV250'],
    'MLI250': ['MLI250'],
    'DFW141': ['DFW141'],
    'TLV62': ['TLV62'],
    'FAR250': ['FAR250'],
    'HKG61': ['HKG64', 'HKG67', 'HKG61'],
    'IAH51': ['IAH51'],
    'CRP250': ['CRP250'],
    'YTO1': ['YTO1'],
    'CLL250': ['CLL250'],
    'MAD1': ['MAD1'],
    'LCK60': ['LCK60'],
    'JLN250': ['JLN250'],
    'CGK60': ['CGK60', 'CGK63'],
    'YYC52': ['YYC52'],
    'JFK250': ['JFK250'],
    'SLC251': ['SLC251'],
    'SCC400': ['SCC400'],
    'HYV300': ['HYV300'],
    'SMF251': ['SMF251'],
    'LBB250': ['LBB250'],
    'MCI60': ['MCI60'],
    'ATL251': ['ATL251'],
    'GIG74': ['GIG74'],
    'MIA5': ['MIA5'],
    'PIT250': ['PIT250'],
    'OSU62': ['OSU62', 'OSU68', 'OSU65'],
    'GPT250': ['GPT250'],
    'AUS251': ['AUS251'],
    'LCK61': ['LCK61'],
    'ZHY52': ['ZHY52', 'ZHY62'],
    'ZRH62': ['ZRH62', 'ZRH65'],
    'BOI250': ['BOI250'],
    'MDW62': ['MDW62'],
    'ORD250': ['ORD250'],
    'CPT61': ['CPT61'],
    'ATL140': ['ATL140'],
    'DUB5': ['DUB70', 'DUB81'],
    'ATL1': ['ATL1'],
    'IAD801': ['IAD801'],
    'BAH52': ['BAH52'],
    'IAD550': ['IAD550'],
    'DFW250': ['DFW250'],
    'SUX250': ['SUX250'],
    'CAE250': ['CAE250'],
    'RIC250': ['RIC250'],
    'AYQ60': ['AYQ60'],
    'SEA2': ['SEA2'],
    'HND402': ['HND402'],
    'YUL51': ['YUL51', 'YUL71'],
    'ZRH60': ['ZRH60'],
    'RDU251': ['RDU251'],
    'AKL60': ['AKL61', 'AKL60'],
    'APA61': ['APA61'],
    'TOL250': ['TOL250'],
    'PHX57': ['PHX57'],
    'DFW2': ['DFW2'],
    'MAA140': ['MAA140'],
    'BHM250': ['BHM250'],
    'FOX100': ['FOX100'],
    'DXB61': ['DXB601', 'DXB61'],
    'CVG250': ['CVG250'],
    'FOX400': ['FOX400'],
    'YUL52': ['YUL54', 'YUL52'],
    'OUTUS14': ['OUTUS14'],
    'RST250': ['RST250'],
    'SEA300': ['SEA300'],
    'PHX100': ['PHX100'],
    'IAD663': ['IAD663'],
    'MEL60': ['MEL80', 'MEL60'],
    'ABQ250': ['ABQ250'],
    'GRU71': ['GRU71'],
    'SFO7': ['SFO7'],
    'ROA250': ['ROA250'],
    'HII250': ['HII250'],
    'AMA250': ['AMA250'],
    'ADS61': ['ADS61'],
    'CDG2': ['CDG2'],
    'IAD470': ['IAD470'],
    'RSW250': ['RSW250'],
    'DUB163': ['DUB163'],
    'LAX4': ['LAX4'],
    'OSU60': ['OSU60', 'OSU63', 'OSU70', 'OSU66'],
    'DEN253': ['DEN253'],
    'HEF60': ['HEF60'],
    'YUL50': ['YUL93', 'YUL63', 'YUL50'],
    'AKL55': ['AKL56', 'AKL55'],
    'SFO250': ['SFO250'],
    'APA250': ['APA250'],
    'ORD140': ['ORD140'],
    'QRO60': ['QRO60'],
    'MRE69': ['MRE69'],
    'CGK61': ['CGK64', 'CGK61'],
    'AKL70': ['AKL71', 'AKL70'],
    'PRC250': ['PRC250'],
    'ZTS300': ['ZTS300'],
    'STL251': ['STL251'],
    'CLT252': ['CLT252'],
    'AMS2': ['AMS2'],
    'LHR87': ['LHR87'],
    'SAN250': ['SAN250'],
    'NRT7': ['NRT7'],
    'WIW300': ['WIW300'],
    'ORD58': ['ORD58'],
    'ORD64': ['ORD64'],
    'TPE60': ['TPE60', 'TPE63'],
    'TLV61': ['TLV61'],
    'OUTUS11': ['OUTUS11'],
    'AOH250': ['AOH250'],
    'SEL3': ['SEL3'],
    'CRW252': ['CRW252'],
    'LUX2': ['LUX2'],
    'SEA3': ['SEA3'],
    'OUTUS13': ['OUTUS13'],
    'OUTPR': ['OUTPR'],
    'ORD4': ['ORD4'],
    'PHX54': ['PHX54'],
    'JBR250': ['JBR250'],
    'HYV400': ['HYV400'],
    'SJC6': ['SJC6'],
    'LAX61': ['LAX61'],
    'OSL400': ['OSL400'],
    'SJC254': ['SJC254'],
    'ATL74': ['ATL74'],
    'ADS62': ['ADS62'],
    'PUQ60': ['PUQ60'],
    'DEN251': ['DEN251'],
    'ARN52': ['ARN52', 'ARN62'],
    'HWY120': ['HWY120'],
    'ATL2': ['ATL2'],
    'SLC250': ['SLC250'],
    'MCO250': ['MCO250'],
    'BJS80': ['BJS80'],
    'GGG250': ['GGG250'],
    'ABI250': ['ABI250'],
    'BNA250': ['BNA250'],
    'CLT250': ['CLT250'],
    'BPM61': ['BPM61', 'BPM54'],
    'TYR250': ['TYR250'],
    'BPM62': ['BPM62'],
    'FOX300': ['FOX300'],
    'OMA251': ['OMA251'],
    'LHR8': ['LHR8'],
    'CPT62': ['CPT62'],
    'IAD3': ['IAD3'],
    'DUB160': ['DUB160'],
    'ADS60': ['ADS60'],
    'DUB77': ['DUB77'],
    'IAD664': ['IAD664'],
    'MIA140': ['MIA140'],
    'BTP250': ['BTP250'],
    'ORD3': ['ORD3'],
    'LAS252': ['LAS252'],
    'DCA58': ['DCA58'],
    'LGA250': ['LGA250'],
    'RAD1': ['RAD1'],
    'CLT262': ['CLT262'],
    'BOG52': ['BOG52'],
    'LUX9': ['LUX9'],
    'OUTUS20': ['OUTUS20'],
    'ATL700': ['ATL700'],
    'SNA250': ['SNA250'],
    'CMB400': ['CMB400'],
    'OUTUS1': ['OUTUS1'],
    'HYV500': ['HYV500'],
    'ACT250': ['ACT250'],
    'RIC251': ['RIC251'],
    'SIN851': ['SIN851'],
    'CRW251': ['CRW251'],
    'NCL60': ['NCL60'],
    'ORD251': ['ORD251'],
    'YYC62': ['YYC62'],
    'SLC252': ['SLC252'],
    'MCI253': ['MCI253'],
    'ICT80': ['ICT80'],
    'BKK2': ['BKK2'],
    'DEN252': ['DEN252'],
    'RDU252': ['RDU252'],
    'OUTGI': ['OUTGI'],
    'OUTUK1': ['OUTUK1'],
    'MSY250': ['MSY250'],
    'JAN81': ['JAN81'],
    'IAH140': ['IAH140'],
    'HEF62': ['HEF62'],
    'SDF250': ['SDF250'],
    'QTA200': ['QTA200'],
    'SCL62': ['SCL62'],
    'LTW60': ['LTW60'],
    'MCI61': ['MCI61'],
    'MDW60': ['MDW60'],
    'PVG250': ['PVG250'],
    'SJT250': ['SJT250'],
    'PEK50': ['PEK50'],
    'ATH52': ['ATH52'],
    'HNL252': ['HNL252'],
    'RDU253': ['RDU253'],
    'ATL72': ['ATL72'],
    'BPM60': ['BPM60'],
    'DCA53': ['DCA53'],
    'RIO4': ['RIO4'],
    'DBO400': ['DBO400'],
    'MSY251': ['MSY251'],
    'CLT251': ['CLT251'],
    'TUS250': ['TUS250'],
    'BAH53': ['BAH53'],
    'IAD254': ['IAD254'],
    'LUX8': ['LUX8'],
    'NCL61': ['NCL61']
}

export const LOCATION: any = {
    'IAD': {
        'IAD7': ['IAD316', 'IAD315', 'IAD77', 'IAD193', 'IAD211', 'IAD112', 'IAD602', 'IAD109', 'IAD74', 'IAD24', 'IAD108', 'IAD314', 'IAD97', 'IAD96', 'IAD131', 'IAD104', 'IAD197', 'IAD95', 'IAD103', 'IAD113', 'IAD14', 'IAD224', 'IAD195', 'IAD102', 'IAD7', 'IAD107', 'IAD101', 'IAD223', 'IAD133', 'IAD110', 'IAD222', 'IAD106', 'IAD85', 'IAD221', 'IAD11', 'IAD100', 'IAD317', 'IAD607', 'IAD59', 'IAD805', 'IAD235', 'IAD132', 'IAD206', 'IAD370', 'IAD105', 'IAD210', 'IAD73', 'IAD111', 'IAD35', 'IAD130', 'IAD205', 'IAD225', 'IAD75', 'IAD234', 'IAD52', 'IAD200'],
        'IAD12': ['IAD230', 'IAD143', 'IAD57', 'IAD227', 'IAD167', 'IAD147', 'IAD141', 'IAD142', 'IAD282', 'IAD168', 'IAD15', 'IAD192', 'IAD285', 'IAD90', 'IAD56', 'IAD92', 'IAD149', 'IAD500', 'IAD58', 'IAD76', 'IAD146', 'IAD16', 'IAD290', 'IAD145', 'IAD88', 'IAD148', 'IAD608', 'IAD89', 'IAD144', 'IAD603', 'IAD91', 'IAD175', 'IAD501', 'IAD12', 'IAD166', 'IAD51', 'IAD140', 'IAD214', 'IAD226', 'IAD165'],
        'IAD87': ['IAD87'],
        'IAD6': ['IAD50', 'IAD667', 'IAD169', 'IAD80', 'IAD164', 'IAD216', 'IAD114', 'IAD99', 'IAD179', 'IAD13', 'IAD116', 'IAD420', 'IAD606', 'IAD668', 'IAD54', 'IAD183', 'IAD98', 'IAD138', 'IAD78', 'IAD71', 'IAD182', 'IAD79', 'IAD161', 'IAD60', 'IAD228', 'IAD181', 'IAD129', 'IAD244', 'IAD243', 'IAD163', 'IAD115', 'IAD174', 'IAD213', 'IAD6', 'IAD118', 'IAD229', 'IAD215', 'IAD119', 'IAD139', 'IAD601', 'IAD128', 'IAD180', 'IAD117', 'IAD10', 'IAD32', 'IAD162'],
        'IAD62': ['IAD614', 'IAD68', 'IAD62', 'IAD120', 'IAD172', 'IAD81', 'IAD121', 'IAD93', 'IAD83', 'IAD609', 'IAD251', 'IAD122'],
        'IAD1': ['IAD156', 'IAD61', 'IAD157', 'IAD171', 'IAD134', 'IAD170', 'IAD1', 'IAD124', 'IAD9', 'IAD153', 'IAD159', 'IAD152', 'IAD126', 'IAD135', 'IAD155', 'IAD125', 'IAD136', 'IAD150', 'IAD158', 'IAD154', 'IAD86', 'IAD65', 'IAD600', 'IAD63', 'IAD151', 'IAD127'],
        'IAD5': ['IAD5'],
        'IAD55': ['IAD196', 'IAD604', 'IAD84', 'IAD64', 'IAD94', 'IAD55'],
        'IAD450': ['IAD457', 'IAD450'],
        'IAD802': ['IAD802'],
        'IAD350': ['IAD353', 'IAD350', 'IAD351'],
        'IAD801': ['IAD801'],
        'IAD550': ['IAD550'],
        'IAD663': ['IAD663'],
        'IAD470': ['IAD470'],
        'IAD3': ['IAD3'],
        'IAD664': ['IAD664'],
        'IAD254': ['IAD254']
    },
    'OUTDE': {'OUTDE1': ['OUTDE1']},
    'PDT': {
        'PDT2': ['PDT195', 'PDT54', 'PDT65', 'PDT63', 'PDT130', 'PDT60', 'PDT67', 'PDT197', 'PDT2', 'PDT51', 'PDT196'],
        'PDT1': ['PDT64', 'PDT56', 'PDT66', 'PDT170', 'PDT62', 'PDT110', 'PDT53', 'PDT59', 'PDT50', 'PDT1', 'PDT112', 'PDT109'],
        'PDT4': ['PDT55', 'PDT61', 'PDT90', 'PDT58', 'PDT178', 'PDT91', 'PDT93', 'PDT4', 'PDT181']
    },
    'PDX': {
        'PDX2': ['PDX65', 'PDX2', 'PDX95', 'PDX197', 'PDX63', 'PDX67', 'PDX57', 'PDX69', 'PDX131', 'PDX132', 'PDX121', 'PDX194', 'PDX96', 'PDX605', 'PDX133', 'PDX60', 'PDX196', 'PDX130', 'PDX51', 'PDX195', 'PDX54'],
        'PDX4': ['PDX401', 'PDX91', 'PDX4', 'PDX55', 'PDX162', 'PDX97', 'PDX52', 'PDX179', 'PDX93', 'PDX92', 'PDX154', 'PDX178', 'PDX181', 'PDX61', 'PDX606', 'PDX180', 'PDX202', 'PDX90', 'PDX58'],
        'PDX80': ['PDX81', 'PDX138', 'PDX245', 'PDX146', 'PDX84', 'PDX607', 'PDX83', 'PDX98', 'PDX82', 'PDX80', 'PDX140', 'PDX139'],
        'PDX1': ['PDX604', 'PDX173', 'PDX111', 'PDX59', 'PDX68', 'PDX56', 'PDX171', 'PDX53', 'PDX110', 'PDX50', 'PDX62', 'PDX64', 'PDX112', 'PDX109', 'PDX1', 'PDX172', 'PDX170', 'PDX99', 'PDX260', 'PDX66'],
        'PDX311': ['PDX311']
    },
    'SAT': {'SAT250': ['SAT250'], 'SAT253': ['SAT253']},
    'DTW': {'DTW251': ['DTW251'], 'DTW250': ['DTW250']},
    'EWR': {'ZTS100': ['ZTS100']},
    'LFK': {'LFK250': ['LFK250']},
    'NRT': {
        'NRT12': ['NRT98', 'NRT64', 'NRT12', 'NRT67', 'NRT56', 'NRT73', 'NRT72', 'NRT96', 'NRT600', 'NRT74'],
        'NRT700': ['NRT700'],
        'NRT8': ['NRT82', 'NRT22', 'NRT11', 'NRT78', 'NRT20', 'NRT79'],
        'NRT55': ['NRT75', 'NRT80', 'NRT62', 'NRT76', 'NRT69', 'NRT66', 'NRT55'],
        'NRT6': ['NRT6'],
        'NRT7': ['NRT7']
    },
    'RDU': {'RDU250': ['RDU250'], 'RDU84': ['RDU84'], 'RDU251': ['RDU251'], 'RDU252': ['RDU252'], 'RDU253': ['RDU253']},
    'MXP': {
        'MXP60': ['MXP73', 'MXP104', 'MXP83', 'MXP100', 'MXP109', 'MXP90', 'MXP87', 'MXP60', 'MXP84', 'MXP86', 'MXP63', 'MXP85', 'MXP106', 'MXP101'],
        'MXP62': ['MXP125', 'MXP81', 'MXP120', 'MXP62', 'MXP80', 'MXP121'],
        'MXP61': ['MXP110', 'MXP76', 'MXP111', 'MXP74', 'MXP61', 'MXP113']
    },
    'DCA': {
        'DCA54': ['DCA54', 'DCA64'],
        'DCA57': ['DCA57'],
        'DCA50': ['DCA60', 'DCA50'],
        'DCA56': ['DCA56'],
        'DCA52': ['DCA52'],
        'DCA51': ['DCA51', 'DCA62'],
        'DCA58': ['DCA58'],
        'DCA53': ['DCA53']
    },
    'PEK': {'PEK7': ['PEK7'], 'PEK50': ['PEK50']},
    'DUB': {
        'DUB8': ['DUB52', 'DUB8', 'DUB68', 'DUB162', 'DUB49', 'DUB161', 'DUB32', 'DUB601', 'DUB78', 'DUB91', 'DUB60', 'DUB57', 'DUB58'],
        'DUB62': ['DUB87', 'DUB72', 'DUB62'],
        'DUB4': ['DUB159', 'DUB54', 'DUB165', 'DUB105', 'DUB64', 'DUB10', 'DUB51', 'DUB74', 'DUB600', 'DUB104', 'DUB144', 'DUB50', 'DUB164', 'DUB84', 'DUB41', 'DUB90', 'DUB94', 'DUB114', 'DUB139'],
        'DUB2': ['DUB179', 'DUB83', 'DUB80', 'DUB149', 'DUB79', 'DUB99', 'DUB180', 'DUB35', 'DUB150', 'DUB66', 'DUB97', 'DUB53', 'DUB151', 'DUB33', 'DUB9', 'DUB89', 'DUB602', 'DUB56', 'DUB63', 'DUB169', 'DUB999', 'DUB55', 'DUB69'],
        'DUB401': ['DUB401'],
        'DUB5': ['DUB70', 'DUB81'],
        'DUB163': ['DUB163'],
        'DUB160': ['DUB160'],
        'DUB77': ['DUB77']
    },
    'LAS': {'LAS250': ['LAS250'], 'LAS251': ['LAS251'], 'LAS254': ['LAS254'], 'LAS252': ['LAS252']},
    'SUX': {'SUX251': ['SUX251'], 'SUX250': ['SUX250']},
    'LHR': {
        'LHR55': ['LHR38', 'LHR602', 'LHR65', 'LHR59', 'LHR95', 'LHR55', 'LHR37'],
        'LHR53': ['LHR186', 'LHR112', 'LHR600', 'LHR40', 'LHR114', 'LHR103', 'LHR86', 'LHR99', 'LHR56', 'LHR57', 'LHR53'],
        'LHR119': ['LHR119'],
        'LHR54': ['LHR82', 'LHR79', 'LHR54', 'LHR63', 'LHR94', 'LHR64', 'LHR601', 'LHR74'],
        'LHR87': ['LHR87'],
        'LHR8': ['LHR8']
    },
    'CMH': {
        'CMH50': ['CMH143', 'CMH83', 'CMH59', 'CMH142', 'CMH73', 'CMH133', 'CMH105', 'CMH116', 'CMH70', 'CMH101', 'CMH76', 'CMH135', 'CMH103', 'CMH120', 'CMH107', 'CMH141', 'CMH139', 'CMH148', 'CMH137', 'CMH145', 'CMH82', 'CMH138', 'CMH140', 'CMH102', 'CMH136', 'CMH85', 'CMH56', 'CMH144', 'CMH149', 'CMH121', 'CMH600', 'CMH134', 'CMH62', 'CMH146', 'CMH106', 'CMH147', 'CMH79', 'CMH53', 'CMH100', 'CMH84', 'CMH50'],
        'CMH52': ['CMH78', 'CMH602', 'CMH75', 'CMH92', 'CMH93', 'CMH94', 'CMH58', 'CMH81', 'CMH61', 'CMH52', 'CMH55', 'CMH64', 'CMH72', 'CMH91', 'CMH117'],
        'CMH51': ['CMH51', 'CMH77', 'CMH86', 'CMH60', 'CMH601', 'CMH80', 'CMH87', 'CMH57', 'CMH109', 'CMH88', 'CMH54', 'CMH71', 'CMH74', 'CMH63', 'CMH89']
    },
    'FRA': {
        'FRA54': ['FRA19', 'FRA60', 'FRA167', 'FRA16', 'FRA54', 'FRA168', 'FRA32', 'FRA169', 'FRA87', 'FRA86', 'FRA20', 'FRA61', 'FRA74', 'FRA15'],
        'FRA57': ['FRA57'],
        'FRA53': ['FRA41', 'FRA91', 'FRA17', 'FRA29', 'FRA18', 'FRA137', 'FRA44', 'FRA90', 'FRA134', 'FRA135', 'FRA63', 'FRA141', 'FRA142', 'FRA28', 'FRA40', 'FRA92', 'FRA53'],
        'FRA52': ['FRA64', 'FRA72', 'FRA82', 'FRA35', 'FRA55', 'FRA88', 'FRA56', 'FRA26', 'FRA62', 'FRA95', 'FRA33', 'FRA24', 'FRA14', 'FRA25', 'FRA34', 'FRA52'],
        'QTA400': ['QTA400']
    },
    'BWI': {'BWI700': ['BWI700']},
    'STL': {'STL250': ['STL250'], 'STL251': ['STL251']},
    'OUTUS': {
        'OUTUS10': ['OUTUS10'],
        'OUTUS16': ['OUTUS16'],
        'OUTUS21': ['OUTUS21'],
        'OUTUS12': ['OUTUS12'],
        'OUTUS6': ['OUTUS6'],
        'OUTUS14': ['OUTUS14'],
        'OUTUS11': ['OUTUS11'],
        'OUTUS13': ['OUTUS13'],
        'OUTUS20': ['OUTUS20'],
        'OUTUS1': ['OUTUS1']
    },
    'LTW': {'LTW62': ['LTW62'], 'LTW61': ['LTW61'], 'LTW60': ['LTW60']},
    'ZAZ': {'ZAZ61': ['ZAZ71', 'ZAZ61'], 'ZAZ62': ['ZAZ62', 'ZAZ72'], 'ZAZ60': ['ZAZ70', 'ZAZ60']},
    'DFW': {
        'DFW62': ['DFW62'],
        'DFW56': ['DFW56'],
        'DFW64': ['DFW64'],
        'DFW141': ['DFW141'],
        'DFW250': ['DFW250'],
        'DFW2': ['DFW2']
    },
    'LCK': {
        'LCK51': ['LCK66', 'LCK54', 'LCK51', 'LCK63'],
        'LCK50': ['LCK62', 'LCK53', 'LCK50', 'LCK65'],
        'LCK52': ['LCK52', 'LCK67', 'LCK64', 'LCK55'],
        'LCK60': ['LCK60'],
        'LCK61': ['LCK61']
    },
    'BOM': {
        'BOM52': ['BOM79', 'BOM76', 'BOM62', 'BOM74', 'BOM52', 'BOM106', 'BOM66', 'BOM111', 'BOM97'],
        'BOM53': ['BOM108', 'BOM60', 'BOM53', 'BOM59', 'BOM63'],
        'BOM54': ['BOM56', 'BOM75', 'BOM61', 'BOM107', 'BOM69', 'BOM54']
    },
    'BLR': {'BLR140': ['BLR140']},
    'RIC': {'RIC84': ['RIC84'], 'RIC250': ['RIC250'], 'RIC251': ['RIC251']},
    'ICN': {
        'ICN52': ['ICN56', 'ICN62', 'ICN59', 'ICN71', 'ICN63', 'ICN52'],
        'ICN53': ['ICN66', 'ICN72', 'ICN53', 'ICN69'],
        'ICN54': ['ICN61', 'ICN65', 'ICN54'],
        'ICN80': ['ICN80']
    },
    'ZHY': {'ZHY50': ['ZHY50', 'ZHY60'], 'ZHY51': ['ZHY51', 'ZHY61'], 'ZHY52': ['ZHY52', 'ZHY62']},
    'YUL': {
        'YUL55': ['YUL55', 'YUL65'],
        'YUL51': ['YUL51', 'YUL71'],
        'YUL52': ['YUL54', 'YUL52'],
        'YUL50': ['YUL93', 'YUL63', 'YUL50']
    },
    'US-POPS': {'MIA50': ['MIA50']},
    'FFZ': {'FFZ80': ['FFZ80'], 'FFZ60': ['FFZ60'], 'FFZ70': ['FFZ70']},
    'TSN': {'FOX200': ['FOX200']},
    'MUC': {'MUC350': ['MUC350'], 'MUC91': ['MUC91'], 'MUC140': ['MUC140']},
    'OKC': {'OKC250': ['OKC250'], 'OKC251': ['OKC251']},
    'ORD': {
        'ORD253': ['ORD253'],
        'ORD62': ['ORD62'],
        'ORD250': ['ORD250'],
        'ORD140': ['ORD140'],
        'ORD58': ['ORD58'],
        'ORD64': ['ORD64'],
        'ORD4': ['ORD4'],
        'ORD3': ['ORD3'],
        'ORD251': ['ORD251']
    },
    'BRQ': {'WIW400': ['WIW400']},
    'DEN': {
        'DEN2': ['DEN2'],
        'DEN22': ['DEN22'],
        'DEN250': ['DEN250'],
        'DEN253': ['DEN253'],
        'DEN251': ['DEN251'],
        'DEN252': ['DEN252']
    },
    'OMA': {'OMA250': ['OMA250'], 'OMA251': ['OMA251']},
    'FMO': {'ZTS200': ['ZTS200']},
    'MEL': {'MEL62': ['MEL63', 'MEL64', 'MEL62'], 'MEL61': ['MEL71', 'MEL70', 'MEL61'], 'MEL60': ['MEL80', 'MEL60']},
    'AUS': {'AUS250': ['AUS250'], 'AUS251': ['AUS251']},
    'BKK': {'BKK62': ['BKK62'], 'BKK61': ['BKK61'], 'BKK60': ['BKK60'], 'BKK2': ['BKK2']},
    'HKG': {'HKG60': ['HKG63', 'HKG60', 'HKG66'], 'HKG62': ['HKG62', 'HKG65'], 'HKG61': ['HKG64', 'HKG67', 'HKG61']},
    'SYD': {
        'SYD6': ['SYD64', 'SYD66', 'SYD6', 'SYD67', 'SYD62', 'SYD61'],
        'SYD52': ['SYD54', 'SYD52', 'SYD58', 'SYD700', 'SYD56'],
        'SYD5': ['SYD55', 'SYD86', 'SYD51', 'SYD53', 'SYD5', 'SYD57', 'SYD701', 'SYD84'],
        'SYD7': ['SYD7', 'SYD71', 'SYD70']
    },
    'GUS': {'GUS81': ['GUS81']},
    'RNO': {'RNO250': ['RNO250']},
    'CID': {'CID250': ['CID250']},
    'LNK': {'LNK250': ['LNK250']},
    'SIN': {
        'SIN850': ['SIN850'],
        'SIN4': ['SIN63', 'SIN69', 'SIN64', 'SIN4', 'SIN68', 'SIN78', 'SIN62', 'SIN92', 'SIN58'],
        'SIN2': ['SIN50', 'SIN53', 'SIN67', 'SIN72', 'SIN2', 'SIN60'],
        'SIN3': ['SIN79', 'SIN61', 'SIN54', 'SIN51', 'SIN3', 'SIN65'],
        'SIN402': ['SIN402'],
        'SIN403': ['SIN403'],
        'SIN851': ['SIN851']
    },
    'SMF': {'SMF700': ['SMF700'], 'SMF250': ['SMF250'], 'SMF251': ['SMF251']},
    'KIX': {
        'KIX700': ['KIX700'],
        'KIX52': ['KIX59', 'KIX58', 'KIX56', 'KIX52'],
        'KIX51': ['KIX51', 'KIX61', 'KIX60', 'KIX62'],
        'KIX53': ['KIX53', 'KIX55', 'KIX50', 'KIX54']
    },
    'ARN': {'ARN50': ['ARN60', 'ARN50'], 'ARN57': ['ARN57'], 'ARN51': ['ARN51', 'ARN61'], 'ARN52': ['ARN52', 'ARN62']},
    'HEL': {'HEL90': ['HEL90']},
    'TPE': {
        'TPE61': ['TPE61', 'TPE64'],
        'QTA300': ['QTA300'],
        'WIW500': ['WIW500'],
        'TPE70': ['TPE70'],
        'TPE2': ['TPE2'],
        'TPE62': ['TPE65', 'TPE62'],
        'TPE71': ['TPE71'],
        'WIW300': ['WIW300'],
        'TPE60': ['TPE60', 'TPE63'],
        'FOX300': ['FOX300']
    },
    'YYC': {'YYC60': ['YYC60'], 'YYC61': ['YYC61'], 'YYC52': ['YYC52'], 'YYC62': ['YYC62']},
    'CJJ': {'CJJ700': ['CJJ700']},
    'KUL': {'KUL60': ['KUL60', 'KUL63', 'KUL70'], 'KUL61': ['KUL64', 'KUL61'], 'KUL62': ['KUL62']},
    'MFE': {'MFE1': ['MFE1']},
    'LAX': {'LAX62': ['LAX62'], 'LAX4': ['LAX4'], 'LAX61': ['LAX61']},
    'GEG': {'GEG250': ['GEG250']},
    'FLL': {'FLL250': ['FLL250']},
    'OSU': {
        'OSU61': ['OSU67', 'OSU64', 'OSU61'],
        'OSU62': ['OSU62', 'OSU68', 'OSU65'],
        'OSU60': ['OSU60', 'OSU63', 'OSU70', 'OSU66']
    },
    'HYD': {
        'HYD110': ['HYD111', 'HYD112', 'HYD110'],
        'HYD60': ['HYD60'],
        'HYD120': ['HYD121', 'HYD122', 'HYD120'],
        'HYD100': ['HYD100', 'HYD101']
    },
    'CGK': {
        'CGK407': ['CGK407'],
        'CGK62': ['CGK65', 'CGK72', 'CGK62'],
        'CGK408': ['CGK408'],
        'CGK60': ['CGK60', 'CGK63'],
        'CGK61': ['CGK64', 'CGK61']
    },
    'CPT': {'CPT60': ['CPT60'], 'CPT61': ['CPT61'], 'CPT62': ['CPT62']},
    'STC': {'STC250': ['STC250']},
    'SFO': {
        'SFO8': ['SFO69', 'SFO8'],
        'SFO9': ['SFO9', 'SFO61', 'SFO71', 'SFO11'],
        'SFO6': ['SFO21', 'SFO60', 'SFO53', 'SFO20', 'SFO62'],
        'SFO7': ['SFO7'],
        'SFO250': ['SFO250']
    },
    'SNA': {'SNA252': ['SNA252'], 'SNA251': ['SNA251'], 'SNA250': ['SNA250']},
    'ATL': {
        'ATL250': ['ATL250'],
        'ATL75': ['ATL75'],
        'ATL251': ['ATL251'],
        'ATL140': ['ATL140'],
        'ATL1': ['ATL1'],
        'ATL74': ['ATL74'],
        'ATL2': ['ATL2'],
        'ATL700': ['ATL700'],
        'ATL72': ['ATL72']
    },
    'OLV': {'HYV200': ['HYV200']},
    'MAF': {'MAF250': ['MAF250']},
    'IAH': {'IAH250': ['IAH250'], 'IAH51': ['IAH51'], 'IAH140': ['IAH140']},
    'BER': {'BER28': ['BER28'], 'BER38': ['BER38']},
    'SEA': {
        'SEA14': ['SEA15', 'SEA14'],
        'SEA250': ['SEA250'],
        'SEA72': ['SEA72'],
        'SEA31': ['SEA31'],
        'SEA32': ['SEA32'],
        'SEA2': ['SEA2'],
        'SEA300': ['SEA300'],
        'SEA3': ['SEA3']
    },
    'CDG': {
        'CDG54': ['CDG70', 'CDG99', 'CDG79', 'CDG76', 'CDG89', 'CDG72', 'CDG54', 'CDG95', 'CDG84', 'CDG92', 'CDG88'],
        'CDG55': ['CDG94', 'CDG85', 'CDG96', 'CDG86', 'CDG69', 'CDG55', 'CDG78', 'CDG87'],
        'CDG53': ['CDG63', 'CDG90', 'CDG83', 'CDG53', 'CDG93', 'CDG91', 'CDG60', 'CDG80'],
        'CDG77': ['CDG77'],
        'CDG2': ['CDG2']
    },
    'HEF': {'HEF61': ['HEF61'], 'HEF60': ['HEF60'], 'HEF62': ['HEF62']},
    'GRU': {
        'GRU1': ['GRU51', 'GRU50', 'GRU67', 'GRU53', 'GRU54', 'GRU1'],
        'GRU4': ['GRU60', 'GRU4', 'GRU65', 'GRU61'],
        'GRU2': ['GRU62', 'GRU63', 'GRU2'],
        'GRU71': ['GRU71']
    },
    'BJS': {
        'BJS11': ['BJS50', 'BJS11', 'BJS52', 'BJS51'],
        'BJS12': ['BJS60', 'BJS12'],
        'BJS20': ['BJS70', 'BJS74', 'BJS20', 'BJS71', 'BJS72', 'BJS73'],
        'BJS80': ['BJS80']
    },
    'APA': {'APA62': ['APA62'], 'APA60': ['APA60'], 'APA61': ['APA61'], 'APA250': ['APA250']},
    'BIS': {'BIS250': ['BIS250']},
    'AEX': {'AEX250': ['AEX250']},
    'SJC': {'HYV100': ['HYV100'], 'SJC6': ['SJC6'], 'SJC254': ['SJC254'], 'HYV500': ['HYV500']},
    'MRE': {'MRE81': ['MRE81'], 'MRE68': ['MRE68'], 'MRE69': ['MRE69']},
    'SAN': {'SAN251': ['SAN251'], 'SAN250': ['SAN250']},
    'TPA': {'TPA250': ['TPA250']},
    'TTN': {'TTN700': ['TTN700']},
    'YTO': {'YTO53': ['YTO53'], 'YTO1': ['YTO1']},
    'TLV': {'TLV60': ['TLV60'], 'TLV62': ['TLV62'], 'TLV61': ['TLV61']},
    'TYO': {'TYO4': ['TYO4']},
    'PHX': {
        'PHX53': ['PHX53'],
        'PHX250': ['PHX250'],
        'PHX101': ['PHX101'],
        'PHX251': ['PHX251'],
        'PHX57': ['PHX57'],
        'PHX100': ['PHX100'],
        'PHX54': ['PHX54']
    },
    'DXB': {'DXB60': ['DXB60', 'DXB600'], 'DXB62': ['DXB602', 'DXB62'], 'DXB61': ['DXB601', 'DXB61']},
    'BOS': {'BOS250': ['BOS250']},
    'FSD': {'FSD250': ['FSD250']},
    'CJS': {'WIW100': ['WIW100']},
    'CRW': {'CRW250': ['CRW250'], 'CRW252': ['CRW252'], 'CRW251': ['CRW251']},
    'EUG': {'EUG250': ['EUG250']},
    'SZX': {'WIW200': ['WIW200']},
    'FLG': {'FLG250': ['FLG250']},
    'BAH': {'BAH54': ['BAH54'], 'BAH52': ['BAH52'], 'BAH53': ['BAH53']},
    'TUP': {'TUP250': ['TUP250']},
    'COS': {'COS250': ['COS250']},
    'MSP': {'MSP250': ['MSP250']},
    'MEM': {'MEM252': ['MEM252']},
    'ALE': {'ALE61': ['ALE61'], 'ALE60': ['ALE60'], 'ALE62': ['ALE62']},
    'MSN': {'MSN250': ['MSN250']},
    'QRO': {'QRO62': ['QRO62'], 'QRO61': ['QRO61'], 'QRO60': ['QRO60']},
    'SBN': {'SBN250': ['SBN250']},
    'DSM': {'DSM250': ['DSM250']},
    'GTU': {'GTU250': ['GTU250']},
    'HHR': {'HHR250': ['HHR250'], 'HHR251': ['HHR251']},
    'MDW': {'MDW61': ['MDW61'], 'MDW62': ['MDW62'], 'MDW60': ['MDW60']},
    'CLT': {
        'CLT253': ['CLT253'],
        'CLT252': ['CLT252'],
        'CLT250': ['CLT250'],
        'CLT262': ['CLT262'],
        'CLT251': ['CLT251']
    },
    'MCO': {'MCO251': ['MCO251'], 'MCO250': ['MCO250']},
    'TLH': {'TLH250': ['TLH250']},
    'BOG': {'BOG90': ['BOG90'], 'BOG52': ['BOG52']},
    'HNL': {'HNL251': ['HNL251'], 'HNL252': ['HNL252']},
    'SCL': {'SCL91': ['SCL91'], 'SCL60': ['SCL60'], 'SCL61': ['SCL61'], 'SCL62': ['SCL62']},
    'ACV': {'ACV250': ['ACV250']},
    'LCH': {'LCH250': ['LCH250']},
    'HOT': {'HOT250': ['HOT250']},
    'BNA': {'BNA262': ['BNA262'], 'QTA100': ['QTA100'], 'BNA250': ['BNA250']},
    'ORH': {'ORH250': ['ORH250']},
    'SHV': {'SHV250': ['SHV250']},
    'SVA': {'SVA250': ['SVA250']},
    'TUL': {'TUL80': ['TUL80']},
    'BOI': {'BOI252': ['BOI252'], 'BOI250': ['BOI250']},
    'IND': {'IND250': ['IND250']},
    'DLH': {'DLH250': ['DLH250']},
    'HOU': {'HOU250': ['HOU250']},
    'NCL': {'NCL62': ['NCL62'], 'NCL60': ['NCL60'], 'NCL61': ['NCL61']},
    'JFK': {'JFK140': ['JFK140'], 'JFK250': ['JFK250']},
    'HLN': {'HLN250': ['HLN250']},
    'VIE': {'VIE2': ['VIE2']},
    'ZRH': {'ZRH61': ['ZRH61'], 'ZRH62': ['ZRH62', 'ZRH65'], 'ZRH60': ['ZRH60']},
    'MMU': {'MMU250': ['MMU250']},
    'CVG': {'CVG120': ['CVG120'], 'CVG250': ['CVG250']},
    'EVV': {'EVV250': ['EVV250']},
    'MLI': {'MLI250': ['MLI250']},
    'FAR': {'FAR250': ['FAR250']},
    'CRP': {'CRP250': ['CRP250']},
    'CLL': {'CLL250': ['CLL250']},
    'MAD': {'MAD1': ['MAD1']},
    'JLN': {'JLN250': ['JLN250']},
    'SLC': {'SLC251': ['SLC251'], 'SLC250': ['SLC250'], 'SLC252': ['SLC252']},
    'SCC': {'SCC400': ['SCC400']},
    'BHX': {'HYV300': ['HYV300']},
    'LBB': {'LBB250': ['LBB250']},
    'MCI': {'MCI60': ['MCI60'], 'MCI253': ['MCI253'], 'MCI61': ['MCI61']},
    'GIG': {'GIG74': ['GIG74']},
    'MIA': {'MIA5': ['MIA5'], 'MIA140': ['MIA140']},
    'PIT': {'PIT250': ['PIT250']},
    'GPT': {'GPT250': ['GPT250']},
    'CAE': {'CAE250': ['CAE250']},
    'AYQ': {'AYQ60': ['AYQ60']},
    'HND': {'HND402': ['HND402']},
    'AKL': {'AKL60': ['AKL61', 'AKL60'], 'AKL55': ['AKL56', 'AKL55'], 'AKL70': ['AKL71', 'AKL70']},
    'TOL': {'TOL250': ['TOL250']},
    'MAA': {'MAA140': ['MAA140']},
    'BHM': {'BHM250': ['BHM250']},
    'GDL': {'FOX100': ['FOX100']},
    'PRG': {'FOX400': ['FOX400']},
    'RST': {'RST250': ['RST250']},
    'ABQ': {'ABQ250': ['ABQ250']},
    'ROA': {'ROA250': ['ROA250']},
    'HII': {'HII250': ['HII250']},
    'AMA': {'AMA250': ['AMA250']},
    'ADS': {'ADS61': ['ADS61'], 'ADS62': ['ADS62'], 'ADS60': ['ADS60']},
    'RSW': {'RSW250': ['RSW250']},
    'PRC': {'PRC250': ['PRC250']},
    'CAN': {'ZTS300': ['ZTS300']},
    'AMS': {'AMS2': ['AMS2']},
    'AOH': {'AOH250': ['AOH250']},
    'SEL': {'SEL3': ['SEL3']},
    'LUX': {'LUX2': ['LUX2'], 'LUX9': ['LUX9'], 'LUX8': ['LUX8']},
    'OUTPR': {'OUTPR': ['OUTPR']},
    'JBR': {'JBR250': ['JBR250']},
    'WUX': {'HYV400': ['HYV400']},
    'OSL': {'OSL400': ['OSL400']},
    'PUQ': {'PUQ60': ['PUQ60']},
    'HWY': {'HWY120': ['HWY120']},
    'GGG': {'GGG250': ['GGG250']},
    'US': {'ABI250': ['ABI250']},
    'BPM': {'BPM61': ['BPM61', 'BPM54'], 'BPM62': ['BPM62'], 'BPM60': ['BPM60']},
    'TYR': {'TYR250': ['TYR250']},
    'BTP': {'BTP250': ['BTP250']},
    'LGA': {'LGA250': ['LGA250']},
    'RAD': {'RAD1': ['RAD1']},
    'CMB': {'CMB400': ['CMB400']},
    'ACT': {'ACT250': ['ACT250']},
    'ICT': {'ICT80': ['ICT80']},
    'OUTGI': {'OUTGI': ['OUTGI']},
    'OUTUK': {'OUTUK1': ['OUTUK1']},
    'MSY': {'MSY250': ['MSY250'], 'MSY251': ['MSY251']},
    'JAN': {'JAN81': ['JAN81']},
    'SDF': {'SDF250': ['SDF250']},
    'SHA': {'QTA200': ['QTA200']},
    'PVG': {'PVG250': ['PVG250']},
    'SJT': {'SJT250': ['SJT250']},
    'ATH': {'ATH52': ['ATH52']},
    'RIO': {'RIO4': ['RIO4']},
    'DBO': {'DBO400': ['DBO400']},
    'TUS': {'TUS250': ['TUS250']}
}
