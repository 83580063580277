import React, {useEffect, useState} from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from "../navigation";
import ToolsPanel from "../TableView/ToolsPanel";
import {appLayoutLabels} from "../Common/labels";
import axios from 'axios'
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from '@amzn/awsui-components-react/polaris/container';
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import SelfTopNavigation from "../topNavigation/topNavigation";
import SustainabilityDetailView from "./SustainabilityDetailView";


export default function SustainabilityDetailPage(props) {
    // const [OwnerOptions, setOwnerOptions] = useState([])
    // useEffect(() => {
    //     axios({
    //         method: "GET",
    //         url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/scanlogin",
    //     }).then(res => {
    //         const ownerOptionList: any = []
    //         res.data.map((item) => {
    //             ownerOptionList.push({
    //                 "label": item['gecos'],
    //                 "value": item['uid']
    //             })
    //         })
    //         setOwnerOptions(ownerOptionList)
    //     })
    // }, [])

    const [toolsOpen, setToolsOpen] = useState(false);
    const [sustainability_id, setSustainability_id] = useState(props.match.params.id)
    const [details, setDetails] = useState<any>()
    const [loginUser, setLoginUser] = useState('')
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);

    useEffect(() => {
        updateDetail()
    }, []);
    const updateDetail = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability/" + sustainability_id,
        }).then(res => {
            setDetails(res.data)
        })
    }

    const toolsChangeHandler = () => {
        if (toolsOpen) {
            setToolsOpen(false);
        } else {
            setToolsOpen(true);
        }
    };
    return (
        // <body>
        //         <div id="h" style={{position: 'sticky', top: 0, zIndex: 1002}}>
        //     <SelfTopNavigation></SelfTopNavigation>
        // </div>
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            // flash notification for demo purposes
            breadcrumbs={<Breadcrumbs items={[{text: 'Pain Point/Good Idea Details', href: ''}]}/>}
            // notifications={<AlertBar type={'info'} header={'Aladdin DashBoard Lunch New Version'}
            //                          content={'From April 1st, all PainPoint/GoodIdea submissions will use the Aladdin Dashboard. Data before April 1st will be gradually synchronized from Quip to the System.'} dismissible={true}/>}
            content={
                <ContentLayout
                    header={
                        <SpaceBetween size="m">
                            <Header
                                variant="h1"
                                // info={<Link>Info</Link>}
                                description="Every PainPoint/GoodIdea deserves to be applauded."
                                actions={
                                    <Button href='https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/'
                                            variant="primary" target="_blank">Help</Button>
                                }
                            >
                                Review the Sustainability Initiative
                            </Header>
                        </SpaceBetween>
                    }>

                    {details === undefined ?
                        <Box textAlign="center" margin={{top: "xxxl"}}>
                            <Spinner size="large"/>
                        </Box> : (
                            !details ? <SpaceBetween size="xs">
                                    <Box textAlign="center" margin={{top: 'xxxl'}}>
                                        <img width='500px'
                                             src='https://maxis-service-prod-dub.amazon.com/issues/8f158fd4-6951-463c-b3ec-87d77ead7ba8/attachments/9877ff46d60cd4e1bd0596dca0086afd2723326b60d67b4122edd0be3f913340_dcf9afac-7c9d-4c58-a54e-6ded1fd405a5'/>
                                    </Box>
                                    <Box textAlign="center" variant="div">
                                        <h1 style={{color: '#0073bb'}}>This Sustainability Initiative is not exist in
                                            Aladdin.</h1>
                                    </Box>
                                    <Box textAlign="center" variant="p">
                                        Please confirm the link to the Sustainability Initiative.
                                        <br/>
                                        To get more information about the Sustainability Initiative,
                                        please contact with system admin <Link external
                                                                               href="https://phonetool.amazon.com/users/ayunlong">ayunlong@
                                    </Link>.
                                    </Box>
                                </SpaceBetween> :

                                <SpaceBetween size="xs">
                                    <Container>
                                        <Header>Sustainability Initiative Detail</Header>
                                        <SustainabilityDetailView SustainabilityDetail={details} loginUser={loginUser}
                                                                  updateDetail={updateDetail}/>
                                    </Container>
                                </SpaceBetween>
                        )
                    }
                </ContentLayout>
            }
            contentType="table"
            toolsOpen={toolsOpen}
            maxContentWidth={Number.MAX_VALUE}
            onToolsChange={toolsChangeHandler}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
        />
        // </body>
    )
}