import {useEffect, useRef, useState} from 'react';
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table, {TableProps} from '@amzn/awsui-components-react/polaris/table';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import {TextFilterProps} from '@amzn/awsui-components-react/polaris/text-filter';
import axios from 'axios'
import {PropertyFilterProps} from "@amzn/awsui-components-react/polaris/property-filter";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import {
    CONTENT_SELECTOR_OPTIONS, CUSTOM_PREFERENCE_OPTIONS, DEFAULT_PREFERENCES, EmptyState,
    getMatchesCountText,
    PAGE_SELECTOR_OPTIONS,
    paginationLabels
} from "../TableView/table-config";
import PainPointPropertyFilter from "../Common/PainPointFilter";
import DateRangePicker from "../Common/DateRangePicker";
import DetailView from "../DetailView/DetailView";
import ModalBox, {ConfirmationModalBox} from "../Common/ModalBox";
import AutoLink, {LoginIcon, updateDateToString, UserIcon} from "../Common/AutoLink";
import {Distribution} from "../interfaces";
import {FavoriteFunction} from "../Common/ButtonFunctions";
import Input from "@amzn/awsui-components-react/polaris/input";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import {DogSvg} from "../Common/Token";

export default function RequestByMeDashboard() {
    const [OwnerOptions, setOwnerOptions] = useState([])
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/scanlogin",
        }).then(res => {
            const ownerOptionList: any = []
            res.data.map((item) => {
                ownerOptionList.push({
                    "label": item['gecos'],
                    "value": item['uid']
                })
            })
            setOwnerOptions(ownerOptionList)
        })
    }, [])
    const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Distribution>[] = [
        {
            id: 'Title',
            header: 'Title',
            cell: item => (<Link onFollow={() => ModalCell(item)}>{item.Title}</Link>),
            // cell: item => (<Link onFollow={ModalCell}>{item.Title}</Link>),
            minWidth: 330,
            // maxWidth: 240,
            width: 330
        }, {
            id: 'Description',
            // cell: item => <TablePopover content={item.Description}></TablePopover>,
            cell: item => item.Description,
            header: 'Description',
            minWidth: 210,
            // maxWidth: 160,
            width: 210,
        }, {
            id: 'Site',
            header: 'ImpactSite',
            cell: item => (<div>{item.Site.join(',')}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'Site'
        }, {
            id: 'SubmitterAZ',
            header: 'Site',
            cell: item => (<Badge color="green">{item.SubmitterAZ}</Badge>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'Site'
        }, {
            id: 'Owner',
            header: 'Owner',
            cell: item => (<LoginIcon user={item.Owner}/>),
            minWidth: 110,
            // maxWidth: 100,
            width: 110,
            sortingField: 'Owner'
        }, {
            id: 'Label',
            header: 'Label',
            cell: item => (<div>{item.Label}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'Label'
        }, {
            id: 'Category',
            header: 'Category',
            cell: item => (<div>{item.Category}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'Category'
        }, {
            id: 'Priority',
            header: 'Priority',
            cell: item => (<div>{item.Priority.join(', ')}</div>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'Priority'
        }, {
            id: 'ImpactTeam',
            header: 'ImpactTeam',
            cell: item => (<div>{item.Team.join(',')}</div>),
            minWidth: 110,
            // maxWidth: 140,
            width: 110,
            sortingField: 'ImpactTeam'
        }, {
            id: 'Status',
            header: 'Status',
            cell: item => (<StatusIndicator
                type={item.Status === 'Assign' ? 'info' : (item.Status === 'Working in Process' ? 'in-progress' : (item.Status === 'Pending' ? 'pending' : (item.Status === 'Resolved' ? 'success' : 'error')))}>{item.Status}</StatusIndicator>),
            minWidth: 100,
            // maxWidth: 100,
            width: 100,
            sortingField: 'status'
        }, {
            id: 'State',
            header: 'State',
            cell: item => (
                <StatusIndicator
                    type={item.State === 'Unapproved' ? 'error' : (item.State === 'Approved' ? 'success' : (item.State === 'Reviewed' ? 'pending' : 'info'))}>{item.State}</StatusIndicator>
            ),
            minWidth: 120,
            // maxWidth: 120,
            width: 120,
            sortingField: 'State'
        }, {
            id: 'Submitter',
            header: 'Submitter',
            cell: item => (<Box textAlign="center"><UserIcon user={item.Submitter}/></Box>),
            minWidth: 110,
            // maxWidth: 110,
            width: 110,
            sortingField: 'Submitter'
        }, {
            id: 'SubmitterLogin',
            header: 'SubmitterLogin',
            cell: item => item.Submitter,
            minWidth: 110,
            // maxWidth: 110,
            width: 110,
            sortingField: 'SubmitterLogin'
        }, {
            id: 'SubmitDate',
            header: 'SubmitDate',
            cell: item => updateDateToString(item.SubmitDate),
            minWidth: 80,
            maxWidth: 200,
            width: 200,
            sortingField: 'SubmitDate'
        }, {
            id: 'Like',
            header: 'Like',
            cell: item => (
                <FavoriteFunction FavoriteID={item.id} login={loginUser} Like={item.Like} Hate={item.Hate}/>),
            minWidth: 80,
            // maxWidth: 140,
            // width: 140,
            sortingField: 'Like',
            sortingComparator: (a, b) => b.Like.length.valueOf() - a.Like.length.valueOf(),
        }

    ];
    const [permissionUser, setPermissionUser] = useState(false)
    const [allItems, setAllItems] = useState<Distribution[]>([])
    const [loginUser, setLoginUser] = useState('')
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/getuser",
        }).then(res => {
            res.data.hasOwnProperty('Preferences') ? setPreferences(res.data.Preferences) :
                console.log('Default Setting')
        })
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);
    const [refreshLoading, setRefreshLoading] = useState(false)

    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);
    // useEffect(() => {
    //     propertyFilterProps.query.operation = query.operation
    //     propertyFilterProps.query.tokens = query.tokens
    // })
    const updateDetail = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/" + Itemslist.id,
        }).then(res => {
            setItemslist(res.data)
        })
    }

    const loadItems = async () => {
        Itemslist ? updateDetail() : null
        setLoading(true);
        setRefreshLoading(true)
        await axios({
            method: "POST",
            data: DateLimitValue,
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/ownership/request",
        }).then(res => {
            setAllItems(res.data)
            setLoading(false);
            setRefreshLoading(false)
        });
    }
    const updateDateDoNotCloseModal = async () => {
        setLoading(true);
        await axios({
            method: "POST",
            data: DateLimitValue,
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/ownership/request",
        }).then(res => {
            setAllItems(res.data)
            setLoading(false);
        });
    }

    useEffect(() => {
        loadItems()
    }, []);
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    })
    const {items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps} = useCollection(
        allItems || [],
        {
            propertyFiltering: {
                filteringProperties: [
                    {
                        key: "Submitter",
                        operators: ["=", "!="],
                        propertyLabel: "Submitter",
                        groupValuesLabel: "Submitter"
                    }, {
                        key: "Site",
                        operators: [":", "!:"],
                        propertyLabel: "Site",
                        groupValuesLabel: "Site",
                    }, {
                        key: "Owner",
                        operators: ["=", "!="],
                        propertyLabel: "Owner",
                        groupValuesLabel: "Owner"
                    }, {
                        key: "Status",
                        operators: ["=", "!="],
                        propertyLabel: "Status",
                        groupValuesLabel: "Status"
                    }, {
                        key: "State",
                        operators: ["=", "!="],
                        propertyLabel: "State",
                        groupValuesLabel: "State"
                    }, {
                        key: "Label",
                        operators: ["=", "!="],
                        propertyLabel: "Label",
                        groupValuesLabel: "Label"
                    }, {
                        key: "Category",
                        operators: [":", "!:"],
                        propertyLabel: "Category",
                        groupValuesLabel: "Category"
                    }, {
                        key: "Priority",
                        operators: [":", "!:"],
                        propertyLabel: "Priority",
                        groupValuesLabel: "Priority"
                    }, {
                        key: "FirstReviewer",
                        operators: ["=", "!="],
                        propertyLabel: "FirstReviewer",
                        groupValuesLabel: "FirstReviewer"
                    }, {
                        key: "CMApproval",
                        operators: ["=", "!="],
                        propertyLabel: "CMApproval",
                        groupValuesLabel: "CMApproval"
                    }
                ],
                defaultQuery: query,
                empty: (
                    <EmptyState
                        title="No PainPoint/GoodIdea"
                        subtitle="No PainPoint/GoodIdea to display."
                        action={<Button href="#/create_painpoint">Create PainPoint/GoodIdea</Button>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No PainPoint/GoodIdea"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {
                defaultState: {
                    sortingColumn: COLUMN_DEFINITIONS[13],
                    isDescending: true,
                }
            },
        }
    );

    // const selectedItems = collectionProps.selectedItems!;
    const [selectedItems, setSelectedItems] = useState<any>([])
    const isOnlyOneSelected = selectedItems.length === 1;
    const [Itemslist, setItemslist] = useState<any>()
    const [visible, setVisible] = useState<boolean>(false);
    const [deleteAction, setDeleteAction] = useState('')
    const [DeniedVisible, setDeniedVisible] = useState<boolean>(false);
    const [DeleteVisible, setDeleteVisible] = useState<boolean>(false);
    const ModalCell = (detail) => {
        setItemslist(detail)
        setVisible(true)
        loginUser != '' && detail.submitterAZ != '' ?
            axios({
                method: "GET",
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/" + detail.SubmitterAZ,
            }).then(res => {
                res.data.UserList.map((items, index) => {
                    if (loginUser === items.Login) {
                        setPermissionUser(true)
                    }
                })
            }) : null
    }
    const PermissionDeniedShow = () => {
        setDeniedVisible(true)
    }
    const DeleteConfirmShow = () => {
        setDeleteVisible(true)
    }
    const DeleteAction = () => {
        setDeleteVisible(false)
        axios({
            method: "DELETE",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/" + selectedItems[0]["id"],
        }).then(res => {
            setLoading(true);
            axios({
                method: "POST",
                data: DateLimitValue,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/ownership/request",
            }).then(res => {
                setAllItems(res.data)
                setDeleteAction(selectedItems[0]["id"])
            });
        })
    }
    const deletePainpoint = () => {

        loginUser === selectedItems[0].Owner || loginUser === selectedItems[0].Submitter || loginUser === selectedItems[0].FirstReviewer || loginUser === 'sukvi' || loginUser === 'junjil' ? (
            DeleteConfirmShow()
        ) : (PermissionDeniedShow())


    }
    const editPainPoint = () => {
        loginUser === selectedItems[0].Owner || loginUser === selectedItems[0].Submitter || loginUser === selectedItems[0].FirstReviewer || loginUser === 'sukvi' || loginUser === 'junjil' ? (
            window.location.href = "#/edit/" + selectedItems[0]["id"]
        ) : (
            PermissionDeniedShow()
        )

    }

    useEffect(() => {
        Itemslist ? setVisible(true) : null
    }, [Itemslist])
    useEffect(() => {
        deleteAction !== '' ? setLoading(false) : null
    }, [deleteAction])
    const setUserPreferences = (event) => {
        setPreferences(event)
        axios({
            method: "POST",
            data: event,
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/updatepreferences",
        }).then(res => {
            console.log(event)
        })
    }

    const handlePainPointFilter = (event) => {
        setQuery(event.detail)
        loadItems()
        propertyFilterProps.query.operation = event.detail.operation
        propertyFilterProps.query.tokens = event.detail.tokens
    }
    const [DateLimitValue, setDateLimitValue] = useState<any>({
        type: "relative",
        amount: 6,
        unit: "month"
    });
    const handleLineChartChange = (detail) => {
        setLoading(true);
        const datelimit = detail.value
        axios({
            method: "POST",
            data: datelimit,
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/ownership/request",
        }).then(res => {
            setAllItems(res.data)
            setLoading(false)
        })
        setDateLimitValue(detail.value)
    }

    const deleteConsentText = 'confirm';
    const [deleteConfirmInputText, setDeleteConfirmInputText] = useState<any>('')
    useEffect(() => {
        setDeleteConfirmInputText('');
    }, [DeleteVisible]);
    return (
        <ContentLayout
            header={
                <></>
            }
        >

            <SpaceBetween size="m">
                <Table
                    {...collectionProps}
                    selectedItems={selectedItems}
                    onSelectionChange={({detail}) => setSelectedItems(detail.selectedItems)}
                    onRowClick={({detail}) => setSelectedItems([detail.item])}
                    visibleColumns={preferences.visibleContent}
                    wrapLines={preferences.wrapLines}
                    loading={loading}
                    loadingText="Loading Pain Point/Good Idea List"
                    selectionType="single"
                    stickyHeader={true}
                    variant='container'
                    resizableColumns={true}
                    header={
                        <Header
                            variant="awsui-h1-sticky"
                            counter={
                                allItems &&
                                (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                            }
                            actions={
                                <SpaceBetween size="xs" direction="horizontal">
                                    <Button onClick={() => loadItems()} loading={refreshLoading}><Icon
                                        name="refresh"/>&ensp; Refresh</Button>
                                    <Button disabled={!isOnlyOneSelected} onClick={() => {
                                        window.location.href = "#/detail/" + selectedItems[0]["id"]
                                    }}>Trace/Approval items</Button>
                                    <Button onClick={() => editPainPoint()} disabled={!isOnlyOneSelected}>Edit</Button>
                                    <Button onClick={() => deletePainpoint()}
                                            disabled={selectedItems.length === 0}>Delete</Button>
                                </SpaceBetween>
                            }
                            description="A dashboard for good idea to shine."
                        >
                            Submit By Myself
                        </Header>
                    }
                    columnDefinitions={COLUMN_DEFINITIONS}
                    items={items}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                    filter={
                        <Grid
                            gridDefinition={[{colspan: 4}, {colspan: 3}, {colspan: 2}, {colspan: 2}, {colspan: 1}]}
                        >
                            <PainPointPropertyFilter
                                {...propertyFilterProps}
                                countText={getMatchesCountText(filteredItemsCount!)}
                                onChange={(event) => handlePainPointFilter(event)}
                                query={query} filteringProperties={propertyFilterProps.filteringProperties}
                                filteringOptions={[
                                    {
                                        propertyKey: "Submitter",
                                        value: loginUser
                                    }, {
                                        propertyKey: "Owner",
                                        value: loginUser
                                    }, {
                                        propertyKey: "Status",
                                        value: "Assign"
                                    }, {
                                        propertyKey: "Status",
                                        value: "Working in Process"
                                    }, {
                                        propertyKey: "Status",
                                        value: "Pending"
                                    }, {
                                        propertyKey: "Status",
                                        value: "Resolved"
                                    }, {
                                        propertyKey: "Status",
                                        value: "Assign"
                                    }, {
                                        propertyKey: "State",
                                        value: "initialization"
                                    }, {
                                        propertyKey: "State",
                                        value: "Reviewed"
                                    }, {
                                        propertyKey: "State",
                                        value: "Approved"
                                    }, {
                                        propertyKey: "State",
                                        value: "Unapproved"
                                    }, {
                                        propertyKey: "CMApproval",
                                        value: "Approved"
                                    }, {
                                        propertyKey: "CMApproval",
                                        value: "Unapproved"
                                    }, {
                                        propertyKey: "Label",
                                        value: "Pain Point"
                                    }, {
                                        propertyKey: "Label",
                                        value: "Good Idea"
                                    }, {
                                        propertyKey: "Category",
                                        value: "Tools"
                                    }, {
                                        propertyKey: "Category",
                                        value: "Employee Experience"
                                    }, {
                                        propertyKey: "Category",
                                        value: "Process"
                                    }, {
                                        propertyKey: "Category",
                                        value: "Operation"
                                    }, {
                                        propertyKey: "Category",
                                        value: "Script"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Safety"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Security"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Availability"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Productivity"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Efficiency"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Cost"
                                    }, {
                                        propertyKey: "Priority",
                                        value: "Quality"
                                    }, {
                                        propertyKey: "FirstReviewer",
                                        value: loginUser
                                    }
                                ]}
                            />
                            <DateRangePicker onChange={({detail}) => handleLineChartChange(detail)}
                                             value={DateLimitValue}
                                             relativeOptions={[
                                                 {
                                                     key: "previous-1-day",
                                                     amount: 1,
                                                     unit: "day",
                                                     type: "relative"
                                                 }, {
                                                     key: "previous-7-days",
                                                     amount: 7,
                                                     unit: "day",
                                                     type: "relative"
                                                 }, {
                                                     key: "previous-1-month",
                                                     amount: 1,
                                                     unit: "month",
                                                     type: "relative"
                                                 }, {
                                                     key: "previous-6-months",
                                                     amount: 6,
                                                     unit: "month",
                                                     type: "relative"
                                                 }, {
                                                     key: "previous-1-year",
                                                     amount: 1,
                                                     unit: "year",
                                                     type: "relative"
                                                 }
                                             ]} rangeSelectorMode={undefined}/>
                            <div></div>
                            <div></div>
                            <div></div>
                        </Grid>
                    }
                    preferences={
                        <CollectionPreferences
                            preferences={preferences}
                            onConfirm={event => setUserPreferences(event.detail)}
                            title="Preferences Setting"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            visibleContentPreference={{
                                title: 'Select visible columns',
                                options: CONTENT_SELECTOR_OPTIONS
                            }}
                            pageSizePreference={{
                                title: 'Page size',
                                options: PAGE_SELECTOR_OPTIONS
                            }}
                            wrapLinesPreference={{
                                label: 'Wrap lines',
                                description: 'Check to see all the text and wrap the lines'
                            }}
                            customPreference={(state, setState) => (
                                <FormField stretch={true} label="View as">
                                    <RadioGroup
                                        value={state}
                                        onChange={event => setState(event.detail.value)}
                                        items={CUSTOM_PREFERENCE_OPTIONS}
                                    />
                                </FormField>
                            )}
                        />
                    }
                />
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    className="ModelPainPointDetails"
                    closeAriaLabel="Close modal"
                    id="ModelPainPointDetails"
                    size="large"
                    header="PainPoint/GoodIdea Detail"
                >

                    {Itemslist ? <DetailView Itemslist={Itemslist} loginUser={loginUser} OwnerOptions={OwnerOptions}
                                             permissionUser={permissionUser} updateDetail={loadItems}
                                             updateDateDoNotCloseModal={updateDateDoNotCloseModal}/> : null}
                </Modal>
                <ModalBox cancel={() => setDeniedVisible(false)} onDismiss={() => setDeniedVisible(false)} content={
                    <h4>You don't have permission to Edit/Delete the Item</h4>
                } id={"ModelPermissionDenied"} header={'Error'} visible={DeniedVisible}
                          confirm={() => setDeniedVisible(false)}/>

                <ConfirmationModalBox id='ConfirmationModalBox' visible={DeleteVisible}
                                      onDismiss={() => setDeleteVisible(false)}
                                      disabled={!(deleteConfirmInputText == deleteConsentText)}
                                      content={
                                          <SpaceBetween size="m">
                                              <Box variant="span">
                                                  Permanently delete the item{' '}
                                                  <Box variant="span" fontWeight="bold">
                                                      {selectedItems.length > 0 ? selectedItems[0]['Title'] : null}
                                                  </Box>
                                                  ? You can’t undo this action.
                                              </Box>
                                              <Alert type="warning"
                                                     statusIconAriaLabel="Warning">
                                                  Proceeding with this action will delete the
                                                  Pain Point/Good Idea with all their team members and
                                                  can affect related viewers.{' '}
                                                  <Link external={true} href="#"
                                                        ariaLabel="Learn more about permission management, opens in new tab">
                                                      Learn more
                                                  </Link>
                                              </Alert>

                                              <Box>To avoid accidental deletions, we ask you
                                                  to provide additional written consent.</Box>

                                              <FormField
                                                  label={`To confirm this deletion, type "${deleteConsentText}".`}>
                                                  <ColumnLayout columns={2}>
                                                      <Input
                                                          placeholder={deleteConsentText}
                                                          onChange={({detail}) => setDeleteConfirmInputText(detail.value)}
                                                          value={deleteConfirmInputText}
                                                      />
                                                  </ColumnLayout>
                                              </FormField>
                                              {selectedItems.length > 0 ? (
                                                  <TextContent>
                                                      <Grid
                                                          gridDefinition={[{colspan: 7}, {colspan: 5}]}
                                                      >
                                                          <Box>
                                                              <Box>
                                                                  <strong>Title</strong>
                                                                  <AutoLink text={selectedItems[0]['Title']}></AutoLink>
                                                              </Box>
                                                              <Box>
                                                                  <strong>Problem Statement</strong>
                                                                  <AutoLink
                                                                      text={selectedItems[0]['Description']}></AutoLink>
                                                              </Box>
                                                              <Box>
                                                                  <strong>Label</strong>
                                                                  <AutoLink
                                                                      text={selectedItems[0]['Label'][0]}></AutoLink>
                                                              </Box>
                                                              <Box>
                                                                  <strong>Submitter</strong>
                                                                  <AutoLink
                                                                      text={selectedItems[0]['Submitter']}></AutoLink>
                                                              </Box>
                                                              <Box>
                                                                  <strong>Submit Date</strong>
                                                                  <AutoLink
                                                                      text={updateDateToString(selectedItems[0]['SubmitDate'])}></AutoLink>
                                                              </Box>
                                                          </Box>
                                                          <Box>
                                                              <DogSvg/>
                                                          </Box>
                                                      </Grid>
                                                  </TextContent>) : null}
                                          </SpaceBetween>
                                      }
                                      header='Attention'
                                      confirm={() => DeleteAction()}
                                      cancel={() => setDeleteVisible(false)}/>
            </SpaceBetween>
            {/*<br/>*/}
            {/*<Container*/}
            {/*    header={*/}
            {/*        <Header*/}
            {/*            variant="h2"*/}
            {/*            description="Container description"*/}
            {/*        >*/}
            {/*            Container header*/}
            {/*        </Header>*/}
            {/*    }*/}
            {/*>*/}
            {/*</Container>*/}

        </ContentLayout>
    )
}