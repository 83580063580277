import React from "react";
import PieChart from "@amzn/awsui-components-react/polaris/pie-chart";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";

export interface PieDonutChartProps {
    i18nStrings: any
    data: any
    detailPopoverContent: any
    statusType: any
}

export default function PieDonutCharts(props: PieDonutChartProps) {
    return (
        <PieChart
            data={props.data}
            statusType={props.statusType}
            detailPopoverContent={props.detailPopoverContent}
            segmentDescription={(datum, sum) =>
                `${datum.value} Ideas, ${(
                    (datum.value / sum) *
                    100
                ).toFixed(0)}%`
            }
            size='large'
            i18nStrings={props.i18nStrings}
            ariaDescription="Pie chart showing how many resources are currently in which state."
            ariaLabel="Pie chart"
            errorText="Error loading data."
            loadingText="Loading chart"
            recoveryText="Retry"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No data available</b>
                    <Box variant="p" color="inherit">
                        There is no data available
                    </Box>
                </Box>
            }
            noMatch={
                <Box textAlign="center" color="inherit">
                    <b>No matching data</b>
                    <Box variant="p" color="inherit">
                        There is no matching data to display
                    </Box>
                    <Button>Clear filter</Button>
                </Box>
            }
        />
    );
}