import {ReactNode} from 'react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import {TableProps} from '@amzn/awsui-components-react/polaris/table';
import {Distribution} from '../interfaces';
import {RadioGroupProps} from '@amzn/awsui-components-react/polaris/radio-group';
import {CollectionPreferencesProps} from '@amzn/awsui-components-react/polaris/collection-preferences';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import {LoginIcon, UserIcon} from "../Common/AutoLink";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Distribution>[] = [
    {
        id: 'Title',
        header: 'Title',
        // cell: item => (<Link onFollow={() => ModalCell(item)}>{item.Title}</Link>),
        cell: item => (<Link>{item.Title}</Link>),
        minWidth: 330,
        // maxWidth: 240,
        width: 330,
    }, {
        id: 'Description',
        // cell: item => <TablePopover content={item.Description}></TablePopover>,
        cell: item => item.Description,
        header: 'Description',
        minWidth: 210,
        // maxWidth: 160,
        width: 210,
    }, {
        id: 'Site',
        header: 'ImpactSite',
        cell: item => (<div>{item.Site.join(',')}</div>),
        minWidth: 100,
        // maxWidth: 100,
        width: 100,
        sortingField: 'Site'
    }, {
        id: 'SubmitterAZ',
        header: 'Site',
        cell: item => (<Badge color="green">{item.SubmitterAZ}</Badge>),
        minWidth: 100,
        // maxWidth: 100,
        width: 100,
        sortingField: 'Site'
    }, {
        id: 'Owner',
        header: 'Owner',
        cell: item => (<LoginIcon user={item.Owner}/>),
        minWidth: 110,
        // maxWidth: 100,
        width: 110,
        sortingField: 'Owner'
    }, {
        id: 'Label',
        header: 'Label',
        cell: item => (<div>{item.Label}</div>),
        minWidth: 100,
        // maxWidth: 100,
        width: 100,
        sortingField: 'Label'
    }, {
        id: 'Category',
        header: 'Category',
        cell: item => (<div>{item.Category}</div>),
        minWidth: 100,
        // maxWidth: 100,
        width: 100,
        sortingField: 'Category'
    }, {
        id: 'Priority',
        header: 'Priority',
        cell: item => (<div>{item.Priority.join(', ')}</div>),
        minWidth: 100,
        // maxWidth: 100,
        width: 100,
        sortingField: 'Priority'
    }, {
        id: 'ImpactTeam',
        header: 'ImpactTeam',
        cell: item => (<div>{item.Team.join(',')}</div>),
        minWidth: 110,
        // maxWidth: 140,
        width: 110,
        sortingField: 'ImpactTeam'
    }, {
        id: 'Status',
        header: 'Status',
        cell: item => (<StatusIndicator
            type={item.Status === 'Assign' ? 'info' : (item.Status === 'Working in Process' ? 'in-progress' : (item.Status === 'Pending' ? 'pending' : (item.Status === 'Resolved' ? 'success' : 'error')))}>{item.Status}</StatusIndicator>),
        minWidth: 100,
        // maxWidth: 100,
        width: 100,
        sortingField: 'status'
    }, {
        id: 'State',
        header: 'State',
        cell: item => (
            <StatusIndicator
                type={item.State === 'Unapproved' ? 'error' : (item.State === 'Approved' ? 'success' : (item.State === 'Reviewed' ? 'pending' : 'info'))}>{item.State}</StatusIndicator>
        ),
        minWidth: 120,
        // maxWidth: 120,
        width: 120,
        sortingField: 'State'
    }, {
        id: 'Submitter',
        header: 'Submitter',
        cell: item => (<Box textAlign="center"><UserIcon user={item.Submitter}/></Box>),
        minWidth: 110,
        // maxWidth: 110,
        width: 110,
        sortingField: 'Submitter'
    }, {
        id: 'SubmitterLogin',
        header: 'SubmitterLogin',
        cell: item => item.Submitter,
        minWidth: 110,
        // maxWidth: 110,
        width: 110,
        sortingField: 'SubmitterLogin'
    }, {
        id: 'SubmitDate',
        header: 'SubmitDate',
        cell: item => item.SubmitDate,
        minWidth: 110,
        // maxWidth: 140,
        // width: 140,
        sortingField: 'SubmitDate'
    }
];

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}

export function EmptyState({title, subtitle, action}: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="240" height="160" fill="none">
                    <g className="Zcp3PONWSf-empty-state" clipPath="url(#clip0_165_426585)">
                        <g className="Zcp3PONWSf-Background-shapes Zcp3PONWSf-Clouds Zcp3PONWSf-Cloud">
                            <path fill="#D3E7F9"
                                  d="M25.2183 79.9933c0-19.4529 16.0322-36.7411 35.2811-36.7411 3.6795 0 7.2257.5775 10.5551 1.6458C74.9621 23.7216 93.3415 7.6904 115.43 7.6904c24.934 0 45.145 20.4277 45.145 45.6238 0 .9861-.034 1.9654-.095 2.9355 5.771-4.7091 13.114-7.5328 21.11-7.5328 18.574.0023 33.628 15.2163 33.628 33.9844 0 16.6978-11.917 30.5837-27.624 33.4437H54.1253c-16.2042-3.027-28.907-18.8993-28.907-36.1517Z"
                                  className="Zcp3PONWSf-illustration-primary-cloud"></path>
                        </g>
                        <g className="Zcp3PONWSf-robot-shadow">
                            <g className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Body">
                                <path fill="#000716"
                                      d="M70.582 149.947c10.6252 0 19.2386-1.286 19.2386-2.871s-8.6134-2.871-19.2386-2.871c-10.6252 0-19.2385 1.286-19.2385 2.871s8.6133 2.871 19.2385 2.871Z"
                                      className="Zcp3PONWSf-Torso Zcp3PONWSf-shadow Zcp3PONWSf-illustration-robot-shadow"></path>
                            </g>
                        </g>
                        <g className="Zcp3PONWSf-robot-full">
                            <g strokeWidth="1.5" className="Zcp3PONWSf-General Zcp3PONWSf-Watering-can">
                                <g stroke="#000716" className="Zcp3PONWSf-Watering Zcp3PONWSf-pot">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="m160.16 84.0566-4.832-4.832c-1.73-1.7294-4.534-1.7294-6.263 0l-4.721 4.7212c-1.73 1.7294-1.73 4.5334 0 6.2628l4.832 4.8319c1.729 1.7295 4.533 1.7295 6.262 0l4.722-4.7211c1.729-1.7294 1.729-4.5334 0-6.2628Z"
                                          className="Zcp3PONWSf-outline-only-grey"></path>
                                    <path fill="#D1D5DB" strokeLinecap="round" strokeLinejoin="round"
                                          d="m171.774 100.613 13.239.729.19 2.791-19.6 2.652 6.171-6.172Z"
                                          className="Zcp3PONWSf-illustration-secondary-grey"></path>
                                    <path fill="white"
                                          d="M185.48 106.724c.734.038 1.51-1.669 1.726-3.813.213-2.148-.202-3.9157-.946-3.957-.733-.038-1.509 1.669-1.726 3.814-.217 2.144.202 3.915.943 3.953l.003.003Z"
                                          className="Zcp3PONWSf-illustration-primary-grey"></path>
                                    <path fill="#F4F4F4" strokeLinecap="round" strokeLinejoin="round"
                                          d="M172.118 100.306a.768.768 0 0 1-.118.154l-9.897 9.896a.737.737 0 0 1-1.044 0l-13.116-13.1158a.7385.7385 0 0 1 0-1.0438L157.84 86.3a.6968.6968 0 0 1 .154-.118l5.3-3.046a.7386.7386 0 0 1 .89.118l10.862 10.862a.7394.7394 0 0 1 .118.8896l-3.046 5.3004Z"
                                          className="Zcp3PONWSf-illustration-primary-grey"></path>
                                    <path strokeLinecap="square" strokeLinejoin="round"
                                          d="m158.514 86.729 13.03 13.0296"
                                          className="Zcp3PONWSf-Vector Zcp3PONWSf-665"></path>
                                </g>
                                <g stroke="#0972D3" className="Zcp3PONWSf-Motion Zcp3PONWSf-Lines Zcp3PONWSf-Water">
                                    <path d="M189.131 102.155c3.085.554 9.592 3.453 10.946 10.617"
                                          className="Zcp3PONWSf-Line Zcp3PONWSf-outline-only-blue"></path>
                                    <path d="M189.11 104.952c3.071.621 8.866 5.286 6.955 14.315"
                                          className="Zcp3PONWSf-Line Zcp3PONWSf-outline-only-blue"></path>
                                </g>
                            </g>
                            <g className="Zcp3PONWSf-Robot-M">
                                <g stroke="#000716"
                                   className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Arm Zcp3PONWSf-Left Zcp3PONWSf-Mid-flexed">
                                    <g strokeWidth="1.5" className="Zcp3PONWSf-Arm">
                                        <path fill="#D1D5DB" strokeLinejoin="round"
                                              d="m86.557 74.8403 22.844 22.8439c2.385 2.3848 6.159 2.4208 8.429.1504 2.271-2.2705 2.235-6.0443-.15-8.4296L94.8362 66.5611c-2.3853-2.3853-6.1591-2.4209-8.4296-.1504-2.2705 2.2705-2.2349 6.0443.1504 8.4296Z"
                                              className="Zcp3PONWSf-Back-arm Zcp3PONWSf-illustration-secondary-grey"></path>
                                        <g className="Zcp3PONWSf-Forearm">
                                            <path fill="#F4F4F4" strokeLinejoin="round"
                                                  d="M117.857 98.801a.5696.5696 0 0 1 .038-.0253l27.112-16.8108c2.215-1.3734 2.94-4.2575 1.636-6.5146-1.303-2.2571-4.163-3.0718-6.46-1.8402l-28.114 15.0744c-.014.0072-.027.014-.041.0203a5.794 5.794 0 0 0-.487.252c-.051.0291-.1.0589-.15.0893a.534.534 0 0 1-.035.0202 1.2982 1.2982 0 0 1-.033.0227c-2.631 1.6727-3.499 5.1388-1.925 7.8649 1.574 2.726 5.01 3.7071 7.774 2.2656a.8569.8569 0 0 1 .036-.0178.5327.5327 0 0 1 .035-.02 6.0535 6.0535 0 0 0 .614-.3807Z"
                                                  className="Zcp3PONWSf-illustration-primary-grey"></path>
                                            <path fill="#0972D3" fillRule="evenodd"
                                                  d="M115.313 91.3988c3.341.8446 1.975 5.94-1.338 4.9915-3.341-.8446-1.975-5.94 1.338-4.9915Z"
                                                  className="Zcp3PONWSf-illustration-primary-blue"
                                                  clipRule="evenodd"></path>
                                        </g>
                                    </g>
                                    <g fill="#F4F4F4"
                                       className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Hand Zcp3PONWSf-Left Zcp3PONWSf-Front Zcp3PONWSf-closed">
                                        <path strokeWidth="1.5"
                                              d="m146.186 74.1689 5.619 5.9406-6.331 6.3314-5.941-5.6196c-1.85-1.7502-1.872-4.7506.015-6.6375 1.887-1.8869 4.888-1.8651 6.638-.0149Z"
                                              className="Zcp3PONWSf-Hand Zcp3PONWSf-illustration-primary-grey"></path>
                                        <path strokeLinejoin="round" strokeWidth="1.4762"
                                              d="M151.79 80.3735c-2.018-2.0185-5.056-2.2538-6.784-.5257-1.728 1.7281-1.492 4.7652.526 6.7837l6.258-6.258Z"
                                              className="Zcp3PONWSf-Fingers"></path>
                                    </g>
                                </g>
                                <g className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Arm Zcp3PONWSf-Right Zcp3PONWSf-Hand-to-hip">
                                    <g stroke="#000716" strokeWidth="1.5" className="Zcp3PONWSf-Arm">
                                        <path fill="#D1D5DB" strokeLinejoin="round"
                                              d="M46.0868 65.5592 18.1071 81.7133c-2.9215 1.6867-3.9328 5.3228-2.3273 8.1036 1.6055 2.7808 5.2601 3.723 8.1816 2.0363l27.9797-16.1541c2.9216-1.6868 3.9328-5.3229 2.3273-8.1036-1.6054-2.7808-5.26-3.7231-8.1816-2.0363Z"
                                              className="Zcp3PONWSf-Back-arm Zcp3PONWSf-illustration-secondary-grey"></path>
                                        <g className="Zcp3PONWSf-Forearm">
                                            <path fill="#F4F4F4" strokeLinejoin="round"
                                                  d="M16.8593 91.4295a.7529.7529 0 0 1 .0342.0302l23.2551 21.8373c1.9 1.784 4.8732 1.737 6.7162-.106 1.8429-1.842 1.8896-4.816.1055-6.716L25.1329 83.2201a.7553.7553 0 0 1-.0302-.0343 5.9589 5.9589 0 0 0-.4944-.5264.7632.7632 0 0 1-.0285-.0284.8495.8495 0 0 1-.0307-.0267c-2.2965-2.1081-5.8692-2.0493-8.095.1765-2.2258 2.2258-2.2846 5.7985-.1765 8.095a.6931.6931 0 0 1 .0266.0306.694.694 0 0 1 .0284.0285 5.9373 5.9373 0 0 0 .5267.4946Z"
                                                  className="Zcp3PONWSf-illustration-primary-grey"></path>
                                            <path fill="#0972D3" fillRule="evenodd"
                                                  d="M18.1457 87.0565c-.0488 3.4457 5.2263 3.4457 5.1676 0 .0489-3.4458-5.2262-3.4458-5.1676 0Z"
                                                  className="Zcp3PONWSf-illustration-primary-blue"
                                                  clipRule="evenodd"></path>
                                        </g>
                                    </g>
                                    <g className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Hand Zcp3PONWSf-Left Zcp3PONWSf-Back Zcp3PONWSf-no Zcp3PONWSf-thumb">
                                        <path fill="#F4F4F4" stroke="#000716" strokeWidth="1.5"
                                              d="m34.3558 116.957 5.1951-9.604c1.2117-2.24 4.1042-3.038 6.4152-1.704 2.311 1.335 3.0665 4.238 1.7323 6.408l-5.7201 9.301c-1.201 1.953-3.8048 2.596-5.8865 1.394-2.0817-1.202-2.8268-3.778-1.736-5.795Z"
                                              className="Zcp3PONWSf-Hand Zcp3PONWSf-illustration-primary-grey"></path>
                                    </g>
                                </g>
                                <g className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Head Zcp3PONWSf-Right">
                                    <g className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Body Zcp3PONWSf-Shadow Zcp3PONWSf-Right">
                                        <g fill="#D1D5DB" stroke="#000716" strokeWidth="1.5"
                                           className="Zcp3PONWSf-Neck">
                                            <path
                                                d="M76.8499 59.4741H64.359c-.5153 0-.9331.5948-.9331 1.3284v3.3596c0 .7336.4178 1.3283.9331 1.3283h12.4909c.5154 0 .9331-.5947.9331-1.3283v-3.3596c0-.7336-.4177-1.3284-.9331-1.3284Z"
                                                className="Zcp3PONWSf-illustration-secondary-grey"></path>
                                            <path
                                                d="M76.8499 53.458H64.359c-.5153 0-.9331.5947-.9331 1.3284v3.3596c0 .7336.4178 1.3283.9331 1.3283h12.4909c.5154 0 .9331-.5947.9331-1.3283v-3.3596c0-.7337-.4177-1.3284-.9331-1.3284Z"
                                                className="Zcp3PONWSf-illustration-secondary-grey"></path>
                                        </g>
                                        <path fill="#000716" d="M64.0002 59.4741v-5.48h13.2085v2.5293l-13.2085 2.9507Z"
                                              className="Zcp3PONWSf-Vector"></path>
                                    </g>
                                    <g className="Zcp3PONWSf-Head">
                                        <path fill="#000716" fillRule="evenodd"
                                              d="M62.0851 21.585h10.9602c4.3969 0 8.0046 3.6198 8.1516 8.1866l.7206 22.0194c0 2.347-1.8333 4.2511-4.093 4.2511H57.2961c-2.2597 0-4.0929-1.9041-4.0929-4.2511l.7205-22.0194c.152-4.5668 3.7548-8.1866 8.1516-8.1866h.0098Z"
                                              className="Zcp3PONWSf-Shadow" clipRule="evenodd"></path>
                                        <path fill="#F4F4F4" fillRule="evenodd" stroke="#000716" strokeWidth="1.5"
                                              d="M65.3254 22.1592h11.4127c4.5783 0 8.3349 3.5015 8.488 7.919l.7503 21.2995c0 2.2703-1.9089 4.1122-4.2619 4.1122H60.3387c-2.3529 0-4.2619-1.8419-4.2619-4.1122l.7503-21.2995c.1583-4.4175 3.9097-7.919 8.4881-7.919h.0102Z"
                                              className="Zcp3PONWSf-Face Zcp3PONWSf-illustration-primary-grey"
                                              clipRule="evenodd"></path>
                                        <path stroke="#000716" strokeWidth="1.5"
                                              d="m85.6817 49.0395-5.8328.5392c-6.176.5734-12.2041.0588-18.38-.5392l-5.1173-.5"
                                              className="Zcp3PONWSf-Mouth"></path>
                                    </g>
                                    <g fill="#0972D3" stroke="#000716" strokeWidth="1.4762"
                                       className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Head Zcp3PONWSf-Eyes Zcp3PONWSf-Right">
                                        <path
                                            d="M65.8651 43.1759c1.2046 0 2.1812-.9766 2.1812-2.1812 0-1.2047-.9766-2.1812-2.1812-2.1812-1.2046 0-2.1812.9765-2.1812 2.1812 0 1.2046.9766 2.1812 2.1812 2.1812ZM80.5643 43.1759c1.2047 0 2.1812-.9766 2.1812-2.1812 0-1.2047-.9765-2.1812-2.1812-2.1812-1.2046 0-2.1812.9765-2.1812 2.1812 0 1.2046.9766 2.1812 2.1812 2.1812Z"
                                            className="Zcp3PONWSf-illustration-primary-blue"></path>
                                    </g>
                                </g>
                                <g stroke="#000716" strokeWidth="1.5"
                                   className="Zcp3PONWSf-Robot Zcp3PONWSf-M Zcp3PONWSf-Body">
                                    <path fill="#F4F4F4"
                                          d="M52.4146 62.5347h34.9669c3.8848 0 6.9374 3.3246 6.6067 7.1953l-3.9696 46.454c-.5542 6.485-5.9805 11.468-12.4898 11.468H62.6593c-6.4692 0-11.8758-4.923-12.4805-11.364l-4.3659-46.5028c-.3652-3.8898 2.6948-7.2505 6.6017-7.2505Z"
                                          className="Zcp3PONWSf-Torso Zcp3PONWSf-illustration-primary-grey"></path>
                                    <g className="Zcp3PONWSf-Control Zcp3PONWSf-box">
                                        <path fill="#D1D5DB"
                                              d="M85.096 83.68H54.8778c-1.446 0-2.6567-1.1077-2.7792-2.5488l-.745-8.6807c-.1373-1.6273 1.142-3.0242 2.7792-3.0242h31.7033c1.6322 0 2.9164 1.3969 2.7792 3.0242l-.745 8.6807c-.1226 1.4411-1.3284 2.5488-2.7792 2.5488h.0049Z"
                                              className="Zcp3PONWSf-Vector"></path>
                                        <path fill="#0972D3"
                                              d="M81.4834 78.5382c1.1342 0 2.0537-.9195 2.0537-2.0538 0-1.1342-.9195-2.0537-2.0537-2.0537-1.1343 0-2.0538.9195-2.0538 2.0537 0 1.1343.9195 2.0538 2.0538 2.0538Z"
                                              className="Zcp3PONWSf-illustration-primary-blue"></path>
                                        <path d="M73.4346 76.313h-19.121" className="Zcp3PONWSf-Vector"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g stroke="#000716" strokeWidth="1.5" className="Zcp3PONWSf-General Zcp3PONWSf-Plant">
                            <path d="M182.763 146.125h30.872"
                                  className="Zcp3PONWSf-Ground Zcp3PONWSf-illustration-plant"></path>
                            <g fill="#037F0C" fillRule="evenodd" strokeLinejoin="round" className="Zcp3PONWSf-Leaves"
                               clipRule="evenodd">
                                <path d="M197.275 145.611s-6.54 1.732-9.712-7.379c0 0 10.118-2.428 9.712 7.379Z"
                                      className="Zcp3PONWSf-illustration-plant"></path>
                                <path d="M197.475 145.596s-.937-8.932 11.63-11.245c0 0 1.129 13.774-11.63 11.245Z"
                                      className="Zcp3PONWSf-illustration-plant"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </Box>
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{bottom: 's'}} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
    {
        label: 'Main Aladdin Properties',
        options: [
            {id: 'Title', label: 'Title', editable: true},
            {id: 'Description', label: 'Description', editable: true},
            {id: 'Owner', label: 'Owner', editable: true},
            {id: 'Site', label: 'ImpactSite', editable: true},
            {id: 'Status', label: 'Status', editable: true},
            {id: 'State', label: 'State', editable: true},
            {id: 'Label', label: 'Label', editable: true},
            {id: 'Category', label: 'Category', editable: true},
            {id: 'Priority', label: 'Priority', editable: true},
            {id: 'ImpactTeam', label: 'ImpactTeam', editable: true},
            {id: 'Submitter', label: 'Submitter', editable: true},
            {id: 'SubmitterAZ', label: 'Site', editable: true},
            {id: 'SubmitDate', label: 'SubmitDate', editable: true},
            {id: 'Like', label: 'Like', editable: false},
            {id: 'SubmitterLogin', label: 'SubmitterLogin', editable: true},
        ]
    }
];
export const SUSTAINABILITY_CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
    {
        label: 'Main Sustainability Properties',
        options: [
            {id: 'BriefDescription', label: 'BriefDescription', editable: true},
            {id: 'Cluster', label: 'Cluster', editable: true},
            {id: 'CompletedDate', label: 'CompletedDate', editable: true},
            {id: 'EnviroOwner', label: 'EnviroOwner', editable: true},
            {id: 'Impact', label: 'Impact', editable: true},
            {id: 'Implementation', label: 'Implementation', editable: true},
            {id: 'InfraOpsOwner', label: 'InfraOpsOwner', editable: true},
            {id: 'Investment', label: 'Investment', editable: true},
            {id: 'ProjectName', label: 'ProjectName', editable: true},
            {id: 'Relevant', label: 'Relevant', editable: true},
            {id: 'StartDate', label: 'StartDate', editable: true},
            {id: 'State', label: 'State', editable: true},
            {id: 'SubmitDate', label: 'SubmitDate', editable: true},
            {id: 'Submitter', label: 'Submitter', editable: false},
            {id: 'ProjectOutcomes', label: 'Project Outcomes', editable: true},
            {id: 'ProjectCosts', label: 'Project Costs & Expenses', editable: true},

        ]
    }
];

export const Kaizen_CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
    {
        label: 'Main Aladdin Properties',
        options: [
            {id: 'Title', label: 'Title', editable: true},
            {id: 'Description', label: 'Description', editable: true},
            {id: 'Owner', label: 'Owner', editable: true},
            {id: 'Site', label: 'ImpactSite', editable: true},
            {id: 'Status', label: 'Status', editable: true},
            {id: 'State', label: 'State', editable: true},
            {id: 'Label', label: 'Label', editable: true},
            {id: 'Category', label: 'Category', editable: true},
            {id: 'Priority', label: 'Priority', editable: true},
            {id: 'ImpactTeam', label: 'ImpactTeam', editable: true},
            {id: 'Submitter', label: 'Submitter', editable: true},
            {id: 'SubmitterAZ', label: 'Site', editable: true},
            {id: 'SubmitDate', label: 'SubmitDate', editable: true},
            {id: 'Like', label: 'Like', editable: false},
            {id: 'PromoteBy', label: 'PromoteBy', editable: true},
            {id: 'PromoteDate', label: 'PromoteDate', editable: true},
            {id: 'PromoteReason', label: 'PromoteReason', editable: true},
            {id: 'SubmitterLogin', label: 'SubmitterLogin', editable: true},
        ]
    }
];

export const PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
    {value: 10, label: '10 Distributions'},
    {value: 30, label: '30 Distributions'},
    {value: 50, label: '50 Distributions'}
];

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
    {value: 'table', label: 'Table'},
    {value: 'cards', label: 'Cards'}
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 30,
    visibleContent: ['Title', 'Description', 'Owner', 'Site', 'Status', 'State', 'Label', 'SubmitDate', 'Like','Submitter'],
    wrapLines: false,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

export const Kaizen_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 30,
    visibleContent: ['SubmitterCluster', 'Title', 'Description', 'Status', 'Label', 'PromoteBy', 'PromoteDate', 'PromoteReason', 'Like'],
    wrapLines: false,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

export const SUSTAINABILITY_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    visibleContent: ['ProjectName', 'BriefDescription', 'Cluster', 'Relevant', 'Impact', 'InfraOpsOwner', 'EnviroOwner', 'StartDate', 'CompletedDate', 'Submitter', 'SubmitDate', "State", 'ProjectOutcomes', 'ProjectCosts'],
    wrapLines: false,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

export const DEFAULT_PREFERENCES_SEARCH: CollectionPreferencesProps.Preferences = {
    pageSize: 30,
    visibleContent: ["Description", "Details"]
};

export const VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
    {
        label: 'Main Aladdin Properties',
        options: [
            {id: 'Description', label: 'Description', editable: true},
            {id: 'Countermeasure', label: 'Countermeasure', editable: true},
            {id: 'Benefit', label: 'Benefit', editable: true},
            {id: 'Details', label: 'Details', editable: true},

        ]
    }
];

export const PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
    {value: 10, label: '10 Distributions'},
    {value: 30, label: '30 Distributions'},
    {value: 50, label: '50 Distributions'}
];

