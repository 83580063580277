import React, {useEffect, useState} from "react";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Link from "@amzn/awsui-components-react/polaris/link";
import Button from "@amzn/awsui-components-react/polaris/button";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import axios from 'axios'
import {useHistory, useParams} from "react-router-dom";
import {EmptyState} from "../TableView/table-config";
import {getMatchesCountText, paginationLabels} from "../table/table-config";
import Table, {TableProps} from "@amzn/awsui-components-react/polaris/table";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import {useCollection} from '@amzn/awsui-collection-hooks';
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import Input from "@amzn/awsui-components-react/polaris/input";
import {ConfirmationModalBox} from "../Common/ModalBox";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import {CHILDREN, CLUSTER, LOCATION} from "../Common/config";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import TokenGroup from "@amzn/awsui-components-react/polaris/token-group";
import {pushToTag, pushToTagsWithList} from "../Common/Token";

export default function PermissionDashboard() {
    const [groupMemberPage, setGroupMemberPage] = useState(false);
    const [loginUser, setLoginUser] = useState('')
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/getuser",
        }).then(res => {
            setLoginUser(res.data.Login)
            setPersonalGroupOwner(res.data.Login)
        })
        setLoading(true)
    }, []);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission",
        }).then(res => {
            setAllItems(res.data)
            setLoading(false)
        })
    }, []);

    const [groupNotice, setGroupNotice] = useState<any>([
        {
            type: "warning",
            content: "The name of Permission Group only is allow to be set Availability Zone.",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setGroupNotice([]),
            id: "message_1"
        }
    ])
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [loading, setLoading] = useState(true)

    const columnDefinitions: any = !groupMemberPage ? [
            {
                id: 'Name',
                header: 'Name',
                cell: item => (<Link
                    onFollow={() => {
                        permissionMemberHandler(item)
                    }
                    }>{item.PermissionName}</Link>),
                minWidth: '180px',
                sortingField: 'PermissionName'
            }, {
                id: 'Cluster',
                cell: item => item.Cluster,
                header: 'Cluster',
                minWidth: '160px',
                sortingField: 'Cluster'
            }, {
                id: 'Description',
                header: 'Description',
                cell: item => item.Description,
                minWidth: '100px'
            }
        ] :
        [
            {
                id: 'Login',
                cell: item => (<Link
                    onFollow={() => {
                        window.open('https://phonetool.amazon.com/users/' + item.Login)
                    }
                    }>{item.Login}</Link>),
                header: 'Login',
                minWidth: '160px',
                sortingField: 'Login'
            },
            {
                id: 'Role',
                cell: item => item.Role,
                header: 'Role',
                minWidth: '160px',
                sortingField: 'Role'
            }, {
            id: 'Description',
            cell: item => item.Description,
            header: 'Description',
            minWidth: '160px'
        }
        ]


    const [allItems, setAllItems] = useState<any[]>()
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [maxId, setMaxId] = useState(0);
    const history = useHistory();
    const [groupModalVisible, setGroupModalVisible] = useState(false);
    const [groupMemberModalVisible, setGroupMemberModalVisible] = useState(false);
    const [roleSelected, setRoleSelected] = useState<any>({label: "Member", value: "Member"})
    const [clusterSelected, setClusterSelected] = useState<any>({label: "BJS", value: "BJS"});
    const [availabilityZoneSelected, setAvailabilityZoneSelected] = useState<any>({label: "BJS11", value: "BJS11"});
    const [availabilityZoneOptions, setAvailabilityZoneOptions] = useState<any>([
        {label: "BJS11", value: "BJS11"},
        {label: "BJS12", value: "BJS12"},
        {label: "BJS20", value: "BJS20"},
        {label: "BJS80", value: "BJS80"},
    ]);
    const [actionMemberModel, setActionMemberModel] = useState('')
    const [descriptionGroup, setDescriptionGroup] = useState("");
    const [descriptionGroupMember, setDescriptionGroupMember] = useState("");
    const [currentName, setCurrentName] = useState("");
    const [currentOwner, setCurrentOwner] = useState("");
    const [memberLogin, setMemberLogin] = useState("");
    const [confirmationModalBoxVisible, setConfirmationModalBoxVisible] = useState(false);
    const [confirmationModalBoxContext, setConfirmationModalBoxContext] = useState(true)
    const [deleteConfirmInputText, setDeleteConfirmInputText] = useState<any>('')
    const [newResourceGroupLoading, setNewResourceGroupLoading] = useState(false)
    const [newResourceGroupMemberLoading, setNewResourceGroupMemberLoading] = useState(false)
    const [adminRole, setAdminRole] = useState(false)
    const [groupType, setGroupType] = useState('cluster')
    const [personalGroupName, setPersonalGroupName] = useState('')
    const [personalGroupDescription, setPersonalGroupDescription] = useState('')
    const [personalGroupMember, setPersonalGroupMember] = useState('')
    const [personalGroupMemberList, setPersonalGroupMemberList] = useState<any>([])
    const [personalGroupOwner, setPersonalGroupOwner] = useState('')
    useEffect(() => {
        setDeleteConfirmInputText('');
    }, [confirmationModalBoxVisible]);
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/admin",
        }).then(res => {
            console.log(res.data)
            setAdminRole(res.data)
        })
    }, []);
    const permissionMemberHandler = (item) => {
        setCurrentName(item.PermissionName)
        setLoading(true)
        setSelectedItems([])
        setGroupMemberPage(true)
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/" + item.PermissionName,
        }).then(res => {
            setAllItems(res.data.UserList)
            setCurrentOwner(res.data.Owner)
            setLoading(false)
        })
    }
    const refreshTable = () => {
        setNewResourceGroupLoading(true)
        setDescriptionGroup('')
        setRefreshTableFlag(true)
        setGroupMemberPage(false)
        setLoading(true)
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission",
        }).then(res => {
            setGroupModalVisible(false)
            setGroupMemberModalVisible(false)
            setSelectedItems([])
            setAllItems(res.data)
            setLoading(false)
            setNewResourceGroupLoading(false)
            setRefreshTableFlag(false)
            setConfirmationModalBoxVisible(false)
        })
    }
    const refreshMember = () => {
        setDescriptionGroupMember('')
        setMemberLogin('')
        setNewResourceGroupMemberLoading(true)
        setLoading(true)
        setGroupMemberPage(true)
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/" + currentName,
        }).then(res => {
            setGroupMemberModalVisible(false)
            setConfirmationModalBoxVisible(false)
            setSelectedItems([])
            setAllItems(res.data.UserList)
            setNewResourceGroupMemberLoading(false)
            setLoading(false)

        })
    }

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action='Please confirm with system admin.'
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {
                defaultState: {
                    sortingColumn: columnDefinitions[0],
                }
            }
        }
    );

    const handleGroupSubmit = () => {
        axios({
            method: actionMemberModel == 'Add' ? "POST" : 'PUT',
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission",
            data:
                groupType == 'cluster' ? {
                        "PermissionName": availabilityZoneSelected['value'],
                        "Cluster": clusterSelected['value'],
                        "Description": descriptionGroup,
                        "Owner": loginUser,
                        "GroupType": groupType
                    } :
                    {
                        "PermissionName": personalGroupName,
                        "Cluster": 'Personal',
                        "Owner": personalGroupOwner,
                        "Description": personalGroupDescription,
                        "UserList": personalGroupMemberList,
                        "GroupType": groupType
                    }
        }).then(res => {
            res.data ?
                refreshTable() :
                alert("Permission Group already exist!")
        })
    }
    const handleGroupMemberSubmit = () => {
        axios({
            method: actionMemberModel == 'Add' ? "POST" : 'PUT',
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/member",
            data: {
                "PermissionName": currentName,
                "Login": memberLogin,
                "Role": roleSelected['value'],
                "Description": descriptionGroupMember
            }
        }).then(res => {
            console.log(res.data)
            res.data == 'success' ?
                refreshMember() :
                (res.data == 'exist' ? alert("Permission User already exist!") : alert("The User is not exist!"))
        })
    }
    // const {selectedItems} = collectionProps;
    const deleteGroup = () => {
        let data: any = []
        selectedItems.map((item) => {
            data.push(item.PermissionName)
        })
        axios({
            method: "DELETE",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission",
            data: data,
        }).then(res => {
            res.data == 'success' ?
                refreshTable() :
                alert("You don't have permission to delete it!")
        })
    }
    const deleteGroupMember = () => {
        axios({
            method: "DELETE",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/member",
            data: {member: selectedItems, permission: currentName},
        }).then(res => {
            res.data == 'success' ?
                refreshMember() :
                alert("You don't have permission to delete it!")
        })
    }
    useEffect(() => {
        setAvailabilityZoneSelected(availabilityZoneOptions[0])
    }, [availabilityZoneOptions])
    const isMultiple = selectedItems.length > 1
    const deleteConsentText = 'confirm';
    return (
        <>
            {loginUser != "" && allItems ? (allItems.length > 0 ? <ContentLayout
                    header={
                        <SpaceBetween size="m">
                            <Header
                                variant="h1"
                                info={<Link>Info</Link>}
                                description="This is used to manage permission group and user."
                            >
                                Permission Control center
                            </Header>
                        </SpaceBetween>
                    }
                >
                    <Table
                        {...collectionProps}
                        selectedItems={selectedItems}
                        onSelectionChange={({detail}) =>
                            setSelectedItems(detail.selectedItems)}
                        loading={loading}
                        loadingText="Loading instances"
                        selectionType="multi"
                        header={
                            <Header
                                counter={
                                    allItems &&
                                    (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                                }
                                actions={
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        {!groupMemberPage ? <Button onClick={refreshTable} loading={refreshTableFlag}><Icon
                                                name="refresh"/>&ensp; Refresh</Button> :
                                            <Button onClick={refreshTable} loading={refreshTableFlag}><Icon
                                                name="redo"/>&ensp; Back</Button>}
                                        {!groupMemberPage ?
                                            (
                                                <ButtonDropdown
                                                    items={[
                                                        {
                                                            text: "Deactivate",
                                                            id: "rm",
                                                            disabled: true
                                                        },
                                                        {
                                                            text: "Activate",
                                                            id: "mv",
                                                            disabled: true
                                                        },
                                                        {
                                                            text: "View details",
                                                            id: "rm",
                                                            disabled: true
                                                        },
                                                        {
                                                            text: "Edit",
                                                            id: "edit",
                                                            disabled: !(selectedItems.length == 1)
                                                        },
                                                        {
                                                            text: "Delete",
                                                            id: "delete",
                                                            disabled: !(selectedItems.length >= 1)
                                                        }
                                                    ]}
                                                    onItemClick={(item) => {
                                                        if (item.detail.id === 'delete') {
                                                            console.log(selectedItems)
                                                            setConfirmationModalBoxVisible(true)
                                                            setConfirmationModalBoxContext(true)
                                                        }

                                                        if (item.detail.id === 'edit') {
                                                            setClusterSelected({
                                                                label: selectedItems[0].Cluster,
                                                                value: selectedItems[0].Cluster
                                                            })
                                                            setAvailabilityZoneSelected({
                                                                label: selectedItems[0].PermissionName,
                                                                value: selectedItems[0].PermissionName
                                                            })
                                                            setDescriptionGroup(selectedItems[0].Description)
                                                            setActionMemberModel('Edit')
                                                            setGroupModalVisible(true)
                                                        }
                                                    }}
                                                >
                                                    Actions
                                                </ButtonDropdown>) : (<ButtonDropdown
                                                items={[
                                                    {
                                                        text: "Deactivate",
                                                        id: "rm",
                                                        disabled: true
                                                    },
                                                    {
                                                        text: "Activate",
                                                        id: "mv",
                                                        disabled: true
                                                    },
                                                    {
                                                        text: "View details",
                                                        id: "rm",
                                                        disabled: true
                                                    },
                                                    {
                                                        text: "Edit",
                                                        id: "edit",
                                                        disabled: !(selectedItems.length == 1)
                                                    },
                                                    {
                                                        text: "Delete",
                                                        id: "delete",
                                                        disabled: !(selectedItems.length >= 1)
                                                    }
                                                ]}
                                                onItemClick={(item) => {
                                                    if (item.detail.id === 'delete') {
                                                        setConfirmationModalBoxVisible(true)
                                                        setConfirmationModalBoxContext(false)
                                                    }

                                                    if (item.detail.id === 'edit') {
                                                        setMemberLogin(selectedItems[0].Login)
                                                        setRoleSelected({
                                                            label: selectedItems[0].Role,
                                                            value: selectedItems[0].Role
                                                        })
                                                        setDescriptionGroupMember(selectedItems[0].Description)
                                                        setActionMemberModel('Edit')
                                                        setGroupMemberModalVisible(true)
                                                    }
                                                }}
                                            >
                                                Actions
                                            </ButtonDropdown>)
                                        }
                                        {!groupMemberPage ?
                                            (<Button variant="primary" onClick={() => {
                                                setActionMemberModel('Add')
                                                setGroupModalVisible(true)
                                            }}>
                                                Create resource
                                            </Button>) : (
                                                <Button variant="primary" onClick={() => {
                                                    setActionMemberModel('Add')
                                                    setGroupMemberModalVisible(true)
                                                }}>
                                                    Create resource
                                                </Button>)
                                        }


                                    </SpaceBetween>
                                }
                            >
                                Records
                            </Header>
                        }
                        columnDefinitions={columnDefinitions}
                        items={items}
                        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                        filter={
                            <TextFilter
                                {...filterProps}
                                countText={getMatchesCountText(filteredItemsCount!)}
                                filteringAriaLabel="Filter records"
                            />
                        }
                    />

                </ContentLayout> : <SpaceBetween size="xs">
                    <Box textAlign="center">
                        <img width='500px'
                             src='https://maxis-service-prod-dub.amazon.com/issues/8f158fd4-6951-463c-b3ec-87d77ead7ba8/attachments/9877ff46d60cd4e1bd0596dca0086afd2723326b60d67b4122edd0be3f913340_dcf9afac-7c9d-4c58-a54e-6ded1fd405a5'/>
                    </Box>
                    <Box textAlign="center" variant="div">
                        <h1 style={{color: '#0073bb'}}>You are not authorized to access.</h1></Box>
                    <Box textAlign="center" variant="p">
                        You must be granted access.
                        <br/>
                        To request access, <Link external href="https://t.corp.amazon.com/">
                        please read the delegation instructions.
                    </Link>
                    </Box>
                </SpaceBetween>) :
                <Box textAlign="center" margin={{top: "xxxl"}}>
                    <Spinner size="large"/>
                </Box>}

            <Modal
                size="large"
                onDismiss={() => setGroupModalVisible(false)}
                visible={groupModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setGroupModalVisible(false)}>Cancel</Button>
                            <Button variant="primary" loading={newResourceGroupLoading} onClick={handleGroupSubmit}>
                                Submit
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Add New Resource Group"
            >
                <Tabs
                    onChange={({detail}) => setGroupType(detail.activeTabId)}
                    tabs={[
                        {
                            label: "Cluster Permission Group",
                            id: "cluster",
                            content:
                                <SpaceBetween size="xs">
                                    <Flashbar items={groupNotice}/>
                                    <FormField
                                        label="Cluster"
                                        // stretch={true}
                                    >
                                        <Select
                                            filteringType="auto"
                                            selectedOption={clusterSelected}
                                            onChange={({detail}) => {
                                                setClusterSelected(detail.selectedOption)
                                                detail.selectedOption.value ? setAvailabilityZoneOptions(pushToTagsWithList(Object.keys(LOCATION[detail.selectedOption.value]))) : null

                                            }}
                                            options={pushToTagsWithList(CLUSTER)}
                                        />
                                    </FormField>
                                    <FormField
                                        // stretch={true}
                                        label="Permission Name"
                                    >
                                        <Select
                                            filteringType="auto"
                                            selectedOption={availabilityZoneSelected}
                                            onChange={({detail}) => {
                                                // setClusterSelected(detail)
                                                setAvailabilityZoneSelected(detail.selectedOption)
                                            }}
                                            options={availabilityZoneOptions}
                                        />
                                    </FormField>
                                    <FormField
                                        stretch={true}
                                        description=""
                                        label={
                                            <span>
                            Description <i>- optional</i>{" "}
                            </span>
                                        }
                                    >
                                        <Textarea value={descriptionGroup} rows={5}
                                                  onChange={({detail}) => setDescriptionGroup(detail.value)}/>
                                    </FormField>
                                </SpaceBetween>
                        },
                        {
                            label: "Personal Permission Group",
                            id: "personal",
                            content:
                                <SpaceBetween size="xs">
                                    <FormField
                                        label="Personal Permission Group Name"
                                        // stretch={true}
                                    >
                                        <Input
                                            value={personalGroupName}
                                            onChange={({detail}) => {
                                                setPersonalGroupName(detail.value)
                                            }}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Group Owner"
                                        // stretch={true}
                                    >
                                        <Input
                                            value={personalGroupOwner}
                                            onChange={({detail}) => {
                                                setPersonalGroupOwner(detail.value)
                                            }}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Group Members"
                                        secondaryControl={<Button
                                            onClick={() => {
                                                setPersonalGroupMemberList([...personalGroupMemberList, personalGroupMember])
                                                setPersonalGroupMember('')
                                            }}>
                                            <Icon name="add-plus"/>&nbsp;Add
                                        </Button>}
                                        // stretch={true}
                                    >
                                        <Input
                                            value={personalGroupMember}
                                            onChange={({detail}) => {
                                                setPersonalGroupMember(detail.value)
                                            }}
                                        />
                                    </FormField>
                                    <TokenGroup
                                        onDismiss={({detail: {itemIndex}}) => {
                                            setPersonalGroupMemberList([
                                                ...personalGroupMemberList.slice(0, itemIndex),
                                                ...personalGroupMemberList.slice(itemIndex + 1)
                                            ]);
                                        }}
                                        items={pushToTagsWithList(personalGroupMemberList)}
                                    />
                                    <FormField
                                        stretch={true}
                                        description=""
                                        label={
                                            <span>
                            Description <i>- optional</i>{" "}
                            </span>
                                        }
                                    >
                                        <Textarea value={personalGroupDescription} rows={5}
                                                  onChange={({detail}) => setPersonalGroupDescription(detail.value)}/>
                                    </FormField>
                                </SpaceBetween>
                        }
                    ]}
                />
            </Modal>


            <Modal
                onDismiss={() => setGroupMemberModalVisible(false)}
                size="large"
                visible={groupMemberModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setGroupMemberModalVisible(false)}>Cancel</Button>
                            <Button variant="primary" loading={newResourceGroupMemberLoading}
                                    disabled={!((currentName != "AladdinAdministrators" && currentName != "OE Process Engineer") && (currentOwner == loginUser || adminRole))}
                                    onClick={handleGroupMemberSubmit}>
                                Submit
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Add New Resource Member"
            >
                <SpaceBetween size="xs">
                    {currentName == "AladdinAdministrators" || currentName == "OE Process Engineer" ? <Flashbar items={[
                        {
                            type: "error",
                            content: "You don't have permission to add new resource member for " + currentName,
                            dismissible: true,
                            dismissLabel: "Dismiss message",
                            onDismiss: () => setGroupNotice([]),
                            id: "message_1"
                        }
                    ]}/> : (currentOwner == loginUser || adminRole ? null : <Flashbar items={[
                        {
                            type: "error",
                            content: "You don't have permission to add new resource member for " + currentName,
                            dismissible: true,
                            dismissLabel: "Dismiss message",
                            onDismiss: () => setGroupNotice([]),
                            id: "message_1"
                        }
                    ]}/>)}
                    <FormField
                        stretch={true}
                        label="Member Login"
                    >
                        <Input
                            onChange={({detail}) => setMemberLogin(detail.value)}
                            value={memberLogin}
                        />
                    </FormField>
                    <FormField
                        stretch={true}
                        label="Role"
                    >
                        <Select
                            selectedOption={roleSelected}
                            onChange={({detail}) => {
                                setRoleSelected(detail.selectedOption)
                            }}
                            options={[
                                {
                                    label: "Member", value: "Member"
                                }, {
                                    label: "Owner", value: "Owner"
                                }, {
                                    label: "POC", value: "POC"
                                }, {
                                    label: "Manager", value: "Manager"
                                }
                            ]}
                        />
                    </FormField>
                    <FormField
                        stretch={true}
                        description=""
                        label={
                            <span>
          Description <i>- optional</i>{" "}
        </span>
                        }
                    >
                        <Textarea value={descriptionGroupMember} rows={5}
                                  onChange={({detail}) => setDescriptionGroupMember(detail.value)}/>
                    </FormField>
                </SpaceBetween>
            </Modal>
            <ConfirmationModalBox id='ConfirmationModalBox' visible={confirmationModalBoxVisible}
                                  onDismiss={() => setConfirmationModalBoxVisible(false)}
                                  disabled={!(deleteConfirmInputText == deleteConsentText)}
                                  content={confirmationModalBoxContext ?
                                      selectedItems.length > 0 && (
                                          <SpaceBetween size="m">
                                              {isMultiple ? (
                                                  <Box variant="span">
                                                      Permanently delete group{' '}
                                                      <Box variant="span" fontWeight="bold">
                                                          {selectedItems.length} instances
                                                      </Box>
                                                      ? You can’t undo this action.
                                                  </Box>
                                              ) : (
                                                  <Box variant="span">
                                                      Permanently delete group{' '}
                                                      <Box variant="span" fontWeight="bold">
                                                          {selectedItems[0].PermissionName}
                                                      </Box>
                                                      ? You can’t undo this action.
                                                  </Box>
                                              )}

                                              <Alert type="warning"
                                                     statusIconAriaLabel="Warning">
                                                  Proceeding with this action will delete the
                                                  {isMultiple ? ' permission group with all their group members' : ' permission group with all its group members'} and
                                                  can affect related permission.{' '}
                                                  <Link external={true} href="#"
                                                        ariaLabel="Learn more about permission management, opens in new tab">
                                                      Learn more
                                                  </Link>
                                              </Alert>

                                              <Box>To avoid accidental deletions, we ask you
                                                  to provide additional written consent.</Box>


                                              <FormField
                                                  label={`To confirm this deletion, type "${deleteConsentText}".`}>
                                                  <ColumnLayout columns={2}>
                                                      <Input
                                                          placeholder={deleteConsentText}
                                                          onChange={({detail}) => setDeleteConfirmInputText(detail.value)}
                                                          value={deleteConfirmInputText}
                                                      />
                                                  </ColumnLayout>
                                              </FormField>
                                          </SpaceBetween>
                                      )
                                      : selectedItems.length > 0 && (
                                      <SpaceBetween size="m">
                                          {isMultiple ? (
                                              <Box variant="span">
                                                  Permanently delete group member{' '}
                                                  <Box variant="span" fontWeight="bold">
                                                      {selectedItems.length} instances
                                                  </Box>
                                                  ? You can’t undo this action.
                                              </Box>
                                          ) : (
                                              <Box variant="span">
                                                  Permanently delete group member{' '}
                                                  <Box variant="span" fontWeight="bold">
                                                      {selectedItems[0].Login}
                                                  </Box>
                                                  ? You can’t undo this action.
                                              </Box>
                                          )}

                                          <Alert type="warning"
                                                 statusIconAriaLabel="Warning">
                                              Proceeding with this action will delete the
                                              {isMultiple ? ' permission group members' : ' permission group member'} and
                                              can affect related permission.{' '}
                                              <Link external={true} href="#"
                                                    ariaLabel="Learn more about permission management, opens in new tab">
                                                  Learn more
                                              </Link>
                                          </Alert>

                                          <Box>To avoid accidental deletions, we ask you
                                              to provide additional written consent.</Box>


                                          <FormField
                                              label={`To confirm this deletion, type "${deleteConsentText}".`}>
                                              <ColumnLayout columns={2}>
                                                  <Input
                                                      placeholder={deleteConsentText}
                                                      onChange={({detail}) => setDeleteConfirmInputText(detail.value)}
                                                      value={deleteConfirmInputText}
                                                  />
                                              </ColumnLayout>
                                          </FormField>
                                      </SpaceBetween>
                                  )
                                  }
                                  header={confirmationModalBoxContext ? "Delete Resource Group" : "Delete Resource Member"}
                                  confirm={confirmationModalBoxContext ? deleteGroup : deleteGroupMember}
                                  cancel={() => setConfirmationModalBoxVisible(false)}/>

        </>

    )

}