import Grid from "@amzn/awsui-components-react/polaris/grid";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Link from "@amzn/awsui-components-react/polaris/link";
import Input from '@amzn/awsui-components-react/polaris/input';
import {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import axios from 'axios'

export default function FunctionIndex() {
    const [loginUser, setLoginUser] = useState('')
    const history = useHistory()
    const handleLibaryClick = () => {
        history.push('/PainPoint & GoodIdea')
    }

    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);
    const [value, setValue] = useState("");
    const cleanDraft = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/cleandraft",
        }).then(res => {
            console.log(res)
        })
    }
    return (
        <Grid
            gridDefinition={[
                {colspan: 12},
                {colspan: 4},
                {colspan: 4},
                {colspan: 4},
                {colspan: 4},
                {colspan: 4},
                {colspan: 4}
            ]}
        >
            <div>
                <Container
                    header={
                        <Header variant="h3" actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button>User Guide</Button>
                                <Button variant="primary" onClick={() => cleanDraft}>
                                    Contact Us
                                </Button>
                                <SpaceBetween size="xs">
                                    <strong>Hi, {loginUser}</strong>
                                    <img width='50px'
                                         src={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + loginUser}/>
                                </SpaceBetween>
                            </SpaceBetween>
                        }
                                description={<strong>Brought to you by <Link href="#">Origin Experience</Link></strong>}
                        >Welcome to PainPoint
                            Dashboard</Header>
                    }
                >
                    <Input
                        onChange={({detail}) => setValue(detail.value)}
                        value={value}
                        placeholder="Search"
                        type="search"
                    />
                </Container>
            </div>
            <div>
                <Container
                    header={
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">

                                <img src='https://rzp.dcsa.aws.dev/work.8203e6e6.png'/>
                                <Header
                                    variant="h2"
                                    description={<span>Hi, we can see PP in this!</span>}
                                >
                                    Pain Point Library
                                </Header>
                            </SpaceBetween>
                        </div>
                    }
                >
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="medium"
                        triggerType="custom"
                        content={
                            <strong>Code snippet copiedCode snippet copiedCode snippet copiedCode snippet copiedCode
                                snippet copiedCode snippet copiedCode snippet copied</strong>

                        }>
                        <strong style={{cursor: "pointer"}}>Code snippet copiedCode snippet copiedCode snippet
                            copiedCode snippet copiedCode snippet copied...</strong><Icon
                        name="flag"/>
                    </Popover>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" onClick={() => handleLibaryClick()}>Open App
                        </Button>
                    </SpaceBetween>
                </Container>
            </div>
            <div>
                <Container
                    header={
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">

                                <img src='https://rzp.dcsa.aws.dev/data-center.d35e23d6.png'/>
                                <Header
                                    variant="h2"
                                    description={<span>Hi, we can see PP in this!</span>}
                                >
                                    Pending build
                                </Header>
                            </SpaceBetween>
                        </div>
                    }
                >
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="medium"
                        triggerType="custom"
                        content={

                            <strong>Code snippet copiedCode snippet copiedCode snippet copiedCode snippet copiedCode
                                snippet copiedCode snippet copiedCode snippet copied</strong>

                        }
                    >
                        <strong style={{cursor: "pointer"}}>Code snippet copiedCode snippet copied...</strong>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Primary button
                            </Button>
                        </SpaceBetween>
                    </Popover>
                </Container>
            </div>
            <div>
                <Container
                    header={
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">

                                <img src='https://rzp.dcsa.aws.dev/data-center.d35e23d6.png'/>
                                <Header
                                    variant="h2"
                                    description={<span>Hi, we can see PP in this!</span>}
                                >
                                    Pending build
                                </Header>
                            </SpaceBetween>
                        </div>
                    }
                >
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="medium"
                        triggerType="custom"
                        content={

                            <strong>Code snippet copiedCode snippet copiedCode snippet copiedCode snippet copiedCode
                                snippet copiedCode snippet copiedCode snippet copied</strong>

                        }
                    >
                        <strong style={{cursor: "pointer"}}>Code snippet copiedCode snippet copied...</strong>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Primary button
                            </Button>
                        </SpaceBetween>
                    </Popover>
                </Container>
            </div>
            <div>
                <Container
                    header={
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">

                                <img src='https://rzp.dcsa.aws.dev/data-center.d35e23d6.png'/>
                                <Header
                                    variant="h2"
                                    description={<span>Hi, we can see PP in this!</span>}
                                >
                                    Pending build
                                </Header>
                            </SpaceBetween>
                        </div>
                    }
                >
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="medium"
                        triggerType="custom"
                        content={

                            <strong>Code snippet copiedCode snippet copiedCode snippet copiedCode snippet copiedCode
                                snippet copiedCode snippet copiedCode snippet copied</strong>

                        }
                    >
                        <strong style={{cursor: "pointer"}}>Code snippet copiedCode snippet copied...</strong>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Primary button
                            </Button>
                        </SpaceBetween>
                    </Popover>
                </Container>
            </div>
            <div>
                <Container
                    header={
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">

                                <img src='https://rzp.dcsa.aws.dev/data-center.d35e23d6.png'/>
                                <Header
                                    variant="h2"
                                    description={<span>Hi, we can see PP in this!</span>}
                                >
                                    Pending build
                                </Header>
                            </SpaceBetween>
                        </div>
                    }
                >
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="medium"
                        triggerType="custom"
                        content={

                            <strong>Code snippet copiedCode snippet copiedCode snippet copiedCode snippet copiedCode
                                snippet copiedCode snippet copiedCode snippet copied</strong>

                        }
                    >
                        <strong style={{cursor: "pointer"}}>Code snippet copiedCode snippet copied...</strong>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Primary button
                            </Button>
                        </SpaceBetween>
                    </Popover>
                </Container>
            </div>
            <div>
                <Container
                    header={
                        <div>
                            <SpaceBetween direction="horizontal" size="xs">

                                <img src='https://rzp.dcsa.aws.dev/data-center.d35e23d6.png'/>
                                <Header
                                    variant="h2"
                                    description={<span>Hi, we can see PP in this!</span>}
                                >
                                    Pending build
                                </Header>
                            </SpaceBetween>
                        </div>
                    }
                >
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="medium"
                        triggerType="custom"
                        content={

                            <strong>Code snippet copiedCode snippet copiedCode snippet copiedCode snippet copiedCode
                                snippet copiedCode snippet copiedCode snippet copied</strong>

                        }
                    >
                        <strong style={{cursor: "pointer"}}>Code snippet copiedCode snippet copied...</strong>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Primary button
                            </Button>
                        </SpaceBetween>
                    </Popover>
                </Container>
            </div>

        </Grid>
    );
}