import {Breadcrumbs, ServiceNavigation} from "../navigation";
import AlertBar from "../Common/Bars";
import ToolsPanel from "../TableView/ToolsPanel";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import {appLayoutLabels} from "../Common/labels";
// The content in the main content area of the App layout
import React, {useState} from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import FunctionIndex from "../Common/FuntionIndex";
import SelfTopNavigation from "../topNavigation/topNavigation";


export default function HomepageContent() {
    // return (
    //   <>
    //     <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
    //       <Grid
    //         gridDefinition={[
    //           { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
    //         ]}
    //       >
    //         <SpaceBetween size="l">
    //           <div>
    //             <Header variant="h1" headingTagOverride="h2">
    //               PainPoint & Good Idea
    //             </Header>
    //             <Container>
    //               <DetailsTable/>
    //             </Container>
    //           </div>
    //         </SpaceBetween>
    //       </Grid>
    //     </Box>
    //   </>
    // );
    const [toolsOpen, setToolsOpen] = useState(false);
    const toolsChangeHandler = () => {
        if (toolsOpen) {
            setToolsOpen(false);
        } else {
            setToolsOpen(true);
        }
    };
    return (
        <AppLayout
            headerSelector='#h'
            navigation={<ServiceNavigation/>}
            // flash notification for demo purposes
            breadcrumbs={<Breadcrumbs items={[{text: 'CardView Index', href: ''}]}/>}
            // notifications={<AlertBar type={'info'} header={'Aladdin DashBoard Lunch New Version'}
            //                          content={'From April 1st, all PainPoint/GoodIdea submissions will use the Aladdin Dashboard. Data before April 1st will be gradually synchronized from Quip to the System.'} dismissible={true}/>}
            content={<div>
                <ContentLayout>
                    <br/>
                    <FunctionIndex/>
                </ContentLayout>
                {/*<FuntionCards/>*/}
            </div>}
            contentType="table"
            toolsOpen={toolsOpen}
            onToolsChange={toolsChangeHandler}
            tools={<ToolsPanel/>}
            ariaLabels={appLayoutLabels}
        />
    )
}
