// Promise polyfill
import 'core-js/features/promise';

import {render} from 'react-dom';
import {HashRouter} from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import {getMidwayJwtToken} from './auth/MidwayJwtToken';
import axios from 'axios';
import {initializeAppSettings} from './config/AppSettings';
import {I18nProvider} from '@amzn/awsui-components-react/polaris/i18n';
import enMessages from '@amzn/awsui-components-react/polaris/i18n/messages/all.en';

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
    const jwtToken = await getMidwayJwtToken();
    config.headers!.Authorization = `Bearer ${jwtToken}`
    return config;
});

(async () => {
    // Make sure Midway is present before rendering
    await getMidwayJwtToken();

    // Initialize application settings
    const appSettings = (await axios('/settings.json')).data;
    initializeAppSettings(appSettings);

    render(
        <HashRouter>
            <I18nProvider locale="en" messages={[enMessages]}>
                <App/>
            </I18nProvider>
        </HashRouter>,
        document.querySelector('#app')
    );
})();
