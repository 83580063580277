import {CSVLink} from "react-csv";
import Link from "@amzn/awsui-components-react/polaris/link";
import * as XLSX from "xlsx";

export interface CSVDataProps {
    data: any
    headers: any
    filename: any
}

export default function ExportCSV(props: CSVDataProps) {
    return (
        <div><CSVLink data={props.data} headers={props.headers} filename={props.filename}
                      className="btn btn-primary ExportCSV"><Link variant="info">Export Report</Link></CSVLink></div>
    )
}

export function Export(data, head, fileName) {
    let header = ''
    for (let i in head) {
        header += head[i] + ','
    }
    const table = header + data.map((row) => {
        let line = ''
        for (let i in row) {
            if (i == 'id') {
                line += 'https://aladdin.portal.amazon.dev/#/detail/' + row[i] + ','
            } else {
                line += row[i].replaceAll(/\n/g, '\\n') + ','
            }
        }
        console.log(line)
        return line
    }).join('\n')
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(table);
    var link = document.createElement("a");
    link.href = uri;
    link.download = fileName + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
}

export function downloadAsExcelFile(data, headers, fileName) {
    let orderedData: any[] = [];
    data.forEach((row: any) => {
        let orderedRow: any = {};
        headers.forEach(column => {
            if (column == 'id') {
                orderedRow[column] = 'https://aladdin.portal.amazon.dev/#/detail/' + row[column]
            } else {
                orderedRow[column] = row[column]
            }
        })
        orderedData.push(orderedRow);
    });
    const worksheet = XLSX.utils.json_to_sheet(orderedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedAnalysis");
    XLSX.writeFile(workbook, fileName + '.xlsx');
}