import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Button from '@amzn/awsui-components-react/polaris/button';

interface ModalBoxProps {
    id: string
    visible: boolean
    onDismiss: () => void
    content: any
    header: string
    confirm: () => void
    cancel: () => void
}

export default function ModalBox(props: ModalBoxProps) {
    return (
        <Modal
            onDismiss={props.onDismiss}
            visible={props.visible}
            className="ModelPainPointDetails"
            closeAriaLabel="Close modal"
            id={props.id}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={props.cancel}>Cancel</Button>
                        <Button variant="primary" onClick={props.confirm}>
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={props.header}
        >
            <TextContent>
                {props.content}
            </TextContent>
        </Modal>
    )
}

interface ConfirmationModalBoxProps {
    id: string
    visible: boolean
    onDismiss: () => void
    content: any
    header: string
    confirm: () => void
    cancel: () => void
    disabled: boolean
}

export function ConfirmationModalBox(props: ConfirmationModalBoxProps) {

    return (
        <Modal
        size="large"
        onDismiss={props.onDismiss}
        visible={props.visible}
        className="ModelPainPointDetails"
        closeAriaLabel="Close modal"
        id={props.id}
        footer={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={props.cancel}>Cancel</Button>
                    <Button variant="primary" onClick={props.confirm} disabled={props.disabled}>
                        Confirm
                    </Button>
                </SpaceBetween>
            </Box>
        }
        header={props.header}
    >
        <TextContent>
            {props.content}
        </TextContent>
    </Modal>)
}


