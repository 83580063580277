import React, {useEffect, useState} from 'react';
import axios from 'axios'
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from '@amzn/awsui-components-react/polaris/container';
import {SelectFormField, SelectPerferences, ToggleForm} from "../Common/FormField";
import {MultiselectProps} from "@amzn/awsui-components-react/polaris/multiselect";
import Form from "@amzn/awsui-components-react/polaris/form";
import {useHistory} from "react-router-dom";
import {CHILDREN, CLUSTER, LOCATION} from "../Common/config";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import '../Common/react-mentions.scss'
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import {pushToTag, pushToTagsWithList} from "../Common/Token";

export default function PreferencesPage() {
    const history = useHistory()
    const [allLocation, setAllLocation] = useState<any>(LOCATION)
    const [childrenLocationList, setChildrenLocationList] = useState<any>(CHILDREN)
    const [loginUser, setLoginUser] = useState('')
    const [DarkMode, setDarkMode] = useState(false)
    const [SelectCluster, setSelectCluster] = useState<MultiselectProps.Options>([])
    const [SelectAZ, setSelectAZ] = useState<any>([])
    const [SelectSite, setSelectSite] = useState<MultiselectProps.Options>([])
    const [SelectTeams, setSelectTeams] = useState<any>({})

    const [checkedGlobal, setCheckedGlobal] = useState(false);
    const [checkedLocal, setCheckedLocal] = useState(false);

    const pushValues = (pushList) => {
        const ValueList: any = []
        pushList.map((item) => {
            ValueList.push(item['value'])
        })
        return ValueList
    }
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/getuser",
        }).then(res => {
            console.log(res.data)
            res.data.Team == '' ?
                setSelectTeams({value: '', label: 'All'}) :
                setSelectTeams({value: res.data.Team, label: res.data.Team})
            setSelectCluster(pushToTagsWithList(res.data.Cluster))
            console.log(res.data)
            setSelectAZ(pushToTagsWithList(res.data.AZ))
            setSelectSite(pushToTagsWithList(res.data.Site))
            if (res.data.hasOwnProperty('Subscription')) {
                console.log(res.data.Subscription)
                res.data.Subscription.indexOf('Global Kaizen') > -1 ? setCheckedGlobal(true) : null
                res.data.Subscription.indexOf('Global Kaizen') > -1 && res.data.Subscription.length > 1 ? setCheckedLocal(true) : (res.data.Subscription.indexOf('Global Kaizen') < 0 && res.data.Subscription.length > 0 ? setCheckedLocal(true) : null)
            }
        })
    }, []);
    useEffect(() => {
        const selectCluster: any = []
        const OptionsAZ: any = []
        if (SelectCluster.length > 0) {
            SelectCluster.map((item) => {
                selectCluster.push(item['value'])
            })
            selectCluster.map((item) => {
                Object.keys(allLocation[item]).map((az) => {
                    OptionsAZ.push({
                        label: az,
                        value: az
                    })
                })
            })
            setAZOptions([
                {
                    label: "Select All",
                    options: OptionsAZ
                }])
        } else {
            setAZOptions([])
        }
    }, [SelectCluster])
    useEffect(() => {
        const selectAZ: any = []
        const OptionsSite: any = []
        if (SelectAZ.length > 0) {
            SelectAZ.map((item) => {
                childrenLocationList[item.value].map((site) => selectAZ.push(site))
            })
            selectAZ.sort()
            selectAZ.map((item) => {
                OptionsSite.push({
                    label: item,
                    value: item
                })
            })
            setSiteOptions([{
                label: "Select All",
                options: OptionsSite
            }])
        } else {
            setSiteOptions([])
        }
    }, [SelectAZ])

    const [ClusterOptions, setClusterOptions] = useState<MultiselectProps.Options>(pushToTagsWithList(CLUSTER))
    const [AZOptions, setAZOptions] = useState<MultiselectProps.Options>([])
    const [SiteOptions, setSiteOptions] = useState<MultiselectProps.Options>([])
    const [TeamsOptions, setTeamsOptions] = useState<MultiselectProps.Options>([
        {
            value: "",
            label: "All"
        },
        {
            value: "DCO",
            label: "DCO"
        }, {
            value: "DCEO",
            label: "DCEO"
        }, {
            value: "Security",
            label: "Security"
        }, {
            value: "ID",
            label: "ID"
        }, {
            value: "Logistics",
            label: "Logistics"
        },{
            value: "Decom Tech",
            label: "Decom Tech"
        }
    ])

    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLoginUser(res.data.authorizer.lambda.midwayUser)
        })
    }, []);
    const HandleSelectCluster = (detail) => {
        setSelectCluster(detail)
        setAZOptions([])
        setSiteOptions([])

    }
    const HandleSelectAZ = (detail) => {
        setSelectAZ(detail)
        setSiteOptions([])
    }
    const HandleSelectSite = (detail) => {
        setSelectSite(detail)
    }
    const HandleSelectTeams = (detail) => {
        setSelectTeams(detail)
    }

    const submitAction = (e) => {
        let subscription: string[] = []
        checkedGlobal ? subscription = [...subscription, 'Global Kaizen'] : null
        checkedLocal ? subscription = [...subscription, ...pushValues(SelectCluster)] : null
        console.log(subscription)
        const submitDetail = {
            Cluster: pushValues(SelectCluster),
            AZ: pushValues(SelectAZ),
            Site: pushValues(SelectSite),
            Team: SelectTeams.value ? SelectTeams.value : '',
            Subscription: subscription
        }
        axios({
            method: "POST",
            data: submitDetail,
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/updateuser",
        }).then(res => {
            history.push('/PainPoint & GoodIdea')
        })
    }
    const resetAction = () => {
        setDarkMode(false)
        document.body.classList.remove('awsui-polaris-dark-mode')
    }
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        description="Pesonal Perferences Setting."
                        actions={
                            <Button
                                href='https://w.amazon.com/bin/view/G_China_Infra_Ops/BJSPEK/DCO/Project/AladdinPortal/'
                                variant="primary"
                                target="_blank">Help</Button>
                        }
                    >
                        Perferences Setting
                    </Header>
                </SpaceBetween>
            }>
            {/*<Container>*/}
            {/*<MentionsInput*/}
            {/*    data-testid="directive-payload-input"*/}
            {/*    value={value}*/}
            {/*    allowSpaceInQuery={true}*/}
            {/*    onChange={(e) => setValue(e.target.value)}>*/}
            {/*    <Mention*/}
            {/*        // trigger={/(?:^|\s|.*)(<([^\s<]*))$/}*/}
            {/*        trigger='@'*/}
            {/*        data={users}*/}
            {/*        markup="<__id__@@__display__@@>"*/}
            {/*        displayTransform={(id, display) => `<${display}>`}*/}
            {/*        style={{backgroundColor: '#cee4e5'}}*/}
            {/*        renderSuggestion={suggestion => <span>{suggestion.display}</span>}*/}
            {/*        onAdd={() => console.log(123)}/>*/}
            {/*</MentionsInput>*/}
            {/*</Container>*/}
            <Container>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" variant="link" onClick={() => resetAction()}>
                                Reset
                            </Button>
                            <Button onClick={(e) => submitAction(e)} variant="primary">Submit</Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size="l">
                        <SelectPerferences options={ClusterOptions} selectedOptions={SelectCluster} constraintText={""}
                                           errorValue={""} label={"Cluster"} placeholder={'Select one or many'}
                                           description={'It will limit what you will see in Aladdin Table.'}
                                           onChange={({detail}) => HandleSelectCluster(detail.selectedOptions)}/>
                        <SelectPerferences options={AZOptions} selectedOptions={SelectAZ} constraintText={""}
                                           errorValue={""} label={"Availability Zone"}
                                           placeholder={'Select one or many'}
                                           description={'It will limit what you will see in Aladdin Table.'}
                                           onChange={({detail}) => HandleSelectAZ(detail.selectedOptions)}/>
                        <SelectPerferences options={SiteOptions} selectedOptions={SelectSite} constraintText={""}
                                           errorValue={""} label={"Site"} placeholder={'Select one or many'}
                                           description={'It will limit what you will see in Aladdin Table.'}
                                           onChange={({detail}) => HandleSelectSite(detail.selectedOptions)}/>
                        <SelectFormField label={'Team'} constraintText={''} selectedOption={SelectTeams}
                                         options={TeamsOptions} disable={false}
                                         onChange={({detail}) => HandleSelectTeams(detail.selectedOption)}
                                         errorValue={""}
                                         description={'It will limit what you will see in Aladdin Table.'}/>
                        <FormField
                            constraintText={''}
                            errorText={''}
                            label={'Good Idea Subscription'}
                            description={'Please choose the Good Idea email you want to receive'}
                        >
                            <SpaceBetween size={'xxl'} direction="horizontal">
                                <Toggle
                                    onChange={({detail}) =>
                                        setCheckedGlobal(detail.checked)
                                    }
                                    checked={checkedGlobal}
                                >
                                    Global Kaizen Promotion Pool
                                </Toggle>
                                <Toggle
                                    onChange={({detail}) =>
                                        setCheckedLocal(detail.checked)
                                    }
                                    checked={checkedLocal}
                                >
                                    Local Good Idea Approved
                                </Toggle>
                            </SpaceBetween>

                        </FormField>
                    </SpaceBetween>
                </Form>
            </Container>
        </ContentLayout>
    )
}
