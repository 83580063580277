import SideNavigation, {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';
import BreadcrumbGroup, {BreadcrumbGroupProps} from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import {useHistory, useLocation} from 'react-router-dom';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import React, {useEffect, useState} from 'react';
import axios from 'axios'


const HOME = {text: 'Aladdin Dashboard', href: '#/'};

// const [login, setLogin] = useState<string>('ayunlong')


export function Breadcrumbs({items}: { items: BreadcrumbGroupProps.Item[] }) {
    return <BreadcrumbGroup items={[HOME, ...items]}/>;
}

// ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
export function ServiceNavigation() {
    // If the provided link is empty, do not redirect pages
    const [login, setLogin] = useState<string>('')
    const [notificationNumber, setNotificationNumber] = useState<number>(0)
    const [approvalNumber, setApprovalNumber] = useState<number>(0)
    useEffect(() => {
        axios({
            method: "POST",
            data: {data: ''},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/ownership/approval",
        }).then(res => {
            setApprovalNumber(res.data.length)
        });
    })
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/notifications",
        }).then(res => {
            setNotificationNumber(res.data.length)
        })
    })
    const items: any[] = [
        {
            type: 'section',
            text: 'Main Menu',
            items: [
                // {type: 'link', text: 'Summary Items', href: '#/summary'},
                // {type: 'link', text: 'Open Items', href: '#/open'},
                // {type: 'link', text: 'Resolved Items', href: '#/resolved'},
                // // {
                // //     type: 'link-group', text: 'Kaizen Pool', href: '#/global', items: [
                // //         {type: 'link', text: 'Sustainability', href: '#/sustainability'}
                // //     ]
                // // },
                // {
                //     type: "expandable-link-group",
                //     text: "Sharing Center",
                //     defaultExpanded: true,
                //     items: [
                //         {
                //             type: "link",
                //             text: "Kaizen Pool",
                //             href: "#/global"
                //         },
                //         {
                //             type: "link",
                //             text: "Sustainability",
                //             href: "#/sustainability"
                //         }
                //     ]
                // },
                {
                    type: "link-group",
                    text: "Pain Point & Good Idea",
                    href: "#/PainPoint & GoodIdea",
                    items: [
                        {
                            type: "link",
                            text: "Open Items",
                            href: "#/open"
                        },
                        {
                            type: "link",
                            text: "Resolved Items",
                            href: "#/resolved"
                        }
                    ]
                },
                {
                    type: "link-group",
                    text: "Sustainability Initiative",
                    href: "#/Sustainability Initiative",
                    items: []
                },
                {
                    type: "link",
                    text: "Kaizen Pool",
                    href: "#/global"
                }
            ]
        },
        {
            type: 'section',
            text: 'About Myself',
            items: [
                // {type: 'link', text: 'Create Items', href: '#/create'},
                {
                    type: "link",
                    text: <span>Pending Approval&nbsp;{approvalNumber == 0 ? null :
                        <Badge color="red">{approvalNumber > 99 ? '99+' : approvalNumber}</Badge>}</span>,
                    href: "#/approval"
                },
                {type: "link", text: "Submitted By Me", href: "#/request"},
                // {type: "link", text: "Notifications", href: "#/notifications"},
                {type: "link", text: "Preferences Setting", href: "#/preferences"},
            ]
        },
        {
            type: 'section',
            text: 'Report',
            items: [
                {type: 'link', text: 'Submitted Count', href: '#/report/submission'},
                {type: 'link', text: 'Resolved Count', href: '#/report/resolved'},
                {type: 'link', text: 'Team Analytics', href: '#/analytics'},
            ]
        },
        {
            type: 'section',
            text: 'Admin Tools',
            items: [
                {type: 'link', text: 'Permission', href: '#/permission'},
                {
                    type: 'link',
                    text: <span>Notification&nbsp;{notificationNumber == 0 ? null :
                        <Badge color="red">{notificationNumber > 99 ? '99+' : notificationNumber}</Badge>}</span>,
                    href: '#/notification'
                },
            ]
        }

    ];
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/user",
        }).then(res => {
            setLogin(res.data['authorizer']['lambda']['midwayUser'])
        })
    }, [])
    const location = useLocation();
    const navigate = useHistory();

    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        event.preventDefault();
        if (event.detail.href) {
            if (event.detail.text == 'Team Analytics') {
                window.location.href = '#/analytics/' + login
            } else {
                window.location.href = event.detail.href
            }
        }
    };
    if (location.pathname.indexOf('#/analytics') > 0) {
        return null
    }

    return <SideNavigation items={items}
                           activeHref={location.pathname.indexOf('analytics') > 0 ? '#/analytics' : (location.pathname.indexOf('sustainability') > 0 ? '#/sustainability' : `#${location.pathname}`)}
                           onFollow={onFollowHandler}/>;
}