import {Flashbar} from "@amzn/awsui-components-react";
import {useState} from "react";

export interface ErrorBarProps {
    key?: string,
    header: string,
    type: string,
    content: any,
    dismissible?: boolean,
    onDismiss?: (key: string) => void
}

export default function AlertBar(props: ErrorBarProps) {
    const [items, setItems] = useState([
        {
            header: props.header,
            type: props.type,
            content: props.content,
            dismissible: props.dismissible ? props.dismissible : false,
            onDismiss: () => setItems([])
        }
    ]);
    // @ts-ignore
    return <Flashbar items={items}/>;
}