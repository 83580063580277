import * as React from "react";
import MixedLineBarChart from "@amzn/awsui-components-react/polaris/mixed-line-bar-chart";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";

export interface MixedChartProps {
    series: any
    xDomain: any
    yDomain: any
    statusType: any
    i18nStrings: any
    xTitle: string
    yTitle: string
}

export default function MixedCharts(props: MixedChartProps) {
    return (
        <MixedLineBarChart
            series={props.series}
            xDomain={props.xDomain}
            yDomain={props.yDomain}
            i18nStrings={props.i18nStrings}
            statusType={props.statusType}
            ariaLabel="Mixed bar chart"
            errorText="Error loading data."
            height={300}
            loadingText="Loading chart"
            recoveryText="Retry"
            xScaleType="categorical"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No data available</b>
                    <Box variant="p" color="inherit">
                        There is no data available
                    </Box>
                </Box>
            }
            noMatch={
                <Box textAlign="center" color="inherit">
                    <b>No matching data</b>
                    <Box variant="p" color="inherit">
                        There is no matching data to display
                    </Box>
                    <Button>Clear filter</Button>
                </Box>
            }
        />
    );
}