import {useState, useEffect, useRef} from "react";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import LineCharts from "../Common/LineChart";
import MixedCharts from "../Common/MixedCharts";
import PieDonutCharts from "../Common/PieDonutCharts";
import DateRangePicker from '../Common/DateRangePicker'
import {LineChart_DEFINITIONS, MixedChart_DEFINITIONS, PieDonutChart_DEFINITIONS} from "../Common/i18nStrings";
import axios from 'axios'
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import {downloadAsExcelFile} from "../Common/ReactCSV";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import AreaCharts from "../Common/AreaChart";

export default function ResolvedReportDashboard() {
    const ref = useRef<HTMLDivElement>(null);
    const RELATIVEOPTIONS = [
        {
            key: "previous-3-month",
            amount: 3,
            unit: "month",
            type: "relative"
        }, {
            key: "previous-6-months",
            amount: 6,
            unit: "month",
            type: "relative"
        }, {
            key: "previous-1-year",
            amount: 1,
            unit: "year",
            type: "relative"
        }
    ]
    const defaultRELATIVEOPTIONS = {
        type: "relative",
        amount: 6,
        unit: "month"
    }
    const [visible, setVisible] = useState(false);
    const [ReportDateLimit, setReportDateLimit] = useState<any>(defaultRELATIVEOPTIONS);
    const [LineChartsDateLimitValue, setLineChartsDateLimitValue] = useState<any>(defaultRELATIVEOPTIONS);
    const [MixedChartsDateLimitValue, setMixedChartsDateLimitValue] = useState<any>(defaultRELATIVEOPTIONS);
    const [PieDonutChartsDateLimitValue, setPieDonutChartsDateLimitValue] = useState<any>(defaultRELATIVEOPTIONS);

    const [LineChartLoading, setLineChartLoading] = useState('loading')
    const [LineChartSeries, setLineChartSeries] = useState<any>([])
    const [LineChartXDomain, setLineChartXDomain] = useState<any>([])
    const [LineChartYDomain, setLineChartYDomain] = useState<any>([0, 10])
    const [MixedChartLoading, setMixedChartLoading] = useState('loading')
    const [MixedChartSeries, setMixedChartSeries] = useState<any>([])
    const [MixedChartXDomain, setMixedChartXDomain] = useState<any>([])
    const [MixedChartYDomain, setMixedChartYDomain] = useState<any>([0, 30])
    const [PieDonutChartLoading, setPieDonutChartLoading] = useState('loading')
    const [PieDonutValue, setPieDonutValue] = useState<any>([])
    const [PieDonutGoodIdeaValue, setPieDonutGoodIdeaValue] = useState<any>([])
    const [PieDonutGoodIdeaChartLoading, setPieDonutGoodIdeaChartLoading] = useState('loading')
    useEffect(() => {
        // axios({
        //     method: "POST",
        //     data: {LineChartsDateLimitValue: LineChartsDateLimitValue, Type: 'Resolved'},
        //     url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/linechart",
        // }).then(res => {
        //     const LineChartDetail = res.data
        //     let maxDomain = 0
        //     LineChartDetail['PainPoint'].map((item, key) => {
        //         console.log(LineChartDetail['GoodIdea'][key][Object.keys(item)[0]])
        //         let sumCount = item[Object.keys(item)[0]] + LineChartDetail['GoodIdea'][key][Object.keys(item)[0]]
        //         console.log(sumCount)
        //         sumCount > maxDomain ? maxDomain = sumCount : null
        //     })
        //     maxDomain != 0 ? setLineChartYDomain([0, Math.ceil(Number((maxDomain / 10).toFixed(1))) * 10]) : null
        //     setLineChartSeries([{
        //         title: "Pain Point Count",
        //         type: "area",
        //         data: LineChartDetail['PainPoint'].map((item) => {
        //             return {x: new Date(Object.keys(item)[0] + 'T00:00'), y: item[Object.keys(item)[0]]}
        //         }),
        //         valueFormatter: function l(e) {
        //             return Math.abs(e) >= 1e9
        //                 ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
        //                 "G"
        //                 : Math.abs(e) >= 1e6
        //                     ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
        //                     "M"
        //                     : Math.abs(e) >= 1e3
        //                         ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
        //                         "K"
        //                         : e.toFixed(2);
        //         }
        //     },
        //         {
        //             title: "Good Idea Count",
        //             type: "area",
        //             color: '#2ea597',
        //             data: LineChartDetail['GoodIdea'].map((item) => {
        //                 return {x: new Date(Object.keys(item)[0] + 'T00:00'), y: item[Object.keys(item)[0]]}
        //             }),
        //             valueFormatter: function l(e) {
        //                 return Math.abs(e) >= 1e9
        //                     ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
        //                     "G"
        //                     : Math.abs(e) >= 1e6
        //                         ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
        //                         "M"
        //                         : Math.abs(e) >= 1e3
        //                             ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
        //                             "K"
        //                             : e.toFixed(2);
        //             }
        //         }])
        //     setLineChartXDomain([new Date(LineChartDetail['EndDate'] + 'T00:00'), new Date(LineChartDetail['StartDate'] + 'T00:00')])
        //     setLineChartLoading('finished')
        axios({
            method: "POST",
            data: {LineChartsDateLimitValue: LineChartsDateLimitValue, Type: 'Resolved'},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/linechart",
        }).then(res => {
            const LineChartDetail = res.data
            setLineChartXDomain(getTimeDomain(res.data, 'PainPoint'))
            let maxDomain = 0
            LineChartDetail['PainPoint'].map((item) => {
                item[Object.keys(item)[0]] > maxDomain ?
                    maxDomain = item[Object.keys(item)[0]] : null
            })
            maxDomain != 0 ? setLineChartYDomain([0, Math.ceil(Number((maxDomain / 10).toFixed(1) + 1)) * 10]) : null
            setLineChartSeries([
                {
                    title: "Pain Point",
                    type: "bar",
                    data: LineChartDetail['PainPoint'].map((item) => {
                        return {x: new Date(Object.keys(item)[0] + 'T00:00'), y: item[Object.keys(item)[0]]}
                    }),
                    valueFormatter: function l(e) {
                        return Math.abs(e) >= 1e9
                            ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                            "G"
                            : Math.abs(e) >= 1e6
                                ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                                "M"
                                : Math.abs(e) >= 1e3
                                    ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                                    "K"
                                    : e.toFixed(2);
                    }
                },
                {
                    title: "Good Idea Count",
                    type: "bar",
                    color: '#2ea597',
                    data: LineChartDetail['GoodIdea'].map((item) => {
                        return {x: new Date(Object.keys(item)[0] + 'T00:00'), y: item[Object.keys(item)[0]]}
                    }),
                    valueFormatter: function l(e) {
                        return Math.abs(e) >= 1e9
                            ? (e / 1e9).toFixed(1).replace(/\.0$/, "") +
                            "G"
                            : Math.abs(e) >= 1e6
                                ? (e / 1e6).toFixed(1).replace(/\.0$/, "") +
                                "M"
                                : Math.abs(e) >= 1e3
                                    ? (e / 1e3).toFixed(1).replace(/\.0$/, "") +
                                    "K"
                                    : e.toFixed(2);
                    }
                }])
            // setLineChartXDomain([new Date(LineChartDetail['EndDate'] + 'T00:00'), new Date(LineChartDetail['StartDate'] + 'T00:00')])
            setLineChartLoading('finished')

        })
    }, [LineChartsDateLimitValue])
    const getTimeDomain = (dataSet, searchType) => {
        let result = dataSet[searchType]
        let timeLine: any[] = []
        for (let key in result) {
            timeLine.unshift(new Date(Object.keys(result[key])[0] + 'T00:00'))
        }
        console.log(timeLine)
        return timeLine
    }
    useEffect(() => {
        axios({
            method: "POST",
            data: {MixedChartsDateLimitValue: MixedChartsDateLimitValue, Type: 'Resolved'},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/mixedchart",
        }).then(res => {
            const MixedChartDetail = res.data
            console.log(res.data)
            setMixedChartXDomain(MixedChartDetail['SiteFilter'])
            let maxDomain = 0
            MixedChartDetail['PainPoint'].map((item) => {
                item[Object.keys(item)[0]] > maxDomain ?
                    maxDomain = item[Object.keys(item)[0]] : null
            })
            maxDomain != 0 ? setMixedChartYDomain([0, Math.ceil(Number((maxDomain / 10).toFixed(1) + 1)) * 10]) : null
            setMixedChartSeries([
                {
                    title: "Pain Point",
                    type: "bar",
                    data: MixedChartDetail['PainPoint'].map((item) => {
                        return {x: Object.keys(item)[0], y: item[Object.keys(item)[0]]}
                    }),
                    valueFormatter: e =>
                        e.toLocaleString("zh-CN")
                },
                {
                    title: "Good Idea",
                    type: "bar",
                    color: '#2ea597',
                    data: MixedChartDetail['GoodIdea'].map((item) => {
                        return {x: Object.keys(item)[0], y: item[Object.keys(item)[0]]}
                    }),
                    valueFormatter: e =>
                        e.toLocaleString("zh-CN")
                }
            ])
            // setLineChartXDomain([new Date(LineChartDetail['EndDate'] + 'T00:00'), new Date(LineChartDetail['StartDate'] + 'T00:00')])
            setMixedChartLoading('finished')
        })
    }, [MixedChartsDateLimitValue])
    useEffect(() => {
        axios({
            method: "POST",
            data: {PieDonutChartsDateLimitValue: PieDonutChartsDateLimitValue, Type: 'Resolved', Label: 'Pain Point'},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/piedonutchart",
        }).then(res => {
            setPieDonutValue(res.data.map((item) => {
                return {
                    title: Object.keys(item)[0],
                    value: item[Object.keys(item)[0]]
                }
            }))
            setPieDonutChartLoading('finished')
        })
    }, [PieDonutChartsDateLimitValue])
    useEffect(() => {
        axios({
            method: "POST",
            data: {PieDonutChartsDateLimitValue: PieDonutChartsDateLimitValue, Type: 'Resolved', Label: 'Good Idea'},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/piedonutchart",
        }).then(res => {
            setPieDonutGoodIdeaValue(res.data.map((item) => {
                return {
                    title: Object.keys(item)[0],
                    value: item[Object.keys(item)[0]]
                }
            }))
            setPieDonutGoodIdeaChartLoading('finished')
        })
    }, [PieDonutChartsDateLimitValue])
    const handleLineChartChange = (detail) => {
        setLineChartLoading('loading');
        setLineChartsDateLimitValue(detail.value)
    }
    const handleMixedChartChange = (detail) => {
        setMixedChartLoading('loading');
        setMixedChartsDateLimitValue(detail.value)
    }
    const handlePieDonutChartChange = (detail) => {
        setPieDonutChartLoading('loading');
        setPieDonutGoodIdeaChartLoading('loading')
        setPieDonutChartsDateLimitValue(detail.value)
    }
    const handleReportDateLimitChange = (detail) => {
        setReportDateLimit(detail.value)
    }
    const handleReportData = (Data) => {
        var CSVData = [{}]
        var headers = ['id', 'title', 'Description', 'Countermeasure', 'Benefit', 'Owner', 'Label', 'Category', 'Priority', 'Cluster', 'AZ', 'Team', 'State', 'Status', 'SubmitDate', 'Submitter', 'LMApproval', 'LMCountermeasure', 'LMDescription', 'CMApproval', 'CMCountermeasure', 'CMDescription']
        Data.map((item) => {
            let key = {
                id: item.id,
                title: item.Title,
                Description: item.Description,
                Countermeasure: item.Countermeasure,
                Benefit: item.Benefit,
                Owner: item.Owner,
                Label: item.Label.join(''),
                Category: item.Category.join(','),
                Priority: item.Priority.join(','),
                Cluster: item.SubmitterCluster,
                AZ: item.SubmitterAZ,
                Team: item.SubmitterTeam,
                State: item.State,
                Status: item.Status,
                SubmitDate: item.SubmitDate,
                Submitter: item.Submitter,
                LMApproval: item.LMApproval,
                LMCountermeasure: item.LMCountermeasure,
                LMDescription: item.LMDescription,
                CMApproval: item.CMApproval,
                CMCountermeasure: item.CMCountermeasure,
                CMDescription: item.CMDescription,
            }
            CSVData.push(key)
        })
        return {CSVData: CSVData, headers: headers}
    }
    return (<div ref={ref} className='awsui-visual-refresh'>
            <ContentLayout
                header={
                    <SpaceBetween size="m">
                        <Header
                            variant="h1"
                            actions={
                                // <Button
                                //     onClick={() => {
                                //         console.log(document.getElementsByTagName('body')[0])
                                //         exportPDf(document.getElementsByTagName('body')[0], '报告');
                                //     }}
                                // >
                                //     Export Report
                                // </Button>
                                <Button onClick={() => {
                                    setVisible(true)
                                }}>Export Report</Button>
                                // <Button><ReactCSV data={CSVData} headers={headers}
                                //                   filename='ExportReport.csv'></ReactCSV></Button>
                            }
                        >
                            PainPoint/GoodIdea Resolved Report Charts
                        </Header>
                        <TextContent>
                            <p>Here, we can see the Counts of submissions from PainPoint and GoodIdea, the number of
                                submissions from all sites and the number of submissions
                                from all teams within a certain period of time. If you are more interested in the raw
                                data,
                                you can click the button in the upper right corner to export the raw data of PainPoint
                                and
                                Good Idea as a .CSV file.</p>
                        </TextContent>
                    </SpaceBetween>
                }
            >
                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Counts of submissions from PainPoint and GoodIdea"
                        >
                            Total Resolved Counts
                        </Header>
                    }
                >
                    <DateRangePicker onChange={({detail}) => handleLineChartChange(detail)}
                                     value={LineChartsDateLimitValue}
                                     relativeOptions={RELATIVEOPTIONS} rangeSelectorMode={undefined}/>
                    {/*<AreaCharts statusType={LineChartLoading} xDomain={LineChartXDomain} yDomain={LineChartYDomain}*/}
                    {/*            series={LineChartSeries}*/}
                    {/*            i18nStrings={LineChart_DEFINITIONS}/>*/}
                    <MixedCharts statusType={LineChartLoading} xDomain={LineChartXDomain} yDomain={LineChartYDomain}
                                 series={LineChartSeries}
                                 i18nStrings={LineChart_DEFINITIONS} xTitle="Availability Zone"
                                 yTitle="Submit Counts"/>
                </Container>
                <br/>
                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Counts of submissions from Sites"
                        >
                            Resolved Counts Per Site
                        </Header>
                    }
                >
                    <DateRangePicker onChange={({detail}) => handleMixedChartChange(detail)}
                                     value={MixedChartsDateLimitValue}
                                     relativeOptions={RELATIVEOPTIONS} rangeSelectorMode={undefined}/>
                    <MixedCharts series={MixedChartSeries} xDomain={MixedChartXDomain} yDomain={MixedChartYDomain}
                                 i18nStrings={MixedChart_DEFINITIONS} statusType={MixedChartLoading}
                                 xTitle="Availability Zone"
                                 yTitle="Submit Counts"/>
                </Container>
                <br/>
                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Counts of submissions from Teams"
                        >
                            Good Idea/Pain Point Resolved Pie
                        </Header>
                    }
                >
                    <DateRangePicker onChange={({detail}) => handlePieDonutChartChange(detail)}
                                     value={PieDonutChartsDateLimitValue}
                                     relativeOptions={RELATIVEOPTIONS} rangeSelectorMode={undefined}/>
                    <ColumnLayout columns={2}>
                        <Box textAlign="center">
                            <h3 style={{color: '#0972d3'}}>Pain Point Resolved Pie</h3>
                            <PieDonutCharts i18nStrings={PieDonutChart_DEFINITIONS} data={PieDonutValue}
                                            detailPopoverContent={(datum, sum) => [
                                                {key: "Resource count", value: datum.value},
                                                {
                                                    key: "Percentage",
                                                    value: `${((datum.value / sum) * 100).toFixed(
                                                        0
                                                    )}%`
                                                }
                                            ]} statusType={PieDonutChartLoading}/>
                        </Box>
                        <Box textAlign="center">
                            <h3 style={{color: '#037f0c'}}>Good Idea Resolved Pie</h3>
                            <PieDonutCharts i18nStrings={PieDonutChart_DEFINITIONS} data={PieDonutGoodIdeaValue}
                                            detailPopoverContent={(datum, sum) => [
                                                {key: "Resource count", value: datum.value},
                                                {
                                                    key: "Percentage",
                                                    value: `${((datum.value / sum) * 100).toFixed(
                                                        0
                                                    )}%`
                                                }
                                            ]} statusType={PieDonutGoodIdeaChartLoading}/>
                        </Box>
                    </ColumnLayout>
                </Container>
                <Modal
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => {
                                    setVisible(false)
                                }} variant="link">Cancel</Button>
                                <Button onClick={() => {
                                    setVisible(false)
                                    axios({
                                        method: "POST",
                                        data: ReportDateLimit,
                                        url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/report",
                                    }).then(res => {
                                        const result = handleReportData(res.data)
                                        downloadAsExcelFile(result.CSVData, result.headers, 'PainPointAndGoodIdeaReport')
                                    })

                                }}
                                        variant="primary">Ok</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Export Report"
                >
                    <p>Please Choose the date limit!</p>
                    <DateRangePicker onChange={({detail}) => handleReportDateLimitChange(detail)}
                                     value={ReportDateLimit}
                                     relativeOptions={RELATIVEOPTIONS} rangeSelectorMode={undefined}/>
                </Modal>
            </ContentLayout>
        </div>
    );
}