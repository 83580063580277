import {useEffect, useState} from 'react';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from '@amzn/awsui-components-react/polaris/box';
import CopyText from "../Common/CopyText";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import ProgressBar from "@amzn/awsui-components-react/polaris/progress-bar";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Token, {pushToTag} from "../Common/Token";
import Link from "@amzn/awsui-components-react/polaris/link";
import Icon from "@amzn/awsui-components-react/polaris/icon";
import axios from 'axios'
import {useHistory} from "react-router-dom";
import AutoLink, {updateDateToString, UserDetail, UserIcon} from "../Common/AutoLink";
import SustainabilityDetailTab from "./SustainabilityDetailTab";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import Select from "@amzn/awsui-components-react/polaris/select";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Button from "@amzn/awsui-components-react/polaris/button";
import Form from "@amzn/awsui-components-react/polaris/form";
import {InputNumberFormField} from "../Common/FormField";
import Alert from "@amzn/awsui-components-react/polaris/alert";

export interface DEMO_SustainabilityDetail {
    SustainabilityDetail: any
    loginUser: string
    updateDetail: () => void
}

export default function SustainabilityDetailView(props: DEMO_SustainabilityDetail) {
    const history = useHistory()
    useEffect(() => {
        setCommentsValue('')
        setDetails(props.SustainabilityDetail)
        setEditOutcomesCosts(false)
        if (document.getElementById('editorMultiple')) {
            // @ts-ignore
            document.getElementById('editorMultiple').innerHTML = ''
        }
    }, [props.SustainabilityDetail])
    const [details, setDetails] = useState(props.SustainabilityDetail)
    const [areaInvalid, setAreaInvalid] = useState(false)
    const [CommentsValue, setCommentsValue] = useState("")
    const [markDownValue, setMarkDownValue] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [attachmentKey, setAttachmentKey] = useState("")
    const [selectedStateOption, setSelectedStateOption] = useState<any>(pushToTag(details.State));
    const [editOutcomesCosts, setEditOutcomesCosts] = useState(false)
    const [loadingOutcomesCosts, setLoadingOutcomesCosts] = useState(false)
    const [permissionChecker, setPermissionChecker] = useState(false)
    useEffect(() => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/painpoint/permission/sa-apjc-dc-ops",
        }).then(res => {
            res.data.UserList.map((items, index) => {
                if (props.loginUser === items.Login) {
                    setPermissionChecker(true)
                }
            })
        })
    }, [])
    useEffect(() => {
        details.Attachment ?
            axios({
                method: "POST",
                data: {Key: details.Attachment},
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/attachment",
            }).then(res => {
                setAttachmentKey(res.data)
            }) : null
    }, [props.SustainabilityDetail])
    const CommentsonChange = (value) => {
        setCommentsValue(value)
    }
    const onChangeMention = () => {
        const atItemList = document.getElementById('editorMultiple')?.innerHTML || '';
        setCommentsValue(atItemList);
    }
    const CommentsSubmit = () => {
        setSubmitLoading(true)
        const atItemList = document.getElementById('editorMultiple')?.innerHTML || '';
        const CommentsDetails = {
            "CommentsContent": CommentsValue,
            "CommentsUser": props.loginUser,
            "id": details.id,
            'CommentsMarkdown': markDownValue
        }
        if (CommentsValue === '') {
            setAreaInvalid(true)
            setSubmitLoading(false)
        } else if (atItemList == '' && !markDownValue) {
            setAreaInvalid(true)
            setSubmitLoading(false)
        } else {
            axios({
                method: "POST",
                data: CommentsDetails,
                url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/comments",
            }).then(res => {
                axios({
                    method: "GET",
                    url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability/" + details.id,
                }).then(res => {
                    setDetails(res.data)
                })
                setCommentsValue("")
                setSubmitLoading(false)
                if (document.getElementById('editorMultiple')) {
                    // @ts-ignore
                    document.getElementById('editorMultiple').innerHTML = ''
                }
            })
        }
    }

    const [outcomesExpend, setOutcomesExpend] = useState(true)
    const handleOutcomesExpendChange = (detail) => {
        setOutcomesExpend(detail.expanded)
    }
    const [costsExpend, setCostsExpend] = useState(true)
    const handleCostsExpendChange = (detail) => {
        setCostsExpend(detail.expanded)
    }

    useEffect(() => {
        if (document.getElementById('editorMultiple') != null) {
            // @ts-ignore
            document.getElementById('editorMultiple').innerHTML = CommentsValue
        } else {
            let testStr = CommentsValue.replace(/<div>/g, "\n");
            testStr = testStr.replace(/<\/?.+?>/g, "");
            testStr = testStr.replace(/&nbsp;/g, "");
            setCommentsValue(testStr)
        }
    }, [markDownValue])

    const updateState = () => {
        axios({
            method: "POST",
            data: {id: details.id, State: selectedStateOption.value},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/state",
        }).then(res => {
            updateInformation()
            props.updateDetail()
        })
    }
    const updateInformation = () => {
        axios({
            method: "GET",
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainability/" + details.id,
        }).then(res => {
            setDetails(res.data)
        })
    }

    const defaultState = {
        Financial: details.Unit.Financial,
        EnergyEfficiency: details.Unit.EnergyEfficiency,
        CarbonFootprint: details.Unit.CarbonFootprint,
        WaterEfficiency: details.Unit.WaterEfficiency,
        WasteRecycling: details.Unit.WasteRecycling,
        Payback: details.Unit.Payback,
        ImplementEffort: details.Unit.ImplementEffort,
        OngoingEffort: details.Unit.OngoingEffort,
        CapitalCosts: details.Unit.CapitalCosts,
        OperationalCosts: details.Unit.OperationalCosts,
        DieselEnergy: details.Unit.DieselEnergy
    };

    const [distributionPanelData, setDistributionPanelData] = useState(defaultState)
    useEffect(() => {
        setDistributionPanelData(defaultState)
    }, [details])
    const onChange = (attribute, value) => {
        const newState = {...distributionPanelData};
        console.log(newState)
        newState[attribute] = value;
        console.log(newState)
        setDistributionPanelData(newState);
    };
    const submitOutcomesCosts = () => {
        setLoadingOutcomesCosts(true)
        axios({
            method: "POST",
            data: {DistributionPanelData: distributionPanelData, id: details.id},
            url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/sustainabilityapi/project",
        }).then(res => {
            updateInformation()
            props.updateDetail()
            setLoadingOutcomesCosts(false)
            setEditOutcomesCosts(false)
        })
    }
    return (
        <SpaceBetween size="xs">
            <TextContent>
                <SpaceBetween size="l">
                    <SpaceBetween size="xxs">
                        <h4>Project Name</h4>
                        <AutoLink text={details.ProjectName}></AutoLink>
                        <h4>Brief Description</h4>
                        <AutoLink text={details.BriefDescription}></AutoLink>
                        <h4>Estimated impact at time of submission</h4>
                        <AutoLink text={details.Impact}></AutoLink>
                        <h4>Estimated investment at time of submission (man hour & cost in USD)</h4>
                        <AutoLink text={details.Investment}></AutoLink>
                        <h4>Where is this project relevant (equipment/geography etc)</h4>
                        <AutoLink text={details.Relevant}></AutoLink>
                        <h4>Challenges during implementation AND/OR trick and tips</h4>
                        <AutoLink text={details.Implementation}></AutoLink>
                        <h4>InfraOps POC/s</h4>
                        <AutoLink text={details.InfraOpsOwner}></AutoLink>
                        <h4>AWS Enviro POC/s</h4>
                        <AutoLink text={details.EnviroOwner}></AutoLink>
                        {details.Attachment ?
                            <Link external href={attachmentKey}>
                                <Icon name="file"/>&nbsp;
                                {details.Attachment.split(details.id + '-')[1]}
                            </Link> :
                            null}
                    </SpaceBetween>
                    <ProgressBar
                        value={
                            details.State === 'Assigned' ? 0 : details.State === "Researching" ? 20 : details.State === "Working in Process" ? 40 : details.State === "Pending" ? 60 : details.State === "Completed" ? 100 : 0
                        }
                        additionalInfo={<div>Sustainability Initiative Process status is <StatusIndicator
                            type={details.State === 'Assigned' ? 'info' : details.State === 'Researching' ? 'warning' : details.State === 'Working in Process' ? 'in-progress' : details.State === 'Pending' ? 'pending' : details.State === 'Completed' ? 'success' : 'error'}>
                            {details.State}
                        </StatusIndicator> now!</div>}
                        description="Status Value: Assigned, Researching, Working in Process, Pending, Completed"
                        label={<strong>Process Status of Sustainability Initiative</strong>}
                    />
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <div>
                                <Box variant="awsui-key-label">Sharing Link</Box>
                                <CopyText
                                    copyText={`https://aladdin.portal.amazon.dev/#/detail/` + details.id}
                                    copyButtonLabel="Copy Sharing Link"
                                    successText="Sharing Link copied"
                                    errorText="Sharing Link failed to copy"
                                    showText={details.id}
                                />
                            </div>
                            <div>
                                <Box variant="awsui-key-label">Cluster</Box>
                                <Badge color="green">{details.Cluster}</Badge>
                            </div>
                            <div>
                                <Box variant="awsui-key-label">Submit Date</Box>
                                <div>{updateDateToString(details.SubmitDate)}</div>
                            </div>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <div>
                                <Box variant="awsui-key-label">State</Box>
                                <Box color="text-status-error">
                                    <Popover
                                        fixedWidth
                                        position="top"
                                        triggerType="custom"
                                        size='large'
                                        content={
                                            <FormField
                                                label="Change state to"
                                            >
                                                <Grid
                                                    gridDefinition={[{colspan: 8}, {colspan: 4}]}
                                                >
                                                    <Select
                                                        selectedOption={selectedStateOption}
                                                        onChange={({detail}) =>
                                                            setSelectedStateOption(detail.selectedOption)
                                                        }
                                                        options={[
                                                            {label: "Assigned", value: "Assigned"},
                                                            {label: "Researching", value: "Researching"},
                                                            {label: "Working in Process", value: "Working in Process"},
                                                            {label: "Pending", value: "Pending"},
                                                            {label: "Completed", value: "Completed"},
                                                        ]}
                                                        filteringType="auto"
                                                    />
                                                    <Button
                                                        onClick={updateState}
                                                    >
                                                        <Icon name="check"/>Confirm
                                                    </Button>
                                                </Grid>
                                            </FormField>
                                        }
                                    >
                                        <SpaceBetween direction="horizontal" size="xl">
                                            <StatusIndicator
                                                type={details.State === 'Assigned' ? 'info' : details.State === 'Researching' ? 'warning' : details.State === 'Working in Process' ? 'in-progress' : details.State === 'Pending' ? 'pending' : details.State === 'Completed' ? 'success' : 'error'}>
                                                {details.State}
                                            </StatusIndicator>
                                            <Link
                                                onFollow={() =>
                                                    console.log('Edit State')
                                                }
                                            >
                                                Edit
                                            </Link>
                                        </SpaceBetween>
                                    </Popover>
                                </Box>
                            </div>
                            <div>
                                <Box variant="awsui-key-label">Duration</Box>
                                <div>{details.StartDate} - {details.CompletedDate}</div>
                            </div>
                            <div>
                                <Box variant="awsui-key-label">Submitter</Box>
                                <UserDetail login={details.Submitter}/>
                            </div>
                        </SpaceBetween>
                    </ColumnLayout>
                    <Form
                        actions={
                            permissionChecker ? !editOutcomesCosts ?
                                <Button
                                    onClick={() => setEditOutcomesCosts(true)}>
                                    Edit & Approve
                                </Button>
                                :
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button formAction="none" variant="link"
                                            onClick={() => setEditOutcomesCosts(false)}>
                                        Cancel
                                    </Button>
                                    <Button onClick={() => submitOutcomesCosts()} loading={loadingOutcomesCosts}
                                            variant="primary">Submit & Approve</Button>
                                </SpaceBetween> : null
                        }
                        secondaryActions={!editOutcomesCosts ? permissionChecker ? <StatusIndicator type='success'>
                                You have access to Approve and Eidt the
                                "Project Outcomes" && "Project Costs & Expenses"
                            </StatusIndicator> : null
                            : null}
                    >
                        <SpaceBetween size="xs">
                            {details.OutcomesCostsStatus == 'Unapproved' ?
                                <Alert
                                    statusIconAriaLabel="Error"
                                    type="error"
                                    header="The Data are Unvalidated and UnApproved"
                                >
                                    These data are in draft form and have not been verified and approved by SA team
                                    members.
                                    It cannot be used as a reference, so please identify them carefully.
                                </Alert> :
                                <Alert
                                    statusIconAriaLabel="Success"
                                    type="success"
                                    header="The Data has been Validated and Approved"
                                >
                                    Project Outcomes and Costs & Expenses has been Validated and Approved.
                                </Alert>
                            }

                            {!editOutcomesCosts ?
                                <Box>
                                    <ExpandableSection expanded={outcomesExpend} headerText='Project Outcomes'
                                                       onChange={({detail}) => handleOutcomesExpendChange(detail)}>
                                        <ColumnLayout columns={3}>
                                            <div>
                                                <Box variant="awsui-key-label">Financial Savings</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.Financial}&nbsp;$USD/Year
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Energy Efficiency</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.EnergyEfficiency}&nbsp;kWh/Year
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Carbon footprint</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.CarbonFootprint}&nbsp;Tonnes CO₂e/Year
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Water efficiency</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.WaterEfficiency}&nbsp;m³/Year
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Waste recycling & reduction</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.WasteRecycling}&nbsp;Kg/Year
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Energy Efficiency(Diesel)</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.DieselEnergy}&nbsp;kL/Year
                                                </StatusIndicator>
                                            </div>
                                        </ColumnLayout>
                                    </ExpandableSection>
                                    <ExpandableSection expanded={costsExpend} headerText='Project Costs & Expenses'
                                                       onChange={({detail}) => handleCostsExpendChange(detail)}>
                                        <ColumnLayout columns={3}>
                                            <div>
                                                <Box variant="awsui-key-label">Time to payback</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.Payback}&nbsp;Months
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Effort (Implement)</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.ImplementEffort}&nbsp;Man hours
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Effort (Ongoing)</Box>

                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.OngoingEffort}&nbsp;Man hours/Year
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Capital costs</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.CapitalCosts}&nbsp;$USD
                                                </StatusIndicator>
                                            </div>
                                            <div>
                                                <Box variant="awsui-key-label">Operational costs</Box>
                                                <StatusIndicator
                                                    type={details.OutcomesCostsStatus !== 'Unapproved' ? 'success' : 'error'}>
                                                    {details.Unit.OperationalCosts}&nbsp;$USD/Year
                                                </StatusIndicator>
                                            </div>
                                        </ColumnLayout>
                                    </ExpandableSection>
                                </Box> :
                                <Box>
                                    <FormField stretch={true}
                                               label={<div>Project Outcomes:
                                               </div>}>
                                        <ColumnLayout columns={3}>
                                            <InputNumberFormField
                                                label={"Financial Savings"} inputValue={distributionPanelData.Financial}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('Financial', detail.value)}
                                                constraintText={'Enter energy savings'} unit={"$USD/Year"}/>
                                            <InputNumberFormField
                                                label={"Energy Efficiency"}
                                                inputValue={distributionPanelData.EnergyEfficiency}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('EnergyEfficiency', detail.value)}
                                                constraintText={'Enter energy savings'} unit={"kWh/Year"}/>
                                            <InputNumberFormField
                                                label={"Carbon footprint"}
                                                inputValue={distributionPanelData.CarbonFootprint}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('CarbonFootprint', detail.value)}
                                                constraintText={'Enter greenhouse gas savings'}
                                                unit={"Tonnes CO₂e/Year"}/>
                                            <InputNumberFormField
                                                label={"Water efficiency"}
                                                inputValue={distributionPanelData.WaterEfficiency}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('WaterEfficiency', detail.value)}
                                                constraintText={'Enter water savings'} unit={"m³/Year"}/>
                                            <InputNumberFormField
                                                label={"Waste recycling & reduction"}
                                                inputValue={distributionPanelData.WasteRecycling}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('WasteRecycling', detail.value)}
                                                constraintText={'Enter waste savings'} unit={"Kg/Year"}/>
                                            <InputNumberFormField
                                                label={"Energy Efficiency"}
                                                inputValue={distributionPanelData.DieselEnergy}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('DieselEnergy', detail.value)}
                                                constraintText={'Enter diesel savings'} unit={"kL/Year"}/>
                                        </ColumnLayout>
                                    </FormField>
                                    <FormField stretch={true}
                                               label={<div>Project Costs:</div>}>
                                        <ColumnLayout columns={3}>
                                            <InputNumberFormField
                                                label={"Time to payback"} inputValue={distributionPanelData.Payback}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('Payback', detail.value)}
                                                constraintText={'Enter simple payback'} unit={"Months"}/>
                                            <InputNumberFormField
                                                label={"Effort (Implement)"}
                                                inputValue={distributionPanelData.ImplementEffort}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('ImplementEffort', detail.value)}
                                                constraintText={'Enter staff time'} unit={"Man hours"}/>
                                            <InputNumberFormField
                                                label={"Effort (Ongoing)"}
                                                inputValue={distributionPanelData.OngoingEffort}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('OngoingEffort', detail.value)}
                                                constraintText={'Enter staff time'} unit={"Man hours/Year"}/>
                                            <InputNumberFormField
                                                label={"Capital costs"} inputValue={distributionPanelData.CapitalCosts}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('CapitalCosts', detail.value)}
                                                constraintText={'Enter costs'} unit={"$USD"}/>
                                            <InputNumberFormField
                                                label={"Operational costs"}
                                                inputValue={distributionPanelData.OperationalCosts}
                                                errorValue={""}
                                                onChange={({detail}) => onChange('OperationalCosts', detail.value)}
                                                constraintText={'Enter costs'} unit={"$USD/Year"}/>
                                        </ColumnLayout>
                                    </FormField>
                                </Box>
                            }
                        </SpaceBetween>
                    </Form>
                    <SustainabilityDetailTab Comments={details.Comments} loginUser={props.loginUser}
                                             CommentsValue={CommentsValue}
                                             CommentsonChange={(event) => CommentsonChange(event.detail.value)}
                                             CommentsReset={() => CommentsonChange("")}
                                             CommentsSubmit={CommentsSubmit}
                                             MarkDownValue={markDownValue}
                                             setMarkDownValue={({detail}) => setMarkDownValue(detail.checked)}
                                             updateDetail={() => props.updateDetail()}
                                             sustainability_id={details.id} updateInformation={updateInformation}
                                             OwnerOptions={['ayunlong']} onChangeMention={onChangeMention}
                                             AreaInvalid={areaInvalid}
                                             WorkLogs={[]} SubmitLoading={submitLoading} ItemState={'Approved'}
                                             PromoteProps={details.PromoteProps}
                                             ChildrenList={details.hasOwnProperty('ChildrenList') ? details.ChildrenList : []}
                                             ParentId={details.hasOwnProperty('ParentId') ? details.ParentId : null}
                    />
                </SpaceBetween>
            </TextContent>
        </SpaceBetween>
    )
}