import {useState} from "react";
import DateRangePicker, {DateRangePickerProps} from "@amzn/awsui-components-react/polaris/date-range-picker";
import {DateRangePicker_DEFINITIONS} from "./i18nStrings";

export interface DateRangePickerLayoutProps {
    onChange: (value: any) => void
    value: any
    relativeOptions: any
    rangeSelectorMode: any
}

export const dateRangeValidator = (range: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
    if (range !== null && range.type === 'absolute') {
        const [startDateWithoutTime] = range.startDate.split('T');
        const [endDateWithoutTime] = range.endDate.split('T');
        if (!startDateWithoutTime || !endDateWithoutTime) {
            return {
                valid: false,
                errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.',
            };
        }
        const dateDiff = new Date(range.startDate).valueOf() - new Date(range.endDate).valueOf();
        if (dateDiff > 0) {
            return {
                valid: false,
                errorMessage: 'The selected date range is invalid. The start date must be before the end date.',
            };
        }
    }
    return {valid: true};
};
export default (props: DateRangePickerLayoutProps) => {
    return (
        <DateRangePicker
            onChange={props.onChange}
            value={props.value}
            relativeOptions={props.relativeOptions}
            i18nStrings={DateRangePicker_DEFINITIONS}
            locale='en-US'
            placeholder="Filter by a date and time range"
            // isValidRange={() => ({valid: true})}
            isValidRange={(range) =>
                dateRangeValidator(range)}
            rangeSelectorMode={props.rangeSelectorMode}
        />
    );
}