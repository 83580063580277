import React, {useEffect, useState, useRef} from 'react';
import './index.scss';
import Tribute from "tributejs";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import axios from 'axios';

export const htmlEscape = (html: string) => {
    return html.replace(/[<>"&]/g, function (match, pos, originalText) {
        switch (match) {
            case "<":
                return "&lt;";
            case ">":
                return "&gt;"
            case "&":
                return "&amp;";
            case "\"":
                return "&quot;";
            default:
                return match;
        }
    });
}
export const getDataOfEditorMultiple = () => {
    const childrenData = document.getElementById('editorMultiple')?.innerHTML || '';
    console.log('childrenData', childrenData)
    const toServiceData = htmlEscape(childrenData);
    console.log('toServiceData', toServiceData)
}

export interface DemoProps {
    onChange: () => void
    userList: any[]
    poundList: any[]
}

export function AtDemo(props: DemoProps) {
    let backUrl: any = null
    const pasteFunction = (e) => {
        e.preventDefault();
        let blob = null
        let text;
        let clp = (e.originalEvent || e).clipboardData
        console.log(clp.getData("text"))
        if (clp) {
            if (clp.types[0] == 'Files') {
                blob = clp.items[0].getAsFile()
                // @ts-ignore
                let fileType = blob['name'].split('.')[1]
                // @ts-ignore
                let fileName = blob['name'].split('.')[0]
                console.log(fileName)
                console.log(fileType)
                axios({
                    method: "POST",
                    data: blob,
                    url: "https://esam9karql.execute-api.us-east-2.amazonaws.com/jwt/commentsfile/" + fileName + '/' + fileType,
                }).then(res => {
                    console.log(res.data)
                    backUrl = ('https://test-wide.s3.us-east-2.amazonaws.com/' + res.data)
                    window.open('https://test-wide.s3.us-east-2.amazonaws.com/' + res.data)
                    document.execCommand('insertText', false, '![image](https://test-wide.s3.us-east-2.amazonaws.com/' + res.data + ')');
                })
            } else {
                text = clp.getData('text/plain') || "";
                if (text !== "") {
                    document.execCommand('insertText', false, text);
                }
            }
        } else {
            text = e.clipboardData.getData("text") || "";
            if (text !== "") {
                if (e.getSelection) {
                    // 针对于ie11 10 9 safari
                    var newNode = document.createElement("span");
                    newNode.innerHTML = text;
                    e.getSelection().getRangeAt(0).insertNode(newNode);
                } else {
                    // 兼容ie10 9 8 7 6 5
                    e.selection.createRange().pasteHTML(text);
                }
            }
        }
    }
    useEffect(() => {
        // @ts-ignore
        document.getElementById('editorMultiple').addEventListener('paste', e => pasteFunction(e));
        return () => {
            if (document.getElementById('editorMultiple')) {
                // @ts-ignore
                document.getElementById('editorMultiple').removeEventListener('paste', e => pasteFunction(e));
            }
        };
    }, [document])

    useEffect(() => {
        renderEditor(props.userList, props.poundList);
    }, [props.userList])
    const renderEditor = (_atList: any[], _poundList: any[]) => {
        let tributeMultipleTriggers = new Tribute({
            allowSpaces: true,
            noMatchTemplate: function (): any {
                return null;
            },
            searchOpts: {
                pre: '<div>',
                post: '</div>',
                skip: false // true will skip local search, useful if doing server-side search
            },
            collection: [
                {
                    trigger: "@",
                    selectTemplate: function (item) {
                        return (
                            `<span contenteditable="false"><span class="at-item">@${item.original.value}</span></span>`
                        );
                    },
                    values: _atList,
                    menuItemTemplate: function (item) {
                        return '<div style="display: flex;align-items: center;"><img class="css-user-token" src="https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + item.original.value + '"><div class="am-font">' + item.original.label + '</div></div>'
                    },
                    lookup: "value"
                },
                {
                    trigger: "#",
                    selectTemplate: function (item) {
                        return (
                            `<span contenteditable="false"><span class="pound-item">#${item.original.value}</span></span>`
                        );
                    },
                    values: _poundList,
                    menuItemTemplate: function (item) {
                        return '<span>' + item.original.value + '</span>';
                    },
                    lookup: "value"
                }
            ]
        });

        tributeMultipleTriggers.attach(document.getElementById("editorMultiple") as HTMLElement);
    }

    return (
        <TextContent>
            <div className="at-demo">
                <div
                    id="editorMultiple"
                    className="tribute-demo-input"
                    placeholder=""
                    onInput={props.onChange}
                ></div>
            </div>
        </TextContent>
    )
}